// OH mixin

//  All Buttons mixin scss
@import "./buttons";


//  All mixin
@mixin maincontainer {
  max-width: 100%;
  margin: auto;

 }

 @mixin heading1 {

  font-size: $az-font-66;
  // font-size: $az-font-32;
  font-family: $az-kini-heading-three;
  color: $az-black;

  @media (max-width:767px) {

    font-size: $az-font-26;
  }

}


 @mixin heading2 {

  font-size: $az-font-40;
  // font-size: $az-font-32;
  font-family: $az-kini-heading-three;
  color: $az-black;

  @media (max-width:767px) {

    font-size: $az-font-26;
  }

}

 @mixin heading3 {

  font-size: $az-font-32;
  // font-size: $az-font-32;
  font-family: $az-kini-heading-three;
  color: $az-black;

  @media (max-width:767px) {

    font-size: $az-font-26;
  }

}

 @mixin heading4 {
  font-size: $az-font-24;
  // font-size: $az-font-30;
  // font-family: $az-kini-heading-three;
  font-family: $az-heading-two;
  color: $az-black;

 }


@mixin heading5 {

  font-size: $az-font-16;
  // font-size: $az-font-32;
  font-family: $az-kini-heading-three;
  color: $az-black;

  @media (max-width:767px) {

    font-size: $az-font-26;
  }

}

@mixin heading6 {
  font-size: $az-font-18;
  // font-size: $az-font-18;
  font-family: $az-heading-para;
  color: $az-black;

  @media (max-width:1240px) {

    font-size: $az-font-18;
  }

  @media (max-width:991px) {

    font-size: $az-font-18;
  }

  @media (max-width:767px) {

    font-size: $az-font-18;
  }

}

@mixin p {
  font-size: $az-font-16;
  font-family: $az-heading-two;
  color: $az-black;

}

@mixin borderOne {

  border-bottom: 1px solid $az-oh-seconday;

}


@mixin OHtopHead {

  background: none !important;

    .topAleart{
       background: $az-oh-black;

        @media (max-width:767px) {
        .container {
            // padding: 0 !important;
            // max-width: fit-content !important;
            padding: 0 15px !important;
        }
      }

      .alert-primary {
        background: transparent;
        border-radius: 0;
        border: none;
        outline:none;
        padding-top: 40px;
        padding-bottom: 40px;

        @media (max-width:1366px) {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }

        @media (max-width:1280px) {
          padding-left: 10px !important;
          padding-right: 10px !important;
          padding-top: 30px !important;
          padding-bottom: 30px !important;

        }
        @media (max-width:1240px) {
          padding-left: 10px !important;
          padding-right: 10px !important;
          padding-top: 30px !important;
          padding-bottom: 30px !important;
        }

        @media (max-width:991px) {
          padding: 10px 10px 15px 0 !important;
          flex-wrap: nowrap !important;
          align-items: flex-end !important;
        }

        @media (max-width:767px) {
          padding: 10px 0px 15px 0 !important;
          flex-wrap: nowrap !important;
          align-items: flex-end !important;

        }

          }
      h3 {
        color: $az-white;
        font-family: $az-kini-heading-three;
        padding: 0;
        margin: 0;
        font-size: $az-font-47;
        position: relative;
        top: 0px;
        text-transform: inherit;

        @media (max-width:1240px) {

          font-size: $az-font-36;

        }

      }
      span {
        border-left: 1px solid $az-oh-seconday;
         h4 {
          color: $az-white;
          font-family: $az-heading-two;
          padding: 0;
          margin: 0;
          font-size: $az-font-24;
          font-weight: normal;
          margin-bottom: -3px;
          text-transform: inherit;
          text-align: left;

          @media (max-width:1280px) {

            font-size: $az-font-20;

          }
          @media (max-width:1240px) {

            font-size: $az-font-18;

          }

        }
        p {
          color: $az-white;
          font-family: $az-heading-light;
          padding: 0;
          margin: 0;
          font-size: $az-font-24;
          font-weight: normal;
          text-align: left;
        }
      }

    a.text-decoration-none {

      @media (max-width:991px) {
        text-align:  center !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 15px;
      }
        @media (max-width:767px) {
          text-align:  center !important;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 15px;
        }

          button {
            @include OHprimaybutton;

            @media (max-width:1240px) {

              font-size: $az-font-20;
              height: 48px;
              padding: 9px 20px 10px;

            }
              @media (max-width:991px) {
                margin: auto !important;
                font-size: $az-font-18;
                height: 48px;
                padding: 9px 20px 10px;
          }
            @media (max-width:767px) {
              margin: auto !important;
              font-size: $az-font-11;
              height: 35px;
              padding: 7px 3px 5px;
              width: 130px;
              text-align: center;
              justify-content: center;
           }

          }
        }
      .btn-close {
        background-image: url(../../images/oh/icon-close.svg);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 0;
        opacity: 1;
        top: 41%;
        right: 0px;
        height: 30px;
        width: 30px;
        border:none;
        background-color: transparent;
        margin-right: 0px;
        &:hover, &:focus {
          box-shadow: none;
          outline: none;
          border: none;
      }

      @media (max-width:1240px) {
        top: 41%;
        right: 5%;
        }
      @media (max-width:1199px) {
        top: 41%;
        right: 5%;
        }
        @media (max-width:991px) {
          top: 9%;
          right: 12px;
          }
        @media (max-width:767px) {
                top: 13%;
                right: 0%;
                width: 18px;
        }
    }

       .Mobview1 {

        // @media (max-width:1199px) {
        //   flex-direction: column;
        //   flex-wrap: nowrap !important;
        //   justify-content: flex-start;
        //   align-items: start !important;
        //   padding-left: 15px;

        //   h3 { font-size: $az-font-20; }
        //   span {
        //     margin: auto !important;
        //     padding: 0 15px 15px 0 !important;
        //     border-left: 0;
        //     h4 {
        //         font-size: $az-font-16;
        //     }
        //     p {
        //       font-size: $az-font-16;
        //     }
        //   }

        //  }

        @media (max-width:991px) {
          flex-direction: column;
          flex-wrap: nowrap !important;
          justify-content: flex-start;
          align-items: start !important;
          padding-left: 5px;

          h3 { font-size: $az-font-20; }
          span {
            margin: auto !important;
            padding: 0 15px 15px 0 !important;
            border-left: 0;
            h4 {
                font-size: $az-font-16;
            }
            p {
              font-size: $az-font-16;
            }
          }

         }

         @media (max-width:767px) {
          flex-direction: column;
          flex-wrap: nowrap !important;
          justify-content: flex-start;
          align-items: start !important;
          padding-left: 0px;

          h3 { font-size: $az-font-16; }
          span {
            margin: auto !important;
            padding: 0 10px 2px 0 !important;
            border-left: 0;
            h4 {
                font-size: $az-font-11;
            }
            p {
              font-size: $az-font-16;
            }
          }

         }




       }
      }

      header{
        background: $az-white;
        padding: 40px 0;

        .container {

          @media (max-width:991px) {
            padding: 0 !important;
            max-width: fit-content;
           }

          @media (max-width:767px) {
           padding: 0 !important;
           max-width: fit-content;
          }
        }

        a.logo{
          // overflow: hidden;
          text-align: center;
          max-width: 100%;
          max-height: 135px;
          min-height: 135px;
          display: flex;
          margin: 0;
          align-items: center;
          overflow: hidden;
        img.logo {
          max-width: 165px;
          // max-height: 135px;
          object-fit: contain;
          min-width: 135px;
          max-height: 135px;

          @media (max-width:1240px) {
            max-width: 160px;
          }
          @media (max-width:991px) {
            max-width: 150px;
          }

          @media (max-width:767px) {
            max-width: 150px;
          }

        }
      }
        h5.toll-free{
          color: $az-oh-black;
          font-family: $az-kini-heading-three;
          padding: 0;
          margin: 0;
          font-size: $az-font-22;
          font-weight: normal;
          margin-bottom: -3px;

          @media (max-width:1240px) {
            font-size: $az-font-20;
          }

        }

        button {
          @include OHsecondarybutton;
        }
        .menu {
            a{
              border-top: 2px solid  $az-oh-black;
              border-bottom: 2px solid  $az-oh-black;
              padding: 4px;
              width: 60px;
              display: block;
              position: relative;
              margin-top: -16px;
              &::after {
                position: absolute;
                padding: 4px;
                width: 60px;
                content: '';
                border-bottom: 2px solid  $az-oh-black;
                left: 0;
                top: 10px;
              }
            }
         }
         .bm-overlay {
          top:0 !important;
          transform: translate3d(50%, 0px, 0px) !important;
          transition: 0s !important;
          opacity: 0 !important;



        }
        .bm-menu-wrap {
          top:0 !important;
          width: 20% !important;
          transform: translate3d(0%, 0px, 0px) !important;
          transition: inherit !important;
          background: rgba(29, 29, 27, 0.5);

          .bm-menu {
              padding: 67px 30px;
            a {
              color: $az-oh-seconday;
              font-family: $az-heading-one;
              font-size: $az-font-32;
            }
          }

          .bm-cross-button {
            width: 20px !important;
            height: 20px !important;
            right: 25px !important;
            top: 38px !important;
          }
        }

        .mobviewHeader {

          @media (max-width:991px) {
            padding-left: 0 !important;
            justify-content: center !important;
            padding-right: 0 !important;
          }
          @media (max-width:767px) {
            padding-left: 0 !important;
            justify-content: center !important;
            padding-right: 0 !important;
          }
          .deskviewTop {
            display: block;
            padding-right: 15px !important;

            @media (max-width:1240px) {
              padding-left: 70px !important;
            }

            @media (max-width:991px) {
              display: none !important;
            }

            @media (max-width:767px) {
              display: none !important;
            }

          }
          .mobkviewTop {

            display: none;
            @media (max-width:991px) {
              display: block;
              position: absolute;
              right: 5% !important;

             }
            @media (max-width:767px) {
              display: block;
              position: absolute;
              right: 5% !important;
            }

          }
        }

        .mobviewHeader {

          padding-left: 0px !important;
          padding-right: 0px !important;

          @media (max-width:1366px) {
            padding-left: 0px !important;
            padding-right: 0px !important;
          }
          @media (max-width:1280px) {
            padding-left: 30px !important;
            padding-right: 30px !important;
          }
        }
      }

}


@mixin Banner {
  min-height: 712px;
  height: 712px;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  h1 {
      color: $az-white;
      font-family: $az-kini-heading-three;
      margin-top: 100px;
      font-size: $az-font-75;
      text-align: center;
      line-height: 1;
      padding: 0 215px;
      margin-bottom: 40px;
      text-transform: inherit;
      position: relative;
      z-index: 1;

      @media (max-width:1240px) {
        font-size: $az-font-70;
        padding: 0 30px;
      }

      @media (max-width:1023px) {
        font-size: $az-font-50;
        padding: 0 90px;
      }

      @media (max-width:991px) {
        font-size: $az-font-60;
        padding: 0 15px;
      }

      @media (max-width:767px) {
        font-size: $az-font-36;
        padding: 0 15px;
        margin: 5px  0 15px;
      }

  }
  p {
    position: relative;
    z-index: 1;
  }
  button {
      @include OHprimaybutton-lg;
      position: relative;
      z-index: 1;
  }
  &:after {
    background-color: rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    content: '';
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    transform: translate(0, 0px) !important;
    bottom: 0 !important;
    z-index: 0 !important;
  }
}


@mixin blockOne {
  .Left {
  background: $az-oh-seconday;
  padding: 0 155px;
  min-height: 580px;
  height: 580px;

   @media (max-width:1440px) {
      padding: 0 130px;
  }

  @media (max-width:1280px){
    padding: 0 50px 0 85px !important;

  }

  @media (max-width:1240px) {
    padding: 0 30px !important;
    min-height: 520px;
 }

  @media (max-width:991px) {
    padding: 0 30px !important;
    min-height: 420px;
  }
  @media (max-width:767px) {
    padding: 0 30px !important;
    min-height: 350px;
  }

  h2 {
    color: $az-oh-black;
    font-family: $az-kini-heading-three;
    margin: 0;
    font-size: $az-font-60;
    text-align: left;
    line-height: 1;
    padding: 0 145px 0 0;
    margin-bottom: 19px;
    text-transform: inherit;

    @media (max-width:1240px) {
      font-size: $az-font-50;

    }
    @media (max-width:991px) {
      font-size: $az-font-32;

    }
    @media (max-width:767px) {
      font-size: $az-font-30;

    }

    sup {  font-size: 45%;
      top: -5px;
      vertical-align: revert;
      left: 0;
    }
  }

  p {
      color: $az-black;
      font-family: $az-heading-two;
      padding: 0;
      margin: 0;
      font-size: $az-font-17;
      text-align: left;
      line-height: 1.2;
      padding: 10px 0 0;
      margin-bottom: 38px;

      @media (max-width:1366px){
        font-size: $az-font-16;

      }

      @media (max-width:1280px){
        padding: 0px 80px 0 0;

      }
      @media (max-width:767px) {
        text-align: justify;
        font-size: $az-font-14;
        padding: 0px;
      }
  }

    button {
      @include OHprimaybutton-md;
    }
  }
  .right {
    background-image: url("./../../images/professionally-img.png");
    // background-image: url(https://did1d8jg9ar6w.cloudfront.net/website/kini/professionally-img.png);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: 100% 100%;

    @media (max-width:1366px){
      background-size: 100% 100%;

    }

    @media (max-width:767px){
      background-size: 100% 100%;
      min-height: 300px;
      height: 300px;
      max-height: 300px;
    }



  }
  .pd-r-10 {
    @media (max-width:991px) {
      padding-right: 0 !important;

    }
    @media (max-width:767px) {
      padding-right: 0 !important;

    }
   }

   @media (max-width:991px) {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
 }

   @media (max-width:767px) {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
   }

}
 @mixin fullblock {
   background: $az-oh-black;
   padding: 118px 70px ;
   position: relative;
   @media (max-width:1366px) {
      padding: 118px 70px;
    }
    @media (max-width:1280px) {
      padding: 120px 20px 80px ;
    }
    @media (max-width:1023px) {
      padding: 70px 20px 40px ;
    }
    @media (max-width:991px) {
      padding: 76px 15px 35px;
    }
    @media (max-width:991px) {
      padding: 76px 15px 35px;
    }

    @media (max-width:767px) {
      padding: 76px 15px 35px;
    }

    h2 {
      color:$az-white;
      font-family: $az-kini-heading-three;
      padding: 0;
      margin: 0;
      font-size: $az-font-71 !important;
      text-align: center;
      line-height: 1;
      padding: 0 20px;
      margin-bottom: 30px;
      text-transform: inherit !important;

      @media (max-width:1366px) {
          font-size: $az-font-71;
        }
        @media (max-width:1240px) {
          font-size: $az-font-65 !important;
          line-height: 1;
        }

        @media (max-width:1023px) {

          font-size: $az-font-50 !important;
          line-height: 1;
        }

        @media (max-width:991px) {
          font-size: $az-font-32 !important;
          line-height: 1.5;
        }

        @media (max-width:767px) {
          font-size: $az-font-26 !important;
          line-height: 1.5;
          color: $az-white;
        }

    }

    // &::before {
    //   position: absolute;
    //   left: 0;
    //   content: '';
    //   background-image: url(../../images/kini/shape-2.png);
    //   bottom: 0;
    //   width: 216px;
    //   height: 324px;
    // }
    // &::after {
    //   position: absolute;
    //   right: 0;
    //   content: '';
    //   background-image: url(../../images/kini/shape-1.png);
    //   bottom: 0;
    //   width: 216px;
    //   height: 324px;
    // }

 }

 @mixin footer {
  // background: $az-kini-seconday;
  // padding: 50px 0 0px;

   .f-logo{
      max-width: 216px;
      object-fit: contain;
      min-width: 135px;
      max-height: 135px;

      @media (max-width:1023px){
        max-width: 110px;
      }
      @media (max-width:767px){
        max-width: 104px;
        margin-bottom: 20px;
      }
   }

   .f-border{
     border-right: 1px solid $az-black;
     padding-right: 15px;
     display: flex;
     max-height: 135px !important;
     min-height: 135px !important;
     flex-wrap: wrap;
     align-items: center;
     justify-content: center;
     overflow: hidden;
     @media (max-width:991px){
          border-right: none !important;
          height: auto !important;
          padding-right: 10px !important;
    }
      @media (max-width:767px){
            border-right: none !important;
            height: auto !important;
            padding-right: 10px !important;


    }

      ul{
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        @media (max-width:991px){
          display: flex;
          width: 100%;
          margin: auto;
          flex-wrap: wrap;
        }
        @media (max-width:767px){
          display: flex;
          width: 100%;
          margin: auto;
        }



        li {
            margin: 0 10px;
            color: $az-oh-black;
            font-family: $az-heading-two;
            font-size: $az-font-18;

            @media( max-width:1240px) {

              font-size: $az-font-16;
            }

            @media (max-width:991px){
              padding: 0 30px 0 15px;
              min-width: 160px;
              max-width: 160px;
            }
            @media (max-width:767px){
              padding: 0 10px 0 15px;
              min-width: 135px;
              max-width: 135px;
            }

          a {
            color: $az-oh-black;
            font-family: $az-heading-two;
            font-size: $az-font-18;
            text-decoration: none;
            padding: 8px 0px;
            display: block;
            img {
                  // filter: invert(2);
            }

            @media( max-width:1240px) {

              font-size: $az-font-16;
            }

            @media( max-width:767px) {

              font-size: $az-font-16;
            }
          }

          &:nth-child(1) {

            a {
              img {width: 29px;}
            }

          }
          &:nth-child(2) {

            a {
              img {width: 26px;}
            }

          }
        }
      }

   }
   .border-right-0 { border-right: 0px; height: 100%;


    @media( max-width:1023px) {

      padding-left: 0 !important;
    }


  }

   .footer-bottom {
      // background: $az-oh-black;
      padding: 0;
      // width: 100%;
      // margin-top: 38px;

      // @media (max-width:767px) {
      //   padding: 12px 0 !important;

      // }
   }
   .moblastFoot {


    @media (max-width:991px) {
      padding-left: 0 !important;

    }
    @media (max-width:767px) {
      padding-left: 10px !important;

    }
    ul {
      @media (max-width:991px) {
        margin-top: 30px !important;

       }
       @media (max-width:767px) {
         margin-top: 30px !important;

       }
    li {
      @media (max-width:991px) {
        min-width: 20px;
        max-width: 20px;
        padding: 0 5px;

      }
      @media (max-width:767px) {
        min-width: 20px;
        max-width: 20px;

      }
    }
    &:last-child {

      margin-top: 5px !important;

      @media (max-width:1280px) {
        margin-top: 5px !important;

      }
      @media (max-width:991px) {
       margin-top: 15px !important;

      }
      @media (max-width:767px) {
        margin-top: 15px !important;

      }

      li {
        @media (max-width:991px) {
          min-width: 130px;
          max-width: 130px;
          padding-right: 0;

        }
        @media (max-width:767px) {
          min-width: 145px;
          max-width: 145px;
          padding-right: 0;
          font-size: $az-font-16;

        }
        &:nth-child(2), &:last-child {

          @media (max-width:991px) {
            min-width: 150px;
            max-width: 150px;
            padding-left: 0;
            margin-left: 15px;
            margin-right: 20px;

          }
          @media (max-width:767px) {
            min-width:150px;
            max-width: 150px;

          }
          img {
            position: relative;
            margin-top: -13px;
          }

        }
      }
    }
    }

    }

    .deskfooter {



      @media( max-width:991px) {
        display: none;
       }
       @media( max-width:767px) {
        display: block;
      }

    }
    .tabletfooter {
        display: none;

       @media( max-width:991px) {
         display: flex;
        }

        @media( max-width:767px) {
          display: none;
        }

    }

 }



 @mixin footertwo {
  background: $az-white;
  padding: 70px 0;

  @media( max-width:1279px) {
    display: none;
  }

  @media( max-width:991px) {
    padding: 30px 0;
    display: none;
  }

  @media( max-width:767px) {
    padding: 30px 0;
    display: none;
  }

   .f-border{
     padding-right: 15px;
     height: 100%;
     display: block;
     border: none;




     ul {


          @media( max-width:767px) {
              flex-wrap: wrap;
              justify-content: center !important;
          }

          li {
            list-style: none;
            width: calc(100% / 5);
            border-right: 1px solid $az-kini-common;
            padding-top: 42px;
            padding-bottom: 42px;

            @media( max-width:767px) {
              width: calc(100% / 2);
              min-width: 50%;
              margin: 0 0 10px;
              padding-top: 5px;
              padding-bottom: 5px;

            }


            &:last-child {
              border-right: none;


            }

            a {
                color: $az-black;
                font-family: $az-heading-one;
                font-size: $az-font-25;
                text-decoration: none;





              }

            }
            li:nth-child(even) {

              @media( max-width:767px) {
               border-right: none;

              }

            }
        }
   }



 }


 // Right Drawer

 @mixin Rightdrawer {
   background: #0000 !important;

 }


 // Form scss

 @mixin Multiselect {

  width: 100% !important;
  z-index: 0;
  label{
    color: $az-black !important;
    font-size: $az-font-14;
    font-family: $az-heading-two;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }

  .MuiInputBase-colorPrimary {
    border-radius: 0;
    fieldset {
      legend {
        margin-left: auto;
        margin-right: auto;
        font-family: $az-heading-two;
      }
    }
  }

  label.MuiFormLabel-filled {
    top: 3px !important;
    transform: translate(-50%, -50%) scale(0.75);
    left: 50%;
    margin-left: 8px;
  }

  label.Mui-focused {
    top: 3px !important;
    transform: translate(-50%, -50%) scale(0.75);
    left: 50%;
    margin-left: 8px;
  }
  .Mui-focused {
    .MuiOutlinedInput-notchedOutline{
      border: 2px solid $az-oh-seconday !important;
    }
  }
  .MuiSelect-select {
    padding: 10px 14px !important;
  }

}


@mixin dropmenuInputbox {

  .dropdown{
    .dropdown-toggle {
      padding: 0;
      background: none !important;
      border-radius: 0;
      border: none !important;
      box-shadow: none !important;
      outline: none;
      width: 100%;
      position: relative;
      z-index: 2;
      &:after {
        margin-left: -20px;
        color: rgba(0, 0, 0, 0.54);
        position: absolute;
        top: 50%;
        transform: translate(0px, -50%);
      }

      &:focus {
        border: none !important;
        box-shadow: none !important;
        outline: none;
      }
      &:active {
        box-shadow: none;
        border: none;
        outline: none;
      }
      &:hover {
        box-shadow: none;
        border: none;
        outline: none;
      }
    }
    .dropdown-item {
      background: none !important;
      &:active {
        background: none !important;
      }
      &:focus {
        background: none !important;
      }
      ul {
        li {
              width: calc(50% - 20px ) !important;
              &:nth-child(2) {
                  width: 20px !important;
                  margin: auto;
              }
          }
      }
    }

    .inputBox{
      vertical-align: middle;
      width: 100%;

      label {
        color: $az-black !important;
        font-size: $az-font-14;
        font-family: $az-heading-two;
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
      }
      label.MuiFormLabel-filled {
        top: 3px !important;
        transform: translate(-50%, -50%) scale(0.75);
        left: 50%;
        margin-left: 8px;
      }

      label.Mui-focused {
        top: 3px !important;
        transform: translate(-50%, -50%) scale(0.75);
        left: 50%;
        margin-left: 8px;
      }
      .Mui-focused {
        .MuiOutlinedInput-notchedOutline{
          border: 2px solid $az-oh-seconday !important;
        }
      }
      .MuiOutlinedInput-input {
        padding: 10px 14px !important;
      }
      .MuiOutlinedInput-root {
        border-radius: 0;

        fieldset {
          legend {
            margin-left: auto;
            margin-right: auto;
            font-family: $az-heading-two;
          }
        }
      }
    }


    .Unitprice{

      label {
        color: $az-black !important;
        font-size: $az-font-14;
        font-family: $az-heading-two;
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
      }
      label.MuiFormLabel-filled {
        top: 3px !important;
        transform: translate(-50%, -50%) scale(0.75);
        left: 50%;
        margin-left: 0px;
        width: auto;
      }

      label.Mui-focused {
         top: 3px !important;
         transform: translate(-50%, -50%) scale(0.75);
         left: 50%;
         margin-left: 0px;
         width: auto;

      }
      .Mui-focused {
        .MuiOutlinedInput-notchedOutline{
          border: 2px solid $az-oh-seconday !important;
          legend{
            width: 50px !important;
            margin-left: 20px;
          }
        }
      }
      legend{
        width: 50px !important;
        margin-left: 20px;
      }
      .MuiInputBase-colorPrimary {
        &:hover {
          legend{
            width: 50px !important;
            margin-left: 20px;
          }
        }
      }
      .MuiOutlinedInput-input {
        padding: 10px 14px !important;
        color: $az-black !important;
        font-size: $az-font-14;
        font-family: $az-heading-two;
      }
      .MuiOutlinedInput-root {
        border-radius: 0;

      }


    }

    .dropdown-menu{
      min-width: 28rem;
      z-index: 1;
      ul {
        li {
          b{
            color: $az-black !important;
            font-size: $az-font-14 !important;
            font-family: $az-heading-two !important;
            font-weight: normal;
            margin: 0 5px;
          }
          span {
            background: $az-oh-seconday;
            display: flex;
            height: 100%;
            align-items: center;
            padding: 5px;
            color: $az-black !important;
            font-size: $az-font-14 !important;
            font-family: $az-heading-two !important;
            min-width: 45px;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
  }

}


@mixin datepiker {
  margin: 8px;
  width: 100%;
  label {
    color: $az-black !important;
    font-size: $az-font-14;
    font-family: $az-heading-two;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
  label.MuiFormLabel-filled {
    top: 3px !important;
    transform: translate(-50%, -50%) scale(0.75);
    left: 50%;
    margin-left: 10px;
    width: auto;
  }

  label.Mui-focused {
     top: 3px !important;
     transform: translate(-50%, -50%) scale(0.75);
     left: 50%;
     margin-left: 10px;
     width: auto;

  }
  .Mui-focused {
    .MuiOutlinedInput-notchedOutline{
      border: 2px solid $az-oh-seconday !important;
    }
  }
  .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    color: $az-black !important;
    font-size: $az-font-14;
    font-family: $az-heading-two;
    text-align: center;
  }
  .MuiOutlinedInput-root {
    border-radius: 0;
    fieldset {
      legend {
        margin-left: auto;
        margin-right: auto;
        font-family: $az-heading-two;
      }
    }
  }

  .MuiFormControl-root {
    width: 100% !important;
    &:last-child{
      width: 300px;
    }
  }

}

 // Tabel

@mixin simpleTable {

  font-family: $az-heading-two;
  font-size: $az-font-16;
  color: $az-black;

  tr {

    @media(max-width:767px) {

      display: block !important;


    }
  }

  th {
    font-family: $az-heading-para;
    font-size: $az-font-16;
    color: $az-black;
    background: $az-oh-seconday;
    border: 1px solid $az-border-color;

    @media(max-width:767px) {
      padding: 10px 15px;
      max-height: 55px;
      min-height: 55px;
      display: flex !important;
      align-items: center;
      max-width: 100%;
      min-width: 100%;
    }
  }
  td {
    font-family: $az-heading-two;
    font-size: $az-font-16;
    color: $az-black;
    border: 1px solid $az-border-color;
    padding: 10px 15px;

    button{
      @include OHprimaybutton-table-btn;
    }

    @media(max-width:767px) {
      padding: 10px 15px;
      max-height: 55px;
      min-height: 55px;
      display: flex !important;
      align-items: center;
      max-width: 100%;
      min-width: 100%;
    }
  }

  thead, tbody {
    max-width: 50%;
    min-width: 50%;
  }

}


//Modal box

@mixin modal-xlg {
 width: 1024px;
 max-width: 1024px;
 @media(max-width:1366px) {
  width: 850px;
  max-width: 850px;
 }
 @media(max-width:991px) {
  width: 500px;
  max-width: 500px;
 }
 @media(max-width:767px) {
  width: 95%;
  max-width:95%;
  margin: auto;
 }
}
@mixin modal-xxlg {
  width: 700px;
  max-width: 700px;
  @media(max-width:1366px) {
   width: 700px;
   max-width: 700px;
  }
  @media(max-width:991px) {
   width: 500px;
   max-width: 500px;
  }
  @media(max-width:767px) {
   width: 95%;
   max-width:95%;
   margin: auto;
  }

  button {

    @include OHprimaybutton-xs;
      text-transform: uppercase;
    }

    @include Multiselect;

    .filterMultiselect {
      margin: 0;
    }
    .MuiSelect-select {
      padding: 16.5px 14px !important;
    }
    .MuiOutlinedInput-input {
      text-align: center;
    }
 }


// common


@mixin block-md {


  @media (max-width:1199px) {
    margin-top: 50px;

}
@media (max-width:991px) {
  margin-top: 0px;

}

@media (max-width:767px) {
  margin-top: 0px;

}

  figure {
    max-width: 100%;
    border: none;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;

    @media (max-width:1199px) {
      min-height:350px;

  }

    @media (max-width:991px) {
      min-height: 400px;
      max-width: 535px;
      justify-content: flex-end;
  }

    @media (max-width:767px) {
      min-height: 400px;
      max-width: 535px;
      justify-content: flex-end;
  }

  @media (max-width:620px) {
    min-height: 340px;
    max-width: 450px;
    justify-content: flex-end;
}


  @media (max-width:540px) {
    min-height: 250px;
    max-width: 330px;
    justify-content: flex-end;
}

@media (max-width:414px) {
  min-height: 210px;
  max-width: 275px;
  justify-content: flex-end;
}

    h2 {
      color: $az-white;
      font-family: $az-kini-heading-three;
      font-size: $az-font-153;
      margin: 0px;
      position: relative;
      top: 0px;
      z-index: 2;

      @media (max-width:991px) {
        font-size: $az-font-140;
        right: -86px;
        color: $az-black;
     }


      @media (max-width:767px) {
        font-size: $az-font-65;
        right: -65px;
        color: $az-black;
    }

    }

  }
  .blockcontent {
     display: flex;
     flex-wrap: wrap;
      h3 {
        color: $az-black;
        font-family: $az-kini-heading-three;
        font-size: $az-font-25;
        margin-bottom: 30px;

        @media (max-width:991px) {
          margin-bottom: 0px;
          font-size: $az-font-28;
          padding-bottom: 10px;
          margin-top: 25px;

        }

        @media (max-width:767px) {
          margin-bottom: 0px;
          font-size: $az-font-19;
          padding-bottom: 10px;
          margin-top: 25px;

        }

      }
      h3.md{
        font-family: $az-heading-two;
      }

     strong{
        color: $az-black;
        font-family: $az-heading-two;
        font-size: $az-font-17;
        margin: 0px;
        text-align: left;
        width: 100%;
        @media (max-width:767px) {
          font-size: $az-font-14;
        }
      }
      b{
        color: $az-black;
        font-family: $az-heading-two;
        font-size: $az-font-17;
        margin: 0px;
        text-align: left;
        display: block;
        font-weight: normal;
      }

      p{
        color: $az-black;
        font-family: $az-heading-two;
        font-size: $az-font-17;
        margin-bottom: 30px;

        @media (max-width:991px) {
          font-size: $az-font-16;
          display: block;
        }

        @media (max-width:767px) {
          font-size: $az-font-14;
          display: block;
        }
      }

  }
  .blockcontentone {


    @media (max-width:991px) {
      padding-left: 0 !important;
      padding-right: 120px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: -140px;
        right: 70px;
        width: 3px;
        height: 130%;
        border-right: 1px solid $az-black;
      }
    }

    @media (max-width:767px) {
      padding-left: 0 !important;
      padding-right: 50px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: -40px;
        right: 27px;
        width: 3px;
        height: 100%;
        border-right: 1px solid $az-black;
      }
    }
  }

  .blockcontenttwo {


    @media (max-width:991px) {

      max-width: 535px;
      margin-left: auto;
      padding-left: 0 !important;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -95px;
        left: -138px;
        width: 3px;
        height: 122%;
        border-right: 1px solid $az-black;
      }

      }

    @media (max-width:767px) {

    max-width: 535px;
    margin-left: auto;
    padding-left: 0 !important;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -40px;
      left: -55px;
      width: 3px;
      height: 100%;
      border-right: 1px solid $az-black;
    }

    }

    @media (max-width:620px) {

      max-width: 450px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -40px;
        left: -55px;
        width: 3px;
        height: 100%;
        border-right: 1px solid $az-black;
      }
  }


    @media (max-width:540px) {

      max-width: 330px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -40px;
        left: -55px;
        width: 3px;
        height: 100%;
        border-right: 1px solid $az-black;
      }
  }

  @media (max-width:414px) {

    max-width: 275px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -40px;
      left: -55px;
      width: 3px;
      height: 100%;
      border-right: 1px solid $az-black;
    }
  }

  }

  .blockcontentthree {


    @media (max-width:991px) {
      padding-left: 0 !important;
      padding-right: 120px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: -140px;
        right: 70px;
        width: 3px;
        height: 130%;
        border-right: 1px solid $az-black;
      }
    }

    @media (max-width:767px) {
      padding-left: 0 !important;
      padding-right: 50px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: -40px;
        right: 27px;
        width: 3px;
        height: 100%;
        border-right: 1px solid $az-black;
      }
    }
  }

}


@mixin daterangerFormate {

  // Date Ranger New

.dialog-date-picker {

  top: 83px !important;
  width: 100%;

  .dialog-header {
    .date-picker-input {
      display: none;
    }
  }

}

  // End Date Ranger New


}

@mixin divider {
    display: block !important;
    background:  $az-gray;
    width: 100%;

}

@mixin DateModalbox {

  // Responsive Date Ranger

  @include daterangerFormate;

  .date-picker{
      padding: 0 !important;
  }

  .react-google-flight-datepicker {
      .date.is-focus{
              &:after {
                  border: 1px solid transparent;
                  border-radius: 12px;
              }
      }
  }
  .react-google-flight-datepicker .date:focus, .react-google-flight-datepicker .date.is-focus {
      background: $az-white !important;
    }
    .react-google-flight-datepicker .date-picker-input {
        height: 81px;
        border-radius: 12px 12px 0 0;
    }
  .react-google-flight-datepicker .date-picker-input svg { display: none; }
  .react-google-flight-datepicker .day.selected.hovered::after { background-color: $az-black;  }
  .react-google-flight-datepicker .day.hovered { background-color: #eaeaea; }
  .react-google-flight-datepicker .day .background-day.last-day { background: linear-gradient(to left, #fff, #eaeaea); }
  .react-google-flight-datepicker .day .background-day.first-day { background: linear-gradient(to right, #fff, #eaeaea); }
  .react-google-flight-datepicker .day.selected.hovered.end { background: linear-gradient(90deg, #eaeaea 50%, #fff 50%);}
  .react-google-flight-datepicker .day.selected.hovered:hover { color: #fff;}
  .react-google-flight-datepicker .day:hover::after { border: 2px solid $az-black; }
  .react-google-flight-datepicker .day.selected.hovered { background: linear-gradient(90deg, #fff 50%, #eaeaea 50%);}
  .react-google-flight-datepicker .calendar-wrapper { margin: 12px 23px 8px;}
  .react-google-flight-datepicker .calendar-content { padding: 0 2px;  transform: inherit !important;}
  .react-google-flight-datepicker .day {width: 39px; height: 39px;}
  .react-google-flight-datepicker .month-calendar { margin-right: 7px !important;}
  .react-google-flight-datepicker .dialog-footer .submit-button { background: $az-black;}
  .react-google-flight-datepicker .day::after {width: 39px; height: 39px;}
  .react-google-flight-datepicker .day.hovered.end {  background: linear-gradient(90deg, #eaeaea 50%, #fff 50%); }
  .react-google-flight-datepicker .day.selected::after { background: $az-black;}
  .react-google-flight-datepicker .day.selected:hover { color: #fff !important;}
  .react-google-flight-datepicker .date:focus::after { border: 1px solid $az-black; border-radius: 12px; }
  .react-google-flight-datepicker .calendar-flippers { align-items: inherit !important; right: inherit; top: -43px; bottom: inherit;}
  .react-google-flight-datepicker .dialog-header { justify-content: end; }
  .react-google-flight-datepicker .date { border-radius: 12px 12px 0 0; }
  .react-google-flight-datepicker .flipper-button { box-shadow: 0px 1px 4px #ddd; height: 30px; width: 30px; -webkit-box-shadow: 0px 1px 4px #ddd; margin: 0 10px;}

  .react-google-flight-datepicker .is-focus:last-child:before {
      height: 100% !important;
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      width: 1px;
      background: #fff;
      z-index: 1;
  }

  .react-google-flight-datepicker .date-picker-date-group::after {
      height: 100% !important;
      content: '';
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(0, -50%);
      width: 1px;
      height: 70%;
      background: #dadce0;
  }

  .react-google-flight-datepicker .date-picker-date-group .date:first-child { position: relative; padding: 40px 13px 20px; font-family: $az-heading-two; font-size: $az-font-16; height: 100% !important;
      border-right: 0;
      border-radius: 12px 0 0;
  }
  .react-google-flight-datepicker .date-picker-date-group .date:first-child::before {
      content: 'CHECK-IN';
      position: absolute;
      left: 14px;
      width: auto;
      height: auto;
      top: 20px;
      background: no-repeat;
      text-align: left;
      font-family: $az-heading-para;
      color: $az-black;
      font-size: $az-font-14;

  }
  .react-google-flight-datepicker .date-picker-date-group .date:last-child { position: relative;  padding: 40px 13px 20px; font-family: $az-heading-two;   height: 100% !important;}
  .react-google-flight-datepicker .date-picker-date-group .date:last-child::before {
      content: 'CHECKOUT';
      position: absolute;
      left: 14px;
      width: auto;
      height: auto;
      top: 10px;
      background: no-repeat;
      text-align: left;
      font-family: $az-heading-para;
      color: $az-black;
      font-size: $az-font-14;
  }
  .react-google-flight-datepicker .selected-date {
      font-size: $az-font-16;
  }

.react-google-flight-datepicker .date.is-focus::after {
  border: 1px solid $az-black !important;
}
.react-google-flight-datepicker .date.is-focus { background: #fff !important;}


@media (max-width: 768px) {
  .react-google-flight-datepicker .dialog-header .date-picker-input {
      height: 60px !important;
  }
  .react-google-flight-datepicker .date.is-focus {
      padding-top: 15px;
      height: 60px !important;
  }

  .react-google-flight-datepicker .date:first-child { height: 60px !important;  padding-top: 15px; position: relative; font-family: $az-heading-two; font-size: $az-font-16; }
  .react-google-flight-datepicker .date:first-child::after {
      content: 'CHECK-IN' !important;
      position: absolute;
      left: 10px;
      width: auto;
      height: auto;
      top: 0px;
      background: no-repeat;
      text-align: left;
      font-family: $az-heading-para;
      color: $az-black;
      font-size: $az-font-12;
      padding-left: 0px;
      padding-top: 4px;


  }
  .react-google-flight-datepicker .is-focus:first-child::after {
      padding-left: 10px;
      padding-top: 4px;
      top: 0 !important;
  }

  .react-google-flight-datepicker .date:last-child { position: relative;  font-family: $az-heading-two; padding-top: 15px;}
  .react-google-flight-datepicker .date:last-child::before {
      content: 'CHECKOUT';
      position: absolute;
      left: 10px;
      width: auto;
      height: auto;
      top: 4px;
      background: no-repeat;
      text-align: left;
      font-family: $az-heading-para;
      color: $az-black;
      font-size: $az-font-12;
  }

  .react-google-flight-datepicker .day.selected::after { background-color: $az-black !important;}
  .react-google-flight-datepicker .day.hovered { background-color: #eaeaea !important; }

  .react-google-flight-datepicker .day.selected.hovered {
      background: linear-gradient(90deg, #fff 50%, #eaeaea 50%) !important;

  }
  .react-google-flight-datepicker .day .background-day.last-day {
      background: linear-gradient(to left, #fff, #eaeaea) !important;

  }
  .react-google-flight-datepicker .day .background-day.first-day {
      background: linear-gradient(to right, #fff, #eaeaea) !important;

  }
  .react-google-flight-datepicker .day.hovered.end {
      background: linear-gradient(90deg, #eaeaea 50%, #fff 50%) !important;
  }
  .react-google-flight-datepicker .dialog-footer .submit-button {
      background: $az-black !important;
  }

  // .react-google-flight-datepicker .date:focus::after {
  //     border: 1px solid $az-black !important;
  // }
  .react-google-flight-datepicker .date:focus {
      background-color: $az-white !important;
  }
  // .react-google-flight-datepicker .date-picker-input {
  //     border: 1px solid #dadce0 !important;
  // }
  .strDetail .guest-availabity .guestfilterNew .react-google-flight-datepicker .date-picker-date-group .date:last-child {
      border-radius: 0px 12px 0 0;
      border-left: 0;
      margin-left: 0;
  }
  .strDetail .guest-availabity .guestfilterNew .react-google-flight-datepicker .date.is-focus:after {
      font-size: $az-font-14;
      padding-top: 10px;
  }
  .strDetail .guest-availabity .guestfilterNew .react-google-flight-datepicker .date:after {
      font-size: $az-font-14;
      padding-top: 10px;
  }
  .react-google-flight-datepicker .selected-date {
      font-size: $az-font-14;
  }

}

.react-google-flight-datepicker .date-picker {

  .dialog-date-picker.open {
    animation: showPopup 0.2s forwards;
    position: relative;
  }
  .dialog-date-picker.hide {
    animation: showPopup 0.2s forwards;
    position: relative;
  }
  .dialog-date-picker{
    position: relative;
    animation: showPopup 0.2s forwards;
    width: 570px;
    transform: inherit;
    top: 0 !important;
    margin-bottom: 0;
    left: 0;
    box-shadow: none;
  }



}

.modal-dialog { max-width: 615px;}

.welcomeClose {
  position: relative;
  z-index: 1;
  right: -12px;
  top: -12px;
}

// End Responsive Date Ranger

}


@mixin guestModalbox {

  .modal-dialog { max-width: 430px;}
  .welcomeClose {
    position: relative;
    z-index: 1;
    right: -12px;
    top: -12px;
  }

  h4 {
    @include heading4;
      font-size: 26px !important;
      font-family: $az-kini-heading-three;
  }

  .dropdown-item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: $az-white !important;
    padding-bottom: 0px;
    cursor: auto;
       ul {
           padding: 0;
           li {
               list-style: none;
               flex-direction: column;
               align-items: start;
               padding-right: 0;
               display: flex;
               h4{
                   font-family: $az-heading-para;
                   font-size: $az-font-15;
                   color: $az-oh-primary;
                   text-align: left;
                   margin-bottom: 3px;
                   display: flex;
                   padding-left: 0;
                   align-items: left;
               }
               h5{
                   font-family: $az-heading-two;
                   font-size: $az-font-14;
                   color: $az-oh-primary;
                   text-align: left;
                   margin-bottom: 0;
                   display: flex;
                   padding-left: 0;
                   align-items: left;
                       a{
                           color: $az-oh-primary;
                       }
                   }
           }
       }
       .countBtn {
           font-family: $az-heading-two;
           font-size: $az-font-18;
           color: $az-oh-primary;
           text-align: left;
           margin-bottom: 0;
           display: flex;
           padding-left: 0;
           align-items: left;

           span {
               button {
                   background: none;
                   border: none !important;
                   // border-radius: 50%;
                   // width: 30px;
                   // height: 30px;
                   font-size: $az-font-24;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   color: $az-gray;

               &:hover {
                        border: none !important;
                       // color: $az-white;
                       // background: $az-oh-primary;
               }

               }
               &:nth-child(2) {
                   margin: 0px 2px;
                   min-width: 20px;
                   text-align: center;
                   max-width: 20px;
               }
               &:first-child {
                   button {
                       padding-top: 0px;
                   }
               }
               &:last-child {
                   button {
                       padding-top: 0px;
                   }
               }
           }
       }

       p{
           font-size: $az-font-14;
           margin-top: 30px;
           margin-bottom: 15px;
           white-space: break-spaces;

           @media (max-width: 767px) {
               white-space: break-spaces;
           }
       }
       a.close{
           color: $az-oh-primary;
           font-size: $az-font-16;
           font-family: $az-heading-two;
           text-align: right;
       }
       button.done {
        background: transparent;
        background: $az-black ;
        color: $az-black ;
        border-radius: 0px;
        font-family: $az-kini-heading-three;
        font-size: $az-font-14;
        padding: 2px 15px;
        width: 75px;
        margin-right: 6px;
        margin-bottom: 10px;
        border-radius: 20px;
        color: #fff;
        letter-spacing: 1px;
        border: none;
       }

       &:active {
         color: $az-black;
       }
    }
}
@mixin secodry-header {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 10px 0;
  background: #ced6d7;
  ul {
    padding: 0;
    list-style: none;
    display: flex;
    margin: 0;
    align-items: center;
    li{
      margin: 0 10px;
      a {
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        height: 30px !important;
        padding: 6px 15px !important;
        text-transform: uppercase !important;
        background: transparent;
        border-radius: 6px;
        transition: 00.2s;
        text-transform: uppercase;
        color: $az-ligh-black;
        text-decoration: none;

        &:hover, &:active {
          background: $az-black !important;
          transition: 00.2s;
          color: $az-white;
        }
      }
      a.active {
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        height: 30px !important;
        padding: 6px 15px !important;
        text-transform: uppercase !important;
        background: $az-black !important;
        transition: 00.2s;
        color: $az-white;
      }
    }
    li.notification-m{
      margin: 0px;
      a {
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        height: 30px !important;
        padding: 6px 0px !important;
        text-transform: uppercase !important;
        background: transparent;
        border-radius: 6px;
        transition: 00.2s;
        text-transform: uppercase;
        color: $az-ligh-black;
        text-decoration: none;
        position: relative;

        &:hover, &:active {
          background: transparent !important;
          transition: 00.2s;
          color: $az-ligh-black;
        }

        span{
          position: absolute;
          width: 18px;
          height: 18px;
          line-height: 17px;
          background: $az-black;
          color: #ffffff;
          text-align: center;
          font-size: 10px;
          font-family: $az-heading-two;
          right: -12px;
          top: 0px;
          border-radius: 50%;
        }
      }
      a.active {
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        height: 30px !important;
        padding: 6px 15px !important;
        text-transform: uppercase !important;
        background: transparent;
        transition: 00.2s;
        color: $az-ligh-black;
      }
    }
    li.notification{
      margin: 0px;

      button {
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        height: 30px !important;
        padding: 6px 0px !important;
        text-transform: uppercase !important;
        background: transparent;
        border-radius: 6px;
        transition: 00.2s;
        text-transform: uppercase;
        color: $az-ligh-black;
        text-decoration: none;
        position: relative;
        border: none;
        box-shadow: none;
        margin-right: 0 !important;

        &:hover, &:active {
          background: transparent !important;
          transition: 00.2s;
          color: $az-ligh-black;
        }

        span{
          position: absolute;
          width: 18px;
          height: 18px;
          line-height: 17px;
          background: $az-black;
          color: #ffffff;
          text-align: center;
          font-size: 10px;
          font-family: $az-heading-two;
          right: -7px;
          top: 0px;
          border-radius: 50%;
        }
      }
      button.active {
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        height: 30px !important;
        padding: 6px 15px !important;
        text-transform: uppercase !important;
        background: transparent;
        transition: 00.2s;
        color: $az-ligh-black;
        border: none;
        box-shadow: none;
      }


    }
  }

  .dropdown {
    margin-left: 0px;
    button {
      background: none;
      padding: 0;
      box-shadow: none;
      border: none;
      &:focus, &:active {
        box-shadow: none;
        border: none;
      }

      figure {
        max-height: 28px;
        min-height: 28px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        width: 28px;
        background: #ffffff;
        border-radius: 50%;
        position: relative;
        margin-right: 10px;
        overflow: hidden;
        img {
          max-height: 28px;
          min-height: 28px;
          width: 28px;
        }


      }



      figure.user {
        max-height: 28px;
        min-height: 28px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        width: 28px;
        background: $az-white;
        border-radius: 50%;
        position: relative;
        margin-right: 0px;

        img {
            max-height: 28px;
            min-height: 28px;
            width: 28px;
        }

        .msg{
            position: absolute;
            width: 15px;
            height: 15px;
            line-height: 15px;
            background: $az-light-black;
            color: $az-white;
            text-align: center;
            font-size: $az-font-10;
            font-family: $az-heading-two;
            right: -6px;
            top: -5px;
            border-radius: 50%;
        }

    }
    &::after {
      display: none;
    }
    }
    .dropdown-toggle {
      position: relative;
      background: none;
      border: none;
      margin-left: 10px;
      &::after {
        background-image: url(../../images/kinibrand-img/menu-nav.svg);
        background-repeat: no-repeat;
        border: 0;
        width: 30px;
        height: 30px;
      }
    }
    .dropdown-menu.show {
      min-width: 12rem;
      margin-top: 7px;
      .dropdown-item {

         b {
           font-weight: normal;
         }
         b.aleart {
          font-weight: normal;
          position: relative;
         &::after {
           content: '';
           position: absolute;
           width: 5px;
           height: 5px;
           border-radius: 50%;
           background: $az-kini-primary-two;
           right: -6px;
           top: 5px;
         }
         }

      }

      .dropdown-item:hover, .dropdown-item:focus {
        background: $az-ligh-black;
        font-family: $az-heading-two;
        color: $az-white;
        border: none;
        box-shadow: none;

        b {
          font-weight: normal;
        }
        b.aleart {
         font-weight: normal;
         position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: $az-white;
          right: -6px;
          top: 5px;
        }
        }
       }
     }
  }
  .show .dropdown-toggle {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}

// KINI corporate brand page style

@mixin brandContainer {
  max-width: 1440px;
}
@mixin navigationMenu {
  display: flex;
  justify-content: space-between;
  figure {
    max-height: 61px;
    min-height: 61px;
    margin-bottom: 0;
    display: flex;
    align-items: center;

  }
  hr {
    border-top: 1px solid;
    opacity: 0.15;
  }
  ul {
    padding: 0;
    list-style: none;
    display: flex;
    margin: 0;
    align-items: center;
    flex-wrap: wrap;
    @media(max-width: 991px) {
      margin: 6px 10px 0 0;
    }
    @media(max-width: 767px) {
      margin: 6px 10px 0 0;
    }
    li{
      margin: 0 10px;
      a {
        font-family: $az-heading-two ;
        font-size: $az-font-14;
        // height: 30px ;
        padding: 7px 10px;
        background: transparent;
        transition: 00.2s;
        color: $az-ligh-black;
        text-decoration: none;
        letter-spacing: 0em;
        font-weight: normal;
        border-radius:50px ;
        display: block;
        &:hover, &:active {
          background: #f7f7f7;
          transition: 00.2s;

        }
      }
      a.active {
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        height: 30px !important;
        padding: 12px !important;
        background:  transparent !important;
        transition: 00.2s;
      }

      &:first-child {
         a {
          padding: 12px 10px;
          font-size: $az-font-16;
          color: $az-black;
         }
      }
    }
    li.notification-m{
      margin: 0px;
      a {
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        height: 30px !important;
        padding: 6px 0px !important;
        text-transform: uppercase !important;
        background: transparent;
        border:0;
        transition: 00.2s;
        text-transform: uppercase;
        color: $az-ligh-black;
        text-decoration: none;
        position: relative;

        &:hover, &:active {
          background: transparent !important;
          transition: 00.2s;
          color: $az-ligh-black;
          border: none;
          box-shadow: none;
        }

        span{
          position: absolute;
          width: 18px;
          height: 18px;
          line-height: 18px;
          background: $az-ligh-black;
          color: #ffffff;
          text-align: center;
          font-size: 10px;
          font-family: Lato-Regular;
          right: -12px;
          top: 0px;
          border-radius: 50%;
        }

        img {
          filter: invert(1) brightness(0.4);
        }
      }
      a.active {
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        height: 30px !important;
        padding: 6px 15px !important;
        text-transform: uppercase !important;
        background: transparent;
        transition: 00.2s;
        color: $az-ligh-black;

        &:hover, &:active {
          background: transparent !important;
          transition: 00.2s;
          color: $az-ligh-black;
          border: none;
          box-shadow: none;
        }
      }
    }
    li.notification{
      margin: 0px;
      button {
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        height: 30px !important;
        padding: 6px 0px !important;
        text-transform: uppercase !important;
        background: transparent;
        border-radius: 6px;
        transition: 00.2s;
        text-transform: uppercase;
        color: $az-ligh-black;
        text-decoration: none;
        position: relative;
        border: none;
        box-shadow: none;
        margin-right: 0 !important;
        &:hover, &:active {
          background: transparent !important;
          transition: 00.2s;
          color: $az-ligh-black;
          border: none;
          box-shadow: none;
        }

        span{
          position: absolute;
          width: 18px;
          height: 18px;
          line-height: 18px;
          background: $az-ligh-black;
          color: #ffffff;
          text-align: center;
          font-size: 10px;
          font-family: Lato-Regular;
          right: -7px;
          top: 0px;
          border-radius: 50%;
        }

        img {
          filter: invert(1) brightness(0.4);
        }
      }
      button.active {
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        height: 30px !important;
        padding: 6px 15px !important;
        text-transform: uppercase !important;
        background: transparent;
        transition: 00.2s;
        color: $az-ligh-black;

        &:hover, &:active {
          background: transparent !important;
          transition: 00.2s;
          color: $az-ligh-black;
          border: none;
          box-shadow: none;
        }

      }
    }

  }

  .dropdown {
    margin-left: 25px;
    button {
      background: none;
      border:1px solid #dddddd;
      font-size: inherit;
      height: auto;
      padding: 5px 5px 5px 12px;
      border-radius: 50px;
      transition: box-shadow 0.2s ease;
      &:focus, &:active, &:hover {
        box-shadow: none;
        box-shadow: 0 2px 4px rgba(0,0,0,0.18);
      }
      figure {
        max-height: 28px;
        min-height: 28px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        width: 28px;
        background: #ffffff;
        border-radius: 50%;
        position: relative;
        margin-right: 0px;
        img {
          max-height: 28px;
          min-height: 28px;
          width: 28px;
        }


      }
      figure.user {
        max-height: 28px;
        min-height: 28px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        width: 28px;
        background: $az-white;
        border-radius: 50%;
        position: relative;
        margin-right: 0px;
        margin-left: 12px;
        img {
            max-height: 28px;
            min-height: 28px;
            width: 28px;
            border-radius: 50%;
        }

        .msg{
            position: absolute;
            width: 15px;
            height: 15px;
            line-height: 15px;
            background: $az-light-black;
            color: $az-white;
            text-align: center;
            font-size: $az-font-10;
            font-family: $az-heading-two;
            right: -6px;
            top: -5px;
            border-radius: 50%;
        }

    }
    }
    .dropdown-toggle {
      position: relative;
      &::after {
        background-image: url(../../images/kinibrand-img/menu-nav.svg);
        background-repeat: no-repeat;
        border: none !important;
        box-shadow: none !important;
        width: 30px;
        height: 30px;
        display: none;
      }
      .nav_icon_user
      {
      img{
        width: 16px;
        }
      }
    }
    .dropdown-menu.show {
      // min-width: 17.2rem;
      min-width: 20.2rem;
      margin-top: 10px;
      padding: 8px 20px 16px;
      border: none;
      box-shadow: 0px 0px 5px #ddd;
      h3{
        font-size: $az-font-14;
        font-weight: bold;
        padding-left: 14px;
      }
      .dropdown-item {

         b {
           font-weight: normal;
           font-family: $az-heading-two;
           font-size: $az-font-16;
         }
         b.aleart {
          font-weight: normal;
          position: relative;
         &::after {
           content: '';
           position: absolute;
           width: 5px;
           height: 5px;
           border-radius: 50%;
           background: $az-light-black;
           right: -6px;
           top: 5px;
         }
         }

      }

      .dropdown-item:hover, .dropdown-item:focus {
        background: $az-light-black;
        font-family: $az-heading-two;
        color: $az-white;

        b {
          font-weight: normal;
        }
        b.aleart {
         font-weight: normal;
         position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: $az-white;
          right: -6px;
          top: 5px;
        }
        }
       }
       a {
        padding: 5px 10px;
        color: $az-ligh-black !important;
        &:hover, &:active, &:focus {
          // background: #f7f7f7 !important;
          background: none !important;
          color: $az-black !important;
          padding: 10px;
          font-family: $az-heading-two !important;
        } 
       }
       ul {
        display: block;
       li {
          margin: 0px;
        a {
          
          font-family: $az-heading-two ;
          font-size: $az-font-14;
          // height: 30px ;
          padding: 5px 0px;
          background: transparent;
          transition: 00.2s;
          color: $az-ligh-black;
          text-decoration: none;
          letter-spacing: 0em;
          font-weight: normal;
          border-radius:50px ;
          display: block;


          &:hover, &:active, &:focus {
            // background: #f7f7f7 !important;
            background: none !important;
            color: $az-black !important;
            padding: 5px 0px !important;
            font-family: $az-heading-two !important;
          } 
         }

         &:first-child {
          a {
           padding: 6px 0px 3px  !important;
           font-size: $az-font-16;
           color: $az-black !important;
          }
        }
       }
      }
     }
  }
  .show .dropdown-toggle {
    background: none !important;
  }
}
@mixin mainfilter {
  padding-bottom: 30px;
  position: relative;
  margin-top: -150px;

  @media (max-width:991px) {
    margin-top: -150px;
    padding-bottom: 0px;
}

  @media (max-width:767px) {
    margin-top: -150px;
}
  .filtersection {

  ul.nav-tabs {
    background: #EEEDEB;
    width:292px;
    width: fit-content;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    border: none;
    padding: 6px 8px;
    border-radius: 50px;
    @media (max-width:1200px) {
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
    @media (max-width:991px) {
      width:260px;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
    @media (max-width:767px) {
      width:260px;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }

    li.nav-item{

      button{
        border: none;
        border-radius: 50px;
        color: #969696;
        background: #EEEDEB;
        transition: 0.5s;
        position: relative;
        padding: 15px 30px 12px;
        margin-bottom: 0;
        font-size: $az-font-18;

        @media (max-width:991px) {
          font-size: $az-font-14;
          padding: 11px 25px 9px;
       }
        @media (max-width:767px) {
           font-size: $az-font-14;
           padding: 11px 25px 9px;
        }
      }
      button.active {
        color: $az-white;
        background: $az-ligh-mid-black;
        transition: 0.5s;
        box-shadow: 0px 0px 9px -1px $az-ligh-black;
        -webkit-box-shadow: 0px 0px 9px -1px $az-ligh-black;
        -o-box-shadow: 0px 0px 9px -1px $az-ligh-black;
      }
      &:first-child {
        margin-right: 10px;

        @media (max-width:991px) {
          margin-right: 0px;
       }
        @media (max-width:767px) {
          margin-right: 0px;

        }

      }
    }
  }
  .tab-content {
    width: 100%;
    padding: 20px 15px;
    border-radius: 63px;
    background: $az-white;
      @media(max-width:1200px){
        border: 1px solid  $az-border-color;
        }
        @media(max-width:991px){
          border: none;
          }
    .tab-section {
      padding: 15px;
      border: 1px solid $az-border-color;
      border-radius: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media(max-width:1200px){
        flex-wrap: wrap;
        border: none;

     }
      @media(max-width: 767px){
       flex-wrap: wrap;
       border: none;

    }

    .mobileViewbannerContent {
      display: none !important;
      @media(max-width: 991px) {
        display: none !important;
        h1 {
          color: $az-kini-primary-two;
          font-family: $az-kini-heading-three;
          margin-top: 15px;
          font-size: $az-font-66;
          text-align: left;
          line-height: 1.2;
          padding: 0px;
          margin-bottom: 0px;
          text-transform: uppercase;
          position: relative;
          z-index: 1;
          // text-shadow: 1px 2px 3px #000;
          // text-shadow: 1px 2px 3px #000;
          // -webikit-text-shadow: 1px 2px 3px #000;
          // -moz-text-shadow: 1px 2px 3px #000;
          // -o-text-shadow: 1px 2px 3px #000;
          // letter-spacing: 7.5px;

          @media (max-width:1280px) {
            font-size: $az-font-66;
            padding: 0 0px;
            // letter-spacing: 4.5px;
          }

          @media (max-width:1199px) {
              font-size: $az-font-50;
              padding: 0px;
              // letter-spacing: 4.5px;
            }

          @media (max-width:1023px) {
            font-size: $az-font-50;
            padding: 0px;
            // letter-spacing: 4.5px;
          }

          @media (max-width:991px) {
            font-size: $az-font-40;
            padding: 0px;
          }

          @media (max-width:767px) {
            font-size: $az-font-26;
            padding: 0 5px;
            margin: 0px  auto;
            text-align: left;
            line-height: 1.5;

          }

      }
      h4 {
          position: relative;
          z-index: 1;
          color: $az-ligh-black;
          text-align: left;
          font-size: $az-font-33;
          max-width: 825px;
          margin: 20px 0 50px;

          @media(max-width: 1280px){
              max-width: 800px;

          }
          @media(max-width:1250px){
              max-width: 600px;
              font-size: $az-font-25;

          }
          @media(max-width: 1023px){
              max-width: 600px;
              font-size: $az-font-24;

          }
          @media (max-width:991px) {
            text-align: left;
            margin-top: 10px;
            font-size: $az-font-18;


        }
          @media (max-width:767px) {
              text-align: left;
              margin-top: 10px;
              font-size: $az-font-18;


          }
        }


      }
          @media(max-width: 767px) {
            display: none !important;
            h1 {
              color: $az-kini-primary-two;
              font-family: $az-kini-heading-three;
              margin-top: 15px;
              font-size: $az-font-66;
              text-align: left;
              line-height: 1.2;
              padding: 0px;
              margin-bottom: 0px;
              text-transform: uppercase;
              position: relative;
              z-index: 1;
              // text-shadow: 1px 2px 3px #000;
              // text-shadow: 1px 2px 3px #000;
              // -webikit-text-shadow: 1px 2px 3px #000;
              // -moz-text-shadow: 1px 2px 3px #000;
              // -o-text-shadow: 1px 2px 3px #000;
              // letter-spacing: 7.5px;

              @media (max-width:1280px) {
                font-size: $az-font-66;
                padding: 0 0px;
                // letter-spacing: 4.5px;
              }

              @media (max-width:1199px) {
                  font-size: $az-font-50;
                  padding: 0px;
                  // letter-spacing: 4.5px;
                }

              @media (max-width:1023px) {
                font-size: $az-font-50;
                padding: 0px;
                // letter-spacing: 4.5px;
              }

              @media (max-width:991px) {
                font-size: $az-font-40;
                padding: 0px;
              }

              @media (max-width:767px) {
                font-size: $az-font-26;
                padding: 0 5px;
                margin: 0px  auto;
                text-align: left;
                line-height: 1.5;

              }

          }
          h4 {
              position: relative;
              z-index: 1;
              color: $az-ligh-black;
              text-align: left;
              font-size: $az-font-33;
              max-width: 825px;
              margin: 20px 0 50px;

              @media(max-width: 1280px){
                  max-width: 800px;

              }
              @media(max-width:1250px){
                  max-width: 600px;
                  font-size: $az-font-25;

              }
              @media(max-width: 1023px){
                  max-width: 600px;
                  font-size: $az-font-24;

              }
              @media (max-width:767px) {
                  text-align: left;
                  margin-top: 10px;
                  font-size: $az-font-18;


              }
            }


          }
      }
    }
  }
  label {
    font-family: $az-heading-para;
    color: $az-ligh-black;
    padding: 0 15px;
    font-weight: normal;
    &.vs-hide{
      visibility: hidden;
    }
  }
  input {
      border: none;
      outline: none;
      color: $az-ligh-black;
      font-size: $az-font-18;
      font-family: $az-heading-two;
      padding-left: 0;
      &:focus {
        box-shadow: none;
        border: none;
        outline: none;
      }
  &::placeholder {
    font-size: $az-font-18;
    color:$az-gray ;
    font-family: $az-heading-two;
  }
  }

  .MuiOutlinedInput-root {
    padding: 0 9px !important;
    width: 100%;
    @media(max-width:1200px){
      width:100% !important;
    }
    input {
      padding: 0 6px !important;
      // width: 260px;
      // @media(max-width:1360px){
      //   width:240px !important;
      // }
      // @media(max-width:1280px){
      //   width:220px !important;
      // }
      // @media(max-width:1199px){
      //   width:100% !important;
      // }
    }
    .MuiSvgIcon-root{
      display: none;
    }

  }
  fieldset {
    border: none;
    outline: none;
    &:focus {
      box-shadow: none;
      border: none;
      outline: none;
    }
  }
  .add {
    position: relative;
    @media(max-width:1200px) {
      width: 100%;
      margin:15px 0 0;
      padding: 15px 0 0;
    }
    @media(max-width: 991px) {
      width: 100%;
      margin:15px 0 0;
      padding: 15px 0 0;
    }
    @media(max-width: 767px) {
      width: 100%;
      margin: 12px 0 0;
      padding: 15px 0 0;
    }
    .MuiFormControl-root {
      margin: 3px 0 0;
      padding: 0 5px;
    .MuiSelect-select {
      font-size: $az-font-18;
      color: $az-black;
      font-family: $az-heading-two;
      padding: 0;
      text-align: left;
    }
  }
  &::before {
    height: 35px;
    width: 1px;
    background: $az-border-color;
    content: '';
    left: -10px;
    position: absolute;
    top: 8px;
    @media(max-width:1200px) {
      font-size: $az-font-22;
      height: 1px;
      width: 100%;
      left: 0;
      top: 0;
  }
    @media(max-width: 767px) {
      font-size: $az-font-22;
      height: 1px;
      width: 100%;
      left: 0;
      top: 0;
  }
  }
  }
  .search{
    @media(max-width:1200px) {
      width: 100% !important;
    }
    .css-1jruifh-MuiStack-root{
      width: 250px !important;
      @media(max-width:1200px) {
        width: 100% !important;
      }
    }
  }

  .search-btn {
    display: block;
    z-index: 999;
    @media(max-width:1200px) {
      display: none;
    }
    @media(max-width: 767px) {
      display: none;
    }
    button {
    border: 3px solid #ddd;
    background: $az-ligh-mid-black;
    border-radius: 50%;
    width: 61px;
    height: 61px;
    }

  }
  .mobilesearch-btn {
    display: none;
    @media(max-width:1200px) {
      display: block;
      width: 100%;
    }
    @media(max-width: 767px) {
      display: block;
      width: 100%;
    }
    button {
      border: 3px solid #ddd;
      background: $az-ligh-mid-black;
      border-radius: 50%;
      width: 61px;
      height: 61px;
      color: $az-white;

      @media(max-width:1200px) {
        width: 100%;
        border-radius: 50px;
        font-size: $az-font-14;
      }
      @media(max-width: 767px) {
        width: 100%;
        border-radius: 50px;
        font-size: $az-font-14;
      }
      }

  }
.when {
  position: relative;

  @media(max-width:1200px) {
    width: 100%;
    margin: 12px 0 0;
    padding: 15px 0 0;
  }
  @media(max-width: 991px) {
    width: 100%;
    margin: 12px 0 0;
    padding: 15px 0 0;
  }

  @media(max-width: 767px) {
    width: 100%;
    margin: 12px 0 0;
    padding: 15px 0 0;
  }

  input {
    margin: 0 15px;
    // width: 100%;

    &::placeholder {
      color: #c9c9c9;
    }
  }
  &::before {
    height: 35px;
    width: 1px;
    background: $az-border-color;
    content: '';
    left: -10px;
    position: absolute;
    top: 8px;
    @media(max-width:1200px) {
      font-size: $az-font-22;
      height: 1px;
      width: 100%;
      left: 0;
      top: 0;
  }
    @media(max-width: 767px) {
      font-size: $az-font-22;
      height: 1px;
      width: 100%;
      left: 0;
      top: 0;
  }
  }
}
  .details {
   position: relative;
   @media(max-width:1200px) {
    width: 100%;
    margin: 12px 0 ;
    padding: 12px 0;
  }
   @media(max-width: 991px) {
    width: 100%;
    margin: 12px 0 ;
    padding: 12px 0;
  }
   @media(max-width: 767px) {
    width: 100%;
    margin: 12px 0 ;
    padding: 12px 0;
  }
    .dropdown {

      @media(max-width:1200px) {
        width: 100%;

    }
      @media(max-width: 767px) {
        width: 100%;

    }
       button.dropdown-toggle {
         background: none;
         font-size: $az-font-18;
         color: #c9c9c9 ;
         font-family: $az-heading-two;
         border: none !important;
         box-shadow: none !important;
         padding: 0 15px 0 0px;
        margin: 0;
         &:focus {
          box-shadow: none;
          border: none;
          outline: none;
        }
        &::after {
          display: none;
        }

        @media(max-width:1200px) {
          width: 100%;
          padding: 0;
          text-align: left;
       }

        @media(max-width: 767px) {
          width: 100%;
          padding: 0;
          text-align: left;
         }

       }
      .show {
        margin-top: 24px;
        margin-bottom: 45px;
        min-width:300px;
        border-radius: 8px;
        border: none;
        box-shadow: 0px 0px 3px 0px #cccccc !important;
        margin-left: 15px;
        @media(max-width:1199px) {
          min-width: 23rem;
        }


        @media(max-width: 767px) {
          min-width: 23rem;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            align-items: start;
            flex-direction: column;
            list-style: none;
            padding-right: 0;
            b {
              color: $az-black;
              display: flex;
              font-family:$az-heading-two;
              font-size: $az-font-18;
              margin-top: 2px;
              padding-left: 0;
              text-align: left;
              font-style: normal;
              font-weight: normal;
            }
            i {
              color: $az-black;
              display: flex;
              font-family:$az-heading-two;
              font-size: $az-font-14;
              margin-bottom: 3px;
              padding-left: 0;
              text-align: left;
              font-style: normal;
            }
          }
        }
        .countBtn{
          color: $az-black;
          display: flex;
          font-family:$az-heading-two;
          font-size: $az-font-18;
          margin-bottom: 3px;
          padding-left: 0;
          text-align: left;

          button {
            border: none;
            background: none;
            &:focus {
              box-shadow: none;
              border: none;
              outline: none;
            }
            img {
              width: 23px;
              height: 23px;
            }
          }
          span {
            &:nth-child(2) {
              min-width: 30px;
              max-width: 300px;
              text-align: center;
              padding-top: 2px;
            }
          }
        }

        .done{
          background: transparent;
          background: $az-black ;
          color: $az-black ;
          border-radius: 0px;
          font-family: $az-kini-heading-three;
          font-size: $az-font-14;
          padding: 2px 15px;
          width: 75px;
          margin-right: 22px;
          margin-bottom: 10px;
          border-radius: 20px;
          color: #fff;
          letter-spacing: 1px;
          border: none;
        }
      }

    }

    &::before {
      height: 35px;
      width: 1px;
      background: $az-border-color;
      content: '';
      left: -10px;
      position: absolute;
      top: 8px;
      @media(max-width:1200px) {
        font-size: $az-font-22;
        height: 1px;
        width: 100%;
        left: 0;
        top: 0;
    }
      @media(max-width: 767px) {
        font-size: $az-font-22;
        height: 1px;
        width: 100%;
        left: 0;
        top: 0;
    }
    }

  }
}

.container {
  @media (max-width:991px) {
    padding: 0 !important;
  }
  @media (max-width:767px) {
    padding: 0 !important;
  }
}



}

@mixin brandHeading-1 {
  font-size: $az-font-66;
  font-family: $az-kini-heading-three;
}
@mixin brandHeading-2 {
  font-size: $az-font-55;
  font-family: $az-kini-heading-three;
}
@mixin brandHeading-3 {
  font-size: $az-font-54;
  font-family: $az-kini-heading-three;
  letter-spacing: 0em;

  @media (max-width:1280px) {
    font-size: $az-font-38;
  }

  @media (max-width:1199px) {
    font-size: $az-font-32;
  }
  @media (max-width:1023px) {
    font-size: $az-font-26;
  }
  @media (max-width:991px) {
    line-height: 1.5;
  }
  @media (max-width:767px) {
    line-height: 1.5;
  }

}

@mixin brandHeading-4 {
  font-size: $az-font-33;
  font-family: $az-kini-heading-three;

  @media (max-width:1280px) {
    font-size: $az-font-28;
  }
  @media (max-width:1199px) {
    font-size: $az-font-24;
  }
  @media (max-width:1023px) {
    font-size: $az-font-22;
  }
  @media (max-width:991px) {
    font-size: $az-font-22;
  }
  @media (max-width:767px) {
    font-size: $az-font-22;
  }
}

@mixin brandHeading-5 {
  font-size: $az-font-22;
  font-family: $az-kini-heading-three;

  @media (max-width:1280px) {
    font-size: $az-font-20;
  }

  @media (max-width:1199px) {
    font-size: $az-font-18;
  }
  @media (max-width:991px) {
    margin-bottom: 30px;
  }
  @media (max-width:767px) {
    margin-bottom: 30px;
  }
}


@mixin brandHeading-6 {
  font-size: $az-font-11;
  font-family: $az-kini-heading-three;
}

@mixin brandParagraph {
  font-size: $az-font-18;
  font-family: $az-heading-two;
  color: $az-ligh-black;
}

// End KINI corporate brand page style

@mixin fileUploadeditpage {
  .fileUpload{
    position: relative;
    span {
      width: 170px;
      height: 170px;
      border-radius: 50%;
      background: $az-white;
      border: 2px solid $az-border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding: 0px;
      margin: auto;
      img {
        max-width: 170px;
        min-width: 170px;
        max-height: 170px;
        min-height: 170px;
        object-fit: cover;
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        margin-bottom: 16px;
      }
      label {
        margin-bottom: 0;

          @include fileupldoadinput;

          &::after {
            position: absolute;
            content: 'Edit profile';
            left: 50%;
            bottom: -38px;
            width: 78px;
            background: $az-white !important;
            transform: translate(-50%, 0);
            font-size: 16px;
            text-decoration: underline;
            font-family: $az-heading-two;
          }
      }
    }


  @media(max-width:767px) {
    justify-content: start !important;
  }
 }
}
@mixin fileupldoadinput {

    input[type=file] {
    border: none !important;
    padding: 0;
    position: absolute;
    left: 50%;
    bottom: -35px;
    background: $az-white !important;
    height: 20px !important;
    width: 70px;
    transform: translate(-50%, 0);
    display: none;

  }

  input[type=file]::-ms-browse {
    border: none;
    padding: 10px 15px;
    border-radius: .2em;
    background-color: #e3e7ed;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 4px;
    font-family: $az-heading-two;
    font-size: $az-font-14;
  }

  input[type=file]::-webkit-file-upload-button {
    border: none;
    padding: 10px 15px;
    border-radius: .2em;
    background-color: #e3e7ed;
    transition: 1s;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 4px;
    font-family: $az-heading-two;
    font-size: $az-font-14;
  }

  input[type=file]::file-selector-button {
    border: none;
    padding: 10px 15px;
    border-radius: .2em;
    background-color: #e3e7ed;
    transition: 1s;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 4px;
    font-family: $az-heading-two;
    font-size: $az-font-14;
  }

  input[type=file]::-ms-browse:hover {
    background-color: #e3e7ed;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 4px;
    font-family: $az-heading-two;
    font-size: $az-font-14;
  }

  input[type=file]::-webkit-file-upload-button:hover {
    background-color: #e3e7ed;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 4px;
    font-family: $az-heading-two;
    font-size: $az-font-14;
  }

  input[type=file]::file-selector-button:hover {
    background-color: #e3e7ed;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 4px;
    font-family: $az-heading-two;
    font-size: $az-font-14;
  }

}

@mixin modalbox {
  .modal-dialog {
    margin: auto;
    max-width: 400px;
    .modal-content {
      border-radius: 2px;
      @media(max-width:767px){
        width: 95%;
        margin: auto;
      }
      .modal-body {
        position: relative;
        @include dashbaordprofilecontent;

        padding: 32px 24px;
        img {
          margin-bottom: 24px;
          width: 46px;
        }
        h3 {
          font-weight: normal;
          font-family: $az-heading-one;
          color: $az-black;
          font-size: $az-font-24;
          text-transform: inherit;
          margin-bottom: 24px;
        }
        p {
          font-weight: normal;
          font-family: $az-heading-two;
          color: $az-black;
          font-size: $az-font-15;
          text-transform: inherit;
          margin-bottom: 32px;
        }
        label {
          font-weight: normal;
          font-family: $az-heading-two;
          color: $az-gray;
          font-size: $az-font-14;
          text-transform: inherit;
          margin-bottom: 8px;
        }

        .react-datepicker-wrapper{
          width: 100% !important;
          height: 42px !important;
          padding: 0 !important;
          border-radius: 4px !important;
          font-family: $az-heading-two;
          color: $az-black;
          font-size: $az-font-14;

          input {
              height: 40px !important;
          }
        }
        @include fileUploadeditpage;
        .infosize{
          font-size: $az-font-12;
          color: $az-gray;
          font-family: $az-heading-two;
          line-height: 1.25;
          margin-top: 11px;
          width: 100%;

          img{
            margin-top: 1px;
            width: auto;
            @media (max-width: 767px) {
              vertical-align: middle;
            }
          }
            p{
             margin: 0;
             font-size: $az-font-12;
             color: $az-gray;
             font-family: $az-heading-two;
             line-height: 1.25;
          }
        }
        .welcomeClose {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 1;
          img {
            cursor: pointer;
            width: 24px;
             margin-bottom: 0;
            }
        }
      }
      button.btn-success {
        // @include modalbtn-stroke;
        // min-width: max-content !important;
        // max-width: max-content !important;
      }
      button.btn-outline-light {
        @include modalbtn-stroke;
      }
      button.btn-danger {
        @include modalbtn-delete;
      }
    }
  }
}
@mixin dashbaordprofilecontent {
  .link-url{
    color: #0039e6;
    text-decoration: none;
  }
  .az-profile-name-text {
    font-size: $az-font-14 !important;
    color: $az-gray !important;
    font-family: $az-heading-two;
    font-weight: normal;
    margin-bottom: 0;
  }
  .media-body {
      font-size: $az-font-15;
      color: $az-black;
      font-family: $az-heading-two;
      font-weight: normal;
      padding-bottom: 6px;
      margin-bottom: 1rem;


    span{
      font-size: $az-font-15;
      color: $az-black;
      font-family: $az-heading-two;
      margin-right: 15px;
    }
    span.maplink {
      white-space: break-spaces;
      word-break: break-all;
      color: $az-black;
      font-family: $az-heading-two;
      font-size: 14px;
      &:hover {
        text-decoration: underline;
        text-underline-offset: 0.3em;
      }
    }

    button{
      font-size: $az-font-14;
      color: $az-black;
      font-family: $az-heading-two;
      background: none;
      box-shadow: none;
      padding: 7px 17px 6px;
      border-radius: 4px;
      border: solid 1px #4a4a4a;
      line-height: 1.36;
      font-weight: 500;
    }
  }
  .col-md-12, .d-flex {
    .az-profile-name-text {
      font-size: $az-font-14 !important;
      color: $az-gray !important;
      font-family: $az-heading-two;
      font-weight: normal;
      margin-bottom: 0;

      @media(max-width:767px){
        text-align: left;
      }

  }
  .para{
    font-size: $az-font-15;
    color: $az-black;
    font-family: $az-heading-two;
    font-weight: normal;
    margin: 9px 0;

    a {
      color: $az-black;
      &:hover{
        text-decoration: underline;
        text-underline-offset: 0.3em;
      }
    }
  }
  .brand-sm-logo{
    figure {
      width: 42px;
      height: 42px;
      border: 2px solid $az-border-color;
      border-radius: 50%;
      margin: 0;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: none;

      }
    }
  }
 }

 .multiImgBuuton {

  @media(max-width:767px){

    margin:20px auto;
    text-align: center;

  }
 }
.az-content-body-contacts {
  .media-body {
    font-family:$az-heading-two !important;
  }
}

}


@mixin modalbtn-stroke {
  font-family: $az-heading-two;
  background-color: $az-eage-color;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-eage-color;
  padding: 4px 10px;
  min-width: 90px;
  max-width: 90px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-left: 15px !important;
  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background: $az-black !important;
    color: $az-white !important;
  }
  &:hover {
    border: 2px solid $az-eage-color;
    box-shadow: none;
    outline: none;
    background-color: $az-black !important;
    color: $az-white !important;

  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background: $az-black !important;
    color: $az-white !important;

  }
  @media(max-width:767px){
    min-width: 100px;
    max-width: 100px;
    margin-right: 15px !important;
  }
  span {
      display: flex; justify-content: center; align-items: center;
  }
}
@mixin modalbtn-outline {
  font-family: $az-heading-two;
  background: none;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-eage-color;
  padding: 4px 10px;
  min-width: 90px;
  max-width: 90px;
  outline: none;
  height: auto;
  margin-right: 15px !important;
  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background:  $az-black !important;
    color: $az-white!important;
  }
  &:hover {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background:  $az-black !important;
    color: $az-white!important;

  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background:  $az-black !important;
    color: $az-white!important;
  }
  @media(max-width:767px){
    min-width: 100px;
    max-width: 100px;
    margin-left: 15px !important;
  }
}
@mixin modalbtn-stroke {
  font-family: $az-heading-two;
  background-color: $az-eage-color;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 50px;
  font-weight: 500;
  // border: 2px solid $az-eage-color;
  padding: 4px 10px;
  min-width: 90px;
  max-width: 90px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-left: 15px !important;
  &:focus {
    // border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background: $az-black !important;
    color: $az-white !important;
  }
  &:hover {
    // border: 2px solid $az-eage-color;
    box-shadow: none;
    outline: none;
    background-color: $az-black !important;
    color: $az-white !important;

  }
  &:active {
    // border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background: $az-black !important;
    color: $az-white !important;

  }
  @media(max-width:767px){
    min-width: 100px;
    max-width: 100px;
    margin-right: 15px !important;
  }
  span {
      display: flex; justify-content: center; align-items: center;
  }
}
@mixin modalbtn-outline {
  font-family: $az-heading-two;
  background: none;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-eage-color;
  padding: 4px 10px;
  min-width: 90px;
  max-width: 90px;
  outline: none;
  height: auto;
  margin-right: 15px !important;
  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background:  $az-black !important;
    color: $az-white!important;
  }
  &:hover {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background:  $az-black !important;
    color: $az-white!important;

  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background:  $az-black !important;
    color: $az-white!important;
  }
  @media(max-width:767px){
    min-width: 100px;
    max-width: 100px;
    margin-left: 15px !important;
  }
}
@mixin modalbtn-delete {
  font-family: $az-heading-two;
  // background-color: $az-inactive-status-bg;
  background-color: $az-eage-color;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 0px;
  font-weight: 500;
  border: none;
  padding: 4px 10px;
  max-width: 90px;
  min-width: 90px;
  outline: none;
  height: auto;
  margin-left: 15px !important;
  &:focus {
    border: 2px solid $az-inactive-status-bg;
    box-shadow: none;
    outline: none;
    background: none;
    color: $az-inactive-status-bg;
  }
  &:hover {
    border: 2px solid $az-inactive-status-bg;
    box-shadow: none;
    outline: none;
    background: none;
    color: $az-inactive-status-bg;
  }
  &:active {
    border: 2px solid $az-inactive-status-bg;
    box-shadow: none;
    outline: none;
    background: none;
    color: $az-inactive-status-bg;
  }
}


@mixin textnormal {
  color: $az-black;
  font-size: $az-font-15;
  font-family: $az-heading-two !important;
  font-weight: normal;
}

@mixin textbold {
  font-family: $az-heading-para !important;
}