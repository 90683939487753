/* ###### Global Fonts ###### */

@mixin headingBold {
        font-family: $az-kini-heading-three;
        src: url(../oh/OrpheusPro-Bold.woff);
}

@mixin headingOrpheusBold {
        font-family: $az-Orpheus-Bold;
        src: url(../oh/Orpheus-Bold.woff);
}

/* ###### Global Lato Fonts ###### */

@mixin headingLight {
        font-family: $az-heading-light;
        src: url(../oh/Lato-Light.woff);
}

@mixin headingRg {
        font-family: $az-heading-two;
        src: url(../oh/Lato-Regular.woff);
}


@mixin headingMd {
        font-family: $az-heading-para;
        src: url(../oh/Lato-Medium-Bold.woff);
}

@mixin headingRgbd {
        font-family: $az-heading-para-light;
        src: url(../oh/Lato-Dark-Bold.woff);
}

@mixin RubikMobile {
        font-family: $az-Rubik-mobile;
        src: url(../oh/Rubik-Regular.woff);
}