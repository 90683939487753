//  All Buttons mixin

@mixin OHprimaybutton {
  background: $az-oh-seconday;
  font-size: $az-font-20;
  color: $az-oh-black;
  border-radius: 12px;
  padding: 9px 20px 10px;
  border: 0;
  outline: none;
  height: 57px;
  display: flex;
  align-items: center;
  font-family: $az-kini-heading-three;
  text-transform:inherit;
  margin-right: 45px;


  @media(max-width:767px) {

    font-size: $az-font-18;
    padding: 9px 29px 10px;

  }

  @media(max-width:767px) {

    font-size: $az-font-14;
    padding: 9px 29px 10px;

  }

  &:active {
    border: 0;
    outline: none;
  }
  &:focus {
    border: 0;
    outline: none;
  }
  &:hover {
    border: 0;
    outline: none;
  }
  @include anchor;

}

@mixin OHsecondarybutton {
  background: transparent;
  font-size: $az-font-25;
  color: $az-oh-black;
  border-radius: 12px;
  padding: 9px 20px 10px;
  border: 2px solid $az-oh-black;
  outline: none;
  height: 57px;
  display: flex;
  align-items: center;
  font-family: $az-kini-heading-three;
  justify-content: center;
  text-transform: inherit;


  &:active {
    border: 2px solid $az-oh-black;
    outline: none;
  }
  &:focus {
    border: 2px solid $az-oh-black;
    outline: none;
  }
  &:hover {
    border: 2px solid $az-oh-black;
    outline: none;
  }
  @include anchor;

  @media(max-width:1240px) {

    font-size: $az-font-20;
    height: 48px;
    padding: 9px 20px 10px;

  }

}

@mixin OHprimaybutton-lg {
  background: $az-oh-seconday;
  font-size: $az-font-28;
  color:$az-oh-black;
  border-radius: 12px;
  padding: 17px 20px;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  font-family: $az-kini-heading-three;
  justify-content: center;
  min-width: 183px;
  text-transform: inherit;


  @media(max-width:1240px) {

    font-size: $az-font-26;
    padding: 12px 20px 12px !important;
  }

  @media(max-width:991px) {

    font-size: $az-font-22;
    padding: 9px 15px 10px !important;
  }

  @media(max-width:767px) {

    font-size: $az-font-18;
    padding: 9px 15px 10px !important;
    min-width: 180px;

  }

  &:active {
    border: 0;
    outline: none;
  }
  &:focus {
    border: 0;
    outline: none;
  }
  &:hover {
    border: 0;
    outline: none;
  }
  @include anchor;
}

  @mixin OHprimaybutton-md {
    background: $az-oh-black;
    font-size: $az-font-25;
    color:$az-white;
    border-radius: 12px;
    padding: 9px 20px 10px;
    border: 0;
    outline: none;
    display: flex;
    align-items:center;
    justify-content: center;
    font-family: $az-kini-heading-three;
    text-transform: inherit;
  

    @media (max-width:1240px) { 
      font-size: $az-font-20;
      padding: 9px 20px 10px;
    }

    @media (max-width:991px) { 

      font-size: $az-font-16;
      padding: 9px 15px 10px !important

    }
    

    @media (max-width:767px) { 

      font-size: $az-font-16;
      padding: 7px 12px 7px;

    }
  
    &:active {
      border: 0;
      outline: none;
    }
    &:focus {
      border: 0;
      outline: none;
    }
    &:hover {
      border: 0;
      outline: none;
    }
    @include anchor;

}

@mixin OHprimaybutton-sm {
  background: $az-oh-black;
  font-size: $az-font-25;
  color:$az-white;
  padding: 9px 20px 10px;
  border: 0;
  outline: none;
  display: flex;
  align-items:center;
  justify-content: center;
  font-family: $az-kini-heading-three;
  min-width: 200px;
  text-transform: inherit;
  border-radius: 50px;

  a {
    color: $az-white !important;
  }

  @media (max-width:1240px) {
    font-size: $az-font-20;
    padding: 9px 20px 10px;
   
  }


  @media (max-width:991px) {
    min-width: 180px;
    max-width: 180px;
    font-size: $az-font-16;
    padding: 9px 15px 10px !important;
   
  }
  @media (max-width:767px) {
    min-width: 140px;
    max-width: fit-content;
    font-size: $az-font-19;
    padding: 7px 12px 7px !important;
   
  }

  &:active {
    border: 0;
    outline: none;
  }
  &:focus {
    border: 0;
    outline: none;
  }
  &:hover {
    border: 0;
    outline: none;
  }
  @include anchor;

}

@mixin OHprimaybutton-xs {
  background: $az-oh-seconday;
  font-size: $az-font-25;
  color:$az-oh-black;
  border-radius: 12px;
  padding: 9px 20px 10px;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  font-family: $az-kini-heading-three;
  justify-content: center;
  min-width: 183px;
  text-transform: inherit;


   @media (max-width:1240px) {
    font-size: $az-font-20;
    padding: 9px 15px 10px !important;
   
  }

  @media (max-width:991px) {
    font-size: $az-font-22;
    padding: 9px 15px 10px !important;
   
  }

  @media (max-width:767px) { 

    min-width: 150px;
    max-width: 150px;
    font-size: $az-font-18;
    padding: 9px 15px 10px !important;
  }
  &:active {
    border: 0;
    outline: none;
  }
  &:focus {
    border: 0;
    outline: none;
  }
  &:hover {
    border: 0;
    outline: none;
  }
  @include anchor;
}


@mixin anchor {
  a{
    color:$az-oh-black;
    text-decoration: none;
    &:focus, &:active, &:hover{
      text-decoration: none;
    }
  }
}
@mixin OHprimaybutton-table-btn {
  background: $az-black;
  font-size: $az-font-14;
  color:$az-white;
  border-radius: 12px;
  padding: 10px 15px;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  font-family: $az-kini-heading-three;
  justify-content: center;
  min-width: 90px;
  text-transform: inherit;

  @media (max-width:767px) { 
   padding: 7px 15px;
  }
  &:active {
    border: 0;
    outline: none;
  }
  &:focus {
    border: 0;
    outline: none;
  }
  &:hover {
    border: 0;
    outline: none;
  }
}

// brand leavel corporate button 

@mixin Brandbutton1 {
  border: 0;
  border-radius: 0;
  padding: 12px 25px;
  background: $az-light-black;
  border: 0;
  color:$az-white;
  font-size: $az-font-18;
  border-radius: 30px;
}

// End brand leavel corporate button 