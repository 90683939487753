////////////////////////////////////////////////////////////
//     							                          //
//  Program: Oh_html.scss                                 //
//  Application: scss                                     //
//  Option: Used to create custom css                     //
//  Developer: PS  						                  //
//  Date: 2022-04-12                                      //
//                                                        //
////////////////////////////////////////////////////////////

// Custom variables and mixins
@import "./oh_common/variables";
@import "./oh_common/mixins";
// @import '/node_modules/react-modal-video/scss/modal-video.scss';
@import "video.js/dist/video-js.css";
/* ############### Fonts ############### */

// OH fonts

@import "../fonts/oh/fonts";

@font-face {
    @include headingBold;
}

@font-face {
    @include headingRg;
}

@font-face {
    @include headingMd;
}

@font-face {
    @include headingRgbd;
}

@font-face {
    @include headingLight;
}

@font-face {
    @include headingOrpheusBold;
}

@font-face{
    @include RubikMobile;
}


// Kini fonts

// @import "../fonts/kini/fonts";

// @font-face {
//     @include SummerLovingRegular;
// }

// @font-face {
//     @include SummerLovingSolid;
// }

// @font-face {
//     @include TanPearl;
// }


/* ############### Spacing ############### */
@import "./lib/margin";
@import "./lib/padding";

// body{overflow: auto !important;}
.heading5 {
    @include heading5;
}

.heading6 {
    @include heading6;
}

p {
    @include p;
}

.border-b {
    @include borderOne;
}

.text-uppercase {
    text-transform: uppercase;
}

.container {
    @include brandContainer;
}

.col-xxl-4 {
    @media (min-width:1440px) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;

    }
}

// Modal box

.modal-xlg {
    .modal-dialog {

        @include modal-xlg;
    }

}

.modal-xxlg {
    .modal-dialog {

        @include modal-xxlg;
    }

}

// Home page

.offcanvas,
.offcanvas-end,
.offcanvas.show {
    transition: none;
    // display: none;

    @media(max-width:991px) {
        display: block;
    }

    @media(max-width:991px) {
        display: block;
    }
}

.offcanvas#offcanvasNavbar-expand-false {
    display: none;

    @media(max-width:991px) {
        display: block;
        height: 100%;
        position: fixed;
        top: 0;
        background-color: $az-white;
        overflow: hidden;
    }

    @media(max-width:767px) {
        display: block;
        height: 100%;
        position: fixed;
        top: 0;
        background-color: $az-white;
        overflow: hidden;
        width: 100%;
    }

}

.offcanvas-backdrop.show {
    display: none;

    @media(max-width:991px) {
        display: block;
    }

    @media(max-width:991px) {
        display: block;
    }
}

.notificaitonModal.show {

    z-index: 2;

    @media(max-width:767px) {
       border-left: 0 !important;
    }

    &::after {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: #000;
        opacity: 0.5;
        z-index: -1;

        @media(max-width:991px) {
            z-index: -1;
            opacity: 0;
        }

    }

    .offcanvas-header {
        background: $az-white;

        .offcanvas-title {
            font-size: $az-font-24 !important;
            color: $az-black;

            @media(max-width:767px) {
                padding-top: 30px;
            }
        }
        button.btn-close {
            @media(max-width:767px) {
                right: 20px;
                top: 20px;
            }
        }
    }

    @media(max-width:991px) {
        z-index: 9999;
    }

    @media(max-width:767px) {
        z-index: 9999;
    }

    .offcanvas-body{
        // display: hide;
        padding: 30px 15px;

        hr {
            border-top: 1px solid;
            opacity: 0.15;
        }

        ul {

            li {
                margin: 0px;

                a {
                    font-family: $az-heading-two ;
                    font-size: $az-font-16;
                    // height: 30px ;
                    padding: 7px 0px;
                    background: transparent;
                    transition: 00.2s;
                    color: $az-ligh-black;
                    text-decoration: none;
                    letter-spacing: 0em;
                    font-weight: normal;
                    border-radius: 50px;
                    display: block;


                    &:hover,
                    &:active,
                    &:focus {
                        // background: #f7f7f7 !important;
                        background: none !important;
                        color: $az-black !important;
                        padding: 7px 0px;
                        font-family: $az-heading-two !important;
                    }
                }

                &:first-child {
                    a {
                        padding: 15px 0px 20px;
                        font-size: $az-font-20;
                        color: $az-black !important;

                        &:hover,
                        &:active,
                        &:focus {
                            padding: 15px 0px 20px;

                        }
                    }
                }
            }
        }

        @media (max-width: 767px) {
            height: calc(100% - 66px);
        }
    }
    


}

body.modal-open {
    // padding-right: 10px !important;
    letter-spacing: 0;
    overflow: hidden !important;
}

body {
    scrollbar-width: 10px !important;
    scrollbar-color: #333 transparent;
    -webkit-scrollbar-width: 10px !important;
    -moz-scrollbar-width: 10px !important;
    // padding-right: 0 !important;
    // overflow: auto !important;
}

body::-webkit-scrollbar {
    width: 10px;
    height: 5px;
}

body::-webkit-scrollbar-track {
    background-clip: content-box;
    border: 0px solid transparent;
    width: 10px;
    background-color: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: #333;
}

body::-webkit-scrollbar-thumb:hover {
    background-color: #333;
}

body::-webkit-scrollbar-corner,
body::-webkit-scrollbar-track {
    background-color: transparent;
    width: 10px;
}

[class^="body.modal-open"] {
    /* height: 200px; */
    overflow-y: scroll;
    width: 10px;
}

[class^="body.modal-open"] div {
    /* height: 400px;
  width: 200%;
  padding: 4rem;
  background-image: linear-gradient(130deg, #c8c8c8 0%, #fefefe 100%); */
}

.offcanvas-title {
    font-family: $az-kini-heading-three;
    text-align: center;
    margin: auto;
    padding-left: 20px;
    color: $az-ligh-mid-black;
    font-size: $az-font-20 !important;
    width: 100%;
}

.offcanvas-body {
    ul {
        @media(max-width: 991px) {
            list-style: none;
            text-align: center;
            padding: 0;
            margin: 0;

            li {
                text-align: center;
                padding: 0;
                margin: 10px 0;

                a {
                    background: none;
                    color: $az-gray;
                    text-decoration: none;
                    font-family: $az-heading-two;
                    font-size: $az-font-18;

                }

                a.active {
                    background: none;
                    color: $az-black;
                    text-decoration: none;
                    font-family: $az-heading-two;
                    font-size: $az-font-18;
                }

                a.aleart {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: $az-kini-primary-two;
                        right: -5px;
                        top: 5px;
                    }
                }
            }


        }

        @media(max-width: 767px) {
            list-style: none;
            text-align: center;
            padding: 0;
            margin: 0;

            li {
                text-align: center;
                padding: 0;
                margin: 10px 0;

                a {
                    background: none;
                    color: $az-gray;
                    text-decoration: none;
                    font-family: $az-heading-two;
                    font-size: $az-font-18;

                }

                a.active {
                    background: none;
                    color: $az-black;
                    text-decoration: none;
                    font-family: $az-heading-two;
                    font-size: $az-font-18;
                }

                a.aleart {
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: $az-kini-primary-two;
                        right: -5px;
                        top: 5px;
                    }
                }
            }


        }
    }
}

.Brandheader {
    // position: relative;
    z-index: 999;
    position: sticky;
    top: 0;

    header {
        padding: 9px 0 10px !important;
        // background: $az-kini-seconday;
        display: block;
        box-shadow: 1px 0px 3px #ddd;


        .navigationMenu {
            @include navigationMenu;
        }

        @media(max-width: 991px) {
            display: none;
        }

        @media(max-width: 767px) {
            display: none;
        }
    }

    .mobileHeader {
        display: none;

        @media(max-width: 991px) {
            display: block;
            padding: 3px 0 5px !important;
            // background: $az-kini-seconday;
            background: $az-white;
            box-shadow: 1px 0px 3px #ddd;
        }

        @media(max-width: 767px) {
            display: block;
            padding: 3px 0 5px !important;
            // background: $az-kini-seconday;
            background: $az-white;
            box-shadow: 1px 0px 3px #ddd;
        }

        .navigationMenu {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            figure {
                margin-bottom: 0;
                display: flex;
                align-items: center;
                img{
                    @media (max-width:360px) {
                        max-height: 18px;
                    }
                }
            }

            .headerRight {
                display: flex;
                justify-content: space-between;
                align-items: center;

                figure.user {

                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    width: 28px;
                    background: $az-white;
                    margin: 0 -5px 0 10px;
                    border-radius: 50%;
                    position: relative;
                    overflow: hidden;

                    img {
                        width: 28px;
                    }

                    .msg {
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        line-height: 18px;
                        background: $az-kini-primary-two;
                        color: $az-white;
                        text-align: center;
                        font-size: $az-font-10;
                        font-family: $az-heading-two;
                        right: -8px;
                        top: -9px;
                        border-radius: 50%;
                    }

                }

                button {
                    border: none;
                    box-shadow: none;
                    outline: none;
                    display: flex;
                    border: 1px solid #dddddd !important;
                    font-size: inherit;
                    height: auto;
                    padding: 5px 12px;
                    border-radius: 50px;
                    // line-height: auto;
                    min-width: 41px;
                    height: 42px;
                    justify-content: center;
                    place-items: center;
                    place-content: center;

                    .nav_icon_user {
                        img {
                            width: 17px;
                        }
                    }

                    &:hover,
                    &:focus {
                        border: none;
                        box-shadow: none;
                        outline: none;
                    }

                    .navbar-toggler-icon {
                        background-image: url(../images/oh-corporate-img/menu-nav.svg);
                        background-repeat: no-repeat;
                    }
                }

                ul {
                    padding: 0;
                    list-style: none;
                    display: flex;
                    margin: 0;
                    align-items: center;

                    @media(max-width: 991px) {
                        margin: 0px 10px 0 0;
                    }

                    @media(max-width: 767px) {
                        margin: 0px 10px 0 0;
                    }

                    li {
                        margin: 0 10px;

                        a {
                            font-family: $az-heading-two !important;
                            font-size: $az-font-16 !important;
                            height: 30px !important;
                            padding: 12px !important;
                            background: transparent !important;
                            transition: 00.2s;
                            color: $az-black !important;
                            text-decoration: none !important;
                            letter-spacing: 0em;
                            font-weight: normal !important;
                            border-radius: 50px !important;
                            text-transform: inherit !important;

                            &:hover,
                            &:active {
                                background: #f7f7f7 !important;
                                transition: 00.2s;

                            }

                            &:hover,
                            &:active,
                            &:focus {
                                background: #f7f7f7 !important;
                                transition: 00.2s;
                            }
                        }

                        a.active {
                            font-family: $az-heading-two !important;
                            font-size: $az-font-14 !important;
                            height: 30px !important;
                            padding: 12px !important;
                            background: transparent !important;
                            transition: 00.2s;
                        }
                    }

                    li.notification-m {
                        margin: 0px;

                        a {
                            font-family: $az-heading-two !important;
                            font-size: $az-font-14 !important;
                            height: 30px !important;
                            padding: 6px 0px !important;
                            background: transparent;
                            border-radius: 6px;
                            transition: 00.2s;
                            text-transform: uppercase;
                            color: $az-ligh-black;
                            text-decoration: none;
                            position: relative;

                            &:hover,
                            &:active {
                                background: transparent !important;
                                transition: 00.2s;
                                color: $az-ligh-black;
                                border: none;
                                box-shadow: none;
                            }

                            span {
                                position: absolute;
                                width: 18px;
                                height: 18px;
                                line-height: 18px;
                                background: $az-ligh-black;
                                color: #ffffff;
                                text-align: center;
                                font-size: 10px;
                                font-family: Lato-Regular;
                                right: -12px;
                                top: 0px;
                                border-radius: 50%;
                            }

                            img {
                                filter: invert(1) brightness(0.4);

                                @media(max-width: 991px) {
                                    filter: invert(1) brightness(0.4);
                                    width: 24px;
                                    height: 24px;
                                }

                                @media(max-width: 767px) {
                                    filter: invert(1) brightness(0.4);
                                    width: 24px;
                                    height: 24px;
                                }

                            }
                        }

                        a.active {
                            font-family: $az-heading-two !important;
                            font-size: $az-font-14 !important;
                            height: 30px !important;
                            padding: 6px 15px !important;
                            text-transform: uppercase !important;
                            background: transparent;
                            transition: 00.2s;
                            color: $az-ligh-black;

                            &:hover,
                            &:active {
                                background: transparent !important;
                                transition: 00.2s;
                                color: $az-ligh-black;
                                border: none;
                                box-shadow: none;
                            }
                        }
                    }

                    li.notification {
                        margin: 0px;

                        button {
                            font-family: $az-heading-two !important;
                            font-size: $az-font-14 !important;
                            height: 30px !important;
                            padding: 6px 0px !important;
                            text-transform: uppercase !important;
                            background: transparent;
                            border-radius: 6px;
                            transition: 00.2s;
                            text-transform: uppercase;
                            color: $az-ligh-black;
                            text-decoration: none;
                            position: relative;
                            border: none !important;
                            box-shadow: none !important;
                            margin-right: 0 !important;
                            min-width: auto;

                            &:hover,
                            &:active {
                                background: transparent !important;
                                transition: 00.2s;
                                color: $az-ligh-black;
                                border: none !important;
                                box-shadow: none !important;
                            }

                            span {
                                position: absolute;
                                width: 18px;
                                height: 18px;
                                line-height: 18px;
                                background: $az-ligh-black;
                                color: #ffffff;
                                text-align: center;
                                font-size: 10px;
                                font-family: Lato-Regular;
                                right: -7px;
                                top: 0px;
                                border-radius: 50%;
                            }

                            img {
                                filter: invert(1) brightness(0.4);

                                @media(max-width: 991px) {
                                    filter: invert(1) brightness(0.4);
                                    width: 24px;
                                    height: 24px;
                                }

                                @media(max-width: 767px) {
                                    filter: invert(1) brightness(0.4);
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }

                        button.active {
                            font-family: $az-heading-two !important;
                            font-size: $az-font-14 !important;
                            height: 30px !important;
                            padding: 6px 15px !important;
                            text-transform: uppercase !important;
                            background: transparent;
                            transition: 00.2s;
                            color: $az-ligh-black;

                            &:hover,
                            &:active {
                                background: transparent !important;
                                transition: 00.2s;
                                color: $az-ligh-black;
                                border: none;
                                box-shadow: none;
                            }

                        }
                    }

                    li {
                        margin: 0 10px;

                        a {
                            // font-family: $az-heading-two !important;
                            font-size: $az-font-14 !important;
                            height: 30px !important;
                            padding: 6px 15px !important;
                            background: transparent;
                            border-radius: 6px;
                            transition: 00.2s;
                            text-transform: uppercase;
                            color: $az-white;
                            text-decoration: none;
                            font-weight: bold;

                            &:hover,
                            &:active {
                                // background: $az-kini-primary-two !important;
                                transition: 00.2s;
                                color: $az-white;
                            }
                        }

                        a.active {
                            // font-family: $az-heading-two !important;
                            font-size: $az-font-14 !important;
                            height: 30px !important;
                            padding: 6px 15px !important;
                            // background: $az-kini-primary-two !important;
                            transition: 00.2s;
                            color: $az-white;
                        }
                    }

                    li.notification-m {
                        margin: 0 10px;
                        display: none;

                        a {
                            font-family: $az-heading-two !important;
                            font-size: $az-font-14 !important;
                            height: 30px !important;
                            padding: 6px 0px !important;
                            background: transparent;
                            border-radius: 6px;
                            transition: 00.2s;
                            text-transform: uppercase;
                            color: $az-ligh-black;
                            text-decoration: none;
                            position: relative;

                            &:hover,
                            &:active {
                                background: transparent !important;
                                transition: 00.2s;
                                color: $az-ligh-black;
                                border: none;
                                box-shadow: none;
                            }

                            span {
                                position: absolute;
                                width: 18px;
                                height: 18px;
                                line-height: 18px;
                                background: $az-ligh-black;
                                color: #ffffff;
                                text-align: center;
                                font-size: 10px;
                                font-family: Lato-Regular;
                                right: -12px;
                                top: 0px;
                                border-radius: 50%;
                            }

                            img {
                                filter: invert(1) brightness(0.4);

                                @media(max-width: 991px) {
                                    filter: invert(1) brightness(0.4);
                                    width: 24px;
                                    height: 24px;
                                }

                                @media(max-width: 767px) {
                                    filter: invert(1) brightness(0.4);
                                    width: 24px;
                                    height: 24px;
                                }

                            }
                        }

                        a.active {
                            font-family: $az-heading-two !important;
                            font-size: $az-font-14 !important;
                            height: 30px !important;
                            padding: 6px 15px !important;
                            text-transform: uppercase !important;
                            background: transparent;
                            transition: 00.2s;
                            color: $az-ligh-black;

                            &:hover,
                            &:active {
                                background: transparent !important;
                                transition: 00.2s;
                                color: $az-ligh-black;
                                border: none;
                                box-shadow: none;
                            }
                        }
                    }

                    li.notification {
                        margin: 0 10px;
                        display: none;

                        button {
                            font-family: $az-heading-two !important;
                            font-size: $az-font-14 !important;
                            height: 30px !important;
                            padding: 6px 0px !important;
                            text-transform: uppercase !important;
                            background: transparent;
                            border-radius: 6px;
                            transition: 00.2s;
                            text-transform: uppercase;
                            color: $az-ligh-black;
                            text-decoration: none;
                            position: relative;
                            border: none;
                            box-shadow: none;
                            margin-right: 0 !important;

                            &:hover,
                            &:active {
                                background: transparent !important;
                                transition: 00.2s;
                                color: $az-ligh-black;
                                border: none;
                                box-shadow: none;
                            }

                            span {
                                position: absolute;
                                width: 18px;
                                height: 18px;
                                line-height: 18px;
                                background: $az-ligh-black;
                                color: #ffffff;
                                text-align: center;
                                font-size: 10px;
                                font-family: Lato-Regular;
                                right: -7px;
                                top: 0px;
                                border-radius: 50%;
                            }

                            img {
                                filter: invert(1) brightness(0.4);

                                @media(max-width: 991px) {
                                    filter: invert(1) brightness(0.4);
                                    width: 24px;
                                    height: 24px;
                                }

                                @media(max-width: 767px) {
                                    filter: invert(1) brightness(0.4);
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }

                        button.active {
                            font-family: $az-heading-two !important;
                            font-size: $az-font-14 !important;
                            height: 30px !important;
                            padding: 6px 15px !important;
                            text-transform: uppercase !important;
                            background: transparent;
                            transition: 00.2s;
                            color: $az-ligh-black;

                            &:hover,
                            &:active {
                                background: transparent !important;
                                transition: 00.2s;
                                color: $az-ligh-black;
                                border: none;
                                box-shadow: none;
                            }

                        }
                    }

                }
            }
        }
    }
}

main.Brandmainpage {
    position: relative;
    z-index: 1;

    .container {
        max-width: 1400px;

        @media(max-width:1366px) {
            padding: 0 30px;
        }

        @media(max-width:1280px) {
            padding: 0 30px;
        }

        @media(max-width:1199px) {
            padding: 0 30px;
        }

        @media(max-width:1024px) {
            padding: 0 30px;
        }

        @media(max-width:991px) {
            padding: 0 30px;
        }

        @media(max-width:767px) {
            padding: 0 10px !important;
        }
    }

    // header
    @include OHtopHead;

    // Middle

    .banner {
        background-image: url("");
        min-height: 740px !important;
        height: 740px !important;
        padding: 0;
        background-repeat: no-repeat;
        background-size: 100% !important;
        background-position: bottom 87% right 0 !important;
        position: relative;
        z-index: 1;
        background-color: $az-white !important;


        &.banner_two {
            // background-image: url(https://did1d8jg9ar6w.cloudfront.net/website/kini/banner.jpg);
        }

        @media(max-width: 1920px) {
            // background-size: 120% !important;
            background-size: 100% !important;
            background-position: bottom 87% right 0 !important;
            min-height: 740px !important;
            height: 740px !important;


        }

        @media(max-width: 1366px) {

            // background-size: 120% !important;
            background-size: 100% !important;
            background-position: top 0% right 15% !important;
            min-height: 740px !important;
            height: 740px !important;

        }

        @media(max-width: 1280px) {

            // background-size: 120% !important;
            background-size: 115% !important;
            background-position: top 0% right 15% !important;
            min-height: 740px !important;
            height: 740px !important;

        }

        @media(max-width: 1199px) {
            // background-size: 160% !important;
            background-size: 110% !important;
            background-position: top 0% right 13% !important;
            min-height: 640px !important;
            height: 640px !important;

        }

        @media(max-width: 1023px) {
            // background-size: 130% !important;
            background-size: 130% !important;
            background-position: top 0% right 13% !important;
            min-height: 640px !important;
            height: 640px !important;

        }


        @media(max-width: 991px) {
            background-size: 140% !important;
            background-position: top 0% right 0% !important;
            min-height: 570px !important;
            height: 570px !important;

        }

        @media(max-width: 767px) {
            // background-image: url(../images/oh-corporate-img/banner.png);
            background-position: top 0% right 0% !important;
            min-height: 400px !important;
            height: 400px !important;
            background-size: 200% !important;
            background-position: 78% 0 !important;


        }



        // @include Banner;
        &::after {
            position: absolute;
            content: '';
            width: 100% !important;
            height: 500px !important;
            top: 0 !important;
            left: 0 !important;
            transform: translate(0, 0px) !important;
            bottom: 0 !important;
            z-index: 0 !important;
            border-top: 740px solid rgba(0, 0, 0, 0.3) !important;
            border-left: 0 solid transparent !important;
            border-right: 820px solid transparent !important;

            @media(max-width: 1366px) {
                border-top: 740px solid rgba(0, 0, 0, 0.3) !important;
                border-left: 0 solid transparent !important;
                border-right: 725px solid transparent !important;
            }

            @media(max-width: 1280px) {
                border-top: 740px solid rgba(0, 0, 0, 0.3) !important;
                border-left: 0 solid transparent !important;
                border-right: 725px solid transparent !important;
            }

            @media(max-width: 1199px) {
                border-top: 640px solid rgba(0, 0, 0, 0.3) !important;
                border-left: 0 solid transparent !important;
                border-right: 625px solid transparent !important;
            }

            @media(max-width: 1023px) {
                border-top: 640px solid rgba(0, 0, 0, 0.3) !important;
                border-left: 0 solid transparent !important;
                border-right: 625px solid transparent !important;
            }

            @media(max-width: 991px) {
                display: none;
            }

            @media(max-width: 767px) {
                display: none;
            }


        }

        h1 {
            color: $az-white;
            font-family: $az-kini-heading-three;
            margin-top: -100px;
            font-size: $az-font-66;
            text-align: left;
            line-height: 1.2;
            padding: 0px;
            margin-bottom: 0px;
            position: relative;
            z-index: 1;
            text-shadow: 1px 2px 3px #000;
            text-shadow: 1px 2px 3px #000;
            -webikit-text-shadow: 1px 2px 3px #000;
            -moz-text-shadow: 1px 2px 3px #000;
            -o-text-shadow: 1px 2px 3px #000;
            letter-spacing: 0rem;

            &:nth-child(2) {
                margin-top: 0px;

                @media (max-width:1366px) {
                    margin-top: 0px;
                }

                @media (max-width:1280px) {
                    margin-top: 0px;
                }
            }

            @media (max-width:1366px) {
                font-size: $az-font-66;
                padding: 0 0px;
                margin-top: -100px;
            }

            @media (max-width:1280px) {
                font-size: $az-font-66;
                padding: 0 0px;
                margin-top: -50px;
            }

            @media (max-width:1199px) {
                font-size: $az-font-50;
                padding: 0px;
            }

            @media (max-width:1023px) {
                font-size: $az-font-50;
                padding: 0px;
            }

            @media (max-width:991px) {
                font-size: $az-font-40;
                padding: 0px;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
            }

            @media (max-width:767px) {
                font-size: $az-font-26;
                padding: 0 5px;
                margin: 0px auto;
                text-align: center;
                // display: contents;
            }

        }

        h4 {
            position: relative;
            z-index: 1;
            color: $az-white;
            text-align: left;
            font-size: $az-font-33;
            max-width: 825px;
            margin-top: 20px;
            font-family: $az-heading-two;
            letter-spacing: 0;

            @media(max-width: 1366px) {
                max-width: 800px;

            }

            @media(max-width: 1280px) {
                max-width: 800px;

            }

            @media(max-width: 1199px) {
                max-width: 600px;
                font-size: $az-font-25;

            }

            @media(max-width: 1023px) {
                max-width: 600px;
                font-size: $az-font-24;

            }

            @media (max-width:991px) {
                text-align: center;
                margin-top: 10px;
                font-size: $az-font-18;
                margin-left: auto;
                margin-right: auto;


            }

            @media (max-width:767px) {
                text-align: center;
                margin-top: 10px;
                font-size: $az-font-32;


            }
        }

        span {
            position: relative;
            z-index: 1;
            color: $az-white;
            text-align: left;
            font-size: $az-font-33;
            max-width: 100%;
            margin-top: 0px;
            font-family: $az-heading-two;
            letter-spacing: 0;

            @media(max-width: 1366px) {
                max-width: 100%;

            }

            @media(max-width: 1280px) {
                max-width: 100%;

            }

            @media(max-width: 1199px) {
                max-width: 100%;
                font-size: $az-font-25;

            }

            @media(max-width: 1023px) {
                max-width: 100%;
                font-size: $az-font-24;

            }

            @media (max-width:991px) {
                text-align: center;
                // margin-top: 10px;
                font-size: $az-font-24;
                margin-left: auto;
                margin-right: auto;


            }

            @media (max-width:767px) {
                text-align: center;
                // margin-top: 10px;
                font-size: $az-font-18;


            }
        }

        .container {

            @media (max-width:991px) {
                justify-content: start !important;
                padding-top: 90px !important;
            }

            @media (max-width:767px) {
                justify-content: start !important;
                padding-top: 80px !important;
            }
        }


        .deskViewBannercontent {
            display: flex !important;
            justify-content: start !important;
            padding-top: 130px;

            @media (max-width:991px) {
                justify-content: flex-start !important;
                padding: 70px 0 !important;
                align-items: center !important;
            }

            @media (max-width:767px) {
                justify-content: flex-start !important;
                padding: 70px 0 !important;
                align-items: center !important;
            }

        }
    }

    // main filter
    .mainfilter {
        @include mainfilter;
    }

    footer {
        text-align: center;
        background: $az-light-gray-foot;
        padding: 15px 15px;

        .footer-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width:991px) {
                flex-direction: column;
            }

            @media (max-width:767px) {
                flex-direction: column;
            }

            p {
                @include brandParagraph;
                color: $az-ligh-mid-black;
                margin-bottom: 0;
                font-size: $az-font-16 !important;
            }

            ul {
                list-style: none;
                display: flex;
                margin-bottom: 0;
                padding-left: 0;

                @media (max-width:767px) {
                    flex-wrap: wrap;
                    justify-content: center;
                }

                li {
                    a {
                        text-decoration: none;
                        color: $az-ligh-mid-black;
                        font-family: $az-heading-two;
                        font-size: $az-font-16
                    }

                    margin: 0 10px 0 0;
                    padding: 0 10px 0 0;
                    position: relative;

                    &::after {
                        content: '';
                        height: 15px;
                        width: 1px;
                        background: $az-ligh-mid-black;
                        top: 5px;
                        position: absolute;
                        right: 0;
                    }

                    // &::before {
                    //     content: '';
                    //     height: 15px;
                    //     width: 1px;
                    //     background: $az-ligh-mid-black;
                    //     top: 5px;
                    //     position: absolute;
                    //     left: 0;
                    // }
                    &:last-child {
                        &::after {
                            width: 0;
                        }
                    }

                }
            }


        }

        img.top-arrow {
            position: fixed;
            right: 29px;
            bottom: 150px;
            z-index: 9;
            cursor: pointer;
            display: none;

            @media (max-width:991px) {
                bottom: 150px;
                right: 29px;
            }

            @media (max-width:767px) {
                bottom: 150px;
                right: 20px;
            }
        }
    }

}

.datepickermove {

    @include datepiker;
    position: relative;
    padding: 0px !important;
    z-index: 0;
    height: 40px;


    label {
        margin-left: auto;
        margin-right: auto;
        font-family: Lato-Regular;
        float: inherit;
        position: absolute;
        top: 0 !important;
        z-index: 1;
        background: #fff;
        padding: 0px 8px;
        font-size: 0.75em;

        @media (max-width:991px) {
            padding: 0px 5px;
        }

        @media (max-width:767px) {
            padding: 0px 5px;
        }

    }

    .react-datepicker-wrapper {
        position: absolute;
        left: 0;

        input {

            outline: none !important;
            box-shadow: none !important;
            padding: 10px 14px 12px !important;
            width: 100% !important;
            height: 40px;
            display: block;
            border: 1px solid rgba(0, 0, 0, 0.23);

            @media (max-width:991px) {

                padding: 12px 14px 12px !important;
            }

            @media (max-width:767px) {

                padding: 12px 14px 12px !important;
            }

            &:hover {
                border: 1px solid $az-black;

            }

            &:focus {
                border: 1px solid $az-oh-seconday !important
            }

        }
    }


}

.filter {
    .filterBlock {
        padding: 0 60px;
        position: relative;
        z-index: 1;

        @media (max-width:1366px) {
            padding: 0 15px;
        }
    }
}

.nav-text {
    font-size: $az-font-20;
    color: $az-white !important;
    font-family: $az-kini-heading-three;
    text-transform: capitalize;
    text-decoration: none;
    text-align: left;
    padding: 10px 35px !important;
}

.pointer {
    cursor: pointer !important;
}

.Link {
    color: $az-black;
    text-decoration: underline;
    font-family: $az-heading-para;
    cursor: pointer;
}

.text-align-left {
    text-align: left !important;
}

.marign-bottom {
    margin-bottom: 0px;

    @media(max-width:991px) {
        margin-bottom: 10px;
    }

    @media(max-width:767px) {
        margin-bottom: 10px;
    }
}


// filter style

#menu-add {
    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
        background-color: rgba(219, 219, 219, 0.4) !important;
    }

    .MuiMenu-list {
        width: 100% !important;
    }
}

.MuiAutocomplete-popper {
    border-radius: 20px !important;
    margin-top: 25px !important;
    margin-left: 10px !important;
    width: 220px !important;
    z-index: 99 !important;

    @media (max-width:1280px) {
        margin-left: 25px !important;
    }

    @media (max-width:1200px) {
        margin-left: 40px !important;
        min-width: 50% !important;
    }

    @media (max-width:1023px) {
        margin-left: 40px !important;
        min-width: 50% !important;
    }

    @media (max-width:991px) {
        margin-left: 40px !important;
        min-width: 50% !important;
    }

    @media (max-width:768px) {
        margin-left: 40px !important;
        margin-top: 15px !important;
    }

    @media (max-width:600px) {
        margin-left: 0px !important;
        margin-top: 15px !important;
    }

}

#menu-add {
    li {
        font-family: $az-heading-two !important;
        @media (max-width:767px) {
            white-space: break-spaces !important;
        }
    }

    .MuiMenu-paper {
        box-shadow: 0px 0px 3px 0px #cccccc !important;
        margin-top: 15px;
        // max-width: 16% !important;
        // width: 16% !important;
        margin-left: 7px;
        width: 220px !important;
        // min-height: 250px;
        max-height: 250px;
        overflow: auto;

        @media (max-width:1400px) {
            margin-left: 0px;
        }

        @media (max-width:991px) {
            min-width: 90% !important;
            left: 22px !important;
        }

        @media (max-width:767px) {
            min-width: 90% !important;
            left: 22px !important;
        }

        .MuiMenu-list {
            @media(max-width: 767px) {
                word-wrap: break-word !important;
                white-space: normal !important;
            }
        }
    }
}

#menu-adddate {

    .MuiMenu-paper {
        box-shadow: 0px 0px 3px 0px #cccccc !important;
        margin-top: 15px;
        // max-width: 16% !important;
        // width: 16% !important;
        margin-left: 7px;
        width: 220px !important;
        // min-height: 200px;
        max-height: 200px;
        overflow: auto;

        @media (max-width:1400px) {
            margin-left: 0px;
        }

        @media (max-width:991px) {
            min-width: 75% !important;
            left: 40px !important;
        }

        @media (max-width:767px) {
            min-width: 75% !important;
            left: 40px !important;
        }
    }

    .MuiMenu-list {
        width: 100% !important;
    }
}



.mbsc-picker {

    .mbsc-ios.mbsc-popup-body {
        margin-top: 20px;
        margin-bottom: 43px;
        box-shadow: 0px 0px 3px 0px #cccccc !important;
    }

    .mbsc-ios.mbsc-range-control-value.active {
        color: $az-black;
        font-family: $az-heading-two;
        font-weight: normal;
    }

    .mbsc-ios.mbsc-calendar-button.mbsc-button {
        color: $az-black;
        font-family: $az-heading-two;
        background: none;
    }

    .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
        border-color: #333;
        background: #333 !important;
    }

    .mbsc-ios.mbsc-calendar-cell.mbsc-hover .mbsc-calendar-cell-text {
        background: rgba(0, 0, 0, 0.1);
    }

    .mbsc-ios.mbsc-range-day:after {
        background-color: #f2f2f2;

    }

    .mbsc-ios.mbsc-segmented {
        background: #f2f2f2;
    }

    .mbsc-ios.mbsc-range-control-label.active {
        font-weight: normal;
        font-family: $az-heading-two;
    }

    .mbsc-ios.mbsc-calendar-today {
        color: $az-black;
        font-weight: 600;
    }

}

// End filter style

.mainListing {
    padding: 10px 0 0px;
    background: $az-white;
    position: relative;
    z-index: 8;

    h2 {
        @include heading2;
        text-align: left;
        color: $az-ligh-mid-black;

        @media (max-width:991px) {
            text-align: left;
        }

        @media (max-width:767px) {
            text-align: center;
        }
    }

    h4 {
        @include heading4;
        text-align: left;
        font-family: $az-heading-two;
        margin-top: 20px;
        color: $az-ligh-black;

        @media (max-width:991px) {
            text-align: left;
            margin-bottom: 0;
        }

        @media (max-width:767px) {
            text-align: center;
            margin-bottom: 30px;
        }
    }

    p {
        @include heading4;
        text-align: left;
        font-family: $az-heading-two;
        margin-top: 20px;
        color: $az-ligh-black;

        @media (max-width:991px) {
            text-align: left;
            margin-bottom: 0;
        }

        @media (max-width:767px) {
            text-align: center;
            margin-bottom: 30px;
        }
    }

    .listImg {
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;

        figure {
            max-width: 100%;
            // max-height: 480px;
            // min-height: 480px;
            max-height: 448px;
            min-height: 448px;
            position: relative;
            overflow: hidden;
            margin-bottom: 0;

            img {
                // max-height: 480px;
                // min-height: 480px;
                max-height: 448px;
                min-height: 448px;
                width: 100%;
                -webkit-transition: all .5s;
                transition: all .5s;
                object-fit: cover;
            }
        }

        .content {
            padding: 20px;
            position: absolute;
            bottom: -60px;
            left: 0;
            width: 100%;
            -webkit-transition: all .3s;
            transition: all .3s;
            text-align: left;
            z-index: 1;
            height: auto;
            box-sizing: border-box;
            background: -moz-linear-gradient(top, transparent 0, rgba(0, 0, 0, .1) 2%, rgba(0, 0, 0, .75) 90%);
            background: -webkit-linear-gradient(top, transparent 0, rgba(0, 0, 0, .01) 2%, rgba(0, 0, 0, .75) 90%);
            background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .01) 2%, rgba(0, 0, 0, .75) 90%);


            b {
                position: relative;
                color: $az-white;
                font-size: $az-font-16;
                display: block;
                font-family: $az-heading-two;
                font-weight: normal;
            }

            h1 {
                position: relative;
                color: $az-white;
                font-size: $az-font-20;
                font-weight: 400;
                margin-top: 5px;
                margin-bottom: 5px;
                font-family: $az-kini-heading-three;
                display: block;
                width: 100%;
            }

            a {
                color: $az-white !important;
                text-decoration: none !important;

                span {
                    position: relative;
                    color: $az-white;
                    font-size: $az-font-20;
                    font-weight: 400;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    font-family: $az-kini-heading-three;
                    display: block;
                    width: 100%;
                    text-decoration: none !important;
                }
            }

            .line {
                text-align: center;
                height: 1px;
                width: 60px;
                background-color: rgba(255, 255, 255, 0.2);
                transition-property: all;
                transition-duration: 0.3s;
                transition-timing-function: ease-in-out;
                margin: auto 0 30px;
                border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
            }

            button {
                text-align: right !important;
                border: none;
                background: none;

            }

        }


        a {
            color: $az-white !important;
            text-decoration: none !important;

            span {
                position: relative;
                color: $az-white !important;
                font-size: $az-font-20;
                font-weight: 400;
                margin-top: 5px;
                margin-bottom: 5px;
                font-family: $az-kini-heading-three;
                display: block;
                width: 100%;
                text-decoration: none !important;
            }
        }

        .overlap {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 0;
        }

        &:hover {
            img {
                transform: scale(1.09, 1.09);
                -webkit-filter: brightness(70%);
                -webkit-transition: all 1s ease;
                -moz-transition: all 1s ease;
                -o-transition: all 1s ease;
                -ms-transition: all 1s ease;
                transition: all 1s ease;
            }

            .content {
                bottom: 0;

                .line {
                    width: 100%;
                    transition-property: all;
                    transition-duration: 0.8s;
                    transition-timing-function: ease-in-out;
                }

                button {
                    text-align: right !important;
                    border: none;
                    background: none;
                    color: $az-white;
                    text-transform: uppercase;
                    margin-top: -5px;

                    img {
                        filter: inherit;
                        margin-left: 5px;
                    }

                }
            }
        }
    }

    .listItem {

        @media (max-width:991px) {
            padding-left: 0;
            padding-right: 0;
        }

        @media (max-width:767px) {
            padding-left: 0;
            padding-right: 0;
        }

        .listImg {

            @media (max-width:991px) {
                margin-bottom: 10px;
            }

            @media (max-width:767px) {
                margin-bottom: 10px;
            }

        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            figure {
                max-width: 100%;
                // max-height: 449px;
                // min-height: 449px;
                max-height: 448px;
                min-height: 448px;
                object-fit: cover;

                @media (max-width:991px) {
                    // max-height: 480px;
                    // min-height: 480px;
                    max-height: 448px;
                    min-height: 448px;
                    object-fit: cover;
                }

                @media (max-width:767px) {
                    max-height: 296px;
                    min-height: 296px;
                    object-fit: cover;

                }


                img {

                    // max-height: 449px;
                    // min-height: 449px;
                    max-height: 448px;
                    min-height: 448px;
                    object-fit: cover;
                    width: 100%;

                    @media (max-width:991px) {
                        // min-height: 480px;
                        min-height: 448px;
                        object-fit: cover;
                        // max-height: 480px;
                        max-height: 448px;
                    }

                    @media (max-width:767px) {
                        min-height: 296px;
                        object-fit: cover;
                        max-height: 296px;

                    }
                }
            }
        }
    }
}

.howdoesitworkSection {
    .container {
        .row {
            @media (max-width:991px) {
                margin: 0;
            }

            @media (max-width:767px) {
                margin: 0;
            }
        }
    }

    // background: $az-oh-seconday;
    padding: 0px 0 90px;
    position: relative;
    z-index: 1;

    h3 {
        @include brandHeading-3;
        color: $az-ligh-mid-black;
    }

    .bd-r {
        padding: 0;

        @media (max-width:991px) {
            width: 100%;
        }

        @media (max-width:767px) {
            width: 100%;
        }

        .howwork {
            padding: 0px 15px;
            // border-right: 1px solid $az-border-color;
            height: 100%;

            @media (max-width:991px) {
                border-right: none;
                padding: 30px 15px;
            }

            @media (max-width:767px) {
                border-right: none;
                padding: 30px 15px;
            }


            h1 {
                @include brandHeading-1;
                color: $az-oh-step-light ;
                padding: 0;
                margin: 0;
                text-align: left;
                line-height: 1;
                margin-bottom: 30px;
                opacity: 0.6;
            }

            h3 {
                @include heading3;
                color: $az-light-black;
            }

            h6 {
                @include heading6;
                color: $az-light-black;
                font-family: $az-heading-two !important;
                text-align: left;
                margin-top: 15px;
            }

            p {
                @include heading6;
                color: $az-light-black;
                font-family: $az-heading-two !important;
                text-align: left;
                margin-top: 15px;
            }

            .mobviewhowwork {
                width: 100%;
                margin-top: 50px;
                padding: 0 180px 0 0;
                display: flex;
                align-items: center;
                height: 100%;

                @media (max-width:991px) {
                    margin-top: 20px;
                }

                @media (max-width:767px) {
                    margin-top: 20px;
                }


                h4 {
                    @include heading4;
                    color: $az-ligh-mid-black;
                    margin: 10px 0 0 !important;
                    text-align: left;
                    line-height: 1;
                    margin-bottom: 30px;
                    position: relative;
                    top: -5px;
                    margin-left: 10px;
                    font-family: $az-heading-para;
                }

                .align-items-baseline {

                    @media (max-width:991px) {
                        justify-content: center;
                    }

                    @media (max-width:767px) {
                        justify-content: center;
                    }
                }

            }


        }



        &:nth-child(2) {
            .howwork {
                border: none;
            }
        }

    }

    &::before {
        background-image: url(../images/oh-corporate-img/shape-1.png);
        position: absolute;
        right: 0;
        top: 0;
        width: 288px;
        height: 131px;
        content: '';

        @media (max-width:991px) {
            transform: translate(50%, 0%);
            right: 50%;
            z-index: -1;
        }

        @media (max-width:767px) {
            transform: translate(50%, 0%);
            right: 50%;
            z-index: -1;
        }
    }

    &::after {
        background-image: url(../images/oh-corporate-img/shape-2.png);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 288px;
        height: 131px;
        content: '';

        @media (max-width:991px) {
            transform: translate(-50%, 0%);
            left: 50%;
            z-index: -1;
        }

        @media (max-width:767px) {
            transform: translate(-50%, 0%);
            left: 50%;
            z-index: -1;
        }
    }

    .shapeBg {
        position: relative;

        &::before {
            background-image: url(../images/oh-corporate-img/small-shape.png);
            position: absolute;
            left: 2%;
            top: -12%;
            width: 89px;
            height: 89px;
            content: '';
            display: block;

            @media (max-width:991px) {
                display: none;
            }

            @media (max-width:767px) {
                display: none;
            }
        }

        &::after {
            background-image: url(../images/oh-corporate-img/small-shape.png);
            position: absolute;
            right: 2%;
            bottom: -12%;
            width: 89px;
            height: 89px;
            content: '';
            display: block;

            @media (max-width:991px) {
                display: none;
            }

            @media (max-width:767px) {
                display: none;
            }
        }

        button {
            @include Brandbutton1;
        }
    }

}

.casestudy {
    h4 {
        @include heading4;
        font-family: $az-heading-two !important;
    }
}

.everthing {
    padding: 100px 0 20px;
    position: relative;
    background: $az-white;
    z-index: 1;

    @media (max-width:1366px) {
        padding: 100px 0 20px;
    }

    @media (max-width:1280px) {
        padding: 100px 0 60px;
    }

    @media (max-width:1023px) {
        padding: 100px 0 60px;
    }

    @media (max-width:767px) {
        padding: 60px 0 0px;
    }

    .container {
        .row {

            @media (max-width:991px) {
                margin: 0;
            }

            @media (max-width:767px) {
                margin: 0;
            }
        }
    }

    .simulatorscreen {
        position: relative;
        //  left: -115px;
        margin-top: -20px;
        width: calc(100% - 57%);

        .mobilescreen {
            margin-left: -25px;

        }

        .mobilescreenBg {
            position: absolute;
            left: 0;
            top: 54%;
            transform: translate(0, -50%);
            z-index: -1;

            @media (max-width:1366px) {
                position: absolute;
                left: 0;
                top: 55%;
                transform: translate(0, -51%);
                z-index: -1;
            }

            @media (max-width:1280px) {
                position: absolute;
                left: 0;
                top: 55%;
                transform: translate(0, -51%);
                z-index: -1;
            }
        }

        @media (max-width:1366px) {
            width: calc(100% - 54%);
            margin-top: 45px;
        }


        @media (max-width:1280px) {
            width: calc(100% - 54%);
            margin-top: 45px;
        }


        @media (max-width:1199px) {
            width: calc(100% - 52%);
            margin-top: 60px;
        }

        @media (max-width:1023px) {
            width: calc(100% - 48%);
            margin-top: 60px;
        }

        @media (max-width:991px) {
            width: 72%;
            margin: auto;
        }

        @media (max-width:767px) {
            width: 90%;
            margin: auto;
        }
    }

    .content {
        width: 57%;

        @media (max-width:1366px) {
            width: 54%;
        }

        @media (max-width:1280px) {
            width: 54%;
        }

        @media (max-width:1199px) {
            width: 52%;
        }

        @media (max-width:1023px) {
            width: 48%;
        }

        @media (max-width:991px) {
            width: 100%;
        }

        @media (max-width:767px) {
            width: 100%;
        }

        h2 {
            @include heading2;
            // @include brandHeading-3;
            text-align: left;
            color: $az-ligh-mid-black;
            padding-right: 400px;

            @media (max-width:1280px) {
                text-align: left;
                padding: 0 162px 0 0;
            }

            @media (max-width:991px) {
                text-align: left;
                padding: 0 100px;
            }

            @media (max-width:767px) {
                text-align: center;
                padding: 0px;
            }


        }

        h5 {
            @include brandHeading-5;
            text-align: left;
            font-family: $az-heading-two;
            margin-top: 20px;
            padding-right: 265px;
            color: $az-light-black;

            @media (max-width:1366px) {
                padding-right: 180px;
            }

            @media (max-width:1280px) {
                padding-right: 180px;
            }

            @media (max-width:1199px) {
                padding-right: 40px;
            }

            @media (max-width:991px) {
                text-align: left;
                padding: 0 100px;
            }

            @media (max-width:767px) {
                text-align: center;
                padding: 0px;
            }
        }

        p {
            @include brandHeading-5;
            text-align: left;
            font-family: $az-heading-two;
            margin-top: 20px;
            padding-right: 265px;
            color: $az-light-black;

            @media (max-width:1366px) {
                padding-right: 180px;
            }

            @media (max-width:1280px) {
                padding-right: 180px;
            }

            @media (max-width:1199px) {
                padding-right: 40px;
            }

            @media (max-width:991px) {
                text-align: left;
                padding: 0 100px;
            }

            @media (max-width:767px) {
                text-align: center;
                padding: 0px;
            }
        }


        .downnloadApp {

            @media (max-width:991px) {
                justify-content: center;
            }

            @media (max-width:767px) {
                justify-content: center;
            }
        }

        ul {
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: 30px;

            li {
                width: 50%;
                text-align: left;
                padding-right: 130px;
                display: flex;
                margin-bottom: 30px;
                padding-right: 175px;

                img {
                    width: 46px;
                }

                b {
                    font-weight: normal;
                    font-size: $az-font-18;
                    color: $az-light-black;
                    margin-left: 20px;
                    line-height: 1.3;
                }

                @media (max-width:1366px) {
                    padding-right: 120px;
                }

                @media (max-width:1280px) {
                    padding-right: 100px;
                }

                @media (max-width:1199px) {
                    padding-right: 45px;
                }

                @media (max-width:1023px) {
                    padding-right: 15px;
                }

                @media (max-width:991px) {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                }

                @media (max-width:767px) {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                }
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 153px;
        background: $az-ligh-mid-black;
        z-index: -2;
        display: none;

        @media (max-width:1366px) {
            height: 126px;
        }

        @media (max-width:1280px) {
            height: 126px;
        }
    }

}

.brandBlockone {
    padding: 100px 0 0;
    background: $az-white;
    position: relative;
    z-index: 1;

    .container {
        .row {
            @media (max-width:991px) {
                margin: 0;
            }

            @media (max-width:767px) {
                margin: 0;
            }

            .col-lg-8 {
                flex: 0 0 auto;
                width: 66%;

                @media (max-width:1280px) {
                    flex: 0 0 auto;
                    width: 66.66667%;
                }

                @media (max-width:1199px) {
                    flex: 0 0 auto;
                    width: 66.66667%;
                }

                @media (max-width:1023px) {
                    flex: 0 0 auto;
                    width: 66.66667%;
                }

                @media (max-width:991px) {
                    flex: 0 0 auto;
                    width: 100%;
                }

                @media (max-width:767px) {
                    flex: 0 0 auto;
                    width: 100%;
                }
            }
        }
    }

    figure {
        max-width: 100%;
        max-height: 722px;
        // min-height: 722px;
        position: relative;
        overflow: hidden;
        margin-bottom: 0;

        img {
            max-height: 722px;
            width: 100%;
        }
    }

    .content {
        width: 690px;
        // max-height: 525px;
        // min-height: 480px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: $az-oh-seconday;
        padding: 60px 45px 55px;
        margin-left: 0;
        margin-top: 0px;
        z-index: 1;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);

        @media (max-width: 1366px) {
            width: 630px;
            margin-top: 0px;
            padding: 40px 45px 55px;
        }

        @media (max-width: 1280px) {
            width: 630px;
            margin-top: 0px;
            padding: 40px 45px 55px;
        }

        @media (max-width: 1199px) {
            margin-top: 0px;
            max-height: 410px;
            min-height: 410px;
        }

        @media (max-width: 1023px) {
            margin-top: 0px;
            max-height: 380px;
            min-height: 380px;
        }

        @media (max-width: 991px) {
            width: 100%;
            position: unset;
            left: 0;
            padding: 40px 30px;
            min-height: auto;
            max-height: auto;
            margin-top: 10px;
            margin-bottom: 10px;
            top: 0;
            transform: none;
        }

        @media (max-width: 767px) {
            width: 100%;
            position: relative;
            left: 0;
            padding: 40px 30px;
            min-height: auto;
            max-height: auto;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        h3 {
            @include heading3;
            color: $az-light-black;
            text-align: left;
            line-height: 1.4;
        }

        p {
            font-family: $az-heading-two;
            font-size: $az-font-18;
            color: $az-ligh-mid-black;
            text-align: left;
            padding: 25px 0;

            @media (max-width: 1199px) {
                padding: 10px 0;
                font-size: $az-font-16;

            }

        }

        button {
            border: none;
            background: transparent;
            color: $az-oh-black;
            border: 2px solid $az-oh-black;
            padding: 15px 20px;
            border-radius: 0px;
            transition: 00.2s;

        }
    }

    @media (max-width: 991px) {
        padding: 60px 0 0px;
    }

    @media (max-width: 767px) {
        padding: 60px 0 0px;
    }
}

.brandBlocktwo {
    padding: 100px 0;
    background: $az-white;
    position: relative;
    z-index: 1;

    .container {
        .row {

            @media (max-width:991px) {
                margin: 0;
            }

            @media (max-width:767px) {
                margin: 0;
            }

            .col-lg-8 {
                flex: 0 0 auto;
                width: 66%;

                @media (max-width:1280px) {
                    flex: 0 0 auto;
                    width: 66.66667%;
                }

                @media (max-width:1199px) {
                    flex: 0 0 auto;
                    width: 66.66667%;
                }

                @media (max-width:1023px) {
                    flex: 0 0 auto;
                    width: 66.66667%;
                }

                @media (max-width:991px) {
                    flex: 0 0 auto;
                    width: 100%;
                }

                @media (max-width:767px) {
                    flex: 0 0 auto;
                    width: 100%;
                }
            }
        }
    }

    figure {
        max-width: 100%;
        max-height: 722px;
        // min-height: 722px;
        position: relative;
        overflow: hidden;
        margin-bottom: 0;

        img {
            max-height: 722px;
            width: 100%;
        }
    }

    .content {
        width: 690px;
        // max-height: 525px;
        // min-height: 480px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: $az-oh-seconday;
        padding: 60px 45px 55px;
        margin-left: 0;
        margin-top: 0px;
        z-index: 1;
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translate(0, -50%);

        @media (max-width: 1366px) {
            width: 630px;
            margin-top: 0px;
            padding: 40px 45px 55px;
        }

        @media (max-width: 1280px) {
            width: 630px;
            margin-top: 0px;
            padding: 40px 45px 55px;
        }

        @media (max-width: 1199px) {
            margin-top: 0px;
            max-height: 410px;
            min-height: 410px;
        }

        @media (max-width: 1023px) {
            margin-top: 0px;
            max-height: 380px;
            min-height: 380px;
        }

        @media (max-width: 991px) {
            width: 100%;
            position: unset;
            left: 0;
            padding: 40px 30px;
            min-height: auto;
            max-height: auto;
            margin-bottom: 0px;
            margin-top: 10px;
            top: 0;
            transform: unset;
        }

        @media (max-width: 767px) {
            width: 100%;
            position: unset;
            left: 0;
            padding: 40px 30px;
            min-height: auto;
            max-height:none;
            margin-bottom: 0px;
            margin-top: 10px;
            top: 0;
            transform: unset;
        }

        h3 {
            @include heading3;
            color: $az-light-black;
            text-align: left;
            line-height: 1.4;
        }

        p {
            font-family: $az-heading-two;
            font-size: $az-font-18;
            color: $az-ligh-mid-black;
            text-align: left;
            padding: 25px 0;

            @media (max-width: 1199px) {
                padding: 10px 0;
                font-size: $az-font-16;

            }
        }

        button {
            border: none;
            background: transparent;
            border: 2px solid $az-oh-black;
            color: $az-oh-black;
            padding: 15px 20px;
            border-radius: 0px;
            transition: 00.2s;
        }
    }

    @media (max-width: 991px) {
        padding: 0px 0 60px;
    }

    @media (max-width: 767px) {
        padding: 0px 0 60px;
    }

    .row {
        @media (max-width: 991px) {
            flex-direction: column-reverse;
        }

        @media (max-width: 767px) {
            flex-direction: column-reverse;
        }

    }
}

.brandParent {
    background: $az-oh-seconday;
    padding: 80px 0px 100px;
    position: relative;
    z-index: 1;

    h2 {
        @include heading3;
        color: $az-ligh-mid-black;
    }

    .brandBlockthree {
        padding: 75px 55px 0;
        position: relative;

        .container {
            .row {
                @media (max-width:991px) {
                    margin: 0;
                }

                @media (max-width:767px) {
                    margin: 0;
                }

                .col-lg-8 {
                    flex: 0 0 auto;
                    width: 66%;

                    @media (max-width:1280px) {
                        flex: 0 0 auto;
                        width: 66.66667%;
                    }

                    @media (max-width:1199px) {
                        flex: 0 0 auto;
                        width: 66.66667%;
                    }

                    @media (max-width:1023px) {
                        flex: 0 0 auto;
                        width: 66.66667%;
                    }

                    @media (max-width:991px) {
                        flex: 0 0 auto;
                        width: 100%;
                    }

                    @media (max-width:767px) {
                        flex: 0 0 auto;
                        width: 100%;
                    }
                }
            }
        }

        figure {
            max-width: 100%;
            max-height: 722px;
            // min-height: 722px;
            position: relative;
            overflow: hidden;
            margin-bottom: 0;

            img {
                max-height: 722px;
                width: 100%;
            }
        }

        .content {
            width: 655px;
            // max-height: 525px;
            // min-height: 480px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            background: $az-white;
            padding: 65px 45px 55px;
            margin-left: 0;
            margin-top: 0px;
            z-index: 1;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translate(0, -50%);

            @media (max-width: 1366px) {
                width: 540px;
                margin-top: 0px;
                padding: 65px 45px 55px;
            }

            @media (max-width: 1280px) {
                width: 540px;
                margin-top: 0px;
                padding: 65px 45px 55px;
            }

            @media (max-width: 1199px) {
                padding: 50px 45px 55px;
                max-height: 375px;
                min-height: 375px;

            }

            @media (max-width: 1023px) {
                margin-top: 0px;
                max-height: 325px;
                min-height: 325px;
            }

            @media (max-width: 991px) {
                width: 100%;
                position: unset;
                left: 0;
                padding: 40px 30px;
                min-height: auto;
                max-height: auto;
                margin-bottom: 10px;
                margin-top: 10px;
                transform: none;
            }

            @media (max-width: 767px) {
                width: 100%;
                position: unset;
                left: 0;
                padding: 40px 30px;
                min-height: auto;
                max-height: auto;
                margin-bottom: 10px;
                margin-top: 10px;
                transform: none;
            }

            h3 {
                @include heading3;
                color: $az-light-black;
                text-align: left;
                line-height: 1.4;
            }

            p {
                font-family: $az-heading-two;
                font-size: $az-font-18 !important;
                color: $az-ligh-mid-black;
                text-align: left;
                padding: 25px 0;

                @media (max-width: 1199px) {
                    padding: 10px 0 !important;
                    font-size: $az-font-16 !important;

                }
            }

            button {
                border: none;
                background: transparent;
                border: 2px solid $az-oh-black;
                color: $az-oh-black;
                padding: 15px 20px;
                border-radius: 0px;
                transition: 00.2s;

            }
        }

        &::after {
            position: absolute;
            background-image: url(../images/oh-corporate-img/block-shap-1.png);
            right: 0;
            width: 324px;
            height: 599px;
            content: '';
            top: 135px;
            display: block;

            @media(max-width: 1366px) {
                top: 70px;
            }

            @media(max-width: 1280px) {
                top: 70px;
            }

            @media(max-width: 991px) {
                display: none;
            }

            @media(max-width: 767px) {
                display: none;
            }

        }

        @media(max-width: 991px) {
            padding: 30px 0 0;
        }

        @media(max-width: 767px) {
            padding: 30px 0 0;
        }

    }

    .brandBlockfour {
        padding: 75px 55px 0;
        position: relative;

        .container {
            .row {
                @media (max-width:991px) {
                    margin: 0;
                }

                @media (max-width:767px) {
                    margin: 0;
                }

                .col-lg-8 {
                    flex: 0 0 auto;
                    width: 66%;

                    @media (max-width:1280px) {
                        flex: 0 0 auto;
                        width: 66.66667%;
                    }

                    @media (max-width:1199px) {
                        flex: 0 0 auto;
                        width: 66.66667%;
                    }

                    @media (max-width:1023px) {
                        flex: 0 0 auto;
                        width: 66.66667%;
                    }

                    @media (max-width:991px) {
                        flex: 0 0 auto;
                        width: 100%;
                    }

                    @media (max-width:767px) {
                        flex: 0 0 auto;
                        width: 100%;
                    }
                }
            }
        }

        figure {
            max-width: 100%;
            max-height: 722px;
            // min-height: 722px;
            position: relative;
            overflow: hidden;
            margin-bottom: 0;

            img {
                max-height: 722px;
                width: 100%;
            }
        }

        .content {
            width: 655px;
            // max-height: 525px;
            // min-height: 480px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            background: $az-white;
            padding: 65px 45px 55px;
            margin-left: 0;
            margin-top: 0px;
            z-index: 1;
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translate(0, -50%);

            @media (max-width: 1366px) {
                width: 540px;
                margin-top: 0px;
                padding: 65px 45px 55px;
            }

            @media (max-width: 1280px) {
                width: 540px;
                margin-top: 0px;
                padding: 65px 45px 55px;
            }

            @media (max-width: 1199px) {
                padding: 50px 45px 55px;
                max-height: 375px;
                min-height: 375px;
            }

            @media (max-width: 1023px) {
                margin-top: 0px;
                max-height: 325px;
                min-height: 325px;
            }

            @media (max-width: 991px) {
                width: 100%;
                position: unset;
                left: 0;
                margin-top: 10px;
                max-height: fit-content;
                padding: 50px 30px 15px;
                min-height: auto;
                transform: none;
            }

            @media (max-width: 767px) {
                width: 100%;
                position: relative;
                left: 0;
                margin-top: 10px;
                max-height: fit-content;
                padding: 50px 30px 15px;
            }

            h3 {
                @include heading3;
                color: $az-light-black;
                text-align: left;
                line-height: 1.4;
            }

            p {
                font-family: $az-heading-two;
                font-size: $az-font-18 !important;
                color: $az-ligh-mid-black;
                text-align: left;
                padding: 25px 0;

                @media (max-width: 1199px) {
                    padding: 10px 0 !important;
                    font-size: $az-font-16 !important;

                }

            }

            button {
                border: none;
                background: transparent;
                border: 2px solid $az-oh-black;
                color: $az-oh-black;
                padding: 15px 20px;
                border-radius: 0px;
                transition: 00.2s;

            }
        }

        &::before {
            position: absolute;
            background-image: url(../images/oh-corporate-img/block-shap-2.png);
            left: 0;
            width: 324px;
            height: 599px;
            content: '';
            top: 135px;
            display: block;

            @media(max-width: 1366px) {
                top: 70px;
            }

            @media(max-width: 1280px) {
                top: 70px;
            }

            @media(max-width: 991px) {
                display: none;
            }

            @media(max-width: 767px) {
                display: none;
            }

        }

        @media(max-width:991px) {
            padding: 0px 0 0;
        }

        @media(max-width: 767px) {
            padding: 0px 0 0;
        }

        .row {

            @media (max-width: 991px) {
                flex-direction: column-reverse;
            }

            @media (max-width: 767px) {
                flex-direction: column-reverse;
            }

        }
    }

    @media(max-width: 991px) {
        padding: 60px 0;
    }

    @media(max-width: 767px) {
        padding: 60px 0;
    }

}

.hostingFeatures {
    padding: 80px 0;
    background: $az-white;
    position: relative;
    z-index: 1;

    @media (max-width: 991px) {
        padding: 60px 0;
    }

    @media (max-width: 767px) {
        padding: 60px 0;
    }

    h2 {
        @include heading2;
        color: $az-ligh-mid-black;
    }

    h5 {
        @include brandHeading-5;
        font-family: $az-heading-two;
        margin-top: 20px;
        color: $az-ligh-black !important;
    }

    p {
        @include brandHeading-5;
        font-family: $az-heading-two;
        // margin-top: 20px;
        color: $az-ligh-black !important;

        @media (max-width:991px) {
            padding: 0 !important;
            font-size: $az-font-18 !important;
          }
          @media (max-width:767px) {
            padding: 0 !important;
            font-size: $az-font-18 !important;
          }
    }

    .swiper {
        width: 100%;
        height: 100%;
        margin-top: 80px;

        @media (max-width: 991px) {
            margin-top: 60px;
        }

        @media (max-width: 767px) {
            margin-top: 60px;
        }

    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        margin: 0 !important;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        @media (min-device-width:995px) and (max-device-width:1200px) {
            display: block;
        }
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-button-prev {
        width: 40px;

        &::after {
            background-image: url(../images/oh-corporate-img/prev.svg);
            width: 70px;
            background-repeat: no-repeat;
            font-size: 0;
            height: 20px;
            background-size: 100%;

            @media (max-width: 991px) {
                filter: brightness(0);
            }

            @media (max-width: 600px) {
                filter: brightness(1);
            }
        }
    }

    .swiper-button-next {
        width: 40px;

        &::after {
            background-image: url(../images/oh-corporate-img/next.svg);
            width: 70px;
            background-repeat: no-repeat;
            font-size: 0;
            height: 20px;
            background-size: 100%;

            @media (max-width: 991px) {
                filter: brightness(0);
                ;
            }

            @media (max-width: 600px) {
                filter: brightness(1);
            }
        }
    }

    .featuresitems {
        position: relative;
        transition: all 0.3s;

        figure {
            max-width: 100%;
            //    max-height: 528px;
            // min-height: 722px;
            position: relative;
            overflow: hidden;
            margin-bottom: 0;

            img {
                //    max-height: 722px;
                width: 100%;
                -webkit-transition: all .5s;
                transition: all .5s;
            }
        }

        .content {
            padding: 0px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            text-align: left;
            z-index: 1;
            height: 100%;
            box-sizing: border-box;
            background: -moz-linear-gradient(top, transparent 0, rgba(0, 0, 0, .1) 2%, rgba(0, 0, 0, .75) 90%);
            background: -webkit-linear-gradient(top, transparent 0, rgba(0, 0, 0, .01) 2%, rgba(0, 0, 0, .75) 90%);
            background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .01) 2%, rgba(0, 0, 0, .75) 90%);

            span {
                position: relative;
                color: #ffffff;
                font-size: $az-font-30;
                font-weight: 400;
                margin-top: 0px;
                margin-bottom: 10px;
                font-family: $az-kini-heading-three;
                display: block;
                width: 100%;
                text-align: center;
                transition: all 0.3s;

                @media(max-width: 1366px) {
                    font-size: $az-font-22;
                }

                @media(max-width: 1280px) {
                    font-size: $az-font-22;
                }

                @media(max-width: 1023px) {
                    font-size: $az-font-26;
                }

                @media(max-width: 991px) {
                    line-height: normal;
                }

            }

            b {
                position: relative;
                color: $az-white;
                font-size: $az-font-16;
                display: block;
                font-family: $az-heading-two;
                font-weight: normal;
                text-align: center;
                display: none;
                transition: all 0.3s;

                @media(max-width: 1023px) {
                    font-size: $az-font-14;
                }

            }

            .content-center {
                position: relative;
                left: 0;
                top: 50%;
                padding: 0 60px;
                text-align: center;
                transition: all 0.3s;
                transform: translate(0, -50%);
            }

        }

        .overlap {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
        }

        &:hover {
            img {
                transform: scale(1.09, 1.09);
                -webkit-filter: brightness(70%);
                -webkit-transition: all 1s ease;
                -moz-transition: all 1s ease;
                -o-transition: all 1s ease;
                -ms-transition: all 1s ease;
                transition: all 1s ease;
            }

            .content {
                bottom: 0;
                transition: all 0.3s;

                .content-center {
                    top: 50%;
                    padding: 0 70px;
                    transition: all 0.3s;
                    transform: translate(0, -50%);

                    @media(max-width: 1366px) {
                        // top: 30%;
                        padding: 0 60px;
                    }

                    @media(max-width: 1280px) {
                        // top: 12%;
                        padding: 0 60px;
                    }

                    @media(max-width: 1199px) {
                        // top: 12%;
                        padding: 0 50px;
                    }

                }

                span {
                    text-align: center;
                    transition: all 0.3s;

                    @media(max-width: 1366px) {
                        font-size: $az-font-22;
                    }

                    @media(max-width: 1280px) {
                        font-size: $az-font-22;
                    }

                }

                b {
                    display: block;
                    transition: all 0.3s;

                    @media(max-width: 1366px) {
                        font-size: $az-font-14;
                    }

                    @media(max-width: 1280px) {
                        font-size: $az-font-14;
                    }

                }
            }
        }
    }
}

.subscribeForm {
    background: $az-ligh-mid-black;
    padding: 80px 0;
    position: relative;
    z-index: 1;

    h2 {
        @include heading2;
        color: $az-white;
        margin-top: 30px;
    }

    p {
        font-family: $az-heading-two !important;
        @include heading4;
        font-size: $az-font-22;
        color: $az-white;
        // max-width: 685px;
        max-width: 560px;
        margin: 10px auto 55px;
    }

    .formSection {

        form {
            .group {

                @media(max-width: 1024px) {
                    flex-direction: column;
                }

                @media(max-width: 767px) {
                    flex-direction: column;
                }


                input {
                    background: #1D1C1C;
                    border: 0;
                    width: auto;
                    padding: 23px;
                    border-radius: 0px;
                    margin: 0 5px;
                    font-size: $az-font-20;
                    font-family: $az-heading-two;
                    border: 1px solid rgba(0, 0, 0, 0.0);
                    color: $az-white;
                    font-weight: normal;
                    border-radius: 50px;
                    width: 280px;

                    &::placeholder {
                        color: #b6b6b6 !important;
                    }

                    &:focus,
                    &:active,
                    &:hover {
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        box-shadow: none;
                    }

                    // @media(max-width: 1300px) {
                    //     padding: 23px 90px 23px 25px;
                    // }
                    // @media(max-width: 1260px) {
                    //     padding: 23px 70px 23px 25px;
                    // }
                    // @media(max-width: 1200px) {
                    //     padding: 23px 60px 23px 25px;
                    // }
                    // @media(max-width: 1200px) {
                    //     padding: 23px 10px 23px 25px;
                    // }


                    @media(max-width: 1024px) {
                        width: 100%;
                        margin: 0 0 5px 0;
                    }



                    @media(max-width: 991px) {
                        width: 100%;
                        margin: 0 0 5px 0;
                        padding-left: 23px;
                    }



                    @media(max-width: 767px) {
                        width: 100%;
                        margin: 0 0 5px 0;
                        padding-left: 23px;
                    }

                }

                fieldset {
                    border: 0;
                }

                .MuiFormHelperText-root {
                    display: none;
                }

                button {
                    padding: 20px 50px !important;
                    color: $az-white;
                    border: 2px solid $az-white;
                    font-size: $az-font-22;
                    font-family: $az-heading-two;
                    background: transparent;
                    border-radius: 50px;
                    margin-left: 5px;

                    @media(max-width: 991px) {
                        margin-top: 30px;
                        width: 100%;
                        margin-left: 0px;
                    }

                    @media(max-width: 767px) {
                        margin-top: 30px;
                        width: 100%;
                    }
                }
            }
        }
    }

    .subscribemsg {
        font-family: $az-heading-two;
        color: $az-white;
        padding: 5px 0;
        font-size: $az-font-18;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        width: 100%;

        img {
            width: 20px;
            padding: 0;
            margin-right: 5px;

        }
    }

}

.FooterAbout {
    // background: $az-kini-seconday;
    background: url(../images/oh-corporate-img/bg-img.png) $az-black;
    // background-attachment: unset;
    height: auto;
    position: relative;
    z-index: 0;
    padding: 80px 0;

    @media(max-width: 767px) {
        //   background-attachment: unset;
        height: auto;
        padding: 80px 0;
    }


    .content {
        position: relative;
        // top: 45%;
        // left: 50%;
        // transform: translate(-50%, -50%);

        @media(max-width: 991px) {
            // left: 0;
            // transform: translate(0, -50%);
            // padding: 0 15px;
        }

        @media(max-width: 767px) {
            position: unset;
            transform: translate(0);
        }

        figure {
            img {
                max-height: 100px;
                max-width: 100px;
                min-width: 100px;
                object-fit: contain;
            }
        }


        p {
            @include brandParagraph;
            color: $az-white;
            text-align: center;
        }

        button {
            text-align: center;
            border: 1px solid $az-white;
            padding: 7px 20px;
            background: transparent;
            color: $az-white;
            font-family: $az-kini-heading-three;
            margin-top: 20px;
            border-radius: 50px;
        }

        ul {
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 30px;

            li {
                color: $az-white;
                font-family: $az-heading-two;
                padding: 0px;
                background: $az-white;
                width: 36px;
                height: 36px;
                margin: 0 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;

                a {
                    display: flex;
                    align-items: center;

                    img {
                        width: 18px;

                        @media(max-width:991px) {
                            vertical-align: sub;
                        }

                        @media(max-width: 767px) {
                            vertical-align: sub;
                        }

                    }
                }
            }
        }
    }

}

.mbsc-picker .mbsc-ios.mbsc-popup-body {

    @media(max-width:991px) {
        margin-top: 0 !important;
    }

    @media(max-width: 767px) {
        margin: 0 !important;
    }

    .mbsc-popup-buttons-bottom {

        @media(max-width:991px) {
            position: relative;
            z-index: 3;
        }

        @media(max-width: 767px) {
            position: relative;
            z-index: 3;
        }

    }

    .mbsc-calendar-body {

        @media(max-width:991px) {
            padding-top: 30px;
        }

        @media(max-width: 767px) {
            padding-top: 0px;
        }
    }
}

.mbsc-ios.mbsc-range-control-wrapper {

    @media(max-width:991px) {
        position: sticky;
        top: 0px;
        z-index: 2;
        background: $az-white;
        width: 100%;
    }

    @media(max-width: 767px) {
        position: sticky;
        top: 0px;
        z-index: 2;
        background: $az-white;
        width: 100%;
    }
}

.mbsc-ios.mbsc-button-flat {

    @media(max-width:991px) {
        color: $az-ligh-black !important;

    }

    @media(max-width: 767px) {
        color: $az-ligh-black !important;

    }

}

.mbsc-font {

    @media(max-width:991px) {
        font-family: $az-heading-two !important;
    }

    @media(max-width: 767px) {
        font-family: $az-heading-two !important;
    }
}

.mbsc-ios.mbsc-calendar-month-title {
    @media(max-width:991px) {
        color: $az-ligh-black !important;
        font-weight: normal;
    }

    @media(max-width: 767px) {
        color: $az-ligh-black !important;
        font-weight: normal;
    }
}

.mbsc-picker .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    @media(max-width:991px) {
        font-size: 14px !important;
        text-align: center;
        padding: 2px 2px 2px 2px;
    }

    @media(max-width: 767px) {
        font-size: 14px !important;
        text-align: center;
        padding: 2px 2px 2px 2px;
    }
}

// Notification alert
.offcanvas-header {
    align-items: baseline !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 3rem;

    button.btn-close {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .offcanvas-title {
        font-size: $az-font-22;
        text-align: left;
        margin: 0;
        padding-left: 0px;
        width: 100%;

        @media(max-width: 991px) {
            padding-top: 14px;
        }

        @media(max-width: 767px) {
            padding-top: 14px;
        }

        span {
            font-size: $az-font-22;
            padding: 0 15px;

            @media(max-width: 991px) {
                padding: 0px 15px 0;
            }

            @media(max-width: 767px) {
                padding: 0px 15px 0;
            }
        }

        .notificationlisting {
            overflow-x: auto;
            height: 100vh;
            padding-bottom: 30px;
            margin-top: 10px;

            &::-webkit-scrollbar {
                width: 10px;
                height: 5px;
            }

            &::-webkit-scrollbar-track {
                background-clip: content-box;
                border: 0px solid transparent;
                width: 10px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #333;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #333;
            }

            &::-webkit-scrollbar-corner,
            &::-webkit-scrollbar-track {
                background-color: transparent;
                width: 10px;
            }

            @media(max-width: 991px) {
                height: 100vh;
                padding-bottom: 45px;
            }

            @media(max-width: 767px) {
                height: 100vh;
                padding-bottom: 30px;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 5px 0 30px;

                li {
                    padding: 15px 15px 10px;
                    border-bottom: 1px solid $az-border-color;

                    a {
                        text-decoration: none;
                        color: $az-ligh-black;
                        display: flex;

                        figure {
                            margin: 0;
                            display: block;
                            width: 42px;
                            height: 42px;
                            // border: 2px solid #000;
                            border-radius: 50%;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 100%;
                            }
                        }

                        .content {
                            width: calc(100% - 42px);
                            padding-left: 15px;

                            b {
                                font-family: $az-heading-para;
                                font-weight: normal;
                                font-size: $az-font-16;

                            }

                            p {
                                font-size: $az-font-14;
                                margin-bottom: 5px;
                                word-break: break-word;
                            }

                            i {
                                font-style: normal;
                                text-align: right;
                                font-family: $az-heading-two;
                                font-weight: normal;
                                font-size: $az-font-12;
                            }
                        }

                    }

                    &:hover,
                    &:active {
                        background: #f5f5f5;
                    }
                }

                li.unread {
                    background: #f5f5f5;
                }
            }
        }
    }

    .notification-header {
        ul {
            padding: 0 15px;
            list-style: none;
            display: flex;
            justify-content: end;

            li {
                cursor: pointer;
                color: #000;
                font-size: $az-font-14;
                font-family: $az-heading-two;

                &:first-child {
                    margin-right: 10px;
                    padding-right: 10px;
                    border-right: 1px solid $az-border-color;
                }

                a {
                    color: #000;
                    text-decoration: none;
                }
            }
        }
    }
}

.navigationMenu {
    margin-left: 0;

    figure {
        max-height: 26px;

        img {
            max-height: 26px;
        }
    }
}

#offcanvasNavbar-expand3-false.offcanvas.offcanvas-end {
    width: 100% !important;
}


// Gallery slider

.Gallery-slider {
    margin: 0 1rem;
    position: relative;
    background: #ffffff;
    z-index: 1;
    padding: 50px 0 80px 0 !important;

    @media (max-width:767px) {
        padding: 0 !important;
    }

    figure {
        margin: 0 !important;

        img {
            width: 100%;
        }
    }

    .swiper {
        .swiper-button-prev {
            width: 40px;

            &::after {
                background-image: url(../images/oh-corporate-img/prev.svg);
                width: 70px;
                background-repeat: no-repeat;
                font-size: 0;
                height: 20px;
                background-size: 100%;

                @media (max-width: 991px) {
                    filter: brightness(0);
                }

                @media (max-width: 600px) {
                    filter: brightness(1);
                }
            }
        }

        .swiper-button-next {
            width: 40px;

            &::after {
                background-image: url(../images/oh-corporate-img/next.svg);
                width: 70px;
                background-repeat: no-repeat;
                font-size: 0;
                height: 20px;
                background-size: 100%;

                @media (max-width: 991px) {
                    filter: brightness(0);
                    ;
                }

                @media (max-width: 600px) {
                    filter: brightness(1);
                }
            }
        }
    }

    .featuresitems {
        position: relative;
        transition: all 0.3s;
        overflow: hidden;

        img {
            height: 350px;
            object-fit: cover;
            transition: all 1s ease;

            @media (max-width: 1280px) {
                height: 231px;
            }

            @media (max-width: 820px) {
                height: 243px;
            }

            @media (max-width:600px) {
                height: 257px;
            }
        }

        .overlap {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
        }

        &:hover {
            img {
                transform: scale(1.09, 1.09);
                -webkit-filter: brightness(70%);
                -webkit-transition: all 1s ease;
                -moz-transition: all 1s ease;
                -o-transition: all 1s ease;
                -ms-transition: all 1s ease;
                transition: all 1s ease;
            }
        }
    }
}


//   Str page properties details
.property_detail {
    .pd-l-30 {
        @media (max-width:991px) {
            padding: 0 !important;
        }

    }

    .pd-r-30 {
        @media (max-width:992px) {
            padding: 0 10px !important;
        }

        @media (max-width:600px) {
            padding: 0 10px !important;
        }
    }

    .mg-b-60 {
        @media (max-width:992px) {
            margin-bottom: 20px !important;
        }
    }

    .property_heading {
        padding: 0 0 20px 0;

        a {
            display: table-cell
        }

        h4.listing_title {
            @include brandHeading-4;
            text-align: left;
            color: $az-ligh-mid-black;
            // font-size: $az-font-44;
            padding-top: 30px;



            @media (max-width:991px) {
                font-size: $az-font-33;

            }

            @media (max-width:767px) {
                font-size: $az-font-30;
            }

        }

        h5 {
            @include brandHeading-5;
            text-align: left;
            font-family: $az-heading-two;
            margin-top: 0px;
            color: $az-ligh-black;

            a {
                text-decoration: none;
                color: $az-ligh-mid-black !important;
                font-family: $az-heading-two;
                // text-underline-offset: 3px;
            }

            @media (max-width:991px) {
                margin-bottom: 20px;
            }

            @media (max-width:767px) {
                margin-bottom: 10px;
            }
        }
    }

    .swiper {
        border-radius: 20px;
        background: $az-light-gray;

        .property_img {
            figure {
                margin: 0;

                img {
                    height: 360px;
                    width: 100%;
                    object-fit: cover;

                    @media (max-width: 1280px) {
                        height: 310px;
                    }

                    @media (max-width: 820px) {
                        height: 500px;
                    }

                    @media (max-width:600px) {
                        height: 265px;
                    }
                }
            }
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet-active {
            background: #fff !important;
        }
    }
}

.Property_info {
    text-align: left;
    padding-top: 55px;

    @media (max-width: 991px) {
        padding-top: 0px;
    }



    h4.listing_title {
        @include brandHeading-4;
        text-align: left;
        color: $az-ligh-mid-black;
        // font-size: $az-font-44;
        display: inline-block;



        @media (max-width:991px) {
            font-size: $az-font-33;

        }

        @media (max-width:767px) {
            font-size: $az-font-30;
            word-break: break-word;
        }

    }

    .property_size {
        .property_size_brd {
            li {
                width: 100%;

                @media (max-width: 992px) {
                    width: 60%;
                }

                @media (max-width:600px) {
                    width: 100%;
                }
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                border-bottom: 1px solid $az-border-color;
                padding: 5px 0;
                font-size: $az-font-20;
                color: $az-ligh-mid-black ;

                @media (max-width:767px) {
                    padding: 5px 0;
                    font-size: $az-font-18;
                }
            }
        }
    }

    .property_aminties {
        ul {
            list-style: none;
            margin: 20px 0px 0 0;
            padding: 0;

            @media (max-width:600px) {
                margin: 20px 0px 0 0;
            }

            li {
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 10px;
                font-size: $az-font-16;
                color: $az-black;
                background: $az-light-gray;
                padding: 5px 15px;
                border-radius: 20px;

                @media (max-width:767px) {
                    font-size: $az-font-14;
                }
            }
        }
    }

    .property_bottom {
        margin-top: 30px;

        @media (max-width:1280px) {
            margin-top: 5px;
        }

        @media (max-width:991px) {
            margin-top: 20px;
        }

        h4.listing_title {
            @include brandHeading-4;
            text-align: left;
            color: $az-ligh-mid-black;
            // font-size: $az-font-44;


            @media (max-width:991px) {
                font-size: $az-font-33;

            }

            @media (max-width:767px) {
                font-size: $az-font-24 !important;
                vertical-align: super;
            }

        }

        button {
            @include OHprimaybutton-sm;
            float: right;
        }
    }

    .col-md-6 {
        @media (max-width:767px) {
            display: block;
            width: 100%;
        }
    }
}


.str_property_info {
    h2 {
        font-size: $az-font-44 !important;
        margin: 0 !important;
        font-weight: bold;
        padding-bottom: 10px;

        @media (max-width:820px) {
            font-size: $az-font-40 !important;
        }
    }

    .property_heading {
        h3 {
            font-size: 32px;

            @media (max-width:820px) {
                font-size: 26px !important;
            }
        }
    }

    .guestfilterNew {
        span {
            p {
                margin-top: 10px;
                text-align: center;
            }
        }
    }
}


//   Sign In Model
.model_bg_cust {
    background-color: rgba(0, 0, 0, 0.15);
}

.reservationBook {

    // background: $az-white !important;
    figure {
        margin-bottom: 0;
        display: flex;
        align-items: center;

        img {
            max-height: 61px;
            min-height: 61px;
        }
    }

    .modal-dialog {
        @media(max-width:676px) {
            margin: 0;

            .modal-content {
                height: 100vh;
                border: 0;
                border-radius: 0;
                position: relative;
            }
        }
    }

    .modal-content {
        .welcomeClose {
            position: absolute;
            top: 20px !important;
            right: 20px;
            z-index: 9;

            @media (max-width:991px) {
                top: 65px !important;
            }

            @media (max-width:767px) {
                top: 65px !important;
            }
        }

        .width_402 {
            padding: 14px 14px 42px;

            @media (max-width:767px) {
                position: absolute;
                top: 47%;
                transform: translate(0, -50%);
                padding: 0px;
                width: 100%;
                // display: grid;
                // place-items: center;
                // @media (orientation: portrait) {
                //     width: 100%;
                // }
            }


            figure {
                img {
                    margin-top: 40px;

                    @media (max-width:767px) {
                        margin-top: 0;
                    }
                }
            }

            .forget_text {
                @media (max-width:767px) {
                    display: flex;
                    justify-content: center;
                }
            }
        }

        h1 {
            @include heading4;
            color: $az-light-black;
            font-size: 24px;
            text-align: center;
        }

        h5 {
            @include headingRg;
            color: $az-light-black;
            font-size: 18px;
            padding-top: 10px;
        }

        label {
            font-size: $az-font-14 ;
            color: $az-ligh-black !important;
            font-weight: bold !important;
        }

        label.MuiFormLabel-filled {
            top: 3px !important;
            left: 7px;
            margin-left: 8px;
            transform: translate(0px, 0px) scale(0.75) !important;
            font-weight: normal;
        }

        label.Mui-focused {
            top: 3px !important;
            left: 7px !important;
            margin-left: 8px !important;
            transform: translate(0px, 0px) scale(0.75) !important;
        }


        input {
            padding: 20px 14px 9px !important;
            font-size: $az-font-18 !important;

            &:focus-visible {
                outline: 0px;
            }
        }


        button {
            // @include Brandbutton1;
            @include OHprimaybutton-sm;
            text-transform: initial !important;

            @media (max-width:767px) {
                margin: 15px auto;
            }

            // &:hover {
            //     background: $az-ligh-mid-black;
            // }
            .spinner_loader {
                width: 7px;
                height: 7px;
                margin: 2px;
                position: relative;
                top: 5px;
            }
        }

        a {
            color: $az-oh-black;
            text-decoration: underline;
            text-transform: inherit;
            font-size: $az-font-15;
            display: block;
            font-family: $az-heading-para;
            padding-left: 0;

            &:active,
            &:focus,
            &:hover {
                box-shadow: none;
                outline: none;
            }
        }
    }

    h4 {
        // font-family: $az-heading-para !important;
        font-family: $az-kini-heading-three !important;
        margin-left: 20px;
    }

    p.Mui-error,
    p.text-danger {
        font-size: $az-font-14;
    }

}

.resend {
    text-align: center;

    span {
        text-decoration: underline;
        color: $az-black;
        font-family: $az-heading-two;
        cursor: pointer;
        font-size: $az-font-16;
    }
}

// New mobiscroll calendar str detail page style

.mbsc-picker .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    color: $az-white !important;
}

.guest-availabity {
    .mbsc-page {
        background: none !important;
    }
}

.mobiscrollCalendar {
    border-right: 1px solid #72818e33;
    border-top: 1px solid #72818e33;
    border-left: 1px solid #72818e33;
    border-bottom: 1px solid #72818e33;
    border-radius: 12px 12px 0px 0px;
    background: none;
    position: relative;

    .mbsc-label {
        text-align: left !important;
        width: 100% !important;
        top: 19px !important;
        font-family: $az-heading-two !important;
        font-size: $az-font-14 !important;
        left: 15px !important;
        font-weight: normal !important;
        color: $az-kini-primary !important;
        font-weight: 600 !important;
    }

    span.mbsc-textfield-inner {
        width: 100% !important;
    }

    label.mbsc-textfield-wrapper {
        border: none;

        &::after,
        &::before {
            border: none;
        }


        .mbsc-ios.mbsc-textfield {
            height: 5em !important;
        }
    }

    label.mbsc-hover {
        // position: relative;
        border: none;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }


    .mbsc-col-6 {

        &:first-child {
            border: 1px solid transparent;

            &:hover {
                border: 1px solid $az-black;
                // border-right: 1px solid transparent;
                border-radius: 12px 0px 0px 0px;
            }

            .mbsc-textfield-inner {
                position: relative;

                &::after {
                    content: '';
                    height: 100%;
                    width: 1px;
                    top: 0;
                    background: #72818e33;
                    position: absolute;
                    right: -1px;
                }

            }
        }

        &:last-child {
            border: 1px solid transparent;

            &:hover {
                border: 1px solid $az-black;
                // border-left: 1px solid transparent;
                border-radius: 0px 12px 0px 0px;
            }
        }
    }
}

.guest-availabity-mobileview {
    input {
        border: none;
        font-family: $az-heading-two;
        margin-left: -1px;

        &:hover,
        &:focus,
        &:active {
            border: none;
            box-shadow: none;
            outline: none;
            text-underline-offset: 4px;
            text-decoration: underline;
            text-decoration-style: solid;
            text-decoration-thickness: 1px;
        }
    }
}


@media (max-width: 991px) {

    .mbsc-ios.mbsc-range-control-wrapper {
        position: fixed;
        top: 44px;
        z-index: 2;
        background: #ffffff;
        width: 100%;
    }
}

@media (max-width: 767px) {

    .mbsc-ios.mbsc-range-control-wrapper {
        position: fixed;
        top: 44px;
        z-index: 2;
        background: #ffffff;
        width: 100%;
    }
}


// End New mobiscroll calendar str detail page style




// Partnership Page design

.partner_banner {
    background-image: url(./../images/oh-corporate-img/partners.jpg);
    background-repeat: no-repeat;
    position: relative;
    background-position: 100% 40%;
    min-height: 410px !important;
    height: 410px !important;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    @media (max-width:1023px) {
        // min-height: 615px;
        // height: 615px;
        min-height: 400px !important;
        height: 400px !important;
    }

    @media (max-width:991px) {
        min-height: 300px !important;
        height: 300px !important;
    }

    @media (max-width:767px) {
        min-height: 250px !important;
        height: 250px !important;
    }

    @include Banner;

    h1 {
        color: $az-white !important;
        font-family: $az-kini-heading-three;
        margin-top: 15px;
        text-align: left;
        line-height: 1.2;
        padding: 0px;
        margin-bottom: 0px;
        position: relative;
        z-index: 1;
        text-shadow: 1px 2px 3px #000;
        text-shadow: 1px 2px 3px #000;
        -webikit-text-shadow: 1px 2px 3px #000;
        -moz-text-shadow: 1px 2px 3px #000;
        -o-text-shadow: 1px 2px 3px #000;
        letter-spacing: 0rem;
        // font-size: $az-font-66;
        @include heading1;

        @media (max-width:1366px) {
            font-size: $az-font-66;
            padding: 0 0px;
        }

        @media (max-width:1280px) {
            font-size: $az-font-66;
            padding: 0 0px;
        }

        @media (max-width:1199px) {
            font-size: $az-font-50;
            padding: 0px;
        }

        @media (max-width:1023px) {
            font-size: $az-font-50;
            padding: 0px;
        }

        @media (max-width:991px) {
            font-size: $az-font-40;
            padding: 0px;
            text-align: center;
        }

        @media (max-width:767px) {
            font-size: $az-font-26;
            padding: 0 5px;
            margin: 0px auto;
            text-align: center;
            line-height: 1.5;
            // display: contents;
        }

    }

    a.becomebtn {
        text-decoration: none;
        margin-top: 50px;

        button {
            @include OHprimaybutton-sm;
            padding: 5px 30px 10px;
            display: flex;
        }
    }

}


.partnerships_heading {
    padding: 100px 0 0;

    @media (max-width: 991px) {
        padding: 40px 100px 0;
    }

    @media (max-width: 767px) {
        padding: 60px 15px 30px;
    }

    h2 {
        text-align: center;
        @include heading2;
        color: $az-light-black !important;
        padding: 0 115px;

        @media (max-width: 991px) {
             padding: 0px;
        }
    
        @media (max-width: 767px) {
            padding: 0px;
        }
    }

    span {
        text-align: center;
        @include heading4;
        padding: 0 60px;
        color: $az-oh-primary;
        display: block;

        @media (max-width: 991px) {
            font-size: $az-font-18;
        }

        @media (max-width: 767px) {
            font-size: $az-font-18;
            padding: 0;
        }
    }
}

.partnerships-platform {
    &::before {
        background-image: none;
    }

    &::after {
        background-image: none;
    }

    @media (max-width: 991px) {
        padding: 30px 0;
    }

    @media (max-width: 767px) {
        padding: 30px 0;
    }


    .partnerships-img {
        text-align: right;

        figure {
            margin: 0;

            img {
                min-height: 400px;
                max-height: 400px;
                border-radius: 20px;
                width: 100%;
                object-fit: cover;
                object-position: 100% 43%;

                @media (max-width: 1280px) {
                    min-height: 330px;
                    max-height: 330px;
                }

                @media (min-device-width:992px) and (max-device-width:1200px) {
                    width: 100%;
                    object-fit: cover;
                    margin-bottom: 20px;
                }

                @media (max-width: 820px) {
                    min-height: 450px;
                    max-height: 450px;
                    margin-bottom: 20px;
                }

                @media (max-width:600px) {
                    min-height: 215px;
                    max-height: 215px;
                }

            }
        }


    }

    .partnerships-img2 {
        text-align: left;

        figure {
            margin: 0;

            img {
                min-height: 400px;
                max-height: 400px;
                border-radius: 20px;
                width: 100%;
                object-fit: cover;
                object-position: 100% 60%;

                @media (max-width: 1280px) {
                    min-height: 330px;
                    max-height: 330px;
                }

                @media (min-device-width:992px) and (max-device-width:1200px) {
                    width: 100%;
                    object-fit: cover;
                    margin-bottom: 20px;
                }

                @media (max-width: 820px) {
                    min-height: 450px;
                    max-height: 450px;
                    margin-bottom: 20px;
                }

                @media (max-width:600px) {
                    min-height: 215px;
                    max-height: 215px;
                }

            }
        }


    }

    .partnerships-img3 {
        text-align: left;

        figure {
            margin: 0;

            img {
                min-height: 400px;
                max-height: 400px;
                border-radius: 20px;
                width: 100%;
                object-fit: cover;
                object-position: 100% 55%;

                @media (max-width: 1280px) {
                    min-height: 330px;
                    max-height: 330px;
                }

                @media (min-device-width:992px) and (max-device-width:1200px) {
                    width: 100%;
                    object-fit: cover;
                    margin-bottom: 20px;
                }

                @media (max-width: 820px) {
                    min-height: 450px;
                    max-height: 450px;
                    margin-bottom: 20px;
                }

                @media (max-width:600px) {
                    min-height: 215px;
                    max-height: 215px;
                }

            }
        }


    }

    .partnerships-cnt {
        margin-top: 0 !important;
        padding-left: 20px;

        @media(max-width: 991px) {
            padding-left: 0px !important;
        }

        h2 {
            text-align: left;
            margin: 0 !important;
            // font-size: 40px;
            padding-top: 10px;
            @include heading2;
            color: $az-oh-primary;

            @media (max-width:991px) {
                font-size: 26px;
                text-align: center;
            }

            @media (max-width:767px) {
                padding-top: 0;
            }
        }

        h3 {
            @include heading3;
            color: $az-oh-primary;
            text-align: left;
        }

        .partner_cnt_box {
            padding-top: 20px;

            @media (max-width:991px) {
                padding-top: 10px;
                padding-left: 0;
            }

            @media (max-width:767px) {
                padding-top: 10px;
                padding-left: 0;
            }

            h4 {
                @include heading4;
                margin: 0 0 10px 0 !important;
                text-align: left;
                color: $az-ligh-mid-black;
                // font-size: 23px;
                font-family: $az-heading-two;

                @media (max-width:991px) {
                    text-align: center;
                }
            }

            p {
                text-align: left;
                color: $az-oh-primary;

                @media (max-width:991px) {
                    text-align: center;
                }
            }

            a {
                color: $az-oh-primary;
                text-decoration: underline;
                text-transform: inherit;
                font-size: $az-font-22;
                display: block;
                font-family: $az-heading-para;
                padding-left: 0;

                @media (max-width:991px) {
                    text-align: center;
                }

                &:active,
                &:focus,
                &:hover {
                    box-shadow: none;
                    outline: none;
                }

                img {
                    min-height: 16px;
                    max-height: 16px;
                    margin-right: 5px;
                    margin-left: 2px;
                }
            }


            .mail-para {
                margin-bottom: 30px;
                margin-top: 20px;
            }

            .reviewCountprogress {
                // display: flex;
                // justify-content: space-between;
                // flex-wrap: wrap;
                padding-top: 30px;

                @media(max-width: 991px) {
                    padding-top: 0;
                    width: 88%;
                    margin: 0 auto;
                }

                @media(max-width: 768px) {
                    flex-direction: column;
                    width: 94%;
                }

                @media(max-width: 600px) {
                    flex-direction: column;
                    width: 94%;
                }


                ul {
                    font-family: $az-heading-two;
                    font-size: $az-font-18;
                    color: $az-oh-primary;
                    padding: 0;
                    // width: calc(100% / 1);
                    margin-bottom: 0;
                    float: left;


                    @media(max-width: 767px) {
                        // width: calc(100% / 1);
                        padding-right: 0;
                        padding-top: 20px;
                    }

                    // &:last-child {
                    //     padding-right: 200px;
                    // }
                    li {
                        list-style: none;
                        margin-bottom: 0px;
                        display: inline-block;
                        padding-right: 20px;
                        position: relative;
                        float: left;

                        @media(max-width: 991px) {
                            margin-bottom: 20px;
                        }

                        @media(max-width: 767px) {
                            flex-direction: column;
                            margin-bottom: 10px;
                            display: block;
                            text-align: left;
                        }

                        &::before {
                            content: " ";
                            border: 1px solid $az-border-color;
                            position: absolute;
                            height: 70%;
                            top: 2px;
                            right: 10px;

                            @media(max-width: 767px) {
                                border: 0;
                            }
                        }

                        &:last-child {
                            &::before {
                                border: 0;
                            }
                        }

                        div {
                            text-align: left;
                            margin-bottom: 5;
                            font-size: $az-font-18;
                            display: block;

                            span {
                                font-family: $az-kini-heading-three;
                                font-size: $az-font-26;
                                padding-right: 10px;

                                @media (max-width:1280px) {
                                    padding-right: 5px;
                                }
                            }

                            @media(max-width: 991px) {
                                font-size: $az-font-16;
                            }

                            @media(max-width: 767px) {
                                font-size: $az-font-16;
                            }
                        }

                        // .progrespill {
                        //     // display: flex;
                        //     // flex-direction: row;
                        //     // width: calc( 100% - 200px);
                        //     // align-items: center;

                        //     @media(max-width: 991px) {
                        //         width: calc(100%);

                        //     }

                        //     span {
                        //         background: $az-oh-seconday;
                        //         width: 100%;
                        //         height: 5px;
                        //         display: block;
                        //         margin-right: 15px;
                        //         border-radius: 15px;

                        //     }

                        //     span.filled {
                        //         width: 100%;
                        //         height: 5px;
                        //         display: block;
                        //         margin-right: 15px;
                        //         border-radius: 15px;
                        //         background: $az-oh-primary;

                        //     }

                        //     h6 {
                        //         font-size: $az-font-14;
                        //         margin-bottom: 0;
                        //         font-family: $az-heading-para;
                        //         text-align: left;
                        //     }

                        //     .progress {
                        //         height: 4px;
                        //         background-color: $az-white;

                        //         .progress-bar {
                        //             background: $az-black  !important;
                        //         }
                        //     }
                        // }
                    }
                }

            }
        }
    }

    .partent_head {
        padding-bottom: 70px;
        @include heading2;

        @media(max-width: 991px) {
            padding-bottom: 0px;
            font-size: 30px;
        }

    }

    .blocktwo {
        .partner_cnt_box {
            padding-left: 30px;
            @media (max-width:991px) {
                padding-top: 10px;
                padding-left: 0;
            }

            @media (max-width:767px) {
                padding-top: 10px;
                padding-left: 0;
            }
        }
    }

}


.partner_info {
    h2 {
        @include heading2;
        max-width: 100% !important;
        // font-family: $az-heading-light !important;
        // font-weight: bold;
        color: $az-white;
        ;
    }
}

.partner_padd {
    padding-left: 0px;

    @media(max-width: 991px) {
        padding-left: 20px;
    }

    @media(max-width: 600px) {
        padding-left: 0px;
    }
}



.Brandmainpage {
    .partner_banner {
        .partner_banner_head {
            @media(max-width: 767px) {
                padding-top: 45px !important;
                display: block;
            }
        }
    }
}

.Brandmainpage {
    .disclaim_sec {
        padding: 50px 0;

        @media (max-width:1280px) {
            padding: 50px 30px !important;
        }

        p {
            font-size: $az-font-18;
            color: $az-ligh-mid-black;
        }

        @media (max-width: 991px) {
            padding: 30px !important;
        }

        .reservationBook {
            @media (max-width: 767px) {
                padding: 30px 0 0 0 !important;
            }

            .disclaim {
                text-align: center;

                @media (max-width: 767px) {
                    padding: 10px;
                }

                b {
                    font-family: $az-kini-heading-three;
                    font-size: 36px;
                    color: $az-ligh-mid-black;

                    @media (max-width: 767px) {
                        font-size: 30px !important;
                        line-height: 30px !important;
                    }
                }

                p {

                    @media (max-width: 767px) {
                        padding: 0 10px !important;
                    }
                }
            }

        }
    }
}

.back_arrow {
    font-family: $az-kini-heading-three !important;
    font-size: 30px !important;
    margin-bottom: 50px;

    @media (max-width: 820px) {
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {
        padding: 20px 0 10px 5px;
        margin-bottom: 0px;
        font-size: 20px !important;
    }

    a {
        margin-top: 2px !important;
        margin-right: 10px !important;

        img {

            max-width: 18px !important;

            @media (max-width: 767px) {
                max-width: 16px !important;
            }
        }
    }
}

.reservationListSection {
    .tabsNav {
        @media (max-width: 767px) {
            padding: 10px;
        }

        .nav-item {
            button {
                @media (max-width: 767px) {
                    font-size: 16px !important;
                    padding: 10px 20px !important;
                }

                .active {
                    @media (max-width: 767px) {
                        font-size: 16px !important;
                        padding: 10px 20px !important;
                    }
                }
            }
        }

        a.booknow {
            text-decoration: none;

            &:focus,
            &:active {
                box-shadow: none;
                border: none;
            }

            button {
                border: none;
                background: $az-black;
                color: #ffffff;
                font-size: $az-font-16;
                border-radius: 50px;
                padding: 10px 40px;
                font-family: $az-kini-heading-three;
                letter-spacing: 1px;
                margin-top: 100px;
            }
        }
    }
}


// booking design start

.bookingInformation {
    padding: 30px 0;

    .brd-1 {
        border-bottom: 1px solid $az-border-color;
        width: 100%;
        margin: 15px auto;
    }


    .bookingrecipt {
        padding: 30px 20px 20px;
        background: $az-light-gray;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin-bottom: 5px;

        @media (max-width:767px) {
            margin: 5px 10px;
            padding: 20px;
        }

        h4 {
            float: left;
            @include heading4;
            margin: 0;
            padding-top: 6px;
            font-size: 22px;
        }

        button {
            @include Brandbutton1;
            float: right;
            font-size: 14px;
        }

        .bookingdone {
            width: 40px;
            height: 40px;
            float: left;
            margin-right: 10px;
        }

        .Paymentrecipt {
            h5 {
                font-size: 18px;
                text-align: left;
                padding-bottom: 20px;
                font-weight: bold;
            }

            ul {
                list-style: none;
                text-align: left;
                margin: 0;
                padding: 0;

                li {
                    width: 50%;
                    display: inline-block;
                    font-size: $az-font-16;
                    text-align: left;
                    padding: 5px 0;

                    &:nth-child(even) {
                        text-align: right;
                        font-weight: bold;
                    }

                }
            }
        }

        .amountfinal {
            li {
                font-weight: bold;
                font-size: 18px !important;
            }
        }
    }

    .brd-rd-0 {
        border-radius: 0;
    }

    .brd-rd-last {
        border-radius: 0;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }


    .reservationdetail {
        text-align: left;
        position: relative;

        h5 {
            font-size: 18px;
            text-align: left;
            padding-bottom: 20px;
            font-weight: bold;
        }

        p {
            font-size: $az-font-16;
            font-weight: bold;
            text-align: left;

            a {
                color: $az-black;
            }
        }

        h6 {
            font-size: 14px;
            margin-bottom: 0px;
        }

        .bookingimagebox {
            position: absolute;
            right: 0;
            top: -10px;

            @media (max-width:767px) {
                position: unset;
            }

            figure {
                min-height: 104px;
                max-height: 104px;

                img {
                    min-height: 104px;
                    max-height: 104px;
                    border-radius: 10px;
                }
            }
        }
    }
}

.maiconfirmation {
    img {
        width: 50px;
        height: 50px;
    }

    h4 {
        font-size: $az-font-30;
        font-family: $az-kini-heading-three;
        color: #000000;
        text-align: center;

    }

    p {
        font-size: $az-font-16;
        color: #000000;
    }
}

.confirmbookingbtn {
    @media (max-width:676px) {
        margin-bottom: 40px;
    }

    button {
        min-width: 100% !important;
        max-width: 100% !important;
    }
}


.trip_sec {
    padding: 0;

    @media (max-width:767px) {
        margin-top: 30px;

    }

    .col-12 {
        @media (max-width:676px) {
            padding: 0px;
        }
    }

    .trip_box {
        .card {
            border: 0;
            border-radius: 10px !important;
            overflow: hidden;
            text-align: left;
            box-shadow: 0 0 39px 10px $az-oh-seconday;
            transition: .5s;
            margin-bottom: 21px;

            // background: $az-light-white;
            &:hover {
                box-shadow: 0 0 16px 0px #0000004d;
            }

            figure {
                margin: 0;
                max-width: 100%;
                max-height: 210px;
                overflow: hidden;
                position: relative;
                background: $az-light-white;
                min-height: 210px;
                text-align: center;

                @media (max-width:1366px) {
                    max-height: 196px;
                    min-height: 196px;
                }

                @media (max-width:1280px) {
                    max-height: 196px;
                    min-height: 196px;
                }

                @media (max-width:1024px) {
                    max-height: 196px;
                    min-height: 196px;
                }

                @media (max-width:820px) {
                    max-height: 196px;
                    min-height: 196px;
                }

                @media (max-width:768px) {
                    max-height: 180px;
                    min-height: 180px;
                }

                @media (max-width:690px) {
                    max-height: 160px;
                    min-height: 160px;
                }

                @media (max-width:580px) {
                    max-height: 220px;
                    min-height: 220px;
                }

                @media (max-width:414px) {
                    max-height: 228px;
                    min-height: 228px;
                }

                @media (max-width:360px) {
                    max-height: 210px;
                    min-height: 210px;
                }

                img {
                    max-height: 210px;
                    min-height: 210px;
                    object-fit: cover;

                    @media (max-width:1366px) {
                        max-height: 196px;
                        min-height: 196px;
                    }

                    @media (max-width:1280px) {
                        max-height: 196px;
                        min-height: 196px;
                    }

                    @media (max-width:1024px) {
                        max-height: 196px;
                        min-height: 196px;
                    }

                    @media (max-width:820px) {
                        max-height: 196px;
                        min-height: 196px;
                    }

                    @media (max-width:768px) {
                        max-height: 180px;
                        min-height: 180px;
                    }

                    @media (max-width:690px) {
                        max-height: 160px;
                        min-height: 160px;
                    }

                    @media (max-width:580px) {
                        max-height: 220px;
                        min-height: 220px;
                    }

                    @media (max-width:414px) {
                        max-height: 228px;
                        min-height: 228px;
                    }

                    @media (max-width:360px) {
                        max-height: 210px;
                        min-height: 210px;
                    }
                }
            }

            .card-block {
                padding: 10px;

                .card-heading {
                    padding-bottom: 10px;

                    h3 {
                        font-family: $az-kini-heading-three;
                        font-size: $az-font-20;
                        color: $az-black;
                        margin: 0;
                        padding: 10px 0 0px 0;
                    }
                }

                h5 {
                    font-family: $az-kini-heading-three;
                    font-size: $az-font-20;
                    color: $az-black;
                    margin-bottom: 0;
                }

                span.baged {
                    margin: 10px 0 0 0px;
                    display: block;
                    width: max-content;
                    padding: 2px 10px;
                    color: $az-white;
                    border-radius: 50px;
                    font-size: 12px;
                    text-transform: capitalize;
                }

                .baged-confirm {
                    background: $badge-confirm;
                }

                .baged-cancel {
                    background: $badge-cancelled;
                }

                .baged-pending {
                    background: $badge-pending;
                }

                .baged-completed {
                    background: $badge-completed;
                    color: $az-black;
                }

                .baged-currently {
                    background: $badge-current;
                }

                p {
                    margin-bottom: 0;
                    font-size: $az-font-16;
                    font-family: $az-heading-two;
                    color: $az-gray ;
                    // font-weight: 600;

                    span {
                        color: $az-black;
                        float: right;

                        @media (max-width:1024px) {
                            display: block;
                            width: 100%;
                        }

                        @media (max-width:600px) {
                            display: unset;
                            width: auto;
                        }
                    }
                }



                .btn-card {
                    width: 100%;
                    /* text-align: center; */
                    padding: 25px 0 0px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    button {
                        background: $az-black;
                        border: none;
                        border-radius: 4px;
                        color: $az-white;
                        font-family: $az-kini-heading-three;
                        padding: 7px 20px;
                        text-decoration: none;
                        border-radius: 20px;
                        font-size: $az-font-16 ;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }

    p {
        @media (max-width:767px) {
            display: block;
        }
    }

    .tripColum {
        width: 330px;

        @media (max-width:1440px) {
            width: 330px;
        }

        @media (max-width:767px) {
            width: 316px;
        }

        @media (max-width:690px) {
            width: 270px;
            padding: 0 8px;
        }

        @media (max-width:580px) {
            width: 360px;
            padding: 0 8px;
        }
    }
}


.reservation_sec {
    padding: 0;
    height: calc(100vh - 80px);
    overflow: hidden;



    @media (max-width:991px) {
        overflow: scroll;
    }

    .reserve_mobile {
        display: none;

        @media (max-width:676px) {
            display: block;
        }
    }

    .reserve_desk {
        @media (max-width:676px) {
            display: none;
        }
    }

    .col-lg-5 {
        padding: 0;
    }

    .col-lg-7 {
        padding: 0;
    }

    .reserv-img {
        height: 91.5vh;

        @media (max-width:992px) {
            height: auto;
        }

        figure {
            margin: 0;
            margin: 0;
            max-width: 100%;
            max-height: 92vh;
            overflow: hidden;
            position: relative;
            background: $az-light-white;
            min-height: vh;

            @media (max-width:992px) {
                min-height: auto;
            }

            img {
                min-height: 92vh;
                width: 100%;
                object-fit: cover;

                @media (max-width:992px) {
                    min-height: auto;
                }
            }
        }
    }

    .reservation-data {
        height: 91.5vh;
        overflow-y: scroll;
        text-align: left;
        padding: 50px;


        &::-webkit-scrollbar {
            width: 10px;
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background-clip: content-box;
            border: 0px solid transparent;
            width: 10px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #333;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #333;
        }

        &::-webkit-scrollbar-corner,
        &::-webkit-scrollbar-track {
            background-color: transparent;
            width: 10px;
        }



        @media (max-width:1280px) {
            padding: 30px;
        }

        @media (max-width:992px) {
            height: auto;
        }

        @media (max-width:767px) {
            padding: 30px 20px;
            margin: 0 30px;
            position: relative;
            top: -90px;
            background: $az-white;
            box-shadow: 0px 20px 39px 10px $az-oh-seconday;
            border-radius: 50px;
        }

        h2 {
            font-size: $az-font-44;
            margin: 0;
            font-weight: bold;
            padding-bottom: 10px;
            font-family: $az-kini-heading-three;
            color: $az-ligh-mid-black;

            @media (max-width:1280px) {
                font-size: $az-font-40 ;
            }

            @media (max-width:676px) {
                font-size: $az-font-30 ;
            }
        }

        .reservation-info {
            text-align: left;

            .ShowmoreLink {
                color: $az-oh-black;
                text-decoration: underline;
                border-radius: 12px;
                text-transform: inherit;
                font-size: $az-font-15;
                display: block;
                margin-top: 0px;
                font-family: $az-heading-para;
                position: relative;
                padding-left: 0;
                border: none;

                &:active,
                &:focus,
                &:hover {
                    box-shadow: none;
                    outline: none;
                }

                &::after {
                    position: absolute;
                    content: '';
                    right: -4px;
                    width: 7px;
                    height: 11px;
                    background-image: url(../images/oh/showmoe-arrow.svg);
                    bottom: 9px;
                }
            }


            ul {
                font-family: $az-heading-two;
                font-size: $az-font-18;
                color: $az-oh-primary;
                padding: 0;
                margin-bottom: 0;

                li {
                    list-style: none;
                    margin: 10px 0;
                    display: inline-block;
                    padding-right: 5px;
                    width: 50%;
                    position: relative;

                    &::before {
                        content: " ";
                        border: 1px solid $az-border-color;
                        position: absolute;
                        height: 100%;
                        top: 0px;
                        right: 5px;

                        @media(max-width: 767px) {
                            border: 0;
                        }
                    }

                    &:last-child {
                        &::before {
                            border: 0;
                        }
                    }

                    @media (max-width:676px) {
                        width: 100%;
                        display: block;
                    }

                    label {
                        font-size: $az-font-18;
                        padding-right: 10px;
                        color: $az-gray ;

                        @media (max-width:676px) {
                            font-size: $az-font-14;
                            display: inline-flex;
                        }
                    }

                    p {
                        text-align: left;
                        margin-bottom: 0;
                        font-size: $az-font-18;
                        display: block;
                        color: $az-ligh-mid-black;
                        padding-left: 0px;
                        font-weight: normal;
                        font-family: $az-heading-para;

                        @media (max-width:676px) {
                            font-size: $az-font-16;
                            position: relative;
                            top: -1px;
                            left: 5px;
                        }

                        a {
                            color: $az-ligh-mid-black;
                        }

                    }

                    &:last-child {
                        padding-left: 10px;
                    }
                }
            }

            .reserv_mid_heading {
                padding: 10px 0;
                border-top: 1px solid $az-border-color;
                margin-top: 20px;
                width: 100%;

                h5 {
                    font-size: $az-font-22;
                    color: $az-ligh-mid-black;
                    // font-weight: bold;
                    padding-top: 15px;
                }

                button {
                    background: $az-black;
                    border: none;
                    border-radius: 4px;
                    color: $az-white;
                    font-family: $az-kini-heading-three;
                    padding: 7px 20px;
                    text-decoration: none;
                    border-radius: 20px;
                    font-size: $az-font-16 ;
                    letter-spacing: 1px;
                    margin-top: 20px;
                }

                @media (max-width:991px) {
                    // border: none !important;
                    margin-top: 15px;
                }

                @media (max-width:767px) {
                    // border: none !important;
                    margin-top: 15px;
                }
            }

            p {
                margin-bottom: 10px;
                font-size: $az-font-18;
                font-family: $az-heading-para;
                color: $az-ligh-mid-black ;
                text-transform: inherit;
                word-break: break-word;

                @media (max-width:676px) {
                    font-size: $az-font-16;
                }

                a {
                    color: $az-ligh-mid-black;
                    text-underline-offset: 3px;
                    text-transform: inherit;
                }
            }

            label {
                color: $az-ligh-black;
                font-size: $az-font-16;
            }

            button.ShowmoreLink {
                background: none;
            }
        }
    }
}

.confirm_booking {
    .reservation-info {
        text-align: left;

        .ShowmoreLink {
            color: $az-oh-black;
            text-decoration: underline;
            border-radius: 12px;
            text-transform: inherit;
            font-size: $az-font-15;
            display: block;
            margin-top: 0px;
            font-family: $az-heading-para;
            position: relative;
            padding-left: 0;
            background: none;
            min-width: inherit;
            padding: 0;
            margin-bottom: 15px;
            border: none;

            &:active,
            &:focus,
            &:hover {
                box-shadow: none;
                outline: none;
                color: $az-oh-black;
            }

            &::after {
                position: absolute;
                content: '';
                right: -15px;
                width: 7px;
                height: 11px;
                background-image: url(../images/oh/showmoe-arrow.svg);
                bottom: 5px;
            }

            @media(max-width: 991px) {
                min-width: inherit !important;
                max-width: inherit !important;
                padding: 0 !important;
            }

            @media(max-width: 767px) {
                min-width: inherit !important;
                max-width: inherit !important;
                padding: 0 !important;
            }
        }


        ul {
            font-family: $az-heading-two;
            font-size: $az-font-18;
            color: $az-oh-primary;
            padding: 0;
            margin-bottom: 0;

            li {
                list-style: none;
                margin: 10px 0;
                display: inline-block;
                padding-right: 0px;
                width: 50%;
                position: relative;

                &::before {
                    content: " ";
                    border: 1px solid $az-border-color;
                    position: absolute;
                    height: 100%;
                    top: 0px;
                    right: 0px;

                    @media(max-width: 991px) {
                        border: 1px solid $az-border-color;
                    }

                    @media(max-width: 767px) {
                        border: 1px solid $az-border-color;
                    }
                }

                &:last-child {
                    &::before {
                        border: 0;
                    }
                }

                @media (max-width:676px) {
                    width: 100%;
                    display: block;
                }

                label {
                    font-size: $az-font-18;
                    padding-right: 10px;
                    color: $az-gray ;

                    @media (max-width:676px) {
                        font-size: $az-font-14;
                        display: inline-flex;
                    }
                }

                span {
                    font-size: $az-font-18;
                    color: $az-light-black;
                    font-family: $az-heading-para;
                    padding: 0;
                    text-align: left;
                    font-weight: 400;
                    display: block;


                    // @media (max-width:676px) {
                    //     font-size: $az-font-16;
                    //     position: relative;
                    //     top: -1px;
                    //     left: 5px;
                    // }

                    a {
                        color: $az-ligh-mid-black;
                    }

                }

                span.checkdate {
                    display: block;
                }

                &:last-child {
                    padding-left: 15px;
                }
            }
        }

        .reserv_mid_heading {
            padding: 10px 0;
            border-top: 1px solid $az-border-color;
            margin-top: 20px;
            width: 100%;

            h5 {
                font-size: $az-font-22;
                color: $az-ligh-mid-black;
                // font-weight: bold;
                padding-top: 15px;
                font-family: $az-heading-para;
                font-weight: 400;
            }

            button {
                background: $az-black;
                border: none;
                border-radius: 4px;
                color: $az-white;
                font-family: $az-kini-heading-three;
                padding: 7px 20px;
                text-decoration: none;
                border-radius: 20px;
                font-size: $az-font-16 ;
                letter-spacing: 1px;
                margin-top: 20px;
            }

            @media (max-width:991px) {
                margin-top: 15px;
                // border: none;
            }

            @media (max-width:767px) {
                margin-top: 15px;
                // border: none;
            }
        }

        p {
            margin-bottom: 10px;
            font-size: $az-font-18;
            font-family: $az-heading-para;
            color: $az-ligh-mid-black ;
            text-transform: inherit;
            word-break: break-word;

            @media (max-width:676px) {
                font-size: $az-font-16;
            }

            a {
                color: $az-ligh-mid-black;
                text-underline-offset: 3px;
                text-transform: inherit;
            }
        }

        label {
            color: $az-ligh-black;
            font-size: $az-font-16;
        }


    }
}

.back_go {

    position: relative;

    @media (max-width: 991px) {
        display: flex;
        align-content: center;
        justify-content: center;

        .back_arrow {
            padding: 0;
        }
    }

    @media (max-width: 767px) {
        display: flex;
        align-content: center;
        justify-content: center;

        .back_arrow {
            padding: 0;
        }
    }

    h4 {
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 20px !important;
        margin-left: 0;

        @media (max-width: 991px) {
            font-size: $az-font-24 !important;
            margin-bottom: 0px !important;
        }

        @media (max-width: 767px) {
            font-size: $az-font-24 !important;
            margin-bottom: 0px !important;
        }

    }

    a {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-right: 15px;

        img {
            max-width: 22px;

            @media (max-width: 991px) {
                max-width: 15px;
            }

            @media (max-width: 767px) {
                max-width: 15px;

            }
        }

        @media (max-width: 991px) {
            margin-top: 0px;
            margin-right: 0px;
            position: absolute;
            left: 5px;
            top: 9px;
        }

        @media (max-width: 767px) {
            margin-top: 0px;
            margin-right: 0px;
            position: absolute;
            left: 5px;
            top: 9px;
        }
    }
}

.map_text {
    color: $az-gray;

    &:hover {
        color: $az-black;
    }
}

.CookieConsent {
    position: sticky !important;

    div {
        &:nth-child(2) {

            @media(max-width: 991px) {
                margin: auto;
                width: 100%;
            }

            @media(max-width: 767px) {
                margin: auto;
                width: 100%;
            }


            #rcc-confirm-button {

                @media(max-width: 991px) {
                    margin-top: 0 !important;
                }

                @media(max-width: 767px) {
                    margin-top: 0 !important;
                }
            }


        }
    }
}


#rcc-confirm-button {
    background: $az-black !important;
    border: none !important;
    border-radius: 4px !important;
    color: $az-white !important;
    font-family: $az-kini-heading-three !important;
    padding: 7px 20px !important;
    text-decoration: none !important;
    border-radius: 20px !important;
    font-size: $az-font-16 !important;
    letter-spacing: 1px !important;
    margin: 8px !important;
}

.detail_page {
    @media (max-width: 767px) {
        margin-bottom: 100px;
    }

    footer {
        .top-arrow {
            @media (max-width: 767px) {
                bottom: 110px !important;
            }
        }
    }
}


.detail_page_ltr {
    @media (max-width: 767px) {
        margin-bottom: 68px;
    }

    footer {
        .top-arrow {
            @media (max-width: 767px) {
                bottom: 150px !important;
            }
        }
    }

    .chatSection {
        @media (max-width: 767px) {
            bottom: 90px;
        }
    }
}


.google_link {
    color: $az-gray;

    &:hover {
        color: $az-gray;
    }
}

.confirm-policy {

    .MuiFormGroup-root {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap;
        align-items: center;

        .labeltext {
            width: 30px !important;
            font-size: $az-font-14;
            color: $az-ligh-black;
            font-family: $az-heading-two;
            margin-right: 5px;
        }

        .agree {
            width: calc(100% - 30px) !important;
            display: flex;

            a {
                font-size: $az-font-14;
                color: $az-black;
                font-family: $az-heading-two;
            }

        }
    }

    p {
        white-space: break-spaces;
    }
}

.bgresetPassword {
    background-image: url(../images/oh-corporate-img/banner.png);
    background-position: top 0% right 0% !important;
    height: 100vh !important;
    min-height: max-content !important;
    width: 100% !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.filtersection {
    .search {
        .MuiOutlinedInput-root {
            width: 230px !important;

            @media(max-width:1280px) {
                width: 200px !important;
            }

            @media(max-width:1200px) {
                width: 100% !important;
            }
            @media(max-width:767px) {
                width: 100% !important;
                min-width: 100% !important;
            }

            @media(max-width:767px) {
                width: 100% !important;
                min-width: 100% !important;
            }

            input {
                width: 230px !important;

                @media(max-width:1360px) {
                    width: 200px !important;
                }

                @media(max-width:1280px) {
                    width: 200px !important;
                }

                @media(max-width:1200px) {
                    width: 100% !important;
                }

                @media(max-width:767px) {
                    width: 100% !important;
                    min-width: 100% !important;
                }

            }

        }
    }

    .add {
        .MuiOutlinedInput-root {
            width: 230px !important;

            @media(max-width:1280px) {
                width: 200px !important;
            }

            @media(max-width:1200px) {
                width: 100% !important;
            }

            input {
                width: 230px !important;

                @media(max-width:1360px) {
                    width: 240px !important;
                }

                @media(max-width:1280px) {
                    width: 200px !important;
                }

                @media(max-width:1200px) {
                    width: 100% !important;
                }

            }
        }
    }

    .when {

        input {
            width: 250px !important;

            @media(max-width:1360px) {
                width: 240px !important;
            }

            @media(max-width:1280px) {
                width: 220px !important;
            }

            @media(max-width:1200px) {
                width: 100% !important;
            }

        }
    }

    .details {
        .MuiFormControl-root {
            @media (max-width:767px) {
                display: block;
                display: -webkit-block;
                display: -webkit-block;
                display: -ms-block;
            }
        }

        .MuiOutlinedInput-root {
            width: 300px !important;

            @media(max-width:1280px) {
                width: 300px !important;
            }

            @media(max-width:1200px) {
                width: 100% !important;
            }

            input {
                width: 300px !important;

                @media(max-width:1360px) {
                    width: 240px !important;
                }

                @media(max-width:1280px) {
                    width: 300px !important;
                }

                @media(max-width:1200px) {
                    width: 300px !important;
                }
            }

            // .MuiAutocomplete-option {
            //     color: #474747;
            //     font-family:$az-heading-two;
            //     font-size: $az-font-18;
            // }
        }
    }
}


.css-nen11g-MuiStack-root {
    @media(max-width:1200px) {
        width: 100% !important;
    }
}

.css-qk46qe-MuiStack-root {
    @media(max-width:1200px) {
        width: 100% !important;
    }

}



.mobilesearch-btn {
    button {
        font-size: $az-font-18 !important;
        font-family: $az-kini-heading-three;

        a {
            font-size: $az-font-18;
            text-decoration: none;
            color: $az-white;
            font-family: $az-kini-heading-three;
        }
    }
}

.requestSection {
    label {
        font-size: $az-font-14 !important;
        color: $az-ligh-black !important;
        font-weight: bold;
        font-family: $az-heading-two;
    }

    label.MuiFormLabel-filled {
        top: 3px !important;
        left: 7px !important;
        margin-left: 8px !important;
        transform: translate(0px, 0px) scale(0.75) !important;
        font-weight: normal;
    }

    label.Mui-focused {
        top: 3px !important;
        left: 7px !important;
        margin-left: 8px !important;
        transform: translate(0px, 0px) scale(0.75) !important;
    }

}

.mbsc-calendar {
    font-family: $az-heading-two;
}

.css-e13as6-MuiFormControl-root-MuiTextField-root {
    @media (max-width:1200px) {
        width: 100% !important
    }
}

// .filtersection {
//     .details {
//         .MuiOutlinedInput-root {
//             input {
//                 width: 300px !important;

//                 @media (max-width: 767px) {
//                     width: 100% !important;
//                 }
//             }
//         }

//         .MuiFormControl-root {
//             @media (max-width: 767px) {
//                 width: 100% !important;
//             }
//         }
//     }
// }

.guestfilterNew {
    .mbsc-ios {
        .mbsc-popup-body {
            margin: 0px !important;
        }
    }
}


.css-wgai2y-MuiFormLabel-asterisk {
    color: $az-ligh-black !important;
    position: relative;
    left: -3px;
}


// .accountProfile {
//     .right {
//         p {
//             @media(max-width:767px) {
//                 text-align: center !important;
//             }
//         }
//     }
// }

// responvive_calendar_start

.dual_calendar,
.str_dual_calendar {
    .mbsc-calendar-body {
        @media (max-width:767px) {
            padding: 0 !important;
        }
    }

    .mbsc-ios.mbsc-range-control-wrapper {
        @media (max-width:767px) {
            padding: 0 !important;
        }
    }

    .mbsc-datepicker {
        @media (max-width:767px) {
            margin-top: 0px;
        }
    }

    .mbsc-ios.mbsc-range-control-wrapper {
        @media (max-width:767px) {
            background: $az-white;
            padding: 60px 0 0 !important;
            top: 0px !important;
        }
    }

    .mbsc-popup {
        @media (max-width:767px) {
            border: 0;
            max-width: 100% !important;
            max-height: 100vh !important;
            left: 0 !important;
            top: 0 !important;
            height: 100vh;
            margin: auto;
        }

        .mbsc-popup-buttons {

            // @media (max-width:767px) {
            //     margin: 15px 0 !important;
            // }

            .mbsc-popup-button-close {

                @media (max-width:767px) {
                    margin: 0 15px !important;
                    float: right;
                    cursor: pointer;
                }
            }
        }

    }

    .mbsc-calendar-grid {
        @media (max-width:767px) {
            // margin-top: 165px;
            // margin-top: 120px;
            margin-top: 100px;
        }
    }

    .mbsc-calendar-header {
        @media (max-width:767px) {
            position: fixed;
            top: 115px;
            width: 100%;
            background: $az-white;
            padding: 25px;
        }
    }

    .mbsc-popup-body-round {
        @media (max-width:767px) {
            border-radius: 0 !important;
        }
    }

    .mbsc-ios.mbsc-segmented {
        @media (max-width:767px) {
            width: 19em;
        }
    }
}


.single_pick_date {
    .mbsc-popup {
        @media (max-width:767px) {
            border: 0;
            max-width: 100% !important;
            min-width: 100% !important;
            max-height: 100vh !important;
            left: 0 !important;
            top: 0 !important;
            height: 100vh;
        }
    }

    .mbsc-calendar-grid {
        @media (max-width:767px) {
            margin-top: 75px;
        }
    }

    .mbsc-calendar-header {
        position: fixed;
        top: 0;
        width: 100%;
        background: $az-white;
        padding: 25px 30px 25px 25px;
    }

    .mbsc-popup-body-round {
        border-radius: 0 !important;
    }
}



.str_dual_calendar {
    .mbsc-ios.mbsc-popup-body {
        margin: 0 !important;
    }

    .mbsc-datepicker-tab {
        @media (max-width:767px) {
            margin-top: 90px;
        }
    }

    .mbsc-ios.mbsc-range-control-wrapper {
        top:0px !important;
        padding: 60px 0 0 !important;
    }

}

// responvive_calendar_end


.guestModalbox {
    .dropmenuInputbox {
        .dropdown-item {
            strong {
                color: $az-black;
                display: flex;
                font-family: $az-heading-two;
                font-size: $az-font-18;
                margin-top: 0px;
                padding-left: 0;
                text-align: left;
                font-style: normal;
                font-weight: normal;
            }
        }

    }
}

.pswd_user_chg {
    p.text-danger {
        text-align: left !important;
        margin: 0 !important;
        font-size: $az-font-14 !important;
        margin-left: 12px !important;
    }
}

.place-offers {

    h4.amenities_pop_head {
        font-size: $az-font-16;
        font-family: $az-heading-para !important;
        color: $az-oh-primary;
        text-align: left;
        margin: 5px 0 15px 0;
        text-transform: initial !important;
        font-weight: 500;

        @media (max-width:767px) {}
    }
}

.large_model {
    .model_top_fix {
        background-color: $az-white;
        padding: 20px 0 10px 0 !important;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 9;
    }

    .welcomeClose {
        right: 0 !important;
    }
}

.modal-dialog {
    @media (max-width:676px) {
        height: 100% !important;
    }
}

// Market reports

.market-reports {
    padding: 80px 0;
    background: $az-white;
    position: relative;
    z-index: 1;

    @media (max-width: 991px) {
        padding: 60px 0;
    }

    @media (max-width: 767px) {
        padding: 60px 0;
    }

    h3 {
        @include brandHeading-3;
        color: $az-ligh-mid-black;
    }

    h5 {
        @include brandHeading-5;
        font-family: $az-heading-two;
        margin-top: 20px;
        color: $az-ligh-black !important;
        padding: 0 16%;
    }

    .marketSection {
        padding: 50px 0;

        .marketblockone {
            background: $az-light-gray;
            padding: 30px 30px 50px;
            width: 60%;
            margin: auto;
            text-align: left;
            border-radius: 15px;

            b.heading {
                font-family: $az-heading-para;
                font-size: $az-font-18;
                width: 100%;
                display: block;
                margin-bottom: 15px;
            }

            .left {
                display: flex;
                flex-direction: column;
                width: 200px;

                span {
                    height: 150px;
                    width: 150px;
                    border-radius: 15px;
                    background: $az-black;
                    font-weight: normal;
                    font-family: $az-heading-para;
                    font-size: $az-font-99;
                    color: $az-white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .right {

                width: calc(100% - 200px);

                ul {
                    font-family: $az-heading-two;
                    font-size: $az-font-16;
                    padding: 0;
                    list-style: none;
                    text-align: left;
                    margin-bottom: 0;

                    li {
                        display: flex;
                        align-items: center;

                        i {
                            font-style: normal;
                            width: 135px;
                        }

                        b {
                            margin-left: 15px;
                        }

                        .progress {
                            width: 200px;
                            --bs-progress-bg: #dbdbdb;

                            .progress-bar {
                                background-color: $az-black;
                                --bs-progress-height: 5px;
                            }
                        }
                    }
                }

            }

        }

        .marketblocktwo {
            background: $az-light-gray;
            padding: 30px 30px 50px;
            width: 60%;
            margin: 60px auto;
            text-align: left;
            border-radius: 15px;

            b.heading {
                font-family: $az-heading-para;
                font-size: $az-font-18;
                width: 100%;
                display: block;
                margin-bottom: 15px;
            }

            .left {
                display: flex;
                flex-direction: column;
                width: 200px;

                span {
                    height: 150px;
                    width: 150px;
                    border-radius: 15px;
                    background: $az-black;
                    font-weight: normal;
                    font-family: $az-heading-para;
                    font-size: $az-font-99;
                    color: $az-white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .right {

                width: calc(100% - 200px);

                ul {
                    font-family: $az-heading-two;
                    font-size: $az-font-16;
                    padding: 0;
                    list-style: none;
                    text-align: left;
                    margin-bottom: 0;

                    li {


                        i {
                            font-style: normal;
                            width: 200px;
                        }

                        b {
                            margin-left: 35px;
                        }
                    }
                }

            }

        }
    }


}

// Ltr new section style

.leasefilter {
    position: relative;

    @media(max-width: 767px) {
        margin-bottom: 0 !important;
    }


    label {
        font-size: $az-font-14;
        font-family: $az-heading-two;
        position: absolute;
        top: 10px;
        left: 0px;
        color: $az-ligh-black;
        padding: 0 15px;
        font-weight: bold;
    }

    input {
        font-size: $az-font-18;
        font-family: $az-heading-two;

        box-shadow: none;
        outline: none;
        display: block;
        width: 100%;
        padding: 30px 15px 10px;
        height: 75px;
        border: 1px solid $az-border-color;
        border-radius: 12px;

        &:hover,
        &:focus,
        &:active {
            border: 1px solid $az-black;
            box-shadow: none;
            outline: none;
        }

        &::placeholder {
            color: #c9c9c9;
        }
    }
}

.marketingData {
    padding: 0 35px;

    @media (max-width: 991px) {
        padding: 0 15px;
    }

    @media (max-width: 6px) {
        padding: 0 15px;
    }

    .sectionOne {
        margin: 30px 0 30px;

        .accordion-item {
            border-top: none;
            border-right: none;
            border-left: none;

            .accordion-header {

                .accordion-button {
                    color: $az-black;
                    background: none;
                    box-shadow: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: start;
                    position: relative;
                    font-family: $az-heading-para !important;
                    font-size: $az-font-16;
                    min-height: 52px;
                    padding-left: 0;
                    padding-right: 25px;

                    span {
                        display: block;
                    }

                    b {
                        font-family: $az-heading-two;
                        font-size: $az-font-18;
                        font-weight: normal;
                        margin-top: 6px;
                    }

                    &:after {
                        filter: brightness(0);
                        top: 15px;
                        position: absolute;
                        right: 0;

                    }
                }
            }

            .accordion-body {
                text-align: left;
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;

                p {
                    a {
                        color: $az-black;
                    }
                }

                .averageTable {

                    .daily {
                        @media (max-width: 1199px) {
                            top: 17%;
                        }
                    }
                }

                ul {
                    font-family: $az-heading-two;
                    font-size: $az-font-16;
                    padding: 0;
                    list-style: none;
                    text-align: left;
                    margin-bottom: 0;

                    li {
                        display: flex;
                        align-items: center;
                        margin: 15px 0;
                        justify-content: space-between;

                        i {
                            font-style: normal;
                            width: 205px;
                        }

                        b {
                            margin-left: 15px;
                        }

                        .progress {
                            width: calc(100% - 5px);
                            --bs-progress-bg: #dbdbdb;
                            height: 5px;

                            .progress-bar {
                                background-color: $az-black;
                                --bs-progress-height: 5px;
                            }
                        }

                        span {
                            background: #333;
                            color: #fff;
                            height: 40px;
                            width: 40px;
                            text-align: center;
                            line-height: 40px;
                            border-radius: 50%;
                        }
                    }
                }

                figure {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                ul.overhead {
                    list-style: none;
                    padding: 0;
                    margin-top: 0px;

                    li {
                        i {
                            text-align: right;
                        }

                        b {
                            margin: 0;

                            span {
                                width: inherit;
                                height: inherit;
                                border-radius: inherit;
                                color: $az-black;
                                background: none;
                                font-weight: normal;

                                strike {
                                    margin: 0 5px 0 10px;
                                }
                            }
                        }

                        &:nth-child(4) {
                            width: auto;

                            i {
                                text-decoration: overline;
                                text-underline-position: auto;
                                text-underline-offset: 10px;

                            }
                        }

                        width: auto;
                    }

                }

                span.daily {
                    position: absolute;
                    display: block;
                    width: 100%;
                    left: -49%;
                    top: -17%;
                    transform: rotate(-90deg);
                }

                table {
                    width: 100%;
                    margin-bottom: 20px;

                    caption {
                        text-align: center;
                        width: 100%;
                        color: $az-black;
                        display: block;
                    }

                    th,
                    td {
                        border: 1px solid $az-border-color;
                        padding: 5px 15px;
                    }

                    th {
                        background: $az-black;
                        color: $az-white;
                        font-weight: normal;
                    }

                    td.label {
                        background: $az-light-gray;
                    }

                    td.select {
                        background: $az-gray;
                        color: $az-white;
                    }

                    td.caption {
                        position: relative;

                        caption {
                            display: block;
                            width: 100%;

                        }
                    }

                    td.caption2 {
                        border: none;
                    }
                }
            }
        }

    }

    .sectionTwo {
        margin: 30px 0 30px;

        h5 {
            font-size: $az-font-22;
            font-family: $az-heading-para;
            color: $az-oh-primary;
            text-align: left;
            margin-bottom: 20px;
            text-transform: initial !important;
        }

        .accordion-item {
            border-top: none;
            border-right: none;
            border-left: none;

            .accordion-header {

                .accordion-button {
                    color: $az-black;
                    background: none;
                    box-shadow: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: start;
                    position: relative;
                    font-family: $az-heading-para !important;
                    font-size: $az-font-16;
                    min-height: 50px;
                    padding-left: 0;
                    padding-right: 25px;

                    span {
                        display: block;
                    }

                    &:after {
                        filter: brightness(0);
                        top: 15px;
                        position: absolute;
                        right: 0;
                        background-image: url(../images/oh/market-chart/plus.svg);
                        transition: inherit;
                    }

                    @media (max-width: 1199px) {
                        padding-right: 25px;
                    }

                    @media (max-width: 991px) {
                        padding-right: 25px;
                    }

                    @media (max-width: 767px) {
                        padding-right: 25px;
                    }
                }

                .accordion-button:not(.collapsed)::after {
                    background-image: url(../images/oh/market-chart/minus.svg);
                    transition: inherit;
                    top: 6px;
                }
            }

            .accordion-body {
                text-align: left;
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;

                @media (max-width: 1199px) {
                    overflow: auto;
                }

                table.wholesale {
                    width: 100%;
                    margin-bottom: 20px;

                    caption {
                        text-align: center;
                        width: 100%;
                        color: $az-black;
                        display: block;
                    }

                    th,
                    td {
                        border: 1px solid $az-border-color;
                        padding: 5px 10px;
                    }

                    th {
                        color: $az-black;
                        font-weight: normal;
                        font-family: $az-heading-para;
                        font-size: $az-font-14;
                    }

                    td.label {
                        background: $az-light-gray;
                        font-family: $az-heading-para;
                    }

                    td.select {
                        background: $az-gray;
                        color: $az-white;
                    }

                    td.caption {
                        position: relative;

                        caption {
                            display: block;
                            width: 100%;

                        }
                    }

                    td.caption2 {
                        font-family: $az-heading-para;
                    }
                }


                ul {
                    list-style: none;
                    padding: 0;

                    li {
                        b {
                            font-size: $az-font-16;
                            font-family: $az-heading-para;
                        }

                        ul {
                            padding: 10px 40px;
                            list-style: disc;

                            li {
                                font-size: $az-font-16;
                            }
                        }
                    }
                }
            }
        }

    }

    .sectionThree {
        margin: 30px 0 30px;
        padding-left: 50px;

        @media (max-width: 767px) {
            padding-left: 0px;
        }

        h5 {
            font-size: $az-font-22;
            font-family: $az-heading-para;
            color: $az-oh-primary;
            text-align: left;
            margin-bottom: 20px;
            text-transform: initial !important;
        }

        .accordion-item {
            border-top: none;
            border-right: none;
            border-left: none;

            .accordion-header {

                .accordion-button {
                    color: $az-black;
                    background: none;
                    box-shadow: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: start;
                    position: relative;
                    font-family: $az-heading-para !important;
                    font-size: $az-font-16;
                    min-height: 52px;
                    padding-left: 0;
                    padding-right: 25px;

                    span {
                        display: block;
                    }

                    &:after {
                        filter: brightness(0);
                        top: 15px;
                        position: absolute;
                        right: 0;
                        background-image: url(../images/oh/market-chart/plus.svg);
                        transition: inherit;
                    }

                    @media (max-width: 1199px) {
                        padding-right: 25px;
                    }

                    @media (max-width: 991px) {
                        padding-right: 25px;
                    }

                    @media (max-width: 767px) {
                        padding-right: 25px;
                    }
                }

                .accordion-button:not(.collapsed)::after {
                    background-image: url(../images/oh/market-chart/minus.svg);
                    transition: inherit;
                    top: 6px;
                }
            }

            .accordion-body {
                text-align: left;
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;

                @media (max-width: 1199px) {
                    overflow: auto;
                }




                table.wholesale {
                    width: 100%;
                    margin-bottom: 20px;

                    caption {
                        text-align: center;
                        width: 100%;
                        color: $az-black;
                        display: block;
                    }

                    th,
                    td {
                        border: 1px solid $az-border-color;
                        padding: 5px 10px;
                    }

                    th {
                        color: $az-black;
                        font-weight: normal;
                        font-family: $az-heading-para;
                        font-size: $az-font-14;
                    }

                    td.label {
                        background: $az-light-gray;
                        font-family: $az-heading-para;
                    }

                    td.select {
                        background: $az-gray;
                        color: $az-white;
                    }

                    td.caption {
                        position: relative;

                        caption {
                            display: block;
                            width: 100%;

                        }
                    }

                    td.caption2 {
                        font-family: $az-heading-para;
                    }
                }


                ul {
                    list-style: disc;
                    padding: 0 0 0 20px;
                    font-size: $az-font-16;

                    li {
                        b {
                            font-size: $az-font-16;
                            font-family: $az-heading-para;
                        }

                        ul {
                            padding: 10px 40px;
                            list-style: circle;

                            li {
                                font-size: $az-font-16;

                                ul {
                                    padding: 10px 40px;
                                    list-style: square;

                                    li {}
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

// thank you page

.thankyou {
    padding: 60px 0;

    img.thankyou {
        width: 100px;
        padding: 0;
    }

    h2 {
        font-size: $az-font-44;
        margin: 0;
        font-weight: bold;
        padding-bottom: 10px;
        font-family: $az-heading-para;
        color: $az-black;
        margin-top: 15px;

        @media (max-width:1280px) {
            font-size: $az-font-40 ;
        }

        @media (max-width:676px) {
            font-size: $az-font-30 ;
        }
    }

    p {
        font-size: $az-font-16;
        font-family: $az-heading-two;
        color: $az-black;
        width: 25%;
        margin: auto !important;

        @media (max-width:1440px) {
            width: 25%;
            margin: auto !important;
        }

        @media (max-width:1366px) {
            width: 30%;
            margin: auto !important;
        }

        @media (max-width:1280px) {
            width: 30%;
            margin: auto !important;
        }

        @media (max-width:1199px) {
            width: 35%;
            margin: auto !important;
        }

        @media (max-width:1023px) {
            width: 40%;
            margin: auto !important;
        }

        @media (max-width:991px) {
            width: 45%;
            margin: auto !important;
        }

        @media (max-width:767px) {
            width: 55%;
            margin: auto !important;
        }

        @media (max-width:640px) {
            width: 60%;
            margin: auto !important;
        }

        @media (max-width:576px) {
            width: 70%;
            margin: auto !important;
        }

        @media (max-width:500px) {
            width: 80%;
            margin: auto !important;
        }

        @media (max-width:414px) {
            width: 90%;
            margin: auto !important;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-content: center;
        justify-content: center;

        li {
            margin: 30px 10px;

            a {
                text-decoration: none;

                img {
                    max-width: 143px;
                    min-width: 143px;
                    min-height: 48px;
                    max-height: 48px;

                }

                &:hover,
                &:focus,
                &:active {
                    box-shadow: none;
                    border: none;
                    outline: none;
                }
            }
        }
    }

    .thanksContent {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px 0 15px;

        ul {
            list-style: none;
            padding: 0;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;

            li {
                position: relative;
                margin: 0;

                &::before {
                    position: absolute;
                    content: '';
                    background-image: url(../images/oh-corporate-img/tick.svg);
                    left: -25px;
                    width: 20px;
                    height: 20px;
                    top: 1px;
                }
            }
        }
    }

    a {
        text-decoration: none;

        button {
            @include OHprimaybutton-sm;
            font-size: $az-font-18;
        }

        &:hover,
        &:focus,
        &:active {
            box-shadow: none;
            border: none;
            outline: none;
        }
    }
}

.otp_box input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.otp_box input[type=number] {
    -moz-appearance: textfield;
}

// Homesharing page

.home_share_banner {
    background-image: url(./../images/oh-corporate-img/homesharingprogram.jpg) !important;
    background-position: center !important;
}

.hosting_sec {
    .homesharing_sec {
        padding: 50px 0;

        @media (max-width:676px) {
            padding: 30px 0;
        }
    }

    .home_heading_main {
        h2 {
            @include heading2;
            text-align: center;
            color: $az-ligh-mid-black;
            margin-bottom: 27px;

            @media (max-width:767px) {
                margin-bottom: 20px;
            }
        }

        h4 {
            @include heading4;
            font-family: $az-heading-two !important;
            margin-top: 20px;
            color: $az-ligh-black;
            text-align: center;

            @media(max-width: 1199px) {
                font-size: $az-font-25;

            }

            @media(max-width: 1023px) {
                font-size: $az-font-24;

            }

            @media (max-width:991px) {
                margin-bottom: 0px;
                font-size: $az-font-18;


            }

            @media (max-width:767px) {
                text-align: center;
                margin-bottom: 20px;
                font-size: $az-font-18;


            }
        }
    }

    .get_started {
        padding: 60px 0;

        @media (max-width:676px) {
            padding: 30px 0;
        }

        background: $az-oh-seconday;
        text-align: left;

        h4.flow_heading {
            @include heading3;
            color: $az-light-black;
            text-align: left;
            line-height: 1.4;
            margin: 0;
            padding: 0;

            @media (max-width:767px) {
                font-size: 22px;
            }
        }


        p {
            font-family: $az-heading-two;
            font-size: $az-font-18;
            color: $az-ligh-mid-black;
            text-align: left;
            padding: 0;

            @media (max-width: 1199px) {
                padding: 10px 0;
                font-size: $az-font-16;

            }
        }

        ul {
            padding: 20px 0 0 0;
            margin: 0;
            list-style: none;

            @media (max-width:676px) {
                padding: 0;
            }

            li {
                .flow_first {
                    width: 70px;
                    float: left;
                    position: relative;

                    @media (max-width:767px) {
                        width: 50px
                    }

                    &::before {
                        content: " ";
                        border: 2px solid $az-light-black;
                        position: absolute;
                        height: 105px;
                        top: 95px;
                        right: 50%;
                        transform: translate(50%, 0%);

                        @media (max-width:1280px) {
                            right: 50%;
                        }

                        @media (max-width:1024px) {
                            right: 50%;
                            top: 100px;
                        }

                        @media (max-width:991px) {
                            right: 50%;
                            top: 95px;
                            height: 80px;
                        }

                        @media (max-width:768px) {
                            right: 50%;
                        }

                        @media (max-width:767px) {
                            height: 187px;
                            top: 70px;
                            right: 50%;
                        }
                    }

                    .flow_icon {
                        width: 70px;
                        height: 70px;
                        background: #000000;
                        border-radius: 60px;
                        position: relative;

                        @media (max-width:676px) {
                            width: 50px;
                            height: 50px;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            @media (max-width:676px) {
                                width: 26px;
                            }
                        }
                    }
                }


                &:last-child {
                    .flow_first {
                        &::before {
                            border: 0;
                        }
                    }
                }

                .flow_second {
                    width: 85%;
                    float: left;
                    padding-left: 25px;

                    @media (max-width:1024px) {
                        width: 80%;
                        float: left;
                        padding-left: 15px;
                    }

                    @media (max-width:991px) {
                        width: 83%;
                        float: left;
                        padding-left: 15px;
                    }

                    @media (max-width:767px) {
                        width: 85%;
                    }

                    @media (max-width:360px) {
                        width: 83%;
                    }

                    .flow_cnt {
                        min-height: 200px;
                        padding: 30px 10px 0 0;

                        @media (max-width:1024px) {
                            padding: 35px 10px 0 10px;
                            min-height: 190px;
                        }

                        @media (max-width:820px) {
                            padding: 40px 10px 0 0;
                            min-height: 180px;
                        }

                        @media (max-width:820px) {
                            padding: 27px 10px 0 10px;
                        }

                        @media (max-width:767px) {
                            min-height: 250px;
                            padding: 20px 10px 0 0px;
                        }

                        p {
                            span {
                                font-weight: 600;
                            }
                        }

                        .btn_link_guest {
                            color: #000000 !important;
                        }
                    }
                }


            }

            li.flob_keys {
                .flow_first {
                    &::before {
                        height: 140px;

                        @media (max-width:991px) {
                            height: 100px;
                        }

                        @media (max-width:767px) {
                            height: 210px;
                        }

                        @media (max-width:412px) {
                            height: 235px !important;
                        }
                    }
                }

                .flow_second {
                    .flow_cnt {
                        min-height: 235px !important;

                        @media (max-width:991px) {
                            min-height: 200px !important;
                        }

                    }
                }
            }
        }

        .get_started_img {
            margin-top: 360px;

            @media (max-width:1280px) {
                margin-top: 300px;
            }

            @media (max-width:1024px) {
                margin-top: 350px;
            }

            @media (max-width:991px) {
                margin-top: 10px;
            }


            @media (max-width:767px) {
                margin-top: 10px;
            }

            figure {
                max-width: 100%;
                max-height: 722px;
                // min-height: 722px;
                position: relative;
                overflow: hidden;
                margin-bottom: 0;

                img {
                    max-height: 722px;
                    width: 100%;
                }
            }
        }
    }

    .get_listed {
        padding: 60px 0;

        @media (max-width:676px) {
            padding: 30px 0;
        }

        .home_heading_main {
            padding-bottom: 50px;

            @media (max-width:676px) {
                padding-bottom: 0px;
            }
        }

        .get_listed_img {
            @media (max-width:676px) {
                margin-top: 30px;
            }

            figure {
                max-width: 100%;
                max-height: 722px;
                // min-height: 722px;
                position: relative;
                overflow: hidden;
                margin-bottom: 0;

                img {
                    max-height: 722px;
                    width: 100%;
                }
            }
        }

        .get_listed_cnt {
            text-align: left;
            background: $az-oh-seconday;
            width: 690px;
            // max-height: 525px;
            // min-height: 480px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            background: $az-oh-seconday;
            padding: 60px 45px 55px;
            margin-left: 0;
            margin-top: 0px;
            z-index: 1;
            position: absolute;
            right: -38px;
            top: 50%;
            transform: translate(0, -50%);

            @media (max-width: 1366px) {
                width: 630px;
                margin-top: 0px;
                padding: 40px 45px 55px;
                right: 0px;
            }

            @media (max-width: 1280px) {
                width: 630px;
                margin-top: 0px;
                padding: 40px 45px 55px;
                right: 0px;
            }

            @media (max-width: 1199px) {
                margin-top: 0px;
                max-height: 410px;
                min-height: 410px;
                right: 0px;
            }

            @media (max-width: 1023px) {
                margin-top: 0px;
                max-height: 380px;
                min-height: 380px;
                right: 0px;
            }

            @media (max-width: 991px) {
                width: 100%;
                position: unset;
                left: 0;
                padding: 40px 30px;
                min-height: auto;
                max-height: auto;
                margin-top: 10px;
                margin-bottom: 10px;
                top: 0;
                transform: none;
            }

            @media (max-width: 767px) {
                width: 100%;
                position: relative;
                left: 0;
                padding: 40px 30px;
                min-height: auto;
                max-height: auto;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            @media (max-width:676px) {
                margin-top: 10px;
                padding: 40px 30px;
                max-height: 100%;
            }

            h3 {
                @include heading3;
                color: $az-light-black;
                text-align: left;
                line-height: 1.4;
            }

            p {
                font-family: $az-heading-two;
                font-size: $az-font-18;
                color: $az-ligh-mid-black;
                text-align: left;
                padding: 0;

                @media (max-width: 1199px) {
                    padding: 10px 0;
                    font-size: $az-font-16;

                }

            }

            h5 {
                @include brandHeading-5;
                font-family: $az-heading-two;
                margin-top: 20px;
                color: $az-ligh-black !important;
            }
        }
    }

    .get_hosted {
        padding: 60px 0;
        text-align: left;

        @media (max-width:676px) {
            padding: 0 0 30px 0;
        }

        .guest_cnt_box {
            padding-top: 150px;
            margin-top: 60px;
            position: relative;

            @media (max-width:676px) {
                padding-top: 100px;
                margin-top: 30px;
            }

            .guest_img {
                width: 300px;
                height: 300px;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50%, 0%);

                .resdent_img {
                    background: url(../images/oh-corporate-img/checkout.jpg);
                }

                .clear_clear_img {
                    background: url(../images/oh-corporate-img/cleanclear.jpg);
                    background-position: right 100% !important;
                }

                .check_in_img {
                    background: url(../images/oh-corporate-img/check_in.jpg);
                }

                .check_out_img {
                    background: url(../images/oh-corporate-img/check_out.jpg);
                    background-position: right 100% !important;
                }

                .concierge_img {
                    background: url(../images/oh-corporate-img/concierge.jpg);
                }

                .resdent_ready {
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-position: right;
                }

                @media (max-width:676px) {
                    width: 200px;
                    height: 200px;
                }

                img {
                    width: 100%;
                    border-radius: 50%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .guest_cnt {
                background: $az-oh-seconday;
                padding: 180px 40px 40px 40px;
                min-height: 380px;

                @media (max-width:676px) {
                    min-height: auto;
                    padding: 120px 30px 30px 30px;
                }

                h3 {
                    @include heading3;
                    color: $az-light-black;
                    text-align: left;
                    line-height: 1.4;
                    padding-bottom: 10px;

                    @media (max-width:1199px) {
                        padding-bottom: 0px;
                    }
                }

                p {
                    font-family: $az-heading-two;
                    font-size: $az-font-18;
                    color: $az-ligh-mid-black;
                    text-align: left;
                    padding: 0;

                    @media (max-width: 1199px) {
                        padding: 10px 0;
                        font-size: $az-font-16;

                    }

                }

                h4 {
                    @include heading4;
                    font-family: $az-heading-two !important;
                    margin-top: 20px;
                    color: $az-ligh-black;
                    text-align: left;

                    @media(max-width: 1199px) {
                        font-size: $az-font-25;

                    }

                    @media(max-width: 1023px) {
                        font-size: $az-font-24;

                    }

                    @media (max-width:991px) {
                        margin-bottom: 0px;
                        font-size: $az-font-18;


                    }

                    @media (max-width:767px) {
                        text-align: left;
                        margin-bottom: 20px;
                        font-size: $az-font-18;


                    }
                }



                ul {
                    list-style: none;
                    margin: 0 0 5px 0;
                    padding: 0;

                    // display: inline-block;
                    @media (max-width:676px) {
                        margin-bottom: 14px;
                        display: flow-root;
                    }

                    li {
                        list-style: none;
                        margin-bottom: 0px;
                        // display: inline-block;
                        padding-right: 20px;
                        position: relative;
                        font-size: $az-font-18;
                        color: $az-light-black;
                        // float: left;
                        float: unset;
                        display: block;
                        padding-left: 20px;

                        &::before {
                            content: " ";
                            border: 0;
                            position: absolute;
                            border: 0;
                            width: 5px;
                            height: 5px;
                            background: #000;
                            left: 0;
                            top: 10px;
                            border-radius: 5px;

                            @media (max-width:767px) {
                                border: 0;
                                width: 5px;
                                height: 5px;
                                background: #000;
                                left: 0;
                                top: 10px;
                                border-radius: 5px;
                            }
                        }

                        &:last-child {
                            &::before {
                                border: 0;
                            }
                        }

                        @media (max-width:767px) {
                            font-size: $az-font-18;
                        }
                    }
                }
            }
        }
    }
}

.get_paid {
    text-align: left;
    padding: 60px 0;

    @media (max-width:676px) {
        padding: 30px 5px;
    }

    .paid_mobile {
        display: none;

        @media (max-width:767px) {
            display: block;
        }
    }

    .paid_desk {
        @media (max-width:767px) {
            display: none;
        }
    }

    .get_paid_img {
        figure {
            max-width: 100%;
            max-height: 722px;
            // min-height: 722px;
            position: relative;
            overflow: hidden;
            margin-bottom: 0;

            img {
                max-height: 722px;
                width: 100%;
            }
        }
    }

    .get_pain_cnt {
        height: 100%;
        display: grid;
        place-content: center;
        padding-left: 50px;

        @media (max-width:767px) {
            padding-left: 0;
        }

        h3 {
            @include heading3;
            color: $az-light-black;
            text-align: left;
            line-height: 1.4;

            @media (max-width:767px) {
                padding-top: 15px;
            }
        }

        p {
            font-size: $az-font-18;
            display: inline-block;
            margin: 0;

            @media (max-width:676px) {
                font-size: $az-font-16;
            }
        }
    }
}

.get_flow {
    margin: 0 !important;
    padding-top: 85px;

    @media (max-width:991px) {
        padding-top: 40px;
    }

    @media (max-width:767px) {
        padding-top: 20px;
    }

    h4.pt-h4-new {
        font-size: $az-font-32;
        color: $az-light-black;
        padding: 18px 0;

        @media (max-width:767px) {
            font-size: $az-font-22;
            margin-bottom: 0;
        }
    }
}

.header_hosting {
    .Brandheader {
        @media (max-width:767px) {
            display: none;
        }
    }

    .video_pop_img {
        margin: 30px 0;
    }

    h5 {
        @include brandHeading-5;
        font-family: $az-heading-two;
        margin-top: 20px;
        color: $az-black !important;
    }
}

.update_text {
    @media (max-width:767px) {
        text-align: left !important;
        padding-left: 10px;
    }

}

// .MuiAutocomplete-popper {
//     .Mui-focused{
//         background-color: transparent !important;
//     }
// }

.MuiAutocomplete-option[aria-selected="true"] {
    background-color: rgba(219, 219, 219, 0.4) !important;
}

.css-1km1ehz.Mui-selected:hover {
    background-color: rgba(219, 219, 219, 0.4) !important;
}

.css-1km1ehz.Mui-selected {
    background-color: rgba(219, 219, 219, 0.4) !important;
}


// New Privacy Policy Design
.newcontentPrivacy {
    text-align: justify;

    @media(max-width:767px) {
        text-align: left;
    }

    ul {
        padding-left: 1rem;

        li {
            font-size: $az-font-18;
            font-family: $az-heading-two;
            color: $az-ligh-mid-black;
            text-align: justify;
        }
    }

    ol {
        padding-left: 0px !important;
        margin-bottom: 0 !important;
        @media(max-width:767px) {
            padding-left: 40px !important;
        }

        li {
            font-size: $az-font-24;
            font-family: $az-kini-heading-three;
            color: $az-ligh-mid-black;
            text-align: justify;
            padding: 10px 0;
            list-style: none !important;

            &::marker {
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 40px;
            }


            @media(max-width:767px) {
                text-align: left;
                font-size: $az-font-18;
            }

            span {
                font-size: $az-font-24;
                font-family: $az-kini-heading-three;
                color: $az-ligh-mid-black;
                text-align: justify;
                display: block;
                margin-bottom: 10px;

                @media(max-width:767px) {
                    text-align: left;
                    font-size: $az-font-18;
                }
            }

            p {
                font-size: $az-font-18;
                font-family: $az-heading-two;
                color: $az-ligh-mid-black;
                text-align: justify;

                @media(max-width:767px) {
                    text-align: left;
                    font-size: $az-font-18;
                }

                span {
                    font-family: $az-kini-heading-three;
                }
            }

            ol {
                li {
                    font-size: $az-font-18;
                    font-family: $az-heading-two;
                    color: $az-ligh-mid-black;
                    text-align: justify;
                    list-style: lower-alpha;
                    margin: 0px;
                    padding-left: 0px;

                    @media(max-width:767px) {
                        text-align: left;
                        font-size: $az-font-18;
                    }

                    p {
                        font-size: $az-font-18;
                        font-family: $az-heading-two;
                        color: $az-ligh-mid-black;
                        text-align: justify;

                        @media(max-width:767px) {
                            text-align: left;
                            font-size: $az-font-18;
                        }
                    }
                }
            }

            ul {
                margin-bottom: 10px;

                li {
                    font-size: $az-font-18;
                    font-family: $az-heading-two;
                    color: $az-ligh-mid-black;
                    text-align: justify;
                    margin: 0px 5px;
                    padding: 10px;
                    list-style: disc !important;

                    @media(max-width:767px) {
                        text-align: left;
                        font-size: $az-font-18;
                    }
                }
            }

            .howtocontactus {
                margin: 0 0px 15px 60px;
            }
        }
    }
}

// New T&C Design
.newcontentterms {
    text-align: left;

    span {
        font-size: $az-font-16;
        font-family: $az-heading-two;
        color: $az-ligh-mid-black;
        margin-bottom: 30px;
        display: block;
    }

    p {
        font-size: $az-font-18;
        font-family: $az-heading-two;
        color: $az-ligh-mid-black;
        text-align: left;
        text-align: justify;

        @media(max-width:767px) {
            text-align: left;
            font-size: $az-font-18;
        }


        span {
            font-family: $az-kini-heading-three;
        }

        a {
            color: $az-ligh-mid-black;
        }
    }

    ol {

        @media(max-width:767px) {
            padding-left: 40px !important;
        }

        li {
            font-size: $az-font-24;
            font-family: $az-kini-heading-three;
            color: $az-ligh-mid-black;
            text-align: justify;
            padding: 10px 0;
            list-style: upper-roman;



            @media(max-width:767px) {
                text-align: left;
                font-size: $az-font-18;
            }

            span {
                font-size: $az-font-24;
                font-family: $az-kini-heading-three;
                color: $az-ligh-mid-black;
                text-align: justify;
                display: block;
                margin-bottom: 10px;
                font-weight: normal;

                @media(max-width:767px) {
                    text-align: left;
                    font-size: $az-font-18;
                }

            }

            p {
                font-size: $az-font-18;
                font-family: $az-heading-two;
                color: $az-ligh-mid-black;
                text-align: justify;

                @media(max-width:767px) {
                    text-align: left;
                    font-size: $az-font-18;
                }

                span {
                    font-family: $az-kini-heading-three;
                }
            }

            p.text-transform {
                text-transform: uppercase;
            }

            ol {
                li {
                    font-size: $az-font-24;
                    font-family: $az-heading-two;
                    color: $az-ligh-mid-black;
                    text-align: justify;
                    list-style: upper-alpha;
                    margin: 0px 5px;
                    padding-left: 15px;
                    font-weight: 600;

                    @media(max-width:767px) {
                        text-align: left;
                        font-size: $az-font-18;
                    }

                    p {
                        font-size: $az-font-18;
                        font-family: $az-heading-two;
                        color: $az-ligh-mid-black;
                        font-weight: normal;
                        text-align: justify;

                        @media(max-width:767px) {
                            text-align: left;
                            font-size: $az-font-18;
                        }
                    }
                }

            }

            ol.userConduct {

                li {
                    font-size: $az-font-18;
                    font-family: $az-heading-two;
                    color: $az-ligh-mid-black;
                    text-align: justify;
                    list-style: decimal;
                    margin: 0px 5px;
                    padding: 0 15px;
                    font-weight: lighter;

                    @media(max-width:767px) {
                        text-align: left;
                        font-size: $az-font-18;
                    }

                    p {
                        font-size: $az-font-18;
                        font-family: $az-heading-two;
                        color: $az-ligh-mid-black;
                        font-weight: normal;
                        text-align: justify;

                        @media(max-width:767px) {
                            text-align: left;
                            font-size: $az-font-18;
                        }
                    }
                }

            }

            ul {
                margin-bottom: 10px;

                li {
                    font-size: $az-font-18;
                    font-family: $az-heading-two;
                    color: $az-ligh-mid-black;
                    text-align: justify;
                    margin: 0px 5px;
                    padding: 10px;
                    list-style: disc;

                    @media(max-width:767px) {
                        text-align: left;
                        font-size: $az-font-18;
                    }
                }
            }

            .howtocontactus {
                margin: 0 0px 15px 60px;
            }
        }

        // background-color: rgba(219, 219, 219, 0.4) !important;
    }
}

.mobile_w {
    @media (max-width:767px) {
        width: 100%;
    }
}

.deskview {
    display: block;

    @media (max-width:767px) {
        display: none;

    }
}

.mobview {
    display: none;

    @media (max-width:767px) {
        display: block;

    }

}

h5.mobview {
    margin-bottom: 0 !important;
    margin-top: 20px !important;
}

.cust-mg-t-30 {
    margin-top: 30px;

    @media (max-width:767px) {
        margin-top: 15px !important;
    }
}

.text-left-cust {

    text-align: left;
    font-size: $az-font-22;
    font-family: $az-kini-heading-three;
    margin-bottom: 0;

    b {
        @media (max-width:767px) {
            font-family: $az-kini-heading-three;
            font-weight: normal;
        }
    }
}

.offcanvas-backdrop.show {
    @media (max-width:767px) {
        opacity: 0;
    }
}

@media (max-width:900px) and (orientation:landscape) {
    .modal.show .modal-dialog {
        width: 100%;
        max-width: 100%;
        margin: 0;
        background: #fff;
    }

    .modal.show .modal-dialog .modal-content {
        border-radius: 0;
        border: 0;
    }

    .reservationBook .modal-content .width_402 {
        position: relative;
        top: initial;
        transform: initial;
        padding: 0px;
        width: 100%;
        padding: 30px 0;
    }

    .reservationBook .modal-content .welcomeClose {
        top: 20px;
    }
}

.mg-lg-t-30 {
    margin-top: 30px;

    @media (max-width:767px) {
        margin-top: 0;
    }
}

.pd-lg-b-60 {
    padding-bottom: 60px;

    @media (max-width:767px) {
        padding-bottom: 30px;
    }
}

.span_text {
    p {
        span {
            &:nth-child(2) {
                display: none !important;
            }
        }
    }
}

.service_terms {
    padding-left: 0;

    .agree {
        color: $az-black;

        a {
            display: inline-block !important;
            font-size: $az-font-14 !important;
            color: #2222f2 !important;
            text-underline-offset: 3px;
        }
    }
}

//  leasing-process-page

.leasing-process_banner {
    background-image: url(./../images/oh-corporate-img/leasing_img.jpg) !important;
    background-position: center 65% !important;
    z-index: 1;
}


.leasing_into_sec {
    position: relative;
    overflow: hidden;

    @media (max-width:767px) {
        padding: 30px 0 10px;
    }

    &::after {
        position: absolute;
        background-image: url(./../images/oh-corporate-img/block-shap-2.png);
        left: -200px;
        width: 324px;
        height: 599px;
        content: '';
        top: -400px;
        display: block;

        @media (max-width:767px) {
            left: -250px;
            top: -430px;
        }
    }

    &::before {
        position: absolute;
        background-image: url(./../images/oh-corporate-img/block-shap-1.png);
        right: -200px;
        width: 324px;
        height: 599px;
        content: '';
        bottom: -400px;
        display: block;

        @media (max-width:767px) {
            right: -250px;
            bottom: -430px;
        }
    }

    h5 {
        a {
            color: $az-black
        }
    }

    .home_heading_main {
        @media (max-width:767px) {
            margin-bottom: 30px;
        }
    }

}

.Leasint_tour_sec {
    padding: 60px 0 30px !important;

    @media (max-width:767px) {
        padding: 30px 0 10px !important;
    }

    h5 {
        a {
            color: $az-black
        }

        @media (max-width:767px) {
            margin-top: 10px;
        }
    }

    .home_heading_main {
        margin-bottom: 15px;

        @media (max-width:767px) {
            margin-bottom: 15px;
        }
    }

    .Leasing_list {
        padding: 0;



        ul {
            list-style: initial;
            padding: 0;
            margin-left: 20px;

            @media (max-width:767px) {
                margin-left: 15px;
            }

            li {
                display: list-item;
                text-align: -webkit-match-parent;
                font-size: $az-font-18;
                margin-bottom: 10px;

                a {
                    color: $az-black;
                }
            }
        }
    }
}

.video_link {
    margin-top: 30px !important;
}

.btn-short-video {
    font-family: $az-heading-two !important;
    background: transparent;
    border: 0;
    text-decoration: underline;
}

.video_pop_img {

    text-align: center;


    @media (max-width:991px) {
        margin-top: 60px;
    }

    @media (max-width:600px) {
        margin-top: 30px;
    }

    figure {
        max-height: 570px;
        min-height: 570px;
        margin: 0 auto;

        @media (max-width:1440px) {
            max-height: 571px;
            min-height: 571px;
        }

        @media (max-width:1275px) {
            max-height:609px;
            min-height:609px;
        }

        @media (max-width:991px) {
            max-height: 400px;
            min-height: 400px;
        }

        @media (max-width:767px) {
            max-height: 100%;
            min-height: 100%;
            width: 100%;
        }

        @media (max-width:360px) {
            max-height: 100%;
            min-height: 100%;
            width: 100%;
        }

        button {
            position: relative;
            padding: 0;

            &::before {
                content: '';
                background-image: url('../images/oh/leasing/play-button.svg');
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100px;
                height: 100px;
                display: block;
                z-index: 9999;
                background-size: 75%;
                background-repeat: no-repeat;
                background-position: center;
            }

            &::after {
                background-color: rgba(0, 0, 0, 0.3) !important;
                position: absolute;
                content: '';
                width: 100% !important;
                height: 100% !important;
                top: 0 !important;
                left: 0 !important;
                transform: translate(0, 0px) !important;
                bottom: 0 !important;
                z-index: 0 !important;
            }
        }


    }

    .styles_fakePreview__1UDw2 {
        display: unset;

        img {
            max-height: 570px;
            min-height: 570px;
            position: relative;
            width: auto;

            @media (max-width:1440px) {
                max-height: 571px;
                min-height: 571px;
            }

            @media (max-width:1275px) {
                max-height:609px;
                min-height:609px;
            }

            @media (max-width:991px) {
                max-height: 400px;
                min-height: 400px;
            }

            @media (max-width:767px) {
                max-height: 100%;
                min-height: 100%;
                width: 100%;
            }


            @media (max-width:375px) {
                max-height: 100%;
                min-height: 100%;
                width: 100%;
            }
        }
    }

    .styles_playButton__1Q3EF {
        background-image: url('../images/oh/leasing/play-button.svg');
        background-color: transparent;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        display: block;
        z-index: 9999;
        background-size: 75%;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 !important;
        z-index: 0;

        &::after {
            border: 0 !important;
        }
    }
}

.video_pop_img_2 {
    margin-top: 35px;

    @media (max-width:767px) {
        margin-top: 30px;
    }
}

.modal-video-movie-wrap {
    width: 365px !important;
    height: 600px !important;
    background-color: transparent !important;

    @media (max-width:767px) {
        width: 100% !important;
        height: 100% !important;
    }
}

.list_video {
    margin: 40px 0 !important;

    @media (max-width:767px) {
        margin: 15px 0 20px 0 !important;
        position: relative;
        left: -7px;
    }
}

body::-webkit-full-page-media {
    background-color: transparent !important;
}



.modal-video-movie-wrap iframe {
    @media (max-width:767px) {
        width: 100vw !important;
        height: 100vh !important;
    }
}

.modal-video-close-btn {
    @media (max-width:767px) {
        top: 40px !important;
        right: 15px !important;
    }
}

.modal-video-inner {
    @media (max-width:767px) {
        align-items: baseline;
    }
}


.modal-video-body {
    @media (max-width:767px) {
        padding: 0 !important;
    }
}

// .modal-video-movie-wrap {
//     body{
//         &::-webkit-full-page-media {
//             background-color: red !important;
//         }
//     }
// }


.calendlyModal {
    width: 100%;

    .modal-dialog {
        max-width: 55% !important;

        iframe {
            min-height: 680px;
        }

        .modal-body {
            padding: 0 !important;
        }
    }
}


// Lease availability section LTR page

.leaseAvailability {
    padding: 5px 0px 50px;

    @media(max-width: 767px) {
        padding: 0px 0px 0;
    }

    h5 {
        font-size: $az-font-22;
        font-family: $az-heading-para;
        color: $az-oh-primary;
        text-align: left;
        margin-bottom: 20px;
        text-transform: initial !important;
    }

    ul {
        list-style: none;
        text-align: left;
        padding: 0;

        li {


            display: inline;

            button {
                font-family: $az-heading-two !important;
                color: $az-gray;
                background: none;
                padding: 5px 15px;
                box-shadow: none;
                border: 1px solid $az-oh-seconday !important;
                min-width: 85px;
                border-radius: 0 !important;

                @media(max-width: 767px) {
                    min-width: 25%;
                }

                &:hover,
                &:focus,
                &:active {
                    background: $az-oh-seconday !important;
                    color: $az-black !important;
                    border: 1px solid $az-oh-seconday !important;
                }
            }

            // button.active {
            //     font-family: $az-heading-two;
            //     color: $az-black;
            //     background: $az-oh-seconday;
            //     padding: 5px 15px;
            //     box-shadow: none;
            //     border: 1px solid $az-oh-seconday;
            // }
        }
    }

    table {
        width: 100%;

        th {
            font-size: $az-font-16;
            font-family: $az-heading-two !important;
            color: $az-oh-primary !important;
            text-align: center;
            font-weight: normal;
            background: $az-light-gray !important;
            padding: 10px 15px;
            border: 1px solid #E1E1E1;
        }

        td {
            font-size: $az-font-16;
            font-family: $az-heading-two !important;
            color: $az-gray;
            text-align: center;
            border: 1px solid $az-light-gray;
            padding: 10px 15px;
        }
    }

    .col-md-12 {
        overflow: auto;
        max-height: 320px;

        table {
            position: relative;

            thead {
                position: sticky !important;
                top: 0 !important;
            }
        }
    }
}

.tooltip.bs-tooltip-end {
    padding: 5px 5px;
    background-color: $az-oh-seconday !important;
    border-radius: 10px;
    margin-left: 8px;

    table {
        width: 100px;
        font-family: $az-heading-two;

        th {
            color: $az-gray ;
            font-family: $az-heading-two;
            font-weight: normal;
        }

        td {
            color: $az-black;
            font-family: $az-heading-two;
        }
    }

    .tooltip-inner {
        background-color: $az-oh-seconday !important;

    }

    .tooltip-arrow {
        left: -5px;

        &::before {
            border-right-color: $az-oh-seconday !important;
            border-width: 10px !important;
        }
    }
}

.tooltip.bs-tooltip-top {
    padding: 5px 5px;
    background-color: $az-oh-seconday !important;
    border-radius: 10px;
    margin-bottom: 8px;

    table {
        width: 100px;
        font-family: $az-heading-two;

        th {
            color: $az-gray ;
            font-family: $az-heading-two;
            font-weight: normal;
        }

        td {
            color: $az-black;
            font-family: $az-heading-two;
        }
    }

    .tooltip-inner {
        background-color: $az-oh-seconday !important;

    }

    .tooltip-arrow {
        bottom: -5px;

        &::before {
            border-top-color: $az-oh-seconday !important;
            border-width: 10px !important;
        }
    }
}

.tooltip.bs-tooltip-bottom {
    padding: 5px 5px;
    background-color: $az-oh-seconday !important;
    border-radius: 10px;
    margin-top: 9px;

    table {
        width: 100px;
        font-family: $az-heading-two;

        th {
            color: $az-gray ;
            font-family: $az-heading-two;
            font-weight: normal;
        }

        td {
            color: $az-black;
            font-family: $az-heading-two;
        }
    }

    .tooltip-inner {
        background-color: $az-oh-seconday !important;

    }

    .tooltip-arrow {
        top: -5px;

        &::before {
            border-bottom-color: $az-oh-seconday !important;
            border-width: 10px !important;
        }
    }
}

.tooltip.bs-tooltip-start {
    padding: 5px 5px;
    background-color: $az-oh-seconday !important;
    border-radius: 10px;
    margin-right: 8px;

    table {
        width: 100px;
        font-family: $az-heading-two;

        th {
            color: $az-gray ;
            font-family: $az-heading-two;
            font-weight: normal;
        }

        td {
            color: $az-black;
            font-family: $az-heading-two;
        }
    }

    .tooltip-inner {
        background-color: $az-oh-seconday !important;

    }

    .tooltip-arrow {
        right: -5px;

        &::before {
            border-left-color: $az-oh-seconday !important;
            border-width: 10px !important;
        }
    }
}

.styles_closeButton__20ID4 {
    width: 31px !important;
    height: 31px !important;
    font-size:$az-font-15 !important;
}

.styles_modalContent__2s9Lt {
    width: auto !important;
    max-width: auto !important;

    @media (max-width:767px) {
        width: 80% !important;
        height: max-content !important;
        height: max-content !important;
    }
}

.styles_displayBlock__2eZu5 {
    z-index: 9999 !important;
}

.styles_contentFluid__1bl_d {
    height: 100% !important;

    @media (max-width:767px) {
        height: max-content !important;
    }
}

.hosting_form {
    iframe {
        @media (max-width:767px) {
            width: 100% !important;
        }
    }
}

.mytripList {

    padding: 50px 30px !important;
    max-width: 1400px !important;

    @media (max-width:1680px) {
        padding: 50px 20px !important;
        max-width: 1400px !important;

    }

    @media (max-width:1440px) {
        padding: 50px 40px !important;
        max-width: 1400px !important;

    }

    @media (max-width:1280px) {
        padding: 50px 40px !important;
        max-width: 100% !important;

    }

    @media (max-width:1199px) {
        padding: 50px 40px !important;
        max-width: 100% !important;

    }

    @media (max-width:1023px) {
        padding: 50px 40px !important;
        max-width: 100% !important;

    }


    .container {
        @media (max-width:1366px) {
            padding: 0 !important;
        }
    }
}

.ltrmarketing {
    @media (max-width: 767px) {
        padding: 0;
    }

    .sectionTwo {
        padding-left: 15px !important;

        @media (max-width: 767px) {
            padding: 0 !important;
        }
    }

    .sectionThree {
        @media (max-width: 767px) {
            padding: 0 !important;
        }
    }
}

.commanblockltr {
    @media (max-width: 767px) {
        padding-top: 0 !important;
    }

    .guestBlock {
        @media (max-width: 767px) {
            margin-top: 0 !important;
        }
    }
}

.detail_page_ltr,
.propertyIndex {

    .leaseAvailable {
        margin-bottom: 30px;
        margin-top: 60px !important;

        @media (max-width: 767px) {
            margin-top: 0 !important;
            margin-bottom: 0px !important;

        }

        .sectionOne,
        .sectionTwo,
        .sectionThree {
            h5 {
                font-size: $az-font-20;
                text-transform: uppercase;
                font-family: $az-heading-para !important;
                margin-bottom: 5px;
            }
        }

        .sectionOne {


            span {
                font-family: $az-heading-para;
                font-size: $az-font-16 !important;
                color: $az-black;
                padding: 10px 0 5px;
                display: block;
                width: auto !important;
                text-align: left !important;
            }

            p {
                font-family: $az-heading-two;
                font-size: $az-font-16 !important;
                color: $az-oh-primary;

            }

            .accordion-item {
                &:last-child {
                    border: none;
                }
            }

            ul {
                font-size: $az-font-16 !important;
                list-style: none !important;
                padding: 0 0 0 30px !important;
                display: grid !important;

                li {
                    display: list-item !important;
                    margin: 5px 0 !important;
                    font-size: $az-font-16 !important;
                    color: $az-black;
                    font-family: $az-heading-two;
                    list-style: disc !important;

                    &::after {
                        display: none;
                    }

                    ul {
                        list-style: none !important;
                        padding: 10px 40px;
                        margin: 10px 0 5px !important;

                        li {
                            margin: 5px 0 !important;
                            list-style: circle !important;

                            ul {
                                list-style: none !important;
                                padding: 10px 40px;

                                li {
                                    list-style: square !important;
                                }
                            }

                        }
                    }

                    b {
                        margin-left: 0 !important;
                    }
                }
            }

            button.accordion-button {
                h5 {
                    text-align: left !important;

                    @media (max-width:395px) {
                        padding-right: 50px;
                    }
                }

                background: none;
                padding: 15px 0px 15px;

                &:focus,
                &:active {
                    border: none;
                    box-shadow: none;
                }

                &::after {
                    filter: brightness(0);
                    position: absolute;
                    right: 0;
                    // top: 20px;
                    top: 50% !important;
                    transform: translate(0, -50%);
                    // @media(max-width: 991px) {
                    //     top: 27px;
                    // }

                    // @media(max-width: 767px) {
                    //     top: 27px;
                    // }

                }

                @media(max-width: 991px) {
                    padding: 5px 0px;
                }

                @media(max-width: 767px) {
                    padding: 5px 0px;
                }
            }
        }


        .accordion-item {
            border-left: none;
            border-right: none;

            &:first-child {
                border-top: none;
            }

            .accordion-header {
                padding-bottom: 0;
            }

            .accordion-body {
                padding: 0 0 30px;

                .MuiSelect-select {


                    @media(max-width: 767px) {
                        word-wrap: break-word;
                        white-space: inherit;
                    }
                }
            }

            .accordion-button:not(.collapsed) {
                box-shadow: none !important;
            }
        }

        button.accordion-button {
            background: none;
            padding: 15px 0px 15px;

            &:focus,
            &:active {
                border: none;
                box-shadow: none;
            }

            &::after {
                filter: brightness(0);
                position: absolute;
                right: 0;
                // top: 20px;
                top: 50% !important;
                transform: translate(0, -50%);
                // @media(max-width: 991px) {
                //     top: 10px !important;
                // }

                // @media(max-width: 767px) {
                //     top: 10px !important;
                // }

            }

            @media(max-width: 991px) {
                padding: 0px 0px;
            }

            @media(max-width: 767px) {
                padding: 0px 0px;
            }
        }
    }

    .commanBlockdetail {
        @media(max-width: 767px) {
            padding-top: 0px !important;
        }
    }
}

.whatthisPlace {
    margin-top: 60px !important;

    @media(max-width: 767px) {
        margin-top: 0px !important;
    }

    .commanBlockdetail {
        @media(max-width: 767px) {
            padding-bottom: 0px !important;
        }
    }
}

.leaseMobile {

    @media(max-width: 767px) {
        display: block !important;
        margin-top: 0 !important;
    }

    .mobigustavi {
        @media(max-width: 767px) {
            display: none !important;
        }

    }

    .guest-avaialbility-btn {
        @media(max-width: 767px) {
            display: none !important;
        }
    }
}

.filtersection {
    .search-btn {
        .search_loader {
            position: relative;
            background: #909090 !important;

            &::before {
                animation: 1.5s linear infinite spinner;
                animation-play-state: inherit;
                border: solid 3px $az-oh-seconday;
                border-bottom-color: $az-ligh-black;
                border-radius: 50%;
                content: "";
                height: 61px;
                width: 61px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                will-change: transform;
            }

            @keyframes spinner {
                0% {
                    transform: translate3d(-50%, -50%, 0) rotate(0deg);
                }

                100% {
                    transform: translate3d(-50%, -50%, 0) rotate(360deg);
                }
            }
        }
    }
}

.mobilesearch-btn {
    .spinner_loader {
        margin: 7px 5px 0px 5px;
    }
}

// Chat support

.chatSupport {
    .rcw-widget-container {
        bottom: 40px;
        right: 0;

        @media(max-width: 767px) {
            bottom: 0px;
        }

        .rcw-conversation-container {
            max-width: 320px;

            @media(max-width: 767px) {
                max-width: 100%;
            }
        }
    }

    .rcw-header {
        padding: 15px;
        background: $az-black;

        .rcw-title {
            padding: 0;
            font-family: $az-kini-heading-three;
            font-size: $az-font-24;
            text-align: left;
        }

        span {
            display: none;
        }

        .rcw-close-button {
            @media(max-width: 767px) {
                background: none;
            }
        }
    }

    .rcw-input {
        text-align: left;
    }

    .rcw-send {
        background: $az-black;
        height: 40px;
        width: 40px;
        border-radius: 50px;
        line-height: 40px;
        padding-left: 8px;

        .rcw-send-icon {
            filter: contrast(2.5);
            -webkit-filter: contrast(2.5);
            -moz-filter: contrast(2.5);
            position: relative;
            top: -2px;
            height: 20px;
        }
    }

    .rcw-sender {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .rcw-picker-btn {
            display: none;
        }

        .rcw-new-message {
            width: calc(100% - 55px);
        }

    }

    .rcw-client {
        .rcw-message-text {
            background: $az-ligh-mid-black;
            color: $az-white;
            padding: 10px 15px;

            p {
                color: $az-white;
            }
        }
    }

    .rcw-response {
        .rcw-message-text {
            padding: 10px 15px;

        }
    }

    .rcw-launcher {
        background: $az-black;
        box-shadow: 0px 0px 5px 0px #7b7b7b;
        -webkit-box-shadow: 0px 0px 5px 0px #7b7b7b;
        -moz-box-shadow: 0px 0px 5px 0px #7b7b7b;

        @media(max-width: 767px) {
            bottom: 60px;
        }
    }
}


// Wizard-form-design-start

.wizard_sec {
    padding: 0 !important;
    overflow-y: auto;
    // @media (max-width:767px) {
    //     height: calc(100vh - 100px);
        
    // }
    .mobView {
        padding: 0px;
        @media (max-width:767px) {
            padding: 0 ;
        }
    }

    .container {
        @media (max-width:1366px) {
            padding: 0 30px !important;
        }
    }


    .wizard_mobile {
        display: block;
        padding: 0;

        figure {
            margin: 0;
            max-width: 100%;
            max-height: 100vh;
            overflow: hidden;
            position: relative;
            min-height: 100vh;

            @media (max-width:992px) {
                min-height: 100vh;
            }

            @media (max-width:767px) {
                min-height: auto;
            }

            img {
                min-height: 100vh;
                width: 100%;
                object-fit: cover;
                max-height: inherit;

                @media (max-width:992px) {
                    min-height: 100vh;
                }
            }
        }

        @media (max-width:676px) {
            display: block;


        }
    }

    .wizard_desk {
        padding: 0;

        @media (max-width:676px) {
            display: none;
        }
    }

    .wizard_img_fix {
        position: relative;

        figure.wizard_logo {
            position: absolute;
            z-index: 999;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            img {
                min-height: 150px;
                max-height: 150px;

                @media (max-width:991px) {
                    min-height: 100px;
                    max-height: 100px;
                }

                @media (max-width:767px) {
                    min-height: 80px;
                    max-height: 80px;
                }
            }

            @media (max-width:767px) {
                top: 30%;
            }
        }
    }

    .wizard_box {
        padding: 0;
        position: relative;
        background: $az-white;
        padding:25px 40px ;
        border-radius: 12px;
        margin:60px auto !important;
        width: 960px;
        @media (max-width:991px) {
            width: 600px;
        }
        @media (max-width:767px) {
            width: 100%;
            padding: 0 !important;
            margin: 0 !important;
        }
       
    }

    .wizard-img {
        height: 100vh;
        position: relative;

        @media (max-width:992px) {
            height: 100vh;
        }

        @media (max-width:767px) {
            height: 30vh;
        }

        figure {
            margin: 0;
            margin: 0;
            max-width: 100%;
            max-height: 100vh;
            overflow: hidden;
            position: relative;
            background: $az-light-white;
            min-height: 92vh;

            &::after {
                content: '';
                padding: 0px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: left;
                z-index: 1;
                height: 100%;
                box-sizing: border-box;
                background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, 0.01) 2%, rgba(0, 0, 0, 0.75) 90%);

                @media (max-width:767px) {
                    z-index: 0;
                }
            }

            @media (max-width:992px) {
                min-height: 100vh;
                max-height: 100vh;
            }

            @media (max-width:767px) {
                min-height: 30vh;
                max-height: 30vh;
            }

            img {
                min-height: 100vh;
                width: 100%;
                object-fit: cover;
                max-height: inherit;

                @media (max-width:992px) {
                    min-height: 100vh;
                }

                @media (max-width:992px) {
                    min-height: 30vh;
                }

            }
        }

    }

    .wizard-data {
        

        text-align: left;
        padding: 0;


        @media (max-width:767px) {
            height: auto !important;

        }

        @media (max-width:992px) {
            height: 100%;
        }

        @media (max-width:767px) {
            padding: 0px;
            margin: 0px;
            position: relative;
            top: 0px;
            background: $az-white;
            box-shadow: none;
        }



        &::-webkit-scrollbar {
            width: 10px;
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background-clip: content-box;
            border: 0px solid transparent;
            width: 10px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #333;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #333;
        }

        &::-webkit-scrollbar-corner,
        &::-webkit-scrollbar-track {
            background-color: transparent;
            width: 10px;
        }



        h2 {
            font-size: $az-font-44;
            margin: 0;
            font-weight: bold;
            padding-bottom: 10px;
            font-family: $az-kini-heading-three;
            color: $az-ligh-mid-black;

            @media (max-width:1280px) {
                font-size: $az-font-40 ;
            }

            @media (max-width:676px) {
                font-size: $az-font-30 ;
            }
        }

        .pipe_new {
            padding: 0;

            .step_btns {
                padding: 0px;
                background: $az-white;
                margin: 0;
                margin-top: 10px;    
                @media (max-width:1280px) {
                    padding: 10px 0;
                }

                @media (max-width:991px) {
                    position: unset;
                }

                @media (max-width:767px) {
                    // display: grid;
                    // place-content: center;
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    z-index: 9;
                    padding:  10px;
                    margin: 0px;
                }

            }

            .btn_pre_wzd {
                border: 1px solid $az-black;
                background: transparent;
                font-size: 20px;
                border-radius: 50px;
                padding: 9px 40px;
                font-family: $az-kini-heading-three !important;
                letter-spacing: 1px;
                text-decoration: none;
                text-align: center;
                color: $az-black;
                width: 47%;
                min-width: 47%;
                margin:auto 10px auto 0;

                @media (max-width:767px) {
                    width: 47%;
                    min-width: 47%;
                    padding: 10px 30px;
                    margin: 10px auto;
                }
            }


            // ._hsN1w{
            //     @media (max-width:767px) {
            //         width: 90% !important;
            //         min-width: 90% !important;
            //         margin: 20px auto !important;
            //     }
            // }

            .btn_next_wzd {
                border: none;
                background: $az-black;
                color: $az-white;
                font-size: 20px;
                border-radius: 50px;
                padding: 10px 40px;
                font-family: $az-kini-heading-three;
                letter-spacing: 1px;
                text-decoration: none;
                text-align: center;
                width: 47%;
                min-width: 47%;
                margin:auto 0px auto 10px;

                @media (max-width:767px) {
                    width: 47% !important;
                    min-width: 47% !important;
                    padding: 10px 30px;
                    margin: 10px auto;
                }
            }
        }

        .waz_pipe {
            width: 100%;
            margin: 0;
            display: none;

            .waz_pipe_stp {
                span {
                    display: none;
                }

                &::before {
                    content: '';
                    height: 3px;
                    left: 0;
                }

            }
        }

        ._2Jtxm::before {
            content: '';
            position: absolute;
            top: 50%;
            height: 3px;
            width: 100%;
            right: 50%;
            transform: translateY(-50%);
            z-index: -1;
            background: linear-gradient(to left, #e7eaf3 50%, #000 50%);
            background-size: 202% 100%;
            background-position: right bottom;
            transition: background-position 200ms ease-in-out;
        }

        ._2Jtxm._2ZUAI::before,
        ._2Jtxm._35Ago::before,
        ._2Jtxm._1CcaK::before {
            background-position: left bottom;
        }

        ._2_g61 {
            padding: 0;
        }


        ._2pGos._2RWmX {
            // visibility: hidden;
            display: none;
        }

        .wizard-content {
            margin-top: 20px;
            padding: 0;
            min-height: 100vh;
            // @media (max-width:1280px) {
            //     padding:0 30px;
            // }

            @media (max-width:991px) {
                min-height: 100%;
            }

            h3 {
                @include brandHeading-4;
                color: $az-light-black;
                text-align: left;
                line-height: 1.4;
                position: sticky;
                top: 0px;
                background: #fff;
                width: 100%;
                padding: 20px 0;
                z-index: 1;
                font-size: $az-font-32;
                color: $az-black
            }

            h4 {
                @include brandHeading-4;
                color: $az-light-black;
                text-align: left;
                line-height: 1.4;
                position: sticky;
                top: 0px;
                background: #fff;
                width: 100%;
                padding: 0;
                z-index: 1;
                font-size: $az-font-24;
                font-family: $az-heading-para !important;
                margin: 0 !important;
            }

            p {
                font-family: $az-heading-two;
                font-size: $az-font-18;
                color: $az-ligh-mid-black;
                text-align: left;
                margin-bottom: 10px !important;

                @media (max-width: 1199px) {
                    font-size: $az-font-16;

                }

                b {
                    margin-top: 30px;
                    display: flex;
                }
            }

            p.wzd_label {
                padding-bottom: 50px;
            }

            .form-group {
                .form-control {
                    &:focus {
                        color: #859bb0;
                        background-color: none;
                        border-color: none;
                        outline: 0;
                        box-shadow: none;
                    }
                }
            }

            .Print-wizard {
                float: right;
                position: absolute;
                top: 50px;
                right: 20px;

                @media (max-width:1280px) {
                    top: 30px;
                }

                @media (max-width:991px) {
                    width: auto;
                    top: 0;
                    right: 0;
                }
            }

            .wzd_img_sec {
                .wzd_img_scan {
                    margin: 50px 0;
                    text-align: center;

                    @media (max-width:1280px) {
                        margin: 30px 0;
                    }

                    figure {
                        max-height: 350px;
                        max-width: 400px;
                        margin: 0 auto;
                        border: 1px dashed gray;
                        padding: 20px;
                        border-radius: 5px;

                        @media (max-width:1280px) {
                            max-height: 350px;
                            max-width: 400px;
                        }

                        img {
                            max-height: 250px;

                            @media (max-width:1280px) {
                                max-height: 200px;
                            }
                        }
                    }

                    ul {
                        margin-top: 50px;

                        @media (max-width:1280px) {
                            margin: 30px 0;
                        }

                        li {
                            text-align: left;

                            p {
                                font-size: $az-font-16;
                                margin-bottom: 0 !important;
                            }
                        }
                    }

                    .wzd_cam_btn {
                        display: flex;
                        width: 100%;

                        button {
                            border: none;
                            background: $az-black;
                            color: $az-white;
                            font-size: 20px;
                            border-radius: 50px;
                            padding: 10px 40px;
                            font-family: $az-kini-heading-three !important;
                            letter-spacing: 1px;
                            text-decoration: none;
                            min-width: 200px;
                            margin-right: 20px;
                            margin-top: 20px;

                            @media (max-width:991px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .wzd_thanks {
                text-align: center;

                @media (max-width:767px) {
                    padding-top: 30px;
                    padding-bottom: 10px;
                }

                .pdf_btn {
                    text-decoration: underline;
                    background: transparent;
                    border: 0;
                    padding-left: 0;
                    font-size: $az-font-16;
                }

                figure {
                    max-height: 60px;
                    margin: 0;

                    img {
                        max-height: 60px;
                    }
                }

                h4 {
                    padding: 20px 0 10px 0;
                    margin: 0;
                    text-align: center;
                }

                p {
                    text-align: center;
                }
            }

        }


        .waz_thank_sec {
            display: grid;
            place-items: center;
            height: 80vh;

            @media (max-width:767px) {
                height: auto;
            }
        }



        .btn-wzrd {
            position: absolute;
            min-width: auto;

            @media (max-width:991px) {
                position: unset;
                width: 97% !important;
                max-width: 97% !important;
                margin: 0 !important;
                margin-bottom: 10px !important;
            }
        }

    }
}


.signature_form {
    .form-group {
        label {
            padding-bottom: 5px;

        }

        .form-control {
            text-align: left;
            padding: 17px 10px !important;
            font-family: $az-heading-two;
            font-size: $az-font-18;
            border-radius: 12px;
            border: 1px solid #72818e33;
            color: $az-black;

            &:focus {
                border-color: $az-black;
                color: $az-black !important;
            }
        }
    }
}


.form-wizard .container>div:not(.content) {
    margin: 0px 30px 50px 0;
    text-align: right;

    @media (max-width:1280px) {
        margin: 0px 40px 50px 0;
    }

    @media (max-width:991px) {
        margin: 0px 50px 20px 0;
    }
}


.wizard_header {

    .d-flex {
        display: none !important;
    }

    .wizard_header {
        .headerRight {
            display: none;
        }
    }


}

.wizard-space {
    padding-top: 20px;
}

.guest_detail {
    .guest_heading {
        display: flex;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

        .btn-wz-plus {
            background: transparent !important;
            width: -moz-fit-content !important;
            width: auto;
            padding: 0 !important;
            min-width: auto;
            margin: 0 !important;
        }

        h5 {
            font-family: $az-heading-two !important;
            font-size: $az-font-18;
            color: $az-ligh-mid-black;
            text-align: left;
            margin-bottom: 10px;
            font-weight: 600;

            @media (max-width: 1199px) {
                font-size: $az-font-16;

            }
        }
    }

}

.check_wizard {
    p {
        margin: 0 !important;
        color: $az-oh-primary;
    }

    p.error-message {
        color: #d32f2f !important;
    }

    ul {
        padding-left: 17px !important;

        li {
            margin-bottom: 5px;
        }
    }

    span {
        font-family: $az-heading-two !important;
        font-size: $az-font-16;
    }

}

// wizard_form_end

.styles_closeButton__20ID4 img {
    width: 27px !important;
    height: 27px !important;
}

// Ltr_detail_page

.earn_aptmt_sec {
    background: $az-white;
    padding: 90px 0;
    position: relative;

    &::after {
        position: absolute;
        background-image: url(../images/oh-corporate-img/block-shap-2.png);
        left: 0;
        width: 324px;
        height: 599px;
        content: '';
        top: 50%;
        display: block;
        transform: translate(0, -50%);

        // @media(max-width: 1366px) {
        //     top: 70px;
        // }

        // @media(max-width: 1280px) {
        //     top: 10px;
        // }

        @media(max-width: 991px) {
            display: none;
        }

        @media(max-width: 767px) {
            display: none;
        }

    }

    .can_earn {
        width: 560px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: $az-light-white;
        padding: 85px 40px 135px 100px;
        margin-left: 0;
        margin-top: 0px;
        z-index: 1;
        position: absolute;
        left: 0;
        top: 53%;
        transform: translate(0, -50%);
        text-align: left;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: $az-white;
            left: 25px;
            top: 43%;
            transform: translate(0, -50%);
            z-index: -1;


            @media (max-width:991px) {
                content: '';
                position: absolute;
                width: 93%;
                height: 92%;
                background: #ffffff;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
            }

            @media (max-width:767px) {
                content: '';
                position: absolute;
                width: 93%;
                height: 92%;
                background: #ffffff;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
            }
        }

        @media (max-width:1366px) {
            left: 50px;
            padding: 85px 40px 110px 100px;
        }

        @media (max-width:1024px) {
            width: 500px;
        }

        h3.earn_heading {
            font-size: $az-font-32;
            color: $az-ligh-mid-black;
            margin-bottom: 20px;
            font-family: $az-kini-heading-three !important;

            @media (max-width:767px) {
                font-size: $az-font-26;
            }


        }

        p.earn_para {
            font-size: 18px !important;
            padding-right: 100px;

            @media (max-width:767px) {
                padding-right: 0px;
            }
        }

        @media(max-width: 991px) {
            position: relative;
            left: 0;
            padding: 85px 35px;
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
            justify-content: center;
            align-items: center;
            transform: inherit;
            top: inherit;
        }

        @media(max-width: 767px) {
            position: relative;
            left: 0;
            padding: 85px 35px;
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
            justify-content: center;
            align-items: center;
        }
    }

    .earn_acordion {
        background: $az-ligh-mid-black;
        padding: 100px 30px 100px 250px;

        .leaseAvailable {
            margin: 0 !important;
        }

        .sectionOne {
            background: $az-ligh-mid-black;
            position: relative;
            top: -35px;


            .accordion {
                background-color: $az-ligh-mid-black;

                .accordion-item {
                    background-color: $az-ligh-mid-black;
                    border-color: #1E1E1E !important;
                    border-radius: 0;

                    &:last-child {
                        border-bottom: 1px solid #1E1E1E !important;
                    }

                    .accordion-body {
                        padding: 0 0 10px;
                    }

                    button.accordion-button {
                        // padding: 25px 25px 25px 0;

                        &::after {
                            filter: brightness(0) invert(1) !important;

                            // @media(max-width: 991px) {
                            //     top: 27px !important;
                            // }

                            // @media(max-width: 767px) {
                            //     top: 27px !important;
                            // }
                        }
                    }
                }

                .earn_rate {
                    .MuiFormControl-root {
                        // background: $az-white;
                        width: 100%;
                        text-align: left;
                        font-family: $az-heading-para !important;
                        margin: 0;

                        .MuiInputBase-input {
                            color: $az-white;
                            padding: 0 30px 0 0;
                            margin-bottom: 0px;
                            font-size: $az-font-20;
                            line-height: 1.3;
                        }

                        svg {
                            color: $az-white;
                            right: 0;
                        }
                    }

                    fieldset {
                        border: none !important;
                    }
                }

                p {
                    color: $az-white;
                    margin: 0;
                    padding-left: 0 !important;
                    padding-right: 20px !important;
                    padding-bottom: 10px !important;
                }
            }

            .earn_detail {
                padding-top: 25px;
                text-align: left;

                p {
                    color: $az-white;
                    text-align: left;
                    padding-left: 0 !important;
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;

                    i {
                        font-size: $az-font-14
                    }
                }

                p.adr_price {
                    font-size: $az-font-15 !important;
                    line-height: initial;
                    font-style: italic;
                }

                h5 {
                    color: $az-white;
                    padding-left: 0;
                    padding-right: 0;

                    @media(max-width: 991px) {
                        text-align: left;
                    }

                    @media(max-width: 767px) {
                        text-align: left;
                    }
                }

                a {
                    color: $az-white;

                    @media(max-width: 991px) {
                        font-size: $az-font-14;
                    }

                    @media(max-width: 767px) {
                        font-size: $az-font-14;
                    }
                }
            }

        }

        @media(max-width: 991px) {
            padding: 55px 30px 10px 30px;
        }

        @media(max-width: 767px) {
            padding: 55px 30px 10px 30px;
        }
    }

    @media(max-width: 991px) {
        font-size: $az-font-26;
        padding: 60px 0 0;
        height: auto !important;
    }

    @media(max-width: 767px) {
        font-size: $az-font-26;
        padding: 30px 0 0;
        height: auto !important;

    }

    .row {
        @media(max-width: 991px) {
            flex-direction: column;
        }

        @media(max-width: 767px) {
            flex-direction: column;
        }

        .col-md-4 {

            @media(max-width: 991px) {
                flex: 0 0 auto;
                width: 100%;
            }

            @media(max-width: 767px) {
                flex: 0 0 auto;
                width: 100%;
            }

        }

        .col-md-8 {

            @media(max-width: 991px) {
                flex: 0 0 auto;
                width: 100%;
            }

            @media(max-width: 767px) {
                flex: 0 0 auto;
                width: 100%;
            }

        }
    }
}

.ltr_video_sec {
    background-image: url(../images/oh-corporate-img/concrete-wall.png);
    padding: 60px 0;

    .video_step {
        text-align: left;
        background: $az-white;
        border-left: 14px solid $az-ligh-mid-black;
        padding: 65px 150px 65px 150px;
        height: 570px;
        @media (max-width:1600px) {
            padding: 65px 220px 65px 100px;
        }

        @media (max-width:1440px) {
            padding: 65px 220px 65px 100px;
        }

        @media (max-width:1280px) {
            padding: 65px 140px 65px 89px;
        }

        @media (max-width:1275px) {
            height: 609px;
        }

        @media (max-width:1024px) {
            padding: 60px;
        }

        @media (max-width:991px) {
            padding: 60px 20px;
            height: auto;
        }

        @media (max-width:1767px) {
            padding: 60px 20px;
        }

        h3.video_main_head {
            font-size: 32px;
            color: $az-ligh-mid-black;
            margin-bottom: 20px;
            font-family: $az-kini-heading-three !important;

            @media (max-width:991px) {
                font-size: $az-font-26;
                text-align: center;
            }

            @media (max-width:767px) {
                font-size: $az-font-26;
                text-align: center;
            }
        }

        .video_earn {
            padding-left: 15px;

            h5 {
                font-size: $az-font-20;
                font-family: $az-heading-para !important;
                color: $az-oh-primary;
                text-align: left;
                margin-bottom: 10px;
                text-transform: initial !important;
            }

            p {
                font-size: $az-font-18 !important;
                font-family: $az-heading-two !important;
                color: $az-oh-primary;
            }

        }

        .video_earn_img {
            margin-top: 20px;

            figure {
                max-height: 74px;
                // min-height: 74px;
                margin: 0;

                img {
                    max-height: 74px;
                    // min-height: 74px;
                }
            }
        }
    }

    @media(max-width: 991px) {
        padding: 60px 0 60px;
    }

    @media(max-width: 767px) {
        padding: 60px 0 60px;
    }

    h2 {
        @include heading2;
        color: $az-light-black;
    }

    h4 {
        @include heading4;
        color: $az-light-black;
    }

    p {
        @include heading4;
        color: $az-light-black;
    }

    a.becomebtn {
        text-decoration: none;
        margin-top: 30px;
        max-width: fit-content;

        button {
            @include OHprimaybutton-sm;
            padding: 5px 30px 10px;
            display: flex;
        }
    }
}

.table_compare_sec {
    background-image: url(../images/oh-corporate-img/tablebg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
    position: relative;
    z-index: 1;

    &::after {
        background-color: rgba(0, 0, 0, .3);
        bottom: 0;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        -webkit-transform: translate(0);
        transform: translate(0);
        width: 100%;
        z-index: 0;
    }

    .tbl_cmp_heading {
        text-align: center;
        position: relative;
        z-index: 1;

        h2.oh_tbl_heading {
            color: $az-white;
            // font-size: $az-font-32;
            @include heading2;
            margin-bottom: 10px;
            font-family: $az-kini-heading-three !important;

            @media (max-width:767px) {
                font-size: $az-font-26;
            }
        }

        h3.oh_tbl_heading {
            color: $az-white;
            font-size: $az-font-32;
            margin-bottom: 10px;
            font-family: $az-kini-heading-three !important;

            @media (max-width:767px) {
                font-size: $az-font-26;
            }
        }

        p.oh_tbl_para {
            color: $az-white;
            // font-size: $az-font-18;
            font-size: $az-font-22 !important;
            padding: 10px 95px 30px;
            margin: 0;

            @media(max-width: 991px) {
                padding: 10px 15px 30px !important;
                font-size: $az-font-18 !important;
            }

            @media(max-width: 767px) {
                padding: 10px 15px 30px !important;
                font-size: $az-font-18 !important;
            }
        }
    }

    .table_oh {
        background: $az-white;
        padding: 50px;
        border-radius: 20px;
        position: relative;
        z-index: 1;

        figure {
            margin: 0;
            max-height: 30px;
            min-height: 30px;

            img {
                max-height: 30px;
                min-height: 30px;
            }
        }

        tr {
            border: 0;
            border-bottom: 5px solid #fff;
        }

        th {
            vertical-align: middle;
            height: 45px;
            border: 0;
            background-color: #F9F5F8;

        }

        td {
            vertical-align: middle;
            height: 45px;
            border: 0;
            background-color: #F9F5F8;

        }

        thead {
            th {
                background: transparent;
            }
        }

        @media(max-width: 991px) {
            padding: 15px 10px;
        }

        @media(max-width: 767px) {
            padding: 15px 10px;
        }
    }
}

.ltr_faq_sec {

    .leaseAvailable {
        margin-top: 30px !important;

        .ltr_faq {
            padding-right: 50px;

            @media(max-width: 991px) {
                padding-right: 0px;
            }

            @media(max-width: 767px) {
                padding-right: 0px;
            }
        }

        .sec_faq_ltr {
            padding-left: 50px;

            @media(max-width: 991px) {
                padding-left: 0px;
            }

            @media(max-width: 767px) {
                padding-left: 0px;
            }
        }
    }

    h3.ltr_faq_heading {
        font-size: $az-font-32;
        margin-bottom: 10px;
        color: $az-oh-primary;
        text-align: left;
        font-family: $az-kini-heading-three !important;

        @media (max-width:767px) {
            font-size: $az-font-26;
            text-align: center;
        }
    }

    .accordion-header {
        h5 {
            font-size: $az-font-18;
            margin: 0;
            padding: 20px 0;
            padding-right: 90px;

            @media (max-width:991px) {
                padding-right: 30px;
                text-align: left;
            }

            @media (max-width:767px) {
                padding-right: 30px;
                text-align: left;
            }
        }

        button.accordion-button {
            &::after {
                // top: 40px;
                top: 50% !important;
                transform: translate(0, -50%);

                // @media (max-width:991px) {
                //     top: 42px !important;
                // }

                // @media (max-width:767px) {
                //     top: 42px !important;
                // }
            }
        }
    }

    .accordion-body {
        padding: 0 0 10px !important;
        text-align: left;
    }
}

.estimatePotential {

    @media (max-width: 991px) {
        padding-left: 0 !important;
    }

    @media (max-width: 767px) {
        padding-left: 0 !important;
    }

    .modal-dialog {

        @media (max-width: 991px) {
            max-width: 100% !important;
        }

        @media (max-width: 767px) {
            max-width: 100% !important;
            margin: 0;
        }

        .modal-content {
            max-height: 600px;
            padding-bottom: 20px;

            @media (max-width: 767px) {
                max-height: 100% !important;
                border: 0;
                border-radius: 0;
            }

            .modal-body {
                overflow-x: auto;
            }
        }

    }

}

.ltr_live_trav {
    .brandBlockthree {
        &::after {
            display: none;
        }
    }

    .brandBlockfour {
        &::before {
            display: none;
        }
    }

}

.ready_move_sec {
    position: relative;
    padding: 80px 0;
    z-index: 1;

    &::after {
        position: absolute;
        background-image: url(../images/oh-corporate-img/new_shap.png);
        right: 0;
        width: 490px;
        height: 265px;
        content: '';
        top: 0px;
        display: block;
        z-index: -1;

        @media (max-width:991px) {
            display: none;
        }

        @media (max-width:767px) {
            display: none;
        }
    }

    .ready_cnt {
        text-align: left;
        padding-top: 75px;

        @media (max-width:1366px) {
            padding-top: 50px;
        }

        @media (max-width:1280px) {
            padding-top: 50px;
        }

        @media (max-width:991px) {
            padding-top: 0px;
            margin: auto;
            text-align: center;
        }

        @media (max-width:767px) {
            padding-top: 0px;
            margin: auto;
            text-align: center;
        }

        h3.ready_move_heading {
            font-size: $az-font-32;
            margin-bottom: 50px;
            padding-right: 200px;
            font-family: $az-kini-heading-three !important;

            @media (max-width:1280px) {
                padding-right: 100px;
            }

            @media (max-width:991px) {
                font-size: $az-font-26;
                padding-right: 0px;
                text-align: center;
                margin-bottom: 25px;
                margin-top: 20px;
            }

            @media (max-width:767px) {
                font-size: $az-font-26;
                padding-right: 0px;
                text-align: center;
                margin-bottom: 25px;
                margin-top: 20px;
            }
        }

        button {
            border: none;
            background: $az-black;
            color: $az-white;
            font-size: $az-font-16;
            border-radius: 50px;
            padding: 10px 40px;
            font-family: $az-kini-heading-three;
            letter-spacing: 1px;

            &:hover,
            &:focus {
                border: none;
                box-shadow: none;
                outline: none;
            }
        }
    }

    .ready_box {
        background: $az-light-white ;
        width: 275px;
        height: 275px;
        padding: 25px;
        display: grid;
        place-content: center;
        float: left;
        margin-right: 25px;
        transition: .5s;

        @media (max-width:1366px) {
            width: 250px;
            height: 250px;
        }

        @media (max-width:1280px) {
            width: 240px;
            height: 240px;
        }

        @media (max-width:1280px) {
            width: 210px;
        }

        @media (max-width:991px) {
            width: 90%;
            margin: 0 auto 30px;
        }

        @media (max-width:767px) {
            width: 90%;
            margin: 0 auto 30px;
        }


        figure {
            min-height: 68px;
            max-height: 68px;

            img {
                min-height: 68px;
                max-height: 68px;
            }
        }

        p {
            color: $az-oh-primary;
            font-size: $az-font-18 !important;
        }

        &:hover {
            background: $az-ligh-mid-black;

            p {
                color: $az-white;
            }

            img {
                filter: brightness(0) invert(1) !important;
            }
        }
    }

    .col-md-8 {

        @media (max-width:991px) {
            margin: auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media (max-width:767px) {
            margin: auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    @media (max-width:767px) {
        padding: 60px 0;
    }

    .ready_mob-revert {

        @media (max-width:991px) {
            flex-direction: column-reverse;
        }

        @media (max-width:767px) {
            flex-direction: column-reverse;
        }

        .col-md-4 {

            @media (max-width:991px) {
                flex: 0 0 auto;
                width: 100%;
            }

            @media (max-width:767px) {
                flex: 0 0 auto;
                width: 100%;
            }

        }

    }

}

.ltr_partnered_sec {
    background-color: $az-light-white;
    position: relative;
    z-index: 1;

    .partnered_img {
        figure {
            max-height: 500px;
            min-height: 500px;
            margin: 0;

            @media (max-width:767px) {
                max-height: 300px;
                min-height: 300px;
            }

            img {
                max-height: 500px;
                min-height: 500px;
                object-fit: cover;
                width: 100%;
                object-position: bottom;

                @media (max-width:767px) {
                    max-height: 300px;
                    min-height: 300px;
                }
            }
        }
    }

    .partnered_cnt {
        max-height: 500px;
        text-align: left;
        padding: 100px 60px;

        @media (max-width:1440px) {
            padding: 60px;
        }

        @media (max-width:1366px) {
            padding: 100px 60px;
        }

        @media (max-width:991px) {
            padding: 30px 15px 60px;
            text-align: center;
            max-height: inherit;
        }

        @media (max-width:767px) {
            padding: 30px 15px 60px;
            text-align: center;
            max-height: inherit;
        }

        h3.part_head {
            font-size: $az-font-32;
            padding-top: 25px;
            padding-bottom: 25px;
            padding-right: 15px;
            font-family: $az-kini-heading-three !important;

            @media (max-width:1600px) {
                padding-right: 15px;
            }

            @media (max-width:1366px) {
                padding-right: 15px;
            }

            @media (max-width:991px) {
                font-size: $az-font-26;
                padding-right: 0px;
                text-align: center;
            }

            @media (max-width:767px) {
                font-size: $az-font-26;
                padding-right: 0px;
                text-align: center;
                padding-bottom: 10px;
            }
        }

        p.part_ltr_para {
            font-size: $az-font-18 !important;
            padding-bottom: 25px;
            padding-right: 15px;

            @media (max-width:1600px) {
                padding-right: 15px;
            }

            @media (max-width:1366px) {
                padding-right: 15px;
            }

            @media (max-width:991px) {
                font-size: $az-font-18 !important;
                padding-right: 0px;
                text-align: center;
            }

            @media (max-width:767px) {
                font-size: $az-font-18 !important;
                padding-right: 0px;
                text-align: center;
                padding-bottom: 8px;
            }
        }

        button {
            border: none;
            background: $az-black;
            color: $az-white;
            font-size: $az-font-16;
            border-radius: 50px;
            padding: 10px 40px;
            font-family: $az-kini-heading-three;
            letter-spacing: 1px;

            &:hover,
            &:focus {
                border: none;
                box-shadow: none;
                outline: none;
            }
        }
    }

    .row {

        @media (max-width:991px) {
            flex-direction: column-reverse;
        }

        @media (max-width:767px) {
            flex-direction: column-reverse;
        }

        .col-md-6 {



            @media (max-width:991px) {
                width: 100%;
            }

            @media (max-width:767px) {
                width: 100%;
            }

        }
    }
}

.ltr_view_area {
    margin: 0 0 50px 0;

    .four_view {
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding: 0 30px;

        .overlap {
            width: calc(100% - 60px);
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            display: none;

            @media (max-width: 1440px) {
                width: calc(100% - 30px);
            }

            @media (max-width: 1199px) {
                width: calc(100% - 30px);
            }

            @media (max-width: 991px) {
                width: calc(100% - 30px);
            }

            @media (max-width: 767px) {
                width: calc(100% - 30px);
            }
        }

        figure {
            max-width: 100%;
            position: relative;
            overflow: hidden;
            margin-bottom: 0;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .room360 {
            width: 100%;
            height: 600px;


            @media (max-width: 1199px) {
                width: 100%;
                height: 500px;
            }

            @media (max-width: 991px) {
                width: 100%;
                height: 400px;
            }

            @media (max-width: 767px) {
                width: 100%;
                height: 300px;
            }
        }

        @media (max-width: 1440px) {
            padding: 0 15px;
        }

        @media (max-width: 1199px) {
            padding: 0 15px;
        }

        @media (max-width: 991px) {
            padding: 0 15px;
        }

        @media (max-width: 767px) {
            padding: 0 15px;
        }


    }



    .btn_four_view {
        position: absolute;
        right: 20px;
        bottom: 20px;

        img {
            max-height: 60px;
        }
    }
}


.video_earn_gallery1 {

    .video_pop_img {
        text-align: center;
        padding-top: 25px;
        width: 200px;
        height: 250px;
        border-radius: 20px;
        float: left;

        .styles_fakePreview__1UDw2 img {
            max-height: 200px;
            min-height: 200px;
            position: relative;
            width: 200px;
            object-fit: cover;
            border-top: 30px solid #fff;
            border-bottom: 30px solid #fff;
            border-radius: 5px;
        }

    }

    .styles_playButton__1Q3EF {
        background-size: 40%;
    }
}

.modal-80w {
    max-width: 80%;
}

.ltr_fst_vd {
    .styles_fakePreview__1UDw2 img {
        top: 80px;
        left: 50%;
    }

    .styles_playButton__1Q3EF {
        left: 100px;
        top: 90px;
    }
}

.ltr_fst_vd {
    .styles_fakePreview__1UDw2 img {
        left: 20%;
        transform: rotate(20deg);
    }

    .styles_playButton__1Q3EF {
        left: 70%;
    }
}

.ltr_sec_vd {
    .styles_fakePreview__1UDw2 img {
        left: 10%;
        transform: rotate(7deg);
        top: 5px;
    }

    .styles_playButton__1Q3EF {
        left: 60%;

    }
}

.ltr_thr_vd {
    .styles_fakePreview__1UDw2 img {
        left: -60%;
        transform: rotate(0deg);
        top: 116px;
    }

    .styles_playButton__1Q3EF {
        left: -14%;
        top: 130px;
    }
}

.ltr_fur_vd {
    .styles_fakePreview__1UDw2 img {
        left: 20%;
        transform: rotate(20deg);
    }

    .styles_playButton__1Q3EF {
        left: 70%;
    }
}

.ltr_fiv_vd {
    .styles_fakePreview__1UDw2 img {
        top: 70px;
        left: 16%;
        transform: rotate(2deg);
    }

    .styles_playButton__1Q3EF {
        left: 62%;
        top: 79px;
    }
}

.ltr_six_vd {
    .styles_fakePreview__1UDw2 img {
        left: -20%;
        transform: rotate(20deg);
    }

    .styles_playButton__1Q3EF {
        left: 70%;
    }
}

.video_ltr_position {
    top: 0px;
}

.detail_page_ltr {
    .video_pop_img {
        @media (max-width:991px) {
            margin-top: 60px;
        }

        @media (max-width:767px) {
            margin-top: 60px;
        }
    }

    .sectionOne button.accordion-button {

        @media (max-width:991px) {
            padding: 20px 0px;
        }

        @media (max-width:767px) {
            padding: 20px 0px;
        }

        &::after {
            @media (max-width:991px) {
                top: 10px !important
            }

            @media (max-width:767px) {
                top: 10px !important
            }
        }
    }

}

.contact_sec {
    padding: 60px 0;

    @media (max-width:991px) {
        padding: 0;
    }

    @media (max-width:767px) {
        padding: 0;
    }

    &::before {
        content: '';
        position: absolute;
        right: 0;
        width: 400px;
        height: 100%;
        background: $az-light-white;
        top: 0;
        z-index: -1;

        @media (max-width:1280px) {
            width: 300px;
        }

        @media (max-width:767px) {
            width: 0;
        }
    }

    .contact_form_box {
        padding: 40px;

        @media (max-width:767px) {
            padding: 0;
            margin-top: 40px;
        }

        h1 {
            margin-bottom: 40px;
            text-align: left;
            margin-top: 0px;

            @media (max-width:991px) {
                margin-bottom: 40px;
                margin-top: 40px;
            }

            @media (max-width:767px) {
                margin-bottom: 40px;
                margin-top: 40px;
            }
        }

        button {
            font-size: $az-font-22;
        }
    }

    .address_sec {
        background: $az-ligh-mid-black;
        position: relative;
        padding: 40px 40px 20px 40px;
        width: 500px;
        float: right;
        margin-top: 40px;

        @media (max-width:1440px) {
            margin-left: 50px;
        }

        @media (max-width:1024px) {
            width: 400px;
            padding: 30px 30px 20px 25px;
        }

        @media (max-width:1024px) {
            margin-left: 65px;
        }

        @media (max-width:991px) {
            width: 100%;
            padding: 30px 30px 20px 25px;
            margin-right: 0;
        }

        @media (max-width:767px) {
            margin: 60px 0;
            padding: 25px 25px 10px 25px;
        }


        h3 {
            @include heading3;
            color: $az-white;
            text-align: left;
            line-height: 1.4;
            font-size: $az-font-32 !important;
            margin-bottom: 40px;

            @media (max-width:991px) {
                margin-bottom: 40px;
            }

            @media (max-width:767px) {
                font-size: $az-font-26 !important;
                margin-bottom: 40px;
            }
        }

        p {
            font-family: $az-heading-two;
            font-size: $az-font-20;
            color: $az-white;
            text-align: left;
            padding: 0 0 0 10px;
            margin: 0;
            line-height: normal;

            @media (max-width: 1280px) {
                font-size: $az-font-18;

            }

            @media (max-width: 1024px) {
                font-size: $az-font-16;

            }
        }

        .address_icons {
            margin-bottom: 40px;

            img {
                width: 30px;
                height: 30px;
                margin-top: 8px;
            }
        }
    }
}

.req_book_sec {
    background: $az-oh-seconday;
    padding: 60px 0;

    @media (max-width:767px) {
        padding: 30px 0;
    }

    .book_app_info {
        // background: $az-white;


        @media (max-width:991px) {
            margin: 0;
            flex-wrap: nowrap;
        }

        @media (max-width:767px) {
            margin: 0;
            flex-wrap: nowrap;
        }

        .subhead {
            padding-left: 15px !important;
            margin-bottom: 20px !important;
        }

        label.labelTitle {
            text-align: left;
        }

        .first {
            @media (max-width:991px) {
                padding: 0 5px !important;
            }

            @media (max-width:767px) {
                padding: 0 5px !important;
            }

            input {
                border: none;

                &:hover,
                &:focus,
                &:active {
                    border: none;
                    outline: none;
                    box-shadow: none;
                }

                @media (max-width:991px) {
                    order: none;
                    outline: none;
                    box-shadow: none;
                }

                @media (max-width:767px) {
                    order: none;
                    outline: none;
                    box-shadow: none;
                }


            }

        }

        p {
            font-size: $az-gray;
            font-size: $az-font-14;
        }

        p.alreadyAccount {
            font-size: $az-font-18;

            a {
                font-family: $az-heading-para;
            }
        }

        p.text-danger {
            padding-top: 5px;
            font-size: $az-font-14;
        }

        p.agreepolicy {
            b {
                font-weight: $az-heading-para;
            }
        }

        p.Mui-error {
            font-size: $az-font-12;
        }
    }
}

.book_pay_info {
    background: $az-white;
    padding: 15px 0px;
    margin-top: 10px;
    border-top: 1px solid $az-border-color;

    @media (max-width:767px) {
        margin: 10px 0 10px 0;
    }

    .subhead {
        padding-left: 15px !important;
        margin-bottom: 20px;
    }

}

.leasing {
    h3.part_head {
        font-size: $az-font-32;
        padding-bottom: 25px;
        padding-right: 200px !important;
        font-family: $az-kini-heading-three !important;

        @media (max-width:1600px) {
            padding-right: 300px;
        }

        @media (max-width:1366px) {
            padding-right: 0px !important;
        }


        @media (max-width:1280px) {
            padding-right: 0px !important;
        }

        @media (max-width:991px) {
            font-size: $az-font-26;
            padding-right: 0px !important;
            text-align: center;
        }

        @media (max-width:767px) {
            font-size: $az-font-26;
            padding-right: 0px !important;
            text-align: center;
        }
    }

    a.becomebtn {
        text-decoration: none;
        margin-top: 30px;

        button {
            @include OHprimaybutton-sm;
            padding: 5px 30px 10px;
            letter-spacing: 0 !important;
            display: flex;

            @media (max-width:991px) {
                display: inherit;
                // min-width: 170px;
            }

            @media (max-width:767px) {
                display: inherit;
                min-width: 170px;
            }
        }
    }

    .ltr_partnered_sec {
        .partnered_cnt {

            @media (max-width:991px) {
                padding: 0px 15px 60px !important;
                max-height: inherit;
            }

            @media (max-width:767px) {
                padding: 0px 15px 40px !important;
                max-height: inherit;
            }

        }

        @media (max-width:991px) {
            padding: 40px 15px 60px !important;
        }

        @media (max-width:767px) {
            padding: 40px 15px 60px !important;
        }
    }

    .mobviewhowwork {


        @media (max-width:991px) {
            padding: 0px !important;
        }

        @media (max-width:767px) {
            padding: 0px !important;
        }
    }

    .partner_banner {
        h1 {
            @media (max-width:991px) {
                padding: 0px !important;
            }

            @media (max-width:767px) {
                padding: 0px !important;
            }
        }
    }

    .partnerships_heading {
        h4 {
            @media (max-width:991px) {
                padding: 0px !important;
            }

            @media (max-width:767px) {
                padding: 0px !important;
            }
        }
    }

    .partnerships-platform {
        h3 {
            @media (max-width:991px) {
                text-align: center !important;
            }

            @media (max-width:767px) {
                text-align: center !important;
            }
        }

        h6 {
            @media (max-width:991px) {
                text-align: center !important;
            }

            @media (max-width:767px) {
                text-align: center !important;
            }
        }

        .blockone {
            padding-top: 100px;
            padding-bottom: 100px;

            @media (max-width:991px) {
                flex-direction: column-reverse;
                padding-top: 0px !important;
                padding-bottom: 30px ;
            }

            @media (max-width:767px) {
                flex-direction: column-reverse;
                padding-top: 0px !important;
                padding-bottom: 30px ;
            }
        }

        .brandBlocktwo,
        .blockthree {
            padding-top: 100px;
            padding-bottom: 30px;

            @media (max-width:991px) {
                flex-direction: column-reverse;
                padding-top: 30px !important;
                padding-bottom: 30px !important;
            }

            @media (max-width:767px) {
                flex-direction: column-reverse;
                padding-top: 30px !important;
                padding-bottom: 10px !important;
            }
        }
    }

    .ltr_video_sec {

        h4 {
            @media (max-width:991px) {
                font-size: $az-font-18 !important;
                ;

            }

            @media (max-width:767px) {
                font-size: $az-font-18 !important;
                ;
            }
        }

    }


    .ltr_video_sec {

        h2,
        h4 {

            @media (max-width:767px) {
                padding: 0 30px;
            }
        }
    }

    .ltr_partnered_sec {
        background: $az-white;
    }
}

.propertyIndex {
    .ltr_faq_sec {
        position: relative;
        z-index: 1;
        background-color: $az-light-gray;
        padding: 60px 0;

        h2.ltr_faq_heading {
            @include heading2;
            color: $az-ligh-mid-black;
            margin-bottom: 30px;
            // color: $az-oh-primary;
            text-align: left;
            font-family: $az-kini-heading-three !important;

            @media (max-width:767px) {
                font-size: $az-font-26;
                text-align: center;
            }
        }

        .accordion-header {
            h5 {
                font-size: $az-font-18;
                margin: 0;
                padding: 20px 0px 20px 0 !important;
                padding-right: 90px !important;


                @media (max-width:991px) {
                    padding-right: 30px !important;
                    text-align: left;
                }

                @media (max-width:767px) {
                    padding-right: 30px !important;
                    text-align: left;
                }
            }

            button.accordion-button {
                background: none;
                padding: 15px 0;
                box-shadow: none;

                &::after {
                    top: 50% !important;
                    transform: translate(0, -50%);

                    // @media (max-width:991px) {
                    //     top: 42px !important;
                    // }

                    // @media (max-width:767px) {
                    //     top: 42px !important;
                    // }
                }

                &:hover,
                &:focus,
                &:active {
                    box-shadow: none;
                    border: none;
                    outline: none;
                }
            }

            .accordion-button:not(.collapsed) {
                color: #212529 !important;
                filter: brightness(0);
            }


        }


        .accordion-body {
            padding: 0 0 10px !important;
            text-align: left;
        }


    }

    .earn_aptmt_sec {
        z-index: 1;
        height: 650px;

        h2.earn_heading {
            @include heading2;
            color: $az-ligh-mid-black;
            margin-bottom: 20px;
            font-family: $az-kini-heading-three !important;

            @media (max-width:991px) {
                font-size: $az-font-26;
                text-align: center;
            }

            @media (max-width:767px) {
                font-size: $az-font-26;
                text-align: center;
            }
        }
    }

    .ltr_video_sec {
        position: relative;
        z-index: auto !important;

        h2.video_main_head {
            @include heading2;
            color: $az-ligh-mid-black;
            margin-bottom: 20px;
            font-family: $az-kini-heading-three !important;

            @media (max-width:991px) {
                font-size: $az-font-26;
                text-align: center;
            }

            @media (max-width:767px) {
                font-size: $az-font-26;
                text-align: center;
            }
        }
    }

    .ready_move_sec {
        background-color: $az-white;

        h2.ready_move_heading {
            @include heading2;
            margin-bottom: 50px;
            padding-right: 130px;
            font-family: $az-kini-heading-three !important;

            @media (max-width:1280px) {
                padding-right: 100px;
            }

            @media (max-width:991px) {
                font-size: $az-font-26;
                padding-right: 0px;
                text-align: center;
                margin-bottom: 25px;
                margin-top: 20px;
            }

            @media (max-width:767px) {
                font-size: $az-font-26;
                padding-right: 0px;
                text-align: center;
                margin-bottom: 25px;
                margin-top: 20px;
            }
        }
    }

    .accordion-item {
        border-left: none;
        border-right: none;
        background: transparent;

        &:first-child {
            border-top: none !important;
        }

        &:last-child {
            border-bottom: none !important;
        }
    }

    .table_compare_sec {

        h2.oh_tbl_heading {
            color: $az-white !important;
            // font-size: $az-font-32;
            @include heading2;
            margin-bottom: 10px;
            font-family: $az-kini-heading-three !important;

            @media (max-width:767px) {
                font-size: $az-font-26;
            }
        }
    }

    .ltr_partnered_sec {
        h2.part_head {
            font-size: $az-font-32;
            padding-bottom: 25px;
            padding-right: 15px;
            font-family: $az-kini-heading-three !important;

            @media (max-width:1600px) {
                padding-right: 15px;
            }

            @media (max-width:1366px) {
                padding-right: 0px;
            }

            @media (max-width:991px) {
                font-size: $az-font-26;
                padding-right: 0px;
                text-align: center;
            }

            @media (max-width:767px) {
                font-size: $az-font-26;
                padding-right: 0px;
                text-align: center;
            }
        }
    }

}


//new video gallery

.videoSection {
    position: relative;

    .video_earn_gallery {
        position: relative;

        @media (max-width: 1770px) {
            top: -50px;
        }

        @media (max-width: 1540px) {
            top: 0px;
        }

        @media (max-width: 1199px) {
            left: 40px;
        }

        @media (max-width: 1023px) {
            left: 40px;
        }

        .video_pop_img1 {

            .styles_fakePreview__1UDw2 {
                position: absolute;
                right: 49%;
                top: 50px;
                z-index: 1;
                transform: rotate(10deg);
                width: 250px;
                padding: 25px 0;
                background: #fff;
                border-radius: 10px;
                box-shadow: 4px 2px 4px #ddd;
                transition: 1s;

                img {
                    width: 100%;
                    object-fit: cover;
                    height: 185px;
                    object-position: 100% 40%;

                    @media (max-width:767px) {
                        height: 235px;
                    }

                }

                .styles_playButton__1Q3EF {
                    background-image: url('../images/oh/leasing/play-button.svg');
                    background-color: transparent;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 60px;
                    height: 60px;
                    display: block;
                    z-index: 9999;
                    background-size: 75%;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin: 0 !important;
                    z-index: 0;

                    &::after {
                        border: 0 !important;
                    }


                }

                .styles_closeButton__20ID4 {
                    img {
                        width: 25px !important;
                        height: 26px !important;
                    }
                }

                &:hover,
                &:focus,
                &:active {

                    transition: 1s;
                    transform: inherit;
                    box-shadow: 4px 0px 11px #595959;
                }

                @media (max-width:767px) {
                    width: 300px;
                    right: 42%;
                }

            }
        }

        .video_pop_img2 {
            .styles_fakePreview__1UDw2 {
                position: absolute;
                right: 51%;
                top: 250px;
                z-index: 0;
                transform: rotate(6deg);
                width: 250px;
                padding: 25px 0;
                background: #fff;
                border-radius: 10px;
                box-shadow: 4px 2px 4px #ddd;
                transition: 1s;

                img {
                    width: 100%;
                    object-fit: cover;
                    height: 185px;
                    object-position: 100% 25%;

                    @media (max-width:767px) {
                        height: 235px;
                    }

                }

                .styles_playButton__1Q3EF {
                    background-image: url('../images/oh/leasing/play-button.svg');
                    background-color: transparent;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 60px;
                    height: 60px;
                    display: block;
                    z-index: 9999;
                    background-size: 75%;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin: 0 !important;
                    z-index: 0;

                    &::after {
                        border: 0 !important;
                    }


                }

                .styles_closeButton__20ID4 {
                    img {
                        width: 25px !important;
                        height: 26px !important;
                    }
                }

                &:hover,
                &:focus,
                &:active {

                    transition: 1s;
                    transform: inherit;
                    box-shadow: 4px 0px 11px #595959;
                    z-index: 2;
                }

                @media (max-width:767px) {
                    width: 300px;
                    right: 40%;
                }
            }
        }

        .video_pop_img3 {
            .styles_fakePreview__1UDw2 {
                position: absolute;
                right: 35%;
                top: 100px;
                z-index: 1;
                transform: rotate(-12deg);
                width: 300px;
                padding: 25px 0;
                background: #fff;
                border-radius: 10px;
                box-shadow: 4px 4px 9px #333;
                transition: 1s;

                img {
                    width: 100%;
                    object-fit: cover;
                    height: 235px;
                    object-position: 100% 15%;

                    @media (max-width:767px) {
                        height: 190px;
                    }
                }

                .styles_playButton__1Q3EF {
                    background-image: url('../images/oh/leasing/play-button.svg');
                    background-color: transparent;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 60px;
                    height: 60px;
                    display: block;
                    z-index: 9999;
                    background-size: 75%;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin: 0 !important;
                    z-index: 0;

                    &::after {
                        border: 0 !important;
                    }


                }

                .styles_closeButton__20ID4 {
                    img {
                        width: 25px !important;
                        height: 26px !important;
                    }
                }

                &:hover,
                &:focus,
                &:active {

                    transition: 1s;
                    transform: inherit;
                    box-shadow: 4px 0px 11px #595959;

                }

                @media (max-width:991px) {
                    width: 300px;
                    right: 20%;
                    top: 30px
                }

                @media (max-width:767px) {
                    width: 250px;
                    right: 25%;
                    top: 30px
                }
            }
        }

        .video_pop_img4 {
            .styles_fakePreview__1UDw2 {
                position: absolute;
                right: 143px;
                top: 225px;
                z-index: 0;
                transform: rotate(17deg);
                width: 300px;
                padding: 25px 0;
                background: #fff;
                border-radius: 10px;
                box-shadow: 4px 2px 4px #ddd;
                transition: 1s;

                img {
                    width: 100%;
                    object-fit: cover;
                    height: 235px;
                    object-position: 100% 35%;

                    @media (max-width:767px) {
                        height: 190px;
                    }
                }

                .styles_playButton__1Q3EF {
                    background-image: url('../images/oh/leasing/play-button.svg');
                    background-color: transparent;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 60px;
                    height: 60px;
                    display: block;
                    z-index: 9999;
                    background-size: 75%;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin: 0 !important;
                    z-index: 0;

                    &::after {
                        border: 0 !important;
                    }

                }

                .styles_closeButton__20ID4 {
                    img {
                        width: 25px !important;
                        height: 26px !important;
                    }
                }

                &:hover,
                &:focus,
                &:active {

                    transition: 1s;
                    transform: inherit;
                    box-shadow: 4px 0px 11px #595959;
                    z-index: 1;
                }

                @media (max-width:991px) {
                    width: 300px;
                    right: 80px;
                }

                @media (max-width:767px) {
                    width: 250px;
                    right: 100px;
                    top: 170px
                }
            }
        }

        .video_pop_img5 {
            .styles_fakePreview__1UDw2 {
                position: absolute;
                right: 18%;
                top: 315px;
                z-index: 0;
                transform: rotate(33deg);
                width: 300px;
                padding: 25px 0;
                background: #fff;
                border-radius: 10px;
                box-shadow: 4px 2px 4px #ddd;
                transition: 1s;

                img {
                    width: 100%;
                    object-fit: cover;
                    height: 235px;
                    object-position: 100% 0%;

                    @media (max-width:767px) {
                        height: 235px;
                    }
                }

                .styles_playButton__1Q3EF {
                    background-image: url('../images/oh/leasing/play-button.svg');
                    background-color: transparent;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 60px;
                    height: 60px;
                    display: block;
                    z-index: 9999;
                    background-size: 75%;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin: 0 !important;
                    z-index: 0;

                    &::after {
                        border: 0 !important;
                    }

                }

                .styles_closeButton__20ID4 {
                    img {
                        width: 25px !important;
                        height: 26px !important;
                    }
                }

                &:hover,
                &:focus,
                &:active {

                    transition: 1s;
                    transform: inherit;
                    box-shadow: 4px 0px 11px #595959;
                    z-index: 2;
                }

                @media (max-width:767px) {
                    width: 300px;
                    right: 28%;
                }
            }
        }


    }



    // @media (max-width:991px) {
    //     height: 650px;
    //     padding: 60px 0 90px !important;

    //  }
    //  @media (max-width:767px) {
    //     height: 500px;
    //     padding: 60px 0 90px !important;
    //  }
}


.contact_sec {
    padding: 60px 0;

    @media (max-width:991px) {
        padding: 0px;
    }

    @media (max-width:767px) {
        padding: 0;
    }

    &::before {
        content: '';
        position: absolute;
        right: 0;
        width: 400px;
        height: 100%;
        background: $az-light-white;
        top: 0;
        z-index: -1;

        @media (max-width:1280px) {
            width: 300px;
        }

        @media (max-width:767px) {
            width: 0;
        }
    }

    .contact_form_box {
        padding: 40px;

        @media (max-width:767px) {
            padding: 0;
            margin-top: 0px;
        }

        h1 {
            margin-bottom: 40px;
            text-align: left;

            @media (max-width:991px) {
                margin-bottom: 40px;
            }

            @media (max-width:767px) {
                margin-bottom: 40px;
            }
        }

        button {
            font-size: $az-font-22;
        }
    }

    .address_sec {
        background: $az-ligh-mid-black;
        position: relative;
        padding: 40px 40px 20px 40px;
        width: 500px;
        float: right;
        margin-top: 90px;

        @media (max-width:1440px) {
            margin-left: 50px;
        }

        @media (max-width:1024px) {
            width: 400px;
            padding: 30px 30px 20px 25px;
        }

        @media (max-width:1024px) {
            margin-right: 65px;
        }

        @media (max-width:991px) {
            width: 100%;
            padding: 30px 30px 20px 25px;
            margin-right: 0;
            margin-top: 140px;
        }


        @media (max-width:768px) {
            margin-top: 120px;
        }


        @media (max-width:767px) {
            margin: 60px 0 0;
            padding: 25px 25px 10px 25px;
        }


        h3 {
            @include heading3;
            color: $az-white;
            text-align: left;
            line-height: 1.4;
            font-size: $az-font-32 !important;
            margin-bottom: 40px;

            @media (max-width:991px) {
                margin-bottom: 40px;
            }

            @media (max-width:767px) {
                font-size: $az-font-26 !important;
                margin-bottom: 40px;
            }
        }

        p {
            font-family: $az-heading-two;
            font-size: $az-font-20;
            color: $az-white;
            text-align: left;
            padding: 0 0 0 10px;
            margin: 0;
            line-height: normal;

            @media (max-width: 1280px) {
                font-size: $az-font-18;

            }

            @media (max-width: 1024px) {
                font-size: $az-font-16;

            }
        }

        .address_icons {
            margin-bottom: 40px;

            img {
                width: 30px;
                height: 30px;
                margin-top: 8px;
            }
        }
    }
}

.req_book_sec {
    background: $az-oh-seconday;
    padding: 60px 0;

    @media (max-width:767px) {
        padding: 30px 0;
    }

    .book_app_info {
        // background: $az-white;


        @media (max-width:991px) {
            margin: 0;
            padding: 15px 0 0 !important;
        }

        @media (max-width:767px) {
            margin: 0;
            padding: 15px 0 0 !important;
        }

        .subhead {
            padding-left: 15px !important;
            margin-bottom: 20px;
        }

        .confirm_text {
            width: auto;

            h4 {
                @media(max-width: 991px) {
                    text-align: left !important;
                }

                @media(max-width: 767px) {
                    text-align: left !important;
                }
            }
        }
    }

    .mobView {
        flex-direction: unset !important;

        @media(max-width: 991px) {
            margin: 0 !important;
        }

        @media(max-width: 767px) {
            margin: 0 !important;
        }

        .headingreserv {

            @media(max-width: 991px) {
                order: 0;
                padding: 30px 15px 0;
                background: $az-white;
            }

            @media(max-width: 767px) {
                order: 0;
                padding: 30px 15px 0;
                background: $az-white;
            }
        }

        .priceInfo {
            @media(max-width: 991px) {
                order: 2;
                // padding: 30px 15px 0;
                background: $az-white;
            }

            @media(max-width: 767px) {
                order: 2;
                // padding: 30px 15px 0;
                background: $az-white;
            }
        }

        .reservdetail {

            @media(max-width: 991px) {
                order: 3;
                padding: 0;
            }

            @media(max-width: 767px) {
                order: 3;
                padding: 0;
            }
        }

        .resverBtn {

            @media(max-width:991px) {
                order: 4;
                padding: 0 15px;
            }

            @media(max-width: 767px) {
                order: 4;

                padding: 0 15px;
            }
        }


        .reservSection {
            ul {
                @media(max-width:991px) {
                    padding: 15px 0px 0px;
                    display: flex;
                }

                @media(max-width: 767px) {
                    padding: 15px 0px 0px;
                    display: flex;
                }

                li {
                    @media(max-width:991px) {
                        width: 50%;
                    }

                    @media(max-width: 767px) {
                        width: 50%;
                    }
                }
            }

            @media(max-width:991px) {
                background: $az-white;
                padding: 0px 0px 0px;
            }

            @media(max-width: 767px) {
                background: $az-white;
                padding: 0px 0px 0px;
            }

            &:first-child {
                @media(max-width:991px) {
                    margin-top: 30px;
                }

                @media(max-width: 767px) {
                    margin-top: 30px;
                }
            }
        }

        .confirm_booking {

            @media(max-width:991px) {
                margin-bottom: 15px !important;
            }

            @media(max-width: 767px) {
                margin-bottom: 15px !important;
            }
        }
    }
}

.book_pay_info {
    background: $az-white;
    padding: 15px 0px;
    margin-top: 10px;

    @media (max-width:767px) {
        margin: 10px 0 10px 0;
    }

    .subhead {
        padding-left: 15px !important;
        margin-bottom: 20px;
    }

    .countryphone {
        p.text-danger {
            padding: 16.5px 14px !important;
        }
    }
}

.booking_recipt_sec {
    .booking_recipt_crm {
        .lst_rpt_name {
            background: $az-light-black;
        }

        h2 {
            @include heading2;
            text-align: center;
            color: $az-white;
            padding: 40px 0;
            margin: 0;
        }

        .rcpt_res_details {
            text-align: left;
            padding: 30px;

            h5 {
                font-family: $az-heading-two;
                font-size: $az-font-20;
                color: $az-black;
                text-align: left;

                @media (max-width: 1199px) {
                    padding: 10px 0;
                    font-size: $az-font-16;

                }

            }
        }
    }
}

.error_page {
    padding: 60px 0;
    text-align: left;

    h2 {
        font-size: 26rem;
        font-family: $az-heading-para-light !important;
        text-align: center;

        @media (max-width:1380px) {
            font-size: 21rem;
        }

        @media (max-width:1380px) {
            font-size: 18rem;
        }

        @media (max-width:1280px) {
            font-size: 20rem;
        }

        @media (max-width:991px) {
            padding-top: 20px;
        }

        @media (max-width:767px) {
            font-size: $az-font-128;
        }
    }

    .ShowmoreLink {
        color: $az-oh-black;
        text-decoration: underline;
        border-radius: 12px;
        text-transform: inherit;
        font-size: $az-font-24;
        display: block;
        margin-top: 20px;
        font-family: $az-heading-para;
        position: relative;
        padding-left: 0;
        border: none;
        &:active,
        &:focus,
        &:hover {
            box-shadow: none;
            outline: none;
        }

        &::after {
            position: absolute;
            content: '';
            right: -10px;
            width: 12px;
            height: 18px;
            background-image: url(../images/oh/showmoe-arrow.svg);
            bottom: 9px;
            background-size: cover;
        }

        @media (max-width:767px) {
            margin: 0 auto;
        }
    }

    h5 {
        font-size: $az-font-99;
        color: $az-oh-step-light !important;
        font-family: $az-heading-para !important;
        padding-top: 60px;

        @media (max-width:1380px) {
            font-size: $az-font-76;
            padding-top: 35px;
        }

        @media (max-width:1380px) {
            font-size: $az-font-60;
        }

        @media (max-width:1024px) {
            padding-top: 30px;
        }

        @media (max-width:991px) {
            font-size: $az-font-46;
            margin-bottom: 15px;
            padding-top: 0;
        }

        @media (max-width:767px) {
            text-align: center;
        }
    }

    p {
        font-size: $az-font-28;
        padding-right: 240px;

        @media (max-width:1280px) {
            padding-right: 40px;
        }

        @media (max-width:991px) {
            font-size: $az-font-24;
            margin: 0;
        }

        @media (max-width:767px) {
            text-align: center;
            padding: 0 20px;
        }
    }
}

.mbsc-ios.mbsc-popup-bottom,
.mbsc-ios.mbsc-popup-top {
    height: 100%;
}

//  Help chat style

.chatSection {
    position: fixed;
    right: 20px;
    bottom: 120px;
    z-index: 999;

    @media(max-width: 991px) {
        bottom: 145px !important;
    }

    @media(max-width: 767px) {
        bottom: 145px !important;

    }

    .dropdown-toggle {
        background-color: rgba(0, 0, 0, .98);
        color: $az-white;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background-image: url(../images/oh-corporate-img/help-chat.png);
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center;
        border: none;
        transform: rotate(0deg);
        transition: 0.5s;
        box-shadow: 0px 0px 4px 0px #ddd;
        position: relative;
        // bottom: 40px;



        &::after {
            display: none !important;
            border: 0 !important;
        }

        &:hover,
        &:focus,
        &:active {
            border: none;
            background-color: $az-black !important;
        }

        @media(max-width: 767px) {
            height: 50px;
            width: 50px;
        }


    }

    .dropdown-toggle.show {
        background-image: url(../images/oh-corporate-img/close-chat.png);
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(90deg);
        transition: 0.5s;
        background-color: $az-black !important;
    }

    .dropdown-menu {
        padding: 0px;
        border-radius: 15px;
        width: 350px;
        min-height: 400px;
        max-height: 653px;
        box-shadow: 0px 0px 4px 0px #ddd;
        overflow: hidden;
        margin-bottom: 15px;

        @media (max-width:1500px) {
            min-height: 460px;
            max-height: 460px;
        }

        @media (max-width:991px) {
            min-height: 400px;
            max-height: 653px;
        }

        @media (max-width:767px) {
            width: 100%;
            min-height: 100vh;
            max-height: 100vh;
        }

        .chat-header {
            padding: 15px;
            font-size: $az-font-24;
            font-family: $az-kini-heading-three;
            color: $az-white;
            text-align: left;
            background: $az-black;
            position: relative;

            .chat_close {
                display: none;
                position: absolute;
                right: 15px;
                top: 12px;
                cursor: pointer;
                z-index: 11111;

                @media (max-width:767px) {
                    display: block;
                }

                img {
                    max-height: 24px;
                }
            }

        }

        .chat-body {
            overflow-y: auto;
            height: 390px;
            position: relative;
            z-index: 0;
            scroll-behavior: smooth;

            // margin-bottom: 15px;
            // padding-bottom: 60px;
            @media (max-width:1500px) {
                height: 390px;
                // margin-bottom: 15px;
            }

            @media (max-width:991px) {
                height: 390px;
            }

            @media (max-width:767px) {
                height: -moz-calc(100% - 135px);
                height: -webkit-calc(100% - 135px);
                height: calc(100% - 135px);
                overflow-y: auto;
            }

            &::-webkit-scrollbar {
                width: 10px;
                height: 5px;
            }

            &::-webkit-scrollbar-track {
                background-clip: content-box;
                border: 0px solid transparent;
                width: 10px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #333;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #333;
            }

            &::-webkit-scrollbar-corner,
            &::-webkit-scrollbar-track {
                background-color: transparent;
                width: 10px;
            }



            .recievermsg {
                padding: 10px;
                padding-bottom: 0;
                margin-bottom: 10px;
                display: block;

                p {
                    padding: 10px 15px;
                    font-size: $az-font-14;
                    font-family: $az-heading-two;
                    color: $az-oh-primary;
                    text-align: left;
                    background: $az-light-gray;
                    border-radius: 8px;
                    margin-bottom: 0px;
                }

                span {
                    color: $az-gray;
                    font-size: $az-font-12;
                    margin-left: 5px;
                }

            }

            .sendermsg {
                padding: 10px 15px;
                display: block;

                p {
                    padding: 10px 15px;
                    font-size: $az-font-14;
                    font-family: $az-heading-two;
                    color: $az-white;
                    text-align: left;
                    background: $az-light-black;
                    border-radius: 8px;
                    margin-bottom: 0px;
                }

                span {
                    color: $az-gray;
                    font-size: $az-font-12;
                    margin-left: 5px;
                }

                .phone-white {
                    span {
                        color: $az-white !important;
                        margin-left: 0 !important;
                        font-size: $az-font-13 !important;
                    }
                }

            }

            .chatformSection {
                padding: 20px 15px 20px !important;
                margin: 0 10px;
                background: $az-light-gray ;
                border-radius: 10px;
                // position: absolute;
                // bottom: 0;
                margin-bottom: 10px;
                width: auto !important;

                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    background: transparent;
                    border-radius: 12px;
                    font-size: $az-font-14 !important;
                }


                h4 {
                    @include heading4;
                    text-align: center;
                    margin-bottom: 20px;
                    font-size: $az-font-18;
                    color: $az-oh-primary;
                    margin-left: 0 !important;
                }


                .MuiOutlinedInput-input {
                    font-size: $az-font-14 !important;
                    padding: 10px 17px !important;

                    &:focus {
                        font-size: $az-font-14 !important;
                    }
                }

                label {
                    top: 10px !important;
                    font-size: 12px !important;
                    font-weight: 700 !important;
                    color: $az-black;
                }

                p.Mui-error {
                    font-size: $az-font-12 !important;
                    font-family: $az-heading-two !important;
                }

                label.Mui-focused {
                    top: 1px !important;
                    left: 7px !important;
                    margin-left: 8px !important;
                    transform: translate(0px, 0px) scale(0.75) !important;
                }

                .MuiFormLabel-filled {
                    top: 0px !important;
                }

                .text_filled_up {
                    .MuiFormLabel-filled {
                        background: #eeedeb !important;
                        width: 115%;
                    }
                }


                .react-tel-input {
                    overflow: inherit !important;

                    input {
                        background: $az-light-gray;
                        padding: 10px 17px 10px 50px !important;
                        margin: 0 !important;

                        &:focus {
                            font-size: $az-font-14 !important;
                        }

                        &::placeholder {
                            text-transform: capitalize !important;
                        }
                    }

                    .search-emoji {
                        display: none;
                    }

                    .country-list {
                        max-height: 190px;

                        &::-webkit-scrollbar {
                            width: 10px;
                            height: 5px;
                        }

                        &::-webkit-scrollbar-track {
                            background-clip: content-box;
                            border: 0px solid transparent;
                            width: 10px;
                            background-color: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #333;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background-color: #333;
                        }

                        &::-webkit-scrollbar-corner,
                        &::-webkit-scrollbar-track {
                            background-color: transparent;
                            width: 10px;
                        }
                    }
                }

                button {
                    margin-top: 10px !important;
                    @include OHprimaybutton-sm;
                    // text-transform: uppercase;
                    font-size: $az-font-16;
                    min-width: auto;
                    max-width: auto;
                    width: auto !important;
                    margin: auto;
                    padding-left: 30px;
                    padding-right: 30px;

                    &:hover,
                    &:focus {
                        background-color: $az-black;
                    }

                    .spinner_loader {
                        width: 7px;
                        height: 7px;
                        margin: 2px;
                        position: relative;
                        top: 1px;
                    }

                }

                .css-wgai2y-MuiFormLabel-asterisk.Mui-error {
                    color: #d32f2f !important;
                }


                // .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
                //     color: $az-ligh-black;
                // }

                // .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
                // .MuiOutlinedInput-notchedOutline,  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover
                // .MuiOutlinedInput-notchedOutline {
                //     border-color: $az-ligh-green;
                //     border-width: 1px;



                // }

                // .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                //     border-color: $az-ligh-green !important;
                //     border-width: 1px;

                // }
            }

            #recaptch-block {
                iframe {
                    position: absolute;
                    left: 18px;

                    @media (max-width:767px) {
                        left: 25px;
                    }
                }
            }


        }

        .chat-footer {
            padding: 10px;
            // margin: 10px;
            background: $az-light-gray;
            position: fixed;
            bottom: 0px;
            width: 100%;
            left: 0px;
            display: flex;
            align-items: center;

            // @media (max-width:767px) {
            //     bottom: 50px;
            // }
            textarea {
                width: 100% !important;
                padding: 10px 15px;
                background: $az-white;
                border: none;
                margin-right: 10px;

                &:focus,
                &:hover {
                    box-shadow: none;
                    border: none;
                    outline: none;
                }
            }

            button {
                background: $az-black;
                border: none;
                height: 40px;
                width: 45px;
                line-height: 40px;
                display: grid;
                place-content: center;
                // align-items: center;
                // justify-content: center;
                border-radius: 50%;
                padding-left: 5px;


            }
        }

        .mobClosechat {
            display: none;

            @media(max-width: 767px) {
                display: block;
                position: absolute;
            }
        }

        @media(max-width: 767px) {
            width: 100vw;
            height: 100vh;
            top: 0 !important;
            transform: inherit !important;
            bottom: 0 !important;
            left: 0 !important;
            right: 0 !important;
            position: fixed !important;
            margin-bottom: 0 !important;
            border-radius: 0;
            box-shadow: none;
            border: 0;
            outline: none;
        }
    }





}

// End Help chat style
.leasing {

    .hostingFeatures {

        @media (max-width:991px) {
            padding: 60px 0 60px;
        }

        @media (max-width:767px) {
            padding: 60px 0 60px;
        }
    }
}

// confirmation_booking_design
.confirm-symbl {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    padding: 25px 0px 10px 0px !important;

    @media (max-width:767px) {
        margin-bottom: 10px !important;
        overflow: hidden;
    }

    img {
        max-height: 45px;
        max-width: 45px;
        padding: 0;
    }

    h4 {
        color: #22AE73 !important;
        margin: 0;
        font-size: $az-font-24 !important;
        font-family: $az-heading-two !important;

        @media (max-width:991px) {
            font-size: $az-font-20 !important;
        }

        @media (max-width:767px) {
            font-size: $az-font-20 !important;
        }
    }

    p {
        padding: 0;

        @media (max-width:767px) {
            padding-right: 50px;
        }
    }
}

.confirm_booking {
    .confirm-detail {
        text-align: left;

        p {
            font-size: $az-font-16;
            margin: 0;

            a {
                color: #005DE9 !important;
            }
        }
    }

    .reservation-data-desk {
        display: block;

        @media(max-width: 991px) {
            display: none;
        }

        @media(max-width: 767px) {
            display: none;
        }
    }

}

.confirm_recpt {
    position: sticky !important;
    top: 100px !important;
    max-width: inherit !important;
    min-width: inherit !important;

    figure {
        img {
            max-height: 296px;
            min-height: 296px;
            width: auto;
        }
    }
}

.crmf_btn {
    @media (max-width:767px) {
        min-width: 100% !important;
    }
}



.guest-type-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: $az-white !important;
    padding-bottom: 0px;
    cursor: auto;
    padding-left: 0px;

    ul {
        padding: 0;

        li {
            list-style: none;
            flex-direction: column;
            align-items: start;
            padding-right: 0;

            h4 {
                font-family: $az-heading-two;
                font-size: $az-font-18;
                color: $az-oh-primary;
                text-align: left;
                margin-top: 2px;
                display: flex;
                padding-left: 0;
                align-items: left;
            }

            h5 {
                font-family: $az-heading-two;
                font-size: $az-font-14;
                color: $az-oh-primary;
                text-align: left;
                margin-bottom: 0;
                display: flex;
                padding-left: 0;
                align-items: left;

                a {
                    color: $az-oh-primary;
                }
            }
        }
    }

    .countBtn {
        font-family: $az-heading-two;
        font-size: $az-font-18;
        color: $az-oh-primary;
        text-align: left;
        margin-bottom: 0;
        display: flex;
        padding-left: 0;
        align-items: left;

        span {
            button {
                background: none;
                border: none !important;
                // border-radius: 50%;
                // width: 30px;
                // height: 30px;
                font-size: $az-font-24;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $az-gray;

                &:hover {
                    border: none !important;
                    // color: $az-white;
                    // background: $az-oh-primary;
                }

            }

            &:nth-child(2) {
                margin: 0px 2px;
                min-width: 20px;
                text-align: center;
                max-width: 20px;
            }

            &:first-child {
                button {
                    padding-top: 0px;
                }
            }

            &:last-child {
                button {
                    padding-top: 0px;
                }
            }
        }
    }

    p {
        font-size: $az-font-14;
        margin-top: 30px;
        margin-bottom: 15px;
        white-space: break-spaces;

        @media (max-width: 767px) {
            white-space: break-spaces;
        }
    }

    button.done {
        border: none;
        background: $az-black;
        color: $az-white;
        font-size: $az-font-14 !important;
        font-family: $az-kini-heading-three !important;
        outline: none;
        margin-top: 0;
        padding: 2px 15px;
        border-radius: 4px;
        width: 80px;
        text-transform: inherit;
        border-radius: 20px;
        letter-spacing: 1px;

    }

    button.reset {
        border: none;
        background: transparent;
        color: $az-black;
        font-size: $az-font-14 !important;
        font-family: $az-kini-heading-three !important;
        outline: none;
        margin: 0;
        padding: 2px 15px;
        width: 80px;
        text-transform: inherit;
        border-radius: 20px;
        letter-spacing: 1px;

    }
}

.detail_page_ltr {
    .accordion-button:not(.collapsed)::after {
        transform: translate(0%, -50%) rotate(177deg) !important;
    }
}


// explor articles section

.expl_artls_sec {
    background: $az-ligh-mid-black;
    padding: 80px 0;

    @media (max-width:991px) {
        padding: 60px 0;
    }

    @media (max-width:767px) {
        padding: 60px 0 40px;
    }

    .btn-artls {
        text-decoration: none;
    }

    .artls_head {
        h2 {
            @include heading2;
            color: $az-white;
            text-align: left;
            padding-bottom: 50px;

            @media (max-width:767px) {
                padding-bottom: 30px;
            }
        }
    }

    .artls_box {
        @media (max-width:767px) {
            margin-bottom: 30px;
        }

        .artls_img {
            overflow: hidden;
            position: relative;
            border-radius: 5px;
            transition: all 0.5s;

            figure {
                position: relative;
                max-height: 300px;
                min-height: 300px;
                margin: 0;
                overflow: hidden;

                @media (max-width:991px) {
                    max-height: 175px;
                    min-height: 175px;
                }

                @media (max-width:767px) {
                    max-height: 250px;
                    min-height: 250px;
                }

                img {
                    width: 100%;
                    max-height: 300px;
                    min-height: 300px;
                    object-fit: cover;
                    -webkit-transition: all .5s;
                    transition: all .5s;
                    object-fit: cover;

                    @media (max-width:991px) {
                        max-height: 175px;
                        min-height: 175px;
                    }

                    @media (max-width:767px) {
                        max-height: 250px;
                        min-height: 250px;
                    }
                }
            }

            &:hover {
                img {
                    transform: scale(1.09, 1.09);
                    -webkit-filter: brightness(70%);
                    -webkit-transition: all 1s ease;
                    -moz-transition: all 1s ease;
                    -o-transition: all 1s ease;
                    -ms-transition: all 1s ease;
                    transition: all 1s ease;
                }
            }
        }


        p {
            color: $az-white;
            font-size: $az-font-18;
            text-align: left;
            margin: 0;
            padding-top: 15px;
        }
    }

    .artls_more {
        display: none;
        margin-top: 50px;

        button {
            @include OHprimaybutton-sm;
            padding: 5px 30px 10px;
            margin: 0 auto;
        }
    }
}


// multi-family page start

.multifamily-banner {
    background-image: url(../images/oh-corporate-img/homesharingprogram.jpg);
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    min-height: 410px !important;
    height: 410px !important;

    @media (max-width:1023px) {
        // min-height: 615px;
        // height: 615px;
        min-height: 400px !important;
        height: 400px !important;
    }

    @media (max-width:991px) {
        // min-height: 450px;
        // height: 450px;
        min-height: 400px !important;
        height: 400px !important;
    }

    @media (max-width:767px) {
        min-height: 350px;
        height: 350px;
    }

    @include Banner;

    h1 {
        margin-bottom: 20px;
        padding: 0px 215px;
        margin-top: 0px !important;
        font-size: $az-font-66;

        // @media (max-width:1280px) {
        //     padding: 0px 330px;
        //     margin-top: 30px;
        // }
        @media (max-width:1199px) {
            font-size: $az-font-50;
        }

        @media (max-width:991px) {
            // padding: 0px;
            // font-size: $az-font-50;
            // margin-top: 0 !important;
            padding: 0px;
            font-size: $az-font-40;
            line-height: 1.5;

        }

        @media (max-width:767px) {
            // padding: 0px;
            // font-size: $az-font-32;
            // margin-bottom: 0;
            padding: 0px;
            font-size: $az-font-26;
            line-height: 1.5;
            margin-bottom: 0;
        }
    }

    h4 {
        position: relative;
        z-index: 1;
        color: $az-white;
        text-align: center;
        font-size: $az-font-33;
        margin-top: 20px;
        font-family: $az-heading-two !important;
        letter-spacing: 0;

        @media(max-width: 1199px) {
            font-size: $az-font-25;
        }

        @media(max-width: 1023px) {
            font-size: $az-font-24;
        }

        @media (max-width:991px) {
            margin-top: 10px;
            font-size: $az-font-18;
        }

        @media (max-width:767px) {
            text-align: center;
            margin-top: 10px;
            font-size: $az-font-18;
        }
    }
}

.multifamily-sec {
    padding: 20px 0 50px;
    text-align: left;
    background: $az-oh-seconday;

    @media (max-width:767px) {
        padding: 40px 0;
    }

    .pad-ct-r-0 {
        padding-right: 0;

        @media (max-width:767px) {
            padding-right: 10.5px;
        }
    }

    .multifamily-left {
        background: $az-white;
        padding: 30px;

        @media (max-width:767px) {
            padding: 20px;
        }

        h2 {
            @include heading2;

        }

        span {
            @include heading3;
            font-family: $az-heading-two !important;
            font-size: $az-font-22;
            padding-bottom: 20px;

            @media (max-width:767px) {
                font-size: $az-font-22;
                padding-bottom: 10px;
            }
        }

        p {
            font-size: $az-font-18;

            @media (max-width:767px) {
                font-size: $az-font-16;
            }
        }

        .multi-cnt {
            img {
                width: 70%;
                margin: 0 auto 15px;
                display: flex;

                @media (max-width:767px) {
                    width: 100%;
                }
            }

            ol {
                li {
                    font-size: $az-font-18;

                    @media (max-width:767px) {
                        font-size: $az-font-16;
                    }
                }
            }
        }
    }

    .multifamily-right {
        border: 1px solid $az-border-color;
        background: #fbfbfb;
        position: -webkit-sticky;
        position: sticky;
        top: 90px;
        border-radius: 0px;
        padding: 30px;

        @media (max-width:767px) {
            margin-top: 10.5px;
            margin-bottom: 0px;
            padding: 20px;
        }

        h4 {
            @include heading4;
            margin-bottom: 10px;

            @media (max-width:767px) {
                font-size: $az-font-22;
            }
        }

        p {
            font-size: $az-font-18;

            @media (max-width:767px) {
                font-size: $az-font-16;
            }
        }

        button {
            border: none;
            background: $az-black;
            color: $az-white;
            font-size: $az-font-16;
            border-radius: 50px;
            padding: 10px 40px;
            font-family: $az-kini-heading-three;
            letter-spacing: 1px;
            margin: 10px 0 20px;

            &:hover,
            &:focus {
                border: none;
                box-shadow: none;
                outline: none;
            }
        }

        .multi-address {
            p {
                vertical-align: middle;
                padding-bottom: 5px;

                img {
                    max-height: 20px;
                    margin-right: 5px;
                }

                margin: 0;

                a {
                    text-decoration: none;
                    color: $az-black
                }
            }
        }
    }

    .breadcumbs {

        ul {
            display: flex;
            list-style: none;
            padding-left: 0;
            margin-bottom: 20px;

            @media (max-width:767px) {
                display: block;
            }

            li {
                display: inline;

                a {
                    color: $az-black;
                    font-size: $az-font-16;
                    margin: 0 3px;
                    text-align: none;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    color: $az-gray;
                    font-size: $az-font-16;

                    span {
                        margin: 0 3px;
                    }
                }

            }


        }
    }
}


.expl_inner_detail_sec {
    background: $az-white;

    h2.art_heading {
        color: $az-light-black;
    }

    .artls_box {
        p {
            color: $az-black;
        }
    }
}

body.removeScroll {
    overflow: auto;

    // padding-right: 0 !important;
    //     @media(max-width: 991px) {
    //         overflow: hidden;
    //    }
    @media(max-width: 767px) {
        overflow: hidden !important;
    }
}

#favicon,
#apple_touch_icon {
    fill: #000;
    filter: brightness(0);

    @media (prefers-color-scheme: dark) {
        fill: #fff;
        filter: brightness(1);
    }
}

.text-left-cust-str {
    text-align: left !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background-color: $az-light-gray !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: $az-light-gray !important;
}

.detail_page_str {
    @media (max-width: 767px) {
        margin-bottom: 85px;
    }

    footer {
        .top-arrow {
            display: none;

            @media (max-width: 767px) {
                bottom: 170px !important;
            }
        }
    }

    .chatSection {
        @media (max-width: 991px) {
            bottom: 110px;
        }
    }
}


.ShareModal {
    @media (max-width:767px) {
        padding: 0 !important;
    }

    .modal-dialog {
        @media (max-width:767px) {
            margin: 0 !important;
        }

        .modal-content {

            @media (max-width:767px) {
                height: 100%;
                border: 0;
                border-radius: 0;
            }

            .modal-body {
                @media (max-width:767px) {
                    overflow-y: scroll;
                }

            }
        }
    }

    .modal-dialog-centered {
        @media (max-width:767px) {
            align-items: flex-start;
        }
    }
}

.bgwhitecolor {
    background-color: $az-white !important;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

.tellme {
    .mobView {
        padding: 0 !important;
    }

    h4 {
        font-family: $az-kini-heading-three !important;
        margin-left: 0;
    }
}

.guest-availabity-deskview {
    .guestfilterNew {
        p {
            text-align: left;
            margin-top: 10px;
            margin-left: 15px;
            color: #dc3545 !important;

        }

        p.text-danger {
            font-size: $az-font-12;
            text-align: center;
        }
    }
}

.req_book_sec {

    .reservation-data-mob {
        display: none;

        @media(max-width: 991px) {
            display: block;
            background: $az-white;
            margin-bottom: 30px;
            padding-top: 20px;
            padding-bottom: 20px;

        }

        @media(max-width: 767px) {
            display: block;
            background: $az-white;
            margin-bottom: 30px;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}

.yourInfo {

    @media(max-width: 991px) {
        padding-top: 30px;

    }

    @media(max-width: 767px) {
        padding-top: 30px;
    }
}

.mbsc-popup-wrapper-center {
    .mbsc-popup-buttons-center {
        display: none !important;

        @media(max-width: 991px) {
            display: block !important;

        }

        @media(max-width: 767px) {
            display: block !important;
        }

    }

    .mbsc-popup-limits-center {

        @media(max-width: 991px) {
            max-width: 100% !important;

        }

        @media(max-width: 767px) {
            max-width: 100% !important;
        }
    }

    @media(max-width: 991px) {
        padding: 0 !important;
        width: 100% !important;
    }

    @media(max-width: 767px) {
        padding: 0 !important;
        width: 100% !important;
    }
}

.mbsc-picker .mbsc-ios.mbsc-popup-body {

    @media(max-width:991px) {
        margin-top: 0 !important;
    }

    @media(max-width: 767px) {
        margin: 0 !important;
    }

    .mbsc-popup-buttons-bottom {

        @media(max-width:991px) {
            position: relative;
            z-index: 3;
        }

        @media(max-width: 767px) {
            position: relative;
            z-index: 3;
        }

    }

    .mbsc-calendar-body {

        @media(max-width:991px) {
            padding-top: 30px;
        }

        @media(max-width: 767px) {
            padding-top: 0px;
        }
    }

    .mbsc-ios.mbsc-popup-buttons {
        display: none;

        @media(max-width:991px) {
            border-bottom: none !important;
            display: block;
        }

        @media(max-width: 767px) {
            border-bottom: none !important;
            display: block;
        }
    }
}

.mbsc-ios.mbsc-range-control-wrapper {

    @media(max-width:991px) {
        position: sticky;
        top: 0px;
        z-index: 2;
        background: $az-white;
        width: 100%;
    }

    @media(max-width: 767px) {
        position: sticky;
        top: 0px;
        z-index: 2;
        background: $az-white;
        width: 100%;
    }
}

.mbsc-ios.mbsc-button-flat {

    @media(max-width:991px) {
        color: $az-ligh-black !important;
        margin-top: 20px !important;
        font-size: $az-font-16;
        background: #b6b6b6;
        margin-right: 10px !important;
    }

    @media(max-width: 767px) {
        color: $az-ligh-black !important;
        margin-top: 20px !important;
        font-size: $az-font-16;
        background: #b6b6b6;
        margin-right: 10px !important;
    }

}

button.mbsc-popup-button {
    @media(max-width:991px) {
        font-size: $az-font-22 !important;
        background: #b6b6b6;
        height: auto;
        width: auto;
        padding: 0 !important;

    }

    @media(max-width: 767px) {
        font-size: $az-font-17 !important;
        height: auto;
        width: auto;
        padding: 0 !important;
        background: #eeedeb !important;
    }
}

.mbsc-font {

    @media(max-width:991px) {
        font-family: $az-heading-two !important;
    }

    @media(max-width: 767px) {
        font-family: $az-heading-two !important;
    }
}

.mbsc-ios.mbsc-calendar-month-title {
    @media(max-width:991px) {
        color: $az-ligh-black !important;
    }

    @media(max-width: 767px) {
        color: $az-ligh-black !important;
    }
}

.mbsc-picker .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    @media(max-width:991px) {
        font-size: 14px !important;
        text-align: center;
        padding: 2px 2px 2px 2px;
    }

    @media(max-width: 767px) {
        font-size: 14px !important;
        text-align: center;
        padding: 2px 2px 2px 2px;
    }
}

.paybtn {
    padding: 0 !important;
    margin-top: 15px !important;

    @media(max-width:991px) {
        padding: 0 15px !important;
        margin-top: 30px !important;
    }

    @media(max-width: 767px) {
        padding: 0 15px !important;
        margin-top: 30px !important;
    }
}

.otpModal {

    @media(max-width:991px) {
        padding-left: 0px !important;
    }

    @media(max-width: 767px) {
        padding-left: 0px !important;
    }

    .reservationBook {
        @media(max-width:991px) {
            justify-content: center;
        }

        @media(max-width: 767px) {
            justify-content: center;
        }
    }

    .MuiOutlinedInput-input {
        text-align: left;
        padding: 17px 17px 12px !important;
        font-family: $az-heading-two;
        font-size: $az-font-18;
    }

    .MuiOutlinedInput-root {
        border-radius: 12px !important;

    }

    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid $az-black !important;
    }

    .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
        color: $az-black !important;
    }

    // label {
    //     top: 3px !important;
    //     left: 7px !important;
    //     margin-left: 8px !important;
    //     transform: translate(0px, 0px) scale(0.75) !important;

    //     &:focus {
    //         color: $az-black !important;
    //     }
    // }

    label {
        top: 17px !important;
        left: 17px;
        transform: inherit;
        color: #606060 !important;
        transform: inherit !important;
        font-family: $az-heading-para;
    }

    label.Mui-focused {
        top: 5px !important;
        font-size: $az-font-14;
        transform: translate(0px, 0px) scale(0.75) !important;
    }

    label.MuiFormLabel-filled {
        top: 5px !important;
        font-size: 11px;
    }

    fieldset {
        // border-bottom: transparent;
        // border-top: none;
        // border-left: none;
        // border-right: none;

        legend {
            margin-left: inherit !important;
            margin-right: inherit !important;
            max-width: 0.01px !important;
            transition: inherit;

            span {
                font-size: 18px;
                color: #333333;
                font-family: Lato-medium-bold;
                padding: 0;
                text-align: left;
                font-weight: 400;
            }
        }
    }
}

ul.guestavail {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: baseline !important;

    li {
        list-style: none;

        a {
            font-size: $az-font-14;
            font-family: $az-heading-two;
        }

        &::after {
            display: none;
        }
    }
}

.passshowhide {
    position: relative;
    padding-right: 0px;

    span {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: $az-font-14 !important;
        font-family: $az-heading-para !important;
        text-decoration: underline;
        cursor: pointer;
    }
}

.reservationBook {
    #password {
        padding-right: 60px !important;
    }
}

.yourloggedin {
    display: flex;
    align-items: self-start;
    padding: 0px 0 30px;

    p {
        font-size: $az-font-16 !important;
        color: $az-oh-primary !important;
        font-family: $az-heading-two;
        font-weight: normal;
        margin-bottom: 0;
    }

    img {
        max-width: 42px;
        margin-left: -4px;
    }
}

.paywith {

    @media (max-width: 991px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    @media (max-width: 767px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .requestSection {
        @media (max-width: 991px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        @media (max-width: 767px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

.guestDetails {
    flex-direction: inherit;

    @media (max-width: 991px) {
        flex-direction: inherit;
    }

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.cancellations {
    border-top: 1px solid $az-border-color;

    p {
        font-size: $az-font-15;
        color: $az-oh-primary !important;
        font-family: $az-heading-two;
        font-weight: normal;
        margin-bottom: 0;
    }

    b {
        text-decoration: underline;
        font-size: $az-font-15;
        color: $az-oh-primary !important;
        font-family: $az-heading-para;
        font-weight: normal;
        cursor: pointer;
    }

    @media (max-width: 991px) {
        border-top: none;
        background: $az-white;
        margin: 0px 0;
    }

    @media (max-width: 767px) {
        border-top: none;
        background: $az-white;
        margin: 0px 0;
    }

    ul.receipt {
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;


        li {
            list-style: none;
            width: 100%;
            padding: 0 !important;
            margin: 0;
            border-top: 1px solid $az-border-color;

            a {
                display: flex;
                justify-content: space-between;
                text-decoration: none;
                padding: 15px 0px;
                border-top: 1px solid $az-border-color;

                img {
                    transform: rotate(180deg);
                    width: 10px;
                }

                img.download {
                    transform: inherit;
                    width: 26px;
                }

                img.viewlist {
                    transform: inherit;
                    width: 26px;
                }

                &:last-child {
                    border-top: none;
                }

                .icon1 {
                    img {
                        width: 26px;
                        transform: inherit;
                        margin-right: 5px;
                    }
                }

                .icon2 {
                    img {
                        width: 26px;
                        transform: inherit;
                        margin-right: 5px;
                    }
                }

                .icon2,
                .icon1 {
                    display: flex;
                    align-items: center;
                }
            }

            &::before {
                display: none;
            }

            &:first-child {
                border-top: none;
            }
        }

    }
   
}

.loggedindesk {
    display: block;

    @media (max-width: 991px) {
        display: none;
    }

    @media (max-width: 767px) {
        display: none;
    }

}

.yourtrip.loggedinmob {
    display: none;

    @media (max-width: 991px) {
        display: block;
        order: 2 !important;
        margin-top: 15px;
    }

    @media (max-width: 767px) {
        display: block;
        order: 2 !important;
        margin-top: 15px;
    }

    .yourloggedin {

        @media (max-width: 991px) {
            padding: 0px 0 5px;

        }

        @media (max-width: 767px) {
            padding: 0px 0 5px;

        }
    }
}

strong.gustaccess {
    span {
        cursor: pointer;

        img {
            max-width: 16px;
            cursor: pointer;
        }
    }
}

.requestSection {
    .host {
        figure {
            border: 1px solid #eeedeb;
            border-radius: 8px;
            border-radius: 50%;
            height: 60px;
            margin: 0;
            overflow: hidden;
            position: relative;
            width: 60px;

            img {
                height: 60px;
                max-width: 100%;
                object-fit: cover;
                width: 60px;
            }
        }

        .hostContent {
            padding-left: 0px;
            text-align: left;
            width: calc(100% - 60px);

            span {
                color: #000;
                font-family: Lato-medium-bold;
                font-size: 18px;
                margin-bottom: 0;
                text-align: left;
                word-break: break-word;
                display: contents !important;
            }

            b {
                color: #606060;
                display: block;
                font-size: 16px;
                font-weight: 400 !important;
                margin: 2px 0 10px;

            }
        }
    }
}

.reservationBook .mobView {
    .host {
        background: $az-white;

        .reserv_mid_heading {

            @media(max-width: 991px) {
                border-top: none;
            }

            @media(max-width: 767px) {
                border-top: none;
            }
        }

        @media(max-width: 991px) {
            padding: 0 15px;
        }

        @media(max-width: 767px) {
            padding: 0 15px;
        }

        .guestBlock {
            width: calc(100% - 60px);

            @media(max-width: 991px) {
                padding: 0 15px 0 0;
                width: calc(100% - 60px);
            }

            @media(max-width: 767px) {
                padding: 0 15px 0 0;
                width: calc(100% - 60px);
            }
        }
    }


}

.ltr_pro_desp {
    div {
        text-align: justify;
        font-size: $az-font-16;
    }

    span {
        &:first-child {
            display: block !important;
        }

        &:last-child {
            display: none !important;
        }
    }
}

.font-14-cust {
    font-size: $az-font-14 !important;

}

.propertyIndex {
    .ltr_video_sec {
        z-index: unset !important;
    }

}


// str detail bookflow

.str_heading_top {
    padding: 30px 35px;

    @media (max-width:767px) {
        padding: 20px 20px 20px;
    }

    span.share {
        a {
            color: $az-heading-two;
            font-size: $az-font-16;
            filter: brightness(0);
            text-decoration: none;
            display: flex;
            align-items: center;

            img {
                margin-right: 5px;
            }

            b {
                color: $az-kini-primary-two;
                font-weight: 400 !important;
            }
        }

    }
}

.str_discp_heading {
    padding-bottom: 45px;
}


// Where you’ll sleep section
.str_bed_details {
    padding: 45px 0 30px;
    margin-bottom: 10px;

    @media (max-width:767px) {
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 0px;
    }

    h5 {
        padding-bottom: 30px;

        @media (max-width:767px) {
            text-align: left !important;
        }
    }

    .str_bed_box {
        margin: 0 0 15px 0;
        border: 1px solid $az-border-color ;
        padding: 20px;
        border-radius: 15px;
        text-align: left;

        figure {
            border: none !important;
            border-radius: inherit !important;
            overflow: inherit !important;
            height: 50px !important;

            @media (max-width:991px) {
                border: none !important;
                border-radius: inherit !important;
                overflow: inherit !important;
                height: 50px !important;
            }

            @media (max-width:767px) {
                border: none !important;
                border-radius: inherit !important;
                overflow: inherit !important;
                height: 50px !important;
            }


            img {
                max-height: 30px;
                min-height: 30px;
                width: auto !important;
                height: auto !important;
                object-fit: contain !important;

                @media (max-width:991px) {
                    width: auto !important;

                }

                @media (max-width:767px) {
                    width: auto !important;

                }
            }
        }

        p {
            font-size: $az-font-16;
            filter: brightness(0);
            font-weight: 600;
            margin-bottom: 5px;
        }
    }

    .row {
        flex-direction: inherit !important;

        @media (max-width:991px) {
            flex-direction: inherit !important;

        }

        @media (max-width:767px) {
            flex-direction: inherit !important;

        }
    }
}


// map section
.mapSection {
    span.map_location {
        color: $az-ligh-mid-black;
        font-size: $az-font-22;
        margin-bottom: 30px;
        display: block;
        margin-top: 10px;

        @media (max-width:767px) {
            font-size: $az-font-18;
        }
    }
}

// Do it like to a local section

.do_like_local_box {
    margin: 0px 0 45px 0px;
    box-shadow: 0px 0px 10px #ddd;
    border-radius: 15px;

    .local_box {
        text-decoration: none;
    }

    .local_box_new {
        text-align: center;

        @media (max-width:767px) {
            margin-bottom: 30px;
        }

        h5.loacl_one {
            text-align: center;
            padding-top: 20px;
            margin-bottom: 20px;
            font-size: $az-font-26;
            font-family: $az-kini-heading-three;
            text-align: center;
        }

        h4.loacl_two {
            color: $az-black;
            margin-bottom: 0;
            font-size: $az-font-22;
            font-family: $az-heading-para !important;
            text-align: center;
        }

        p {
            color: $az-gray;
            font-size: $az-font-18;
            text-align: center;
            margin: 0;
            padding: 5px 0 25px 0;
            font-family: $az-heading-two;
            text-align: center;
        }

        .local_box_img {
            overflow: hidden;
            position: relative;
            border-radius: 15px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 0;
            transition: all 0.5s;

            figure {
                position: relative;
                max-height: 230px;
                min-height: 230px;
                margin: 0;
                overflow: hidden;

                @media (max-width:991px) {
                    max-height: 175px;
                    min-height: 175px;
                }

                @media (max-width:767px) {
                    max-height: 230px;
                    min-height: 230px;
                }

                img {
                    width: 100%;
                    max-height: 230px;
                    min-height: 230px;
                    object-fit: cover;
                    -webkit-transition: all .5s;
                    transition: all .5s;
                    object-fit: cover;

                    @media (max-width:991px) {
                        max-height: 175px;
                        min-height: 175px;
                    }

                    @media (max-width:767px) {
                        max-height: 230px;
                        min-height: 230px;
                    }
                }
            }

        }

        .do_live_footer {
            border-top: 1px solid $az-border-color;

            p {
                padding: 15px !important;
            }
        }

        &:hover {
            img {
                transform: scale(1.09, 1.09);
                -webkit-filter: brightness(70%);
                -webkit-transition: all 1s ease;
                -moz-transition: all 1s ease;
                -o-transition: all 1s ease;
                -ms-transition: all 1s ease;
                transition: all 1s ease;
            }
        }

    }
}




.pricebreakdownsection {
    li {
        span.price_under {
            // cursor: pointer;
            // text-decoration: underline;
            // text-underline-offset: 3px;
        }
    }

    position: relative;

    .toast {
        position: absolute;
        z-index: 99;
        left: -50%;
        top: -55%;
        background: #FFFFFF;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
        border-radius: 11px;
        padding: 10px;

        @media (max-width:991px) {
            left: 0%;
        }

        p {
            font-family: $az-heading-two !important;
            font-size: $az-font-16 !important;
            line-height: 14px;
            color: $az-ligh-mid-black;
            width: 100%;
            display: flex;
            padding: 10px 0;
            white-space: nowrap;

            span {
                text-align: right !important;
            }
        }
    }

    .toast-header {
        float: right !important;
        border: 0;
        position: relative;
        right: 5px;

        .btn-close {
            background-color: #eeedeb;
            border-radius: 12px;
            color: #000;
            width: 17px;
            height: 17px;
            opacity: 1;
            background-size: 9px;

            &:focus {
                outline: 0;
                box-shadow: none !important;
                opacity: 1;
            }
        }
    }

    h5.tost_heading {
        font-size: $az-font-20;
        color: $az-black;
        font-family: $az-kini-heading-three;
    }

    .final_price_tost {
        border: 1px solid #D8D8D8;
        margin: 5px 0;

    }
}

.price_under_fee {
    top: 0% !important;

    p {
        white-space: break-spaces !important;
    }

    .btn-close {
        position: relative;
        top: -15px;
    }
}


.guestviewmobile {
    .modal-dialog {
        margin: 0;

        .modal-content {
            height: 100vh;
            border-radius: 0;
            border: 0;
        }
    }

}


.showmoreLink-desc {
    background: $az-black;
    color: $az-white;
    text-decoration: none;
    border-radius: 50px;
    font-size: $az-font-15;
    display: block;
    margin-top: 30px;
    padding: 7px 20px;
    font-family: $az-kini-heading-three;
    border: none;
    &:active,
    &:focus,
    &:hover {
        box-shadow: none;
        outline: none;
    }
}


.strnewdeskview {
    @media (max-width:767px) {
        display: none;
    }
}

.ltr_overflow {
    @media (max-width:767px) {
        overflow: hidden;
    }
}

.drop_head_menu {
    position: relative;

    .sub_nav_head {
        padding-right: 35px !important;

        &::after {
            position: absolute;
            top: 18%;
            right: 15px;
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-right: 2px solid $az-ligh-black;
            border-top: 2px solid $az-ligh-black;
            transform: rotate(135deg);
        }
    }

    .drop_menu_cust {
        display: none;
        position: absolute;
        width: max-content;
        background: $az-white;
        text-align: left;
        top: 30px;
        min-width: 17.2rem;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
        padding: 10px 0;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.175);

        a {
            padding: .25rem 1rem !important;
            border-radius: 0 !important;
            display: block;
            font-weight: 400 !important;
            height: auto !important;

            &:hover {
                background: $az-light-black !important;
                color: #fff;
            }

        }
    }

    &:hover {
        .drop_menu_cust {
            display: block;

        }
    }
}


.wzrd_phone {
    .react-tel-input.phoneInput {
        input {
            padding: 13px 48px 14px !important;
            height: auto;
            border: 1px solid #72818e7a;
            border-radius: 12px;
            width: 100%;
            font-family: $az-heading-two;
            font-size: $az-font-16;

            &:focus {
                border: 1px solid #000000;
                outline: none;
                box-shadow: none;

            }

            &:active {
                border: 1px solid #000000;
                outline: none;
                box-shadow: none;
            }

            &::placeholder {
                font-size: $az-font-14;
                font-family: $az-heading-para !important;
                font-weight: normal !important;
                color: $az-gray !important;
            }

        }

        .flag-dropdown {
            border-radius: 15px 0px 0px 14px;
            margin: 8px 2px;
            background-color: #fff !important;
            border: 0;
            border-right: 1px solid #72818e33;

            .selected-flag {
                background-color: #fff !important;
            }
        }

        .selected-flag {
            width: 40px;

            &:focus,
            &:active,
            &:hover {
                background-color: $az-white !important;
            }
        }

        .country-list {
            padding-left: 0 !important;
            .search-box {
                padding: 5px !important;
                margin: 0 !important;
                border-radius: 5px !important;
                text-transform: capitalize;
                font-size: $az-font-12;

                &::placeholder {
                    font-size: $az-font-12;
                    font-family: $az-heading-two !important;
                    color: $az-gray !important;
                }
            }

            .search {
                padding: 10px;
                list-style: none;
            }

            .country {
                list-style: none;
            }
        }

        .search-emoji {
            display: none !important;
        }
    }

}

.wizard_row {
    @media (max-width:991px) {
        padding: 0px;
    }

    @media (max-width:767px) {
        padding: 0px;
    }

    .priceTop {
        h5 {
            color: $az-light-black!important ;
        }
    }

    .confirm_recpt {
        margin-top: 50px !important;

        @media (max-width:767px) {
            margin-top: 0px !important;
        }
    }

    .document_upload_done {
        display: grid;
        place-content: center;

        img {
            max-height: 45px;
            max-width: 45px;
        }
    }

    .wizard-space {
        .col-md-6 {
            &:nth-child(odd) {
                padding-left: 0 !important;

                @media (max-width:767px) {
                    padding: 0 !important;
                }
            }

            &:nth-child(even) {
                padding-right: 0 !important;

                @media (max-width:767px) {
                    padding: 0 !important;
                }
            }
        }
    }

    .reservation-info {
        h5 {
            color: $az-light-black !important;
        }
    }

}

.decline {
    font-size: $az-font-16;
    font-family: $az-heading-two;
}


.wizard_new_heading {
    .bg_wzrd_heading {
        padding: 0;
        .mb-5{
            margin-bottom: 0 !important;
            @media (max-width:767px) {
                margin-bottom: 4rem !important;
            }
        }

        .book_app_info {
            padding: 0 5px;

            @media (max-width:767px) {
                padding: 0 !important;
            }
        }

        .reservation_faq {
            @media (max-width:767px) {
                padding: 15px 10px !important;
            }

            .reser_faq_box {
                &:last-child {
                    border: 0 !important;
                }
            }
        }
    }

    .mob_sticky_top{
        position: sticky;
        top: 0;
        z-index: 9;
        margin-top: 30px;
        @media (max-width:767px) {
            border-bottom: 1px solid $az-light-white;
        }
    }

    .sign_wzrd{
        @media (max-width:767px) {
            margin-bottom: 55px;
            position: fixed;
            bottom: 30px;
            width: 100%;
            padding-bottom: 10px
        }
    }

    .wzrd_heading_box {
        background: $az-white !important;
        padding:5px!important;
        position: relative;
        @media (max-width:767px) {
            margin: 30px 0;
            padding: 15px 10px!important;
        }
     

        h4 {
            text-align: left;
            margin-bottom: 0px !important;
            margin-left: 0;
            display: block;
            font-family: $az-kini-heading-three !important;
            font-size: $az-font-35 !important;
            color: $az-oh-primary !important;
            @media (max-width: 991px) {
                font-size: $az-font-24 !important;
                margin-bottom: 0px !important;
            }

            @media (max-width: 767px) {
                text-align: center;
                font-size: $az-font-24 !important;
                margin-bottom: 0px !important;
                padding: 0 10px !important;
            }

        }

        ul{
            // list-style: none;
            margin-bottom: 0;
            padding-left: 0px;
            list-style: disc;

            li {
                color: $az-light-black;
                font-size: $az-font-16;
                label{
                    margin: 0 !important;
                }  
            }
        }

        p {
            margin: 0;
            a{
                // text-decoration: none;
                color: $az-light-black;
            }
            b{
                padding: 20px 0 10px;
                display: block;
                color: $az-light-black;
            }
            label{
                margin: 0 !important;
            }
        }

        p.welcom_text_wzrd{
            font-size: $az-font-18;
            @media (max-width:767px) {
                font-size: $az-font-17;
            }
        }

        p.prop_wzd_loc{
                font-size: $az-font-18;
                color: $az-light-black;
                font-family: $az-heading-para;
                padding: 0;
                text-align: left;
                font-weight: 400;
                // display: block;
                padding-bottom: 5px;
            img{
                max-height: 16px;
                margin-right: 5px;
                filter: brightness(0) invert(20%);
                position: relative;
                top: -2px;
            }
        }

        ul{
            padding-left: 1rem;
        }

        .upd_frnt_img{
            border: 2px dashed $az-gray;
            min-height: 120px;
            max-height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 12px;
            margin-bottom: 20px;
            width: 230px;
            margin: 0 auto 10px auto;
            margin-right: 0;
            cursor: pointer;
            input[type='file']{
                visibility: hidden;
                height: 0;
            }

            p{
                text-align: center;
                padding: 2px 10px;
            }
            @media (max-width:991px) {
                width: 200px;
                min-height: 120px;
                max-height: 120px;
            }
            @media (max-width:767px) {
                min-height: 105px;
                max-height: 105px;
                width: 155px;
            }
            @media (max-width:360px) {
                min-height: 100px;
                max-height: 100px;
                width: 140px;
            }
            img{
                height: 30px;
                margin-bottom: 10px;
                @media (max-width:767px) {
                    height: 20px;
                    margin-bottom: 0px;
                }
                
            }
        }

        .upd_frnt_img_sec{
            min-height: 250px;
            max-height: 250px;
             width: 200px;
             margin: 0;
             input[type='file']{
                visibility: hidden;
                height: 0;
            }
             @media (max-width:991px) {
                min-height: 250px;
                max-height: 250px;
                 width: 180px;
                 margin-top: 0px;
            }
            @media (max-width:767px) {
                margin-bottom: 100px;
                min-height: 200px;
                width: 150px;
                margin: 0 auto;
                margin-top:10px !important;
                margin-bottom: 60px;
            }
            @media (max-width:360px) {
                min-height: 180px;
                width: 140px;
            }
            img{
                height: 45px;
                margin-bottom: 10px;
                @media (max-width:767px) {
                    height: 35px;
                    margin-bottom: 0px;
                }
            }
        }

        .exp_img_close{
            position: absolute;
            width: 20px !important;
            height: 20px !important;
            z-index: 9;   
            right: 5px;
            top: 5px;     
            img{
                width: 20px !important;
                max-height: 20px !important;
                min-height: 20px !important;
            }
        }
        
        .upd_frnt_img_preview{
            min-height: 120px;
            max-height: 120px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            width: 230px;
            transform: translate(-50%, 0%);
           
            @media (max-width:991px) {
                width: 200px;
                min-height: 120px;
                max-height: 120px;
                margin: 0px auto;
                left: 50%;
            }
            @media (max-width:767px) {
                min-height: 105px;
                max-height: 105px;
                width: 156px;
               
            }
            @media (max-width:360px) {
                min-height: 100px;
                max-height: 100px;
                width: 140px;
            }

            img{
                width: 230px;
                min-height: 120px;
                max-height: 120px;
                border-radius: 12px;
                object-fit: cover;
                @media (max-width:991px) {
                    width: 200px;
                    min-height: 120px;
                    max-height: 120px;
                }
                @media (max-width:767px) {
                    min-height: 105px;
                    max-height: 105px;
                    width: 156px;
                   
                }
                @media (max-width:360px) {
                    min-height: 100px;
                    max-height: 100px;
                    width: 140px;
                }
            }
        }
        .upd_frnt_img_preview_1{
            min-height: 250px;
            max-height: 250px;
            width: 200px;
            position: absolute;
            top: 0px;
            left:0;
            @media (max-width:991px) {
                min-height: 250px;
                max-height: 250px;
                 width: 180px;
                 top:0;
            }
            @media (max-width:767px) {
                margin-bottom: 100px;
                min-height: 200px;
                width: 150px;
                left: 50%;
                transform: translate(-50%, 0%);
                margin-top: 0px;
                top:10px;
            }
            @media (max-width:360px) {
                min-height: 180px;
                width: 140px;
            }
            img{
                min-height: 250px;
                max-height: 250px;
                width: 200px;
                border-radius: 12px;
                object-fit: cover;
                @media (max-width:991px) {
                    min-height: 250px;
                    max-height: 250px;
                     width: 180px;
                     margin-top: 0px;
                }
                @media (max-width:767px) {
                    margin-bottom: 100px;
                    min-height: 200px;
                    width: 150px; 
                    margin-top: 0;                  
                }
                @media (max-width:360px) {
                    min-height: 180px;
                    width: 140px;
                }
            }
        }

        .wizardInfo {
            border-bottom: 1px solid $az-light-white;
            margin-bottom: 15px;
            padding-bottom: 15px;
            padding-top: 5px;

            h5 {
                // font-family: $az-heading-two !important;
                font-family:  $az-kini-heading-three !important;
                font-size: $az-font-22;
                color: $az-ligh-mid-black;
                text-align: left;
                // line-height: 1.5;
                @media (max-width:1024px) {
                    font-size: $az-font-22;
                }
            }

        }

        .wizard_timing {
            margin: 15px 0 20px 0;
            ul {
                list-style: none;
                list-style: none;
                padding: 0;
                display: flex;
                margin-bottom: 0px;
                li {
                    width: 50%;
                    display: block;
                    position: relative;
                    &::before {
                        content: " ";
                        border: 1px solid $az-border-color;
                        position: absolute;
                        height: 100%;
                        top: 0px;
                        right: 0px;
    
                        @media(max-width: 991px) {
                            border: 1px solid $az-border-color;
                        }
    
                        @media(max-width: 767px) {
                            border: 1px solid $az-border-color;
                        }
                    }
    
                    &:last-child {
                        padding-left: 15px;
                        &::before {
                            border: 0;
                        }
                    }

                    strong {
                        font-family: $az-heading-two;
                        color: $az-gray;
                        font-size: $az-font-16;
                        font-weight: normal;
                        text-align: left;
                        display: block;
                    }

                    span {
                        font-size: $az-font-18;
                        color: $az-light-black;
                        font-family: $az-heading-para;
                        padding: 0;
                        text-align: left;
                        font-weight: 400;
                        // display: block;
                    }

                    
        
                }

            }

            ul.resr_detl_list{
                li{
                    &::before {
                        border: 0 !important;
                    }
                }
            }

            ul.full_len {
                display: block;

                li.w-100 {
                    width: 100% !important;
                    margin-bottom: 15px;
                    &::before {
                        border: 0 !important;
                    }
                    &:last-child {
                        padding-left: 0px;
                    }
                    .edit_phone{
                        cursor: pointer;
                        float: right;
                        img{
                            height: 16px;
                        }
                    }
                }
            }
        }

        .wzrd_mid_heading {
            padding: 10px 0 0 0;
            border-top: 1px solid $az-border-color;
            margin-top: 0px;
            width: 100%;
            @media (max-width:767px) {
                margin-top: 20px;
            }
            h5 {
                font-size: $az-font-22;
                color: $az-ligh-mid-black;
                // font-weight: bold;
                padding-top: 15px;
                font-family: $az-heading-para;
                font-weight: 400;
                margin-bottom: 10px;
            }

            @media (max-width:991px) {
                // border: none !important;
                margin-top: 15px;
            }

            @media (max-width:767px) {
                // border: none !important;
                margin-top: 0px;
            }



            .MuiOutlinedInput-input {
                text-align: left;
                padding: 17px 17px 12px !important;
                font-family: $az-heading-two;
                font-size: $az-font-18;
            }

            .MuiOutlinedInput-root {
                border-radius: 12px !important;

            }

            .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                border: 1px solid $az-black !important;
            }


            label {
                top: 17px !important;
                left: 17px;
                transform: inherit;
                color: #606060 !important;
                transform: inherit !important;
                font-family: $az-heading-para;
            }

            label.Mui-focused {
                top: 5px !important;
                font-size: $az-font-14;
                transform: translate(0px, 0px) scale(0.75) !important;
            }

            label.MuiFormLabel-filled {
                top: 5px !important;
                font-size: $az-font-14;
                transform: translate(0px, 0px) scale(0.75) !important;
            }
            .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: $az-black !important;
                border-width: 1px !important;
            }

            fieldset {

                legend {
                    margin-left: inherit !important;
                    margin-right: inherit !important;
                    max-width: 0.01px !important;
                    transition: inherit;

                    span {
                        font-size: 18px;
                        color: #333333;
                        font-family: $az-heading-para;
                        padding: 0;
                        text-align: left;
                        font-weight: 400;
                    }
                }
            }
        }

        .check_wizard {
            span {
                color: #000;
                font-weight: 300;
                margin-top: 0;
                text-align: left;
            }
        }
    }

    .wzrd_main_section {
        background: gray;
        @media (max-width:767px) {
            padding: 1px !important;
        }
       .container{
            .content{
                @media (max-width:767px) {
                    padding: 0 !important;
                }
            }
       }
    }


}

.vs-hiddne{
    visibility: hidden;
}

main.Brandmainpage.wzard_main_bg {
    background-image:url(../images/oh-corporate-img/access-your-reservation.jpg) !important;
    background-position: top 0% right 0% !important;
    min-height: 100vh !important;
    width: 100% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;

    @media (max-width:767px) {
        background: transparent !important;
        height: 100% !important;
    }

    .req_book_secw-zaed{
        background: $az-white;
        @media (max-width:767px) {
            background: $az-light-gray;
        }
    }

    .wzrd_middle_desk{
        padding: 0;
        margin-bottom: 20px;
        @media (max-width:767px) {
            height:calc(100vh - 160px);
            max-height:calc(100vh - 160px);
            margin: 0;
            padding: 0;
            overflow: auto;
        }

        &::-webkit-scrollbar {
            width: 7px;
            height: 5px;
        }
        
        &::-webkit-scrollbar-track {
            background-clip: content-box;
            border: 0px solid transparent;
            width: 7px;
            background-color: transparent;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #C6C6C6;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background-color: #C6C6C6;
        }
        
        &::-webkit-scrollbar-corner,
        &::-webkit-scrollbar-track {
            background-color: $az-light-gray;
            width: 7px;
        }
    }

    .accommodations_sec{
        max-height: 400px;
        @media (max-width:767px) {
            max-height: 100%;
        }
    }

    .headerRight{
        @media (max-width:767px) {
            display: none !important;
        }
    }

       
}


.wzrd_main_section {
    @media (max-width:767px) {
        padding:0 1px !important;
    }
    .checkbox_valid_star{
            display: none;
            position: absolute;
            right: 5px;
            top: 5px;
    }
    .checkbox_valid{
        border: 1px solid #FA0505;
        .checkbox_valid_star{
            color: #FA0505 !important;
        }
     
    }
    .check_wizard{
        padding: 0 5px !important;
        margin: 5px 0 0 0!important;
    }
    .pc_view_wzd{
        display: flex !important;
        @media (max-width:767px) {
            display: block !important;
        }
        li{
            @media (max-width:767px) {
                width: 100% !important;

            }
            &::before{
                border: 0 !important;
                
            }
            &:last-child{
                @media (max-width:767px) {
                    padding-left: 0 !important;
                    padding-top: 15px;
                }
            }
        }
    }
    .accommodations_sec{
       @media (max-width:767px) {
        margin-bottom: 150px !important;
       }
    }
}


.step_btns {
    padding: 0px;
    background: $az-white;
    margin: 0;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    @media (max-width:1280px) {
        padding: 10px 0;
    }

    @media (max-width:991px) {
        position: unset;
    }

    @media (max-width:767px) {
        // display: grid;
        // place-content: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 9;
        padding:  15px;
        margin-top: 0;
    }

}

.btn_pre_wzd {
    border: 1px solid $az-black;
    background: transparent;
    font-size: $az-font-20;
    border-radius: 50px;
    padding: 9px 40px;
    font-family: $az-kini-heading-three !important;
    letter-spacing: 1px;
    text-decoration: none;
    text-align: center;
    color: $az-black;
    width: 47%;
    min-width: 47%;
    margin: 10px 5px;

    @media (max-width:767px) {
        width: 47%;
        min-width: 47%;
        padding: 7px 30px;
        margin: 10px 5px;
        font-size: $az-font-19;
    }

}


// ._hsN1w{
//     @media (max-width:767px) {
//         width: 90% !important;
//         min-width: 90% !important;
//         margin: 20px auto !important;
//     }
// }

.btn_next_wzd {
    border: none;
    background: $az-black;
    color: $az-white;
    font-size: $az-font-20;
    border-radius: 50px;
    padding: 10px 40px;
    font-family: $az-kini-heading-three;
    letter-spacing: 1px;
    text-decoration: none;
    text-align: center;
    width: 47%;
    min-width: 47%;
    margin: 10px 5px;

    @media (max-width:767px) {
        width: 47% !important;
        min-width: 47% !important;
        padding: 7px 30px;
        margin: 10px 5px;
        font-size: $az-font-19;
    }
    .spinner-grow-sm{
        @media (max-width:767px) {
            width: .5rem !important;
            height: .5rem !important;
            position: relative;
            top: -5px;
            margin: 0 1px 0 0;
        }
    }
}

.id_screen_upd{
    position: relative;
    min-height: 120px;
    max-height: 120px;
    width: 230px;
    float: right;
    margin-bottom: 10px;
    @media (max-width:991px) {
        width: 200px;
        min-height: 120px;
        max-height: 120px;
        margin-bottom: 10px;
      }
    @media (max-width:767px) {
        display: inline-flex;
        min-height: 105px;
        max-height: 105px;
        width: 156px;
        margin: 0 10px 10px;
    }
}

.id_verf_mob{
    @media (max-width:767px) {
        display: flex;
        justify-content: center;
    }
}

.wzrd_100b{
    margin-left: 0 !important;
    margin-right: 0 !important;
   
}

.doc_upload_heading{
    color: $az-light-black !important;
    // font-weight: 600 !important;
    font-size: $az-font-22;
    font-family: $az-heading-para !important;
    padding-top: 20px !important;

    @media (max-width:767px) {
        padding-top: 20px !important;
    }
}

.doc_upload_sec{
    @media (max-width:767px) {
        margin-bottom: 0px;
        width: 100%;
        overflow-x: hidden;
    }
}

.upload_info_detail{
    ul{
        li{
            margin-bottom: 5px;
            &::marker{
                color: $az-oh-black ;
            } 
            p{
                color: $az-light-upload-text;
                font-size: $az-font-16;
            }
        }
    }
}

.btn-checkin{
    .spinner-grow-sm{
        @media (max-width:767px) {
            width: .5rem !important;
            height: .5rem !important;
            margin: 0 1px 0 0;
            position: relative;
            left: 5px;
        }
    }
}

.upload_test_box{
    @media (max-width:767px) {
        padding-bottom: 0px !important;
    }
}

.pl-cust-0{
    padding-right: 0 !important;
    @media (max-width:767px) {
        padding-left: 0 !important;
    }
  
}

.pl-cust-two-0{
    padding-left: 0 !important;
    @media (max-width:767px) {
        padding-right: 0 !important;
    }
}

.edit_phone{
    position: absolute;
    right: 0;
}


// Ltr new update style

.ltr_pro_detail {
    .strnewdeskview {
        padding: 0px;

        h4.listing_title {
            padding-top: 0;
        }

        .ltr-listing_title {
            display: block;
            @media (max-width: 767px) {
                display: none;
            }  
        }
        .ltr-location  {
            display: block;
            @media (max-width: 767px) {
                display: none;
            }  
        }
        h5 {
            a {
                text-decoration: underline;
            }
        }
        .partnered_img {
            figure {
                width: 82px;
                height: 82px;
                img {
                    width: 82px;
                    height: 82px;

                    @media (max-width: 767px) {
                        width: 70px;
                        height: 70px;
                    }
                }


                @media (max-width: 767px) {
                    width: 70px;
                    height: 70px;
                }

            }
        }

        @media (max-width: 767px) {
            display: block;
        }
    }

   
}
.mainstrDetail .strImgLising .mainstrviewimag {
 .property_heading {
    h5 {
        a {
            text-decoration: underline;
        }
    }
 }
}
.guest_last_checkin{
    margin-top: 20px !important;
   @media (max-width:767px) {
    margin-top: 0px !important;
   }
}

#signature-helper-text{
    font-size: $az-font-14 !important;
    font-family: $az-heading-two !important;
    position: absolute;
    bottom: -20px;
    z-index: 999999;
}


.wzrd_main_section {
   .container{
        .content{
            padding: 0 !important;
            
        }
   }
}

.pc_middle_wzd{
    max-height: 535px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
        height: 5px;
    }
    
    &::-webkit-scrollbar-track {
        background-clip: content-box;
        border: 0px solid transparent;
        width: 10px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #333;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background-color: #333;
    }
    
    &::-webkit-scrollbar-corner,
    &::-webkit-scrollbar-track {
        background-color: $az-light-gray;
        width: 10px;
    }
    @media (max-width:767px) {
        max-height: 100%;
        overflow:  unset;
    }
}

.wzrd_middle_desk_new{
    margin-bottom: 0 !important;
    @media (max-width:767px) {
        height:calc(100vh - 250px) !important;
        max-height:calc(100vh - 250px) !important;
    }
}

.signamture_btm{
    @media (max-width:767px) {
        margin-bottom: 0px;
    }
} 

.pd-b-0-mob{
    @media (max-width:767px) {
        padding-bottom: 0 !important;
    }
}

// .gust_lst_check{
//     @media (max-width:767px) {
//         position: absolute;
//         bottom: 0;
//     }
// }

.mob_fullscreen {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #00000063;
    top: 0;
    left: 0;
    display: flex;
    place-content: center;
    place-items: center;
    z-index: 9999;
}

.mob_row_reserv{
    @media (max-width:767px) {
        flex-direction: column-reverse;
    }
    .wzrd_phone{
        @media (max-width:767px) {
            margin-bottom: 10px;
        }
    }
}

.fst_wzd_list{
    @media (max-width:767px) {
        margin: 25px 0 !important;
    }
}

.property_aminties{
    display: none;
}


.guest_strp_info{
    .StripeElement {
        height: 40px;
        padding: 10px 12px;
        width: 100%;
        color: #000;
        background-color: white;
        border: 1px solid transparent;
        border-radius: 12px;
        border-color: black;
        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
        margin-bottom: 5px;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
      }
      
      .StripeElement--invalid {
        border-color: #fa755a;
      }
      
      .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
      }
}
.checkoutinstruction {
    border-top: 1px solid $az-border-color;
    p {
        color: $az-oh-primary;
        font-family: $az-heading-two !important;
        font-size: $az-font-15;
        font-weight: revert !important;
        text-align: inherit;
    }
    b {
        color: $az-oh-primary;
        font-family: $az-heading-two !important;
        font-size: $az-font-15;
        font-weight: revert !important;
        text-align: inherit;
    }
    strong {
        color: $az-oh-primary;
        font-family: $az-heading-two !important;
        font-size: $az-font-15;
        font-weight: revert !important;
        text-align: inherit;
    }
   span {
        color: $az-oh-primary;
        font-family: $az-heading-two !important;
        font-size: $az-font-15;
        font-weight: revert !important;
        text-align: inherit;
    }
    ul {
            color: $az-oh-primary;
            font-family: $az-heading-two !important;
            font-size: $az-font-15;
            font-weight: revert !important;
            text-align: inherit;
            margin: 0;
            padding-left: 31px !important;
        li {
            width: auto !important;
            display: list-item !important;
            color: $az-oh-primary;
            font-family: $az-heading-two !important;
            font-size: $az-font-15;
            font-weight: revert !important;
            text-align: inherit;
            list-style: initial !important;
            margin: 0 !important;

            span { 
                display: initial !important;
            }
        }
    }
    ol {
        color: $az-oh-primary;
        font-family: $az-heading-two !important;
        font-size: $az-font-15;
        font-weight: revert !important;
        text-align: inherit;
        margin: 0;
        padding-left: 31px !important;
        
    li {
        width: auto !important;
        display: list-item !important;
        color: $az-oh-primary;
        font-family: $az-heading-two !important;
        font-size: $az-font-15;
        font-weight: revert !important;
        text-align: inherit;
        list-style: initial !important;
        margin: 0 !important;

        span { 
            display: initial !important;
            font-size:$az-font-15;
        }
    }
}

}

.strip_parking {
    margin-bottom: 0;
    padding: 0 5px !important;

    @media (max-width:767px) {
        margin-bottom: 100px;
        padding: 0 !important;
    }

    .step_btns {
        @media (max-width:767px) {
            left: 0;
        }
    }

}

.parking_list_sty {
    padding-left: 5px !important;
}

.parking_pay_detail {
    padding-left: 0px !important;

    li {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        span {
            font-size: $az-font-16;
            color: $az-oh-primary;
            font-family: $az-heading-two;
            font-weight: normal;
            margin-bottom: 0;
        }
    }
}


    // onboarding pages style

    .container.onboardingpagestyle {

        padding: 30px 15px !important;

        @media (max-width: 991px) {
            padding: 30px 15px !important;
        }

        h1 {
            font-size: $az-font-20 !important;

            @media (max-width: 991px) {
                font-size: $az-font-20 !important;
            }
        }
    }



.change-pass-text {
    @media (max-width:991px) {
        margin-top: 20px;
    }
}
.upload_head_text {
    padding-top: 20px;

    @media (max-width:767px) {
        padding-top: 0;
    }
}

.cust_text_left {
    text-align: left !important;
}

.otp_code_pop {
    position: absolute;
    right: 21px;
}

.otp_text_heading {
    padding-top: 25px;
    font-family: $az-heading-para;
    font-size: $az-font-22;
    color: $az-oh-primary;
}

.otp_text_head_two {
    font-size: $az-font-16;
    color: $az-oh-primary;
}

.otpModal {
    .timerText {
        color: $az-gray;
    }
}

.parking_list_sty {
    ul {
        list-style-type: initial !important;
        padding-left: 2px !important;

        li {
            color: $az-oh-primary !important;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}

.car_color_type {
    .form-control {
        padding: 12px 17px 13px;
        font-size: $az-font-18;
        font-family: $az-heading-two;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        margin-top: 10px;

        &:focus {
            border: 1px solid $az-black;
            box-shadow: none !important;
        }
    }

    .getdirection {
        width: 107px;
        text-align: center;
    }

    p {
        font-size: $az-font-18 !important;
    }
}

.card_strp_5step {
    @media (max-width:767px) {
        padding-bottom: 15px;
    }
}

.req_book_secw-zaed {
    .strip_last_form {
        @media (max-width:767px) {
            padding-bottom: 25px !important;
        }
    }
}

.cust_text_left {
    text-align: left !important;
}

.reservation_faq {
    .reser_faq_box {
        // border-bottom: 1px solid $az-border-color;
        padding-bottom: 0px;
        padding-top: 15px;

        h5 {
            font-family: $az-kini-heading-three !important;
            color: $az-oh-primary;
            text-align: left !important;
            font-size: $az-font-18 !important;
            margin: 0;
            padding: 15px 0;

            @media(max-width: 767px) {
                font-size: $az-font-26;
                text-align: center;
            }
        }

        p {
            color: $az-oh-primary ;
            font-family: $az-heading-two;
            font-size: $az-font-16;
            margin-bottom: 10px !important;

            ul {
                li {
                    margin: 0 0 10px 0 !important;
                    width: 100%;
                    font-size: $az-font-16 !important;

                    &::before {
                        border: 0 !important;
                    }

                    &:last-child {
                        padding-left: 0px;
                        margin-bottom: 0;
                    }
                }
            }

            img {
                width: 100%;
            }
        }
    }
}


// onboarding pages style

.container.onboardingpagestyle {

    padding: 30px 15px !important;
    position: relative;

    @media (max-width: 991px) {
        padding: 30px 15px !important;
    }

    @media (max-width: 767px) {
        padding: 30px 15px !important;
    }

    .cust_text_left {
        margin: 0 !important;
    }

    .bannerimg {
        width: 100%;
        height: 340px;
    }

    .content {
        padding: 30px 15px;
        line-height: 19px !important;
        margin: 0;
    }

    h3 {
        font-family: $az-Orpheus-Bold;
        font-size: $az-font-18;
        color: #000000;
        text-align: left;
        margin: 0 0 4px;
        font-weight: unset !important;
    }

    h4 {
        font-family: $az-heading-two;
        font-size: $az-font-18;
        color: #333333;
        text-align: left;
        margin: 15px 0 4px;
        font-weight: normal;
    }

    p {
        font-family: $az-heading-two;
        font-size: $az-font-16;
        color: #606060;
        text-align: left;
        margin: 5px 0 15px;
        line-height: 1.2 !important;
    }

    ol,
    ul {
        margin-top: 10px !important;
    }

    ol li,
    ul li {
        font-family: $az-heading-two;
        font-size: $az-font-16;
        color: #606060;
        text-align: left;
        margin: 5px 0;
        line-height: 1.2 !important;
    }

    a {
        text-decoration: none !important;
    }

    @media (max-width: 991px) {

        .bannerimg {
            width: 100%;
            height: 340px;
        }

        .content {
            padding: 30px 15px;
            line-height: 19px !important;
            margin: 0;
        }

        h3 {
            font-family: $az-Orpheus-Bold;
            font-size: $az-font-18;
            color: #000000;
            text-align: left;
            margin: 0 0 4px;
            font-weight: unset !important;
        }

        h4 {
            font-family: $az-heading-two;
            font-size: $az-font-18;
            color: #333333;
            text-align: left;
            margin: 15px 0 4px;
            font-weight: normal;
        }

        p {
            font-family: $az-heading-two;
            font-size: $az-font-16;
            color: #606060;
            text-align: left;
            margin: 5px 0 15px;
            line-height: 1.2 !important;
        }

        ol,
        ul {
            margin-top: 10px !important;
        }

        ol li,
        ul li {
            font-family: $az-heading-two;
            font-size: $az-font-16;
            color: #606060;
            text-align: left;
            margin: 5px 0;
            line-height: 1.2 !important;
        }

        a {
            text-decoration: none !important;
        }

    }

    @media (max-width: 767px) {

        .bannerimg {
            width: 100%;
            height: 340px;
        }

        .content {
            padding: 30px 15px;
            line-height: 19px !important;
            margin: 0;
        }

        h3 {
            font-family: $az-Orpheus-Bold;
            font-size: $az-font-18;
            color: #000000;
            text-align: left;
            margin: 0 0 4px;
            font-weight: unset !important;
        }

        h4 {
            font-family: $az-heading-two;
            font-size: $az-font-18;
            color: #333333;
            text-align: left;
            margin: 15px 0 4px;
            font-weight: normal;
        }

        p {
            font-family: $az-heading-two;
            font-size: $az-font-16;
            color: #606060;
            text-align: left;
            margin: 5px 0 15px;
            line-height: 1.2 !important;
        }

        ol,
        ul {
            margin-top: 10px !important;
        }

        ol li,
        ul li {
            font-family: $az-heading-two;
            font-size: $az-font-16;
            color: #606060;
            text-align: left;
            margin: 5px 0;
            line-height: 1.2 !important;
        }

    }

    a {
        text-decoration: none !important;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none !important;
        }
    }

    table {
        border: 1px solid #ddd !important;
        border-bottom: 1px solid #000 !important;
        border-radius: 10px !important;
        -webkit-border-radius: 10px !important;
        -moz-border-radius: 10px !important;
        border-radius: 10px !important;
        -khtml-border-radius: 10px !important;

        &:hover {
            box-shadow: 0px 2px 7px #ddd !important;
        }

        tr {
            &:first-child td {
                padding: 15px !important;
            }

            &:last-child td {
                padding: 0 15px 15px 0 !important;
            }
        }


    }

    img.ob-img {
        object-fit: cover !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }

    h1 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .resouce-back-list {
        position: absolute;
        top: 35px;
    }

}


.trips_main_sec {
    .content {
        @media (max-width:767px) {
            padding: 0 !important;
        }
    }
}


.parking-head-text {
    line-height: normal !important;
}


.parking_total_price {
    @media (max-width:767px) {
        margin-bottom: 0 !important;
    }
}

.parking_page {
    .wzrd_main_section .check_wizard {
        @media (max-width:767px) {
            margin-top: 0 !important;
        }
    }
}

.credit_card_inst {
    p {
        font-size: $az-font-16 !important;

        ul {
            li {
                font-size: $az-font-16 !important;

                span {
                    font-size: $az-font-16 !important;
                }
            }
        }
    }
}

.tech_sec_box {
    width: 500px !important;

    @media (max-width:767px) {
        width: 100% !important;
    }

    .tech-spt_sec {
        @media (max-width:767px) {
            min-height: calc(100vh - 280px);
        }
        background: $az-white;

        .issue_text_box {
            textarea {
                width: 100%;
                min-height: 150px;
                padding: 14px;
                border-radius: 12px;
                font-family: $az-heading-two !important;
                border: 1px solid #72818e7a;
                color: $az-black;
                resize: none;
                font-size: $az-font-18;

                &::placeholder {
                    color: $az-gray;
                    font-family: $az-heading-para ;
                    font-size: 1rem;
                }

                &:focus-visible {
                    outline: none !important;
                    border-color: $az-black;
                }
            }
        }
    }
}



.faq_support_sec {
    @media (max-width:767px) {
        height: calc(100vh - 70px) !important;
        max-height: calc(100vh - 70px) !important;
        overflow-x: hidden !important;
    }

}

.reservation_faq {
    h3.tech-spt-title {
        text-align: left !important;
        margin-bottom: 0px !important;
        margin-left: 0px !important;
        padding: 0 !important;
        display: block;
        font-family: $az-kini-heading-three !important;
        font-size: $az-font-28 !important;
        color: $az-oh-primary !important;
        @media (max-width: 991px) {
            font-size: $az-font-24 !important;
            margin-bottom: 0px !important;
        }

        @media (max-width: 767px) {
            text-align: center;
            font-size: $az-font-22 !important;
            margin-bottom: 0px !important;

        }

    }

    .tech_sup_faq {
        // ul {
        //     display: flex;
        //     flex-direction: column;
        //     padding: 0;
        //     width: 100%;
        //     margin-top: 10px;

        //     li {
        //         border-bottom: 1px solid rgba(114, 129, 142, .2);
        //         list-style: none;
        //         margin: 0;
        //         padding: 0 !important;
        //         width: 100%;

        //         a {
        //             color: $az-oh-primary;
        //             font-size: $az-font-18;
        //             margin: 0;
        //             text-align: left;
        //             font-family: $az-kini-heading-three ;
        //             display: flex;
        //             justify-content: space-between;
        //             padding: 15px 0;
        //             text-decoration: none;
        //             cursor: pointer;

        //             .faq_ques {
        //                 align-items: center;
        //                 display: flex;
        //                 padding-right: 20px;
        //             }

        //             img {
        //                 -webkit-transform: rotate(180deg);
        //                 transform: rotate(180deg);
        //                 width: 10px;
        //             }
        //         }
        //     }
        // }
        margin-top: 10px;
        max-height: 455px;
        overflow: auto;
        padding-right: 5px;
        &::-webkit-scrollbar {
            width: 10px;
            height: 5px;
        }
    
        &::-webkit-scrollbar-track {
            background-clip: content-box;
            border: 0px solid transparent;
            width: 10px;
            background-color: gray;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #333;
        }
    
        &::-webkit-scrollbar-thumb:hover {
            background-color: #333;
        }
    
        &::-webkit-scrollbar-corner,
        &::-webkit-scrollbar-track {
            background-color: rgb(208, 208, 208);
            width: 10px;
        }

        @media (max-width:767px) {
            max-height: 100%;
            padding-left: 0;
        }
        .accordion{
            margin: 0;
            .accordion-item{
                border: 0;
                border-bottom: 1px solid rgba(114, 129, 142, .2);
                border-radius: 0;
                .accordion-header{
                    padding-bottom: 0;
                    .accordion-button{
                        padding: 20px 0;
                        background: transparent;
                        color: $az-black;
                        border: 0;
                        box-shadow: unset;
                        &:focus {
                            border-color: transparent;
                            box-shadow: unset;
                        }
                        &::after{
                            filter: brightness(0) !important;
                        }
                        h5{
                            margin: 0;
                        }
                       
                    }
                }
                
            }
            .accordion-collapse{
                .accordion-body{
                    padding: 0 0 15px 0;
                    p{
                        font-size: $az-font-16 !important;
                        img{
                            max-width: 100%;
                        }
                        video{
                            max-width: 100%;
                        }
                        h2{
                            font-family: $az-kini-heading-three !important;
                            font-size: $az-font-18 !important; 
                        }
                        p{
                           font-family: $az-heading-two !important; 
                           font-size: $az-font-16 !important;
                           img{
                            max-width: 100%;
                        }
                        video{
                            max-width: 100%;
                        }
                        }
                    }
                }
            }
        }
    }
}

.tech_spt_check {
    display: flex !important;
    flex-flow: row !important;
    place-items: center !important;
}

.tech_heading {
    @media (max-width:767px) {
        position: relative;
    }

    a {
        display: none;

        @media (max-width:767px) {
            display: block;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }

        img {
            width: 18px;
        }
    }
}

.tech_supp_sec {
    p.error-message {
        margin: 3px 17px 0px !important;
    }

    .react-tel-input.phoneInput {
        input {
            padding: 13px 17px 14px !important;
            font-size: $az-font-18 !important;
        }

        .flag-dropdown {
            display: none;
        } 

        }
    }

.amenities_modal {
    .modal-dialog {
        max-width: 800px !important;
    }
}

// Blog page style
main.Helpblogdetail {
    .blogView {
        padding: 0px 22px;


        @media(max-width:991px) {
            padding: 0px 18px;
        }

        @media(max-width:767px) {
            padding: 0px 18px;
        }


        h1 {
            @include heading2;
            color: $az-kini-primary;
            text-align: left;
            font-size: $az-font-20;
        }


        .tabsNav {
            font-family: $az-heading-two;
            padding: 10px 0;

            @media(max-width:991px) {
                padding: 10px 0;
            }

            @media(max-width:767px) {
                padding: 10px 0;
            }


            .nav-tabs {
                margin-bottom: 30px;

                @media(max-width: 767px) {
                    margin-bottom: 0px;
                }

                .nav-item {

                    button {
                        font-family: $az-heading-two;
                        color: $az-black !important;
                        border: none;
                        font-size: $az-font-20;
                        padding: 10px 30px;

                        @media(max-width: 767px) {
                            font-size: $az-font-18;
                            padding: 10px 30px;

                        }
                    }

                    button.active {
                        font-family: $az-heading-two;
                        border: none;
                        font-size: $az-font-20;
                        padding: 10px 30px;
                        background-color: $az-black ;
                        color: $az-white !important;

                        @media(max-width: 767px) {
                            font-size: $az-font-18;
                            padding: 10px 30px;

                        }
                    }
                }
            }


            .reservationListMobview {
                display: none;

                @media(max-width: 767px) {
                    display: none;

                    ul {
                        list-style: none;
                        padding-left: 0;

                        li {
                            button {
                                text-decoration: none;
                                color: #000000;
                                display: flex;
                                padding: 25px 0 15px;
                                border: 0;
                                background: none;
                                box-shadow: none;
                                border-bottom: 1px solid $az-border-color;

                                figure {
                                    max-width: 80px;
                                    min-height: 63px;
                                    overflow: hidden;
                                    object-fit: cover;
                                    margin-bottom: 0;

                                    img {
                                        max-width: 100%;
                                        height: 63px;
                                    }
                                }

                                .content {
                                    text-align: left;
                                    font-family: $az-heading-two;
                                    font-size: $az-font-14;
                                    padding-left: 15px;
                                    width: calc(100% - 80px);

                                    h3 {
                                        font-family: $az-heading-para;
                                        font-size: $az-font-16;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    h4,
                                    h6 {
                                        color: $az-gray;
                                        font-size: $az-font-14;
                                    }
                                }

                                border-bottom: 1px solid $az-border-color;


                            }


                            &:last-child {
                                button {
                                    border-bottom: none;
                                }
                            }

                        }
                    }
                }

            }

            .blogsection {
                font-family: $az-heading-two;
                font-size: $az-font-18;
                color: $az-oh-primary;

                .blogleft {
                    .blogtitle {
                        text-align: left;
                        margin: 15px 0;
                        font-family: $az-heading-two;
                        font-size: $az-font-18;

                    }

                    ul {
                        text-align: left;
                        padding-left: 0;
                        column-count: 2;
                        display: inherit;

                        @media(max-width:991px) {
                            column-count: 2;
                        }

                        @media(max-width:767px) {
                            column-count: inherit;
                        }

                        li {
                            list-style: none;

                            // max-width: 33.33%;
                            a {
                                color: $az-oh-primary;
                                font-size: $az-font-16;
                                font-family: $az-heading-two;
                            }
                        }
                    }

                    .showmore {
                        background: $az-black;
                        font-family: $az-kini-heading-three;
                        padding: 9px 20px;
                        border-radius: 30px;
                        font-size: $az-font-14 !important;
                        color: $az-white !important;
                        text-decoration: none;
                        border: none;
                    }

                    ul.Helpdata {
                        text-align: left;
                        padding-left: 0;
                        column-count: inherit;
                        display: inherit;

                        li {
                            list-style: none;

                            // max-width: 33.33%;
                            a {
                                color: $az-oh-primary;
                                font-size: $az-font-16;
                                font-family: $az-heading-two;
                            }
                        }
                    }

                    h2 {
                        @include heading2;
                        color: $az-kini-primary;
                        text-align: left;
                        font-family: $az-heading-two;

                        @media(max-width:991px) {
                            color: $az-kini-black;
                        }

                        @media(max-width:767px) {
                            color: $az-kini-black;
                        }
                    }

                    p {
                        color: $az-oh-primary;
                        font-size: $az-font-18;
                        font-family: $az-heading-two;
                        text-align: left;
                    }

                }

                .blogdetails {
                    .blogtitle {
                        text-align: left;
                        margin: 30px 0 15px;
                        font-family: $az-heading-para;

                    }

                    ul {
                        text-align: left;
                        padding-left: 0;
                        column-count: inherit;

                        @media(max-width:991px) {
                            column-count: inherit;
                        }

                        @media(max-width:767px) {
                            column-count: inherit;
                        }

                        li {

                            // max-width: 33.33%;
                            a {
                                color: $az-oh-primary;
                                font-size: $az-font-16;
                                font-family: $az-heading-two;
                            }
                        }
                    }

                    .showmore {
                        background: $az-black;
                        font-family: $az-kini-heading-three;
                        padding: 9px 20px;
                        border-radius: 30px;
                        font-size: $az-font-14 !important;
                        color: $az-white !important;
                        text-decoration: none;
                        border: none;
                    }

                    ul.Helpdata {
                        text-align: left;
                        padding-left: 0;
                        column-count: inherit;
                        display: inherit;

                        li {
                            list-style: none;

                            // max-width: 33.33%;
                            a {
                                color: $az-oh-primary;
                                font-size: $az-font-16;
                                font-family: $az-heading-two;
                            }
                        }
                    }

                    h2 {
                        @include heading2;
                        color: $az-kini-primary;
                        text-align: left;
                        font-family: $az-heading-two;

                        @media(max-width:991px) {
                            color: $az-kini-black;
                        }

                        @media(max-width:767px) {
                            color: $az-kini-black;
                        }
                    }

                    p {
                        color: $az-oh-primary;
                        font-size: $az-font-18;
                        font-family: $az-heading-two;
                        text-align: left;
                        line-height: initial !important;
                    }

                }

                .blogright {
                    padding: 0px 15px 15px;
                    border: 1px solid $az-border-color;
                    border-radius: 12px;
                    position: sticky;
                    top: 110px;
                    margin-top: 0;

                    @media(max-width:991px) {
                        margin-top: 30px;
                    }

                    @media(max-width:767px) {
                        margin-top: 30px;
                    }

                    .blogtitle {
                        text-align: left;
                        margin: 30px 0 15px;
                        font-family: $az-heading-para
                    }

                    ul {
                        text-align: left;
                        padding-left: 0;

                        li {
                            list-style: none;

                            a {
                                color: $az-oh-primary;
                                font-size: $az-font-16;
                                font-family: $az-heading-two;
                            }
                        }
                    }
                }
            }




        }


        .filter {
            width: 32%;
            display: flex;


            @media(max-width:991px) {
                width: 100% !important;
            }

            @media(max-width:767px) {
                width: 100% !important;
            }

            input {
                border: 1px solid $az-border-color;
                padding: 15px 17px 14px !important;
                font-size: $az-font-18;
                font-family: $az-heading-two;
                border-radius: 12px 0 0 12px;
                width: 100%;

                &:active,
                &:focus,
                &:hover {
                    border: 1px solid $az-border-color;
                    outline: 1px solid $az-oh-primary;
                }
            }

            .searchicon {
                background: $az-oh-primary;
                padding: 10px 15px;
                display: flex;
                align-items: center;
                border-radius: 0 12px 12px 0;

                img {
                    fill: $az-white;
                }
            }
        }

        .backarrow {
            max-width: 18px;
        }
    }


}

.cursor-pointer {
    cursor: pointer;
}

// .tech_fst_support{
//     @media (max-width:767px) {
//         min-height: calc(100vh - 270px);
//     }
// }

.faqs_support_modal {
    h5 {
        color: $az-oh-primary;
        font-size: $az-font-18;
        margin-bottom: 14px;
        text-align: left;
        font-family: $az-kini-heading-three ;

    }

    img {
        max-width: 100%;
        margin-bottom: 14px;
    }

    video {
        max-width: 100%;
        margin-bottom: 14px;
    }
}
        

.reser_faq_edit{
    h2 {
        font-family: $az-kini-heading-three !important;
        color: $az-oh-primary !important;
        text-align: left !important;
        font-size: $az-font-18 !important;
        margin: 0 !important;
        border-top: 1px solid $az-border-color;
        padding-top: 20px !important;
        padding-bottom: 15px !important;

        &:first-child {
            border: 0 !important;
        }

        @media(max-width: 767px) {
            font-size: $az-font-26;
            text-align: center;
        }
    }

    p {
        color: $az-oh-primary !important;
        font-family: $az-heading-two !important;
        font-size: $az-font-16 !important;
        margin-bottom: 15px !important;
        padding-bottom: 10px !important;

        ul {
            li {
                margin: 0 0 10px 0 !important;
                width: 100%;
                font-size: $az-font-16 !important;

                &::before {
                    border: 0 !important;
                }

                &:last-child {
                    padding-left: 0px !important;
                    margin-bottom: 0 !important;
                }
            }
        }

    }
}

.modal_faq_sup {
    .modal-content {
        padding-bottom: 0 !important;
    }
}

.subscribeForm {
    .formSection {
        form{
            .MuiFormControl-root{
                @media (max-width:991px) {
                    display: grid !important;
                }
            }
            p.error-message{
                padding-left:30px !important;
                margin: 0 !important;
                @media (max-width:991px) {
                    padding-bottom:15px !important;
                }
            }
        }
    }
}

.unsub_box{
    padding: 40px !important;
    h3{
        background-color: #d8efcc;
        color: #1f5c42;
        font-size: $az-font-16;
        text-align: left;
        padding: 10px;
        margin: 10px 0;
    }
    p.p-dark{
        margin-bottom: 0px !important;
        font-size: $az-font-22 !important;
        @media (max-width:767px) {
            font-size: $az-font-18 !important;
        }
    }
    .rdiobox{
        float: left !important;
        text-align: left !important;
        label{
            color: $az-black !important;
            font-size: $az-font-16 !important;
            cursor: pointer !important;
        }
        .form-check-input{
            margin-top: 3px !important;
            cursor: pointer !important;
            border: 1px solid $az-black !important;
            width: 18px !important;
            height: 18px !important;
        }
        .form-check-input:checked{
            background-color: #000;
        }
        .form-check-input:focus{
            box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
        }
    }
    textarea{
        width: 100%;
        min-height: 150px;
        padding: 14px;
        border-radius: 12px;
        font-family: $az-heading-two !important;
        border:1px solid #72818e26;
        color: $az-black;
        resize: none;
        font-size: $az-font-16;
        &::placeholder{
            color: $az-gray;
            font-family: $az-heading-para ;
            font-size: 1rem ;
        }
        &:focus-visible {
            outline:none !important; 
            border-color: $az-black;
            box-shadow: none !important;
        }
    }
}


.wzrd_middle_desk{
    .scroll_phn_safari {
        @media screen and (max-width: 768px) {
          @supports (-webkit-touch-callout: none) {
            margin-bottom: 80px !important;
          }
        }
      }
    }
      
    .tect_btn_sub{
        font-size: 25px;
        color: #ffffff;
        padding: 9px 20px 10px;
        min-width: 300px;
        @media (max-width:991px) {
            font-size: $az-font-16;
            padding: 9.5px 30px;
            min-width: 100% !important;
        }
       
    } 

.supoort_faq_feed{
    .wzrd_main_section{
        .container{
            padding: 0 10px !important;
        }
    }
}

.tech_form_mg_b{
    margin-bottom: 30px;
    @media (max-width:767px) {
        margin-bottom: 10px;
    }
}

.tect_btn_sub{
    font-size: 20px;
    color: #ffffff;
    padding: 9px 20px 10px;
    min-width: 300px;
    width: 100%;
    @media (max-width:991px) {
        font-size: $az-font-19;
        padding: 7px 30px;
        min-width: 100% !important;
    }
   
}  

.supoort_faq_feed{
    .wzrd_main_section{
        .container{
            padding: 0 10px !important;
        }
    }
}

.tech_form_mg_b{
    margin-bottom: 30px;
    @media (max-width:767px) {
        margin-bottom: 10px;
    }
}

.mobile_faq_sec{
    text-align: left;
    .Brandheader{
        display: none !important;
    }
    .main_title_faqa{
        font-size: $az-font-22;
        font-family: $az-heading-para;
        margin:30px 0;
        color: $az-light-black;
    }
   .mobile_faq{
        border-bottom: 1px solid $az-border-color;
        margin: 30px 0;
        padding-bottom: 16px;
        &:last-child{
            border: 0 !important;
        }
        h5{
            font-family: $az-kini-heading-three;
            font-size: $az-font-16;
            margin-bottom: 15px;
            color: $az-oh-primary;
        }
        p{
            font-size: $az-font-14;
            font-family: $az-heading-two;
            color: $az-oh-primary;  
            img{
                max-width: 100% !important;
            }
            video{
                max-width: 100% !important;
            }
        }
        img{
            max-width: 100% !important;
        }
        video{
            max-width: 100% !important;
        }
   }
}

.cus-text-left{
    text-align: left ;
}

.modal-dialog-scrollable{
    .newcontentterms{
        p{
            font-size: $az-font-16 !important;
        }
        ol{
            li{
                font-size: $az-font-22 !important;
            }
        }
    }
}

.checkin-mobile-over{
    @media (max-width:767px) {
        overflow: auto !important;
    }
}
.unsb_text_not{
    text-align: left;
    padding-left: 20px;
    margin-bottom: 10px;
}

.unsubscribrl_main_sec{
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 10px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background-clip: content-box;
        border: 0px solid transparent;
        width: 10px;
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #333;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #333;
    }

    &::-webkit-scrollbar-corner,
    &::-webkit-scrollbar-track {
        background-color: #fff;
        width: 10px;
    }
}

.cus-text-left{
    text-align: left ;
}

.modal-dialog-scrollable{
    .newcontentterms{
        p{
            font-size: $az-font-16 !important;
        }
        ol{
            li{
                font-size: $az-font-22 !important;
            }
        }
    }
}

.checkin-mobile-over{
    @media (max-width:767px) {
        overflow: auto !important;
    }
}

.property_first{
  figure{
    max-width: 100% !important;
    max-height: 580px !important;
    min-height: 580px !important;
    object-fit: cover;
        @media (max-width:991px) {
            max-height: 280px !important;
            min-height: 280px !important;
        }
    img{
        max-width: 100% !important;
        max-height: 580px !important;
        min-height: 580px !important;
        object-fit: cover !important;
        @media (max-width:991px) {
            max-height: 280px !important;
            min-height: 280px !important;
        }
    }
  }
}


.property_secd{
    figure{
        max-width: 100% !important;
        max-height: 280px !important;
        min-height: 280px !important;
        object-fit: cover;
        img{
            max-width: 100% !important;
            max-height: 280px !important;
            min-height: 280px !important;
            object-fit: cover !important;
        }
      }
}

// .proerty_w_len{
//     .listImg{
//         figure{
//             img{
//                 transform: scale(1.3);
//             }
//         }
//         &:hover{
//             figure{
//                 img{
//                     transform: scale(1.39);
//                 }
//             }        
//         }
//     }
// }

.btn-property-view{
    @include OHprimaybutton-sm;
    margin-top: 20px;
}


.support_faq_btns{
    width: 97.5% !important;
}

.btn_next_wzd.tect_btn_sub.btn_faq_issue{
    @media (max-width:767px) {
        width: 97.5% !important;
        min-width: 97.5% !important;
    }
}
.eye-icon{
    position: absolute;
    right: 0;
    top: 50%;
    transform:translate(-100%, -50%);
    cursor: pointer;
    @media (max-width:767px) {
        
    }
    img{
        width: 20px;
        max-width: 20px;
    }
}

.support_btm_btn{
   @media (max-width:767px) {
    height: 100% !important;
    max-height: 100% !important;
   }
}

.suppot_btn_sec{
    @media (max-width:767px) {
        position: unset !important;
    }
}

main.Brandmainpage.accessyourreservation.homeshare_enquiry{
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background-clip: content-box;
        border: 0px solid transparent;
        width: 10px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #333;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #333;
    }

    &::-webkit-scrollbar-corner,
    &::-webkit-scrollbar-track {
        background-color: #fff;
        width: 10px;
    }
}


main.Brandmainpage.accessyourreservation.homeshare_enquiry{
    display: grid;
    place-items: center;
    .Brandheader{
       display: none;
    }
    .contact_form_box{
        margin: 0px auto !important;
        padding:40px !important ;
    }
    .reservationBook{
        .mobView {
            .requestSection {
                span.Mui-error{
                    color: $az-gray !important;
                } 
            }
        }
    }
}

.reservationBook{
    .ready_labels_form{
        .MuiOutlinedInput-input{
        background-color: #fbfbfb;
            opacity: .9;
            border-radius: 12px;
        }
        label{
            transform: translate(0px, 0px) scale(0.75) !important;
            top: 3px !important;
        }
        span.Mui-error{
            color: $az-gray !important;
        }
    }
}


  .StripeElement--empty{
            border: 1px solid #72818e33 !important;
            border-radius: 12px !important;
            padding: 10px !important;
  }

  .StripeElement--invalid{
    border: 1px solid #72818e33 !important;
    border-radius: 12px !important;
    padding: 10px !important;
  }

  .StripeElement--focus{
    border: 1px solid #000 !important;
    border-radius: 12px !important;
    padding: 10px !important;
  }

  .step_card_deatil{
        left: 0;
  }
  .support_btm_btn{
    @media (max-width:767px) {
     height: 100% !important;
     max-height: 100% !important;
    }
 }
 
 .suppot_btn_sec{
     @media (max-width:767px) {
         position: unset !important;
     }
 }
 
 .access_popup{
     .modal-dialog-centered{
         max-width: 590px;
         @media (max-width:767px) {
             margin:0 10px;
         }
     }
     h1 {
         @include heading1;
         color: $az-oh-primary;
         font-size: $az-font-40;
     }
     h4 {
         @include headingRg;
         color: $az-light-black;
         font-size: 18px;
         padding-top:0px;
     }

     .timerText{
        font-family: $az-heading-two !important;
        margin: 0 !important;
        font-size: $az-font-16 !important;
        margin-bottom: 20px !important;
        span{
            text-decoration: none;
            font-family: $az-heading-para !important;
            font-size: $az-font-18 !important;
        }
    }
 
     .modal-body{
         padding: 40px 40px;
         position: relative;
         @media (max-width:767px) {
            padding: 25px 20px 25px;
        }
         .welcomeClose{
             position: absolute;
             right: 20px;
             top: 20px;
         }
 
         .cancellations{
                
            max-height: 60vh;
            overflow: auto;
            border: 0 !important;
            padding: 0 5px 0 0 !important;

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
        
            &::-webkit-scrollbar-track {
                background-clip: content-box;
                border: 0px solid transparent;
                width: 5px;
                background-color: transparent;
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: #333;
            }
        
            &::-webkit-scrollbar-thumb:hover {
                background-color: #333;
            }
        
            &::-webkit-scrollbar-corner,
            &::-webkit-scrollbar-track {
                background-color: transparent;
                width: 5px;
            }

             ul{
                 text-align: left;
                 color: $az-black;
                 li{
                     text-align: left;
                     color: $az-black !important;
                     font-size: $az-font-16 !important;
                     a{
                        word-break: break-all;
                    }
                 }
             }
             p{
                 text-align: left;
                 color: $az-black !important;
                 font-size: $az-font-16 !important;
                 img{
                     max-width: 100% !important;
                 }
                 a{
                    word-break: break-all;
                }
             }
             a{
                word-break: break-all;
            }
             ol{
                 text-align: left;
                 color: $az-black;
                 li{
                     text-align: left;
                     color: $az-black !important;
                     font-size: $az-font-16 !important;
                     a{
                        word-break: break-all;
                    }
                 }
             }
             video{
                 max-width: 100%;
             }
             @media (max-width:767px) {
                 padding: 0 0 0 10px;
             }
         }
 
         .access_btn_box{
             display: flex;
             justify-content: space-around;
             width: 90%;
             margin: 20px auto 0;
 
             @media (max-width:767px) {
                 display: block;
                 margin-top: 15px;
                 width:100%;
             }
             .btn_pre_access {
                 border: none;
                 background: $az-black;
                 color: $az-white;
                 font-size: $az-font-16;
                 border-radius: 50px;
                 padding: 10px 40px;
                 font-family: $az-kini-heading-three;
                 letter-spacing: 1px;
             
                 &:hover,
                 &:focus {
                     border: none;
                     box-shadow: none;
                     outline: none;
                 }
                 @media (max-width:767px) {
                     margin-bottom: 15px;
                     font-size: 19px;
                     padding: 7px 40px;
                     max-width: 90%;
                     margin: auto;
                 }
             }
         }
     }
 
 }
 
 
 .access_arrival_instrution{
     @media (max-width:767px) {
         padding-left: 0 !important;
     }
     .modal-content{
         @media (max-width:767px) {
             border: 0 !important;
             border-radius: 0 !important;
         }
     }
     .modal-body{
         padding: 20px 0;
         position: relative;
         @media (max-width:767px) {
             height: 100vh;
             padding:60px 0 20px 0;
             overflow: auto  ;
         }
         .welcomeClose {
           @media (max-width:767px) {
             position: fixed;
             z-index: 999;
           }
         }
         h1{
             &:first-child{
               @media (max-width:767px) {
                 padding: 20px 0;
                 background-color: $az-white;
                 margin-bottom: 0px !important;
               }
             }
         }
         .timerText{
             font-family: $az-heading-two !important;
             margin: 0 !important;
             font-size: 18px !important;
         }
         .cancellations{
             max-height: 60vh;
             overflow: auto;
             padding:0 20px;
             @media (max-width:1366px) {
                 max-height: 45vh;
             }
 
             @media (max-width:767px) {
                 max-height: 100%;
                 overflow: visible;
             }
             img{
                 margin-top: 15px;
             }
             &::-webkit-scrollbar {
                 width: 10px;
                 height: 5px;
             }
 
             &::-webkit-scrollbar-track {
                 background-clip: content-box;
                 border: 0px solid transparent;
                 width: 10px;
                 background-color: transparent;
             }
 
             &::-webkit-scrollbar-thumb {
                 background-color: #333;
             }
 
             &::-webkit-scrollbar-thumb:hover {
                 background-color: #333;
             }
 
             &::-webkit-scrollbar-corner,
             &::-webkit-scrollbar-track {
                 background-color: transparent;
                 width: 10px;
             }
 
         }
     }
     .modal.show .modal-dialog{
         margin: 0;
     }
     .modal-dialog-centered{
        @media (max-width:767px) {
         margin: 0;
        }
     }
 }
 
 .support_cnt_font{
    padding: 0 20px;
    color: $az-black !important;
    margin-bottom: 30px !important;
    font-size: $az-font-16 !important;
    font-family: $az-heading-two !important;
    @media (max-width:767px) {
        margin-bottom: 20px !important;
    }  
    p{
        span.access_code_support{
            text-decoration: none !important;
            font-family: $az-heading-para !important;
            font-size: $az-font-18 !important;
        }
    }
    .access_code_support{
        text-decoration: none !important;
        font-family: $az-heading-para !important;
        font-size: $az-font-18 !important;
    }
 }

 .reservationBook{
    .support_cnt_font{
        padding: 0 20px;
        color: $az-black !important;
        margin-bottom: 30px !important;
        font-size: $az-font-16 !important;
        font-family: $az-heading-two !important;
        @media (max-width:767px) {
            margin-bottom: 20px !important;
        }  
        p{
            span.access_code_support{
                text-decoration: none !important;
                font-family: $az-heading-para !important;
                font-size: $az-font-18 !important;
            }
        }
        .access_code_support{
            text-decoration: none !important;
            font-family: $az-heading-para !important;
            font-size: $az-font-18 !important;
        }
    }
}
 
 .access_arrival_instrution{
     .width_402{
       .cancellations{
         border: 0 !important;
       }
     }
   }

   .modal-backdrop.white_bg_modal.show{
    @media (max-width:767px) {
        opacity: 1;
        background-color: $az-white;
    }
}

body.resource_body{
    background-color: $az-green-app;
}

.resource_detail_cnt{
    background-color: $az-green-app;
    height:100vh ;
    overflow: auto;
    font-family: $az-Rubik-mobile !important;
    .reservationBook {
        h1{
            font-family: $az-Rubik-mobile !important;
            font-size: $az-font-14 !important;
            color:$az-white !important;
            margin-bottom: 20px !important;
        }
    }
    .resorce_video{
        height: 100% !important;
        width: 100% !important;
        margin-bottom: 15px;
    }
    video{
        height: 100%;
        width: 100%;
    }
    img{
        max-width:100%;
        display: flex;
        margin: 0 auto 20px;
        border-radius: 5px;
    }
   
    ul{
        li {
            font-family: $az-Rubik-mobile !important;
            color: $az-white !important;
            font-size: $az-font-14 !important;  
            span{
                font-family: $az-Rubik-mobile !important;
                font-size: $az-font-14 !important;
                color: $az-white !important; 
            }
            span{
                font-family: $az-Rubik-mobile !important;
                font-size: $az-font-14 !important;
                color: $az-white !important; 
            }
        }
    }
    p{
        font-family: $az-Rubik-mobile !important;
        color: $az-white !important; 
        font-size: $az-font-14 !important;
        line-height:16.59px;
        span{
            font-family: $az-Rubik-mobile !important;
            font-size: $az-font-14 !important;
            color: $az-white !important; 
        }
        video{
            height: 100%;
            width: 100%;
        }
        img{
            max-width: 100%;
            display: flex;
            margin: auto;
            border-radius:5px;
        }
    }
    a{
        font-family: $az-Rubik-mobile !important;
        font-size: $az-font-14 !important;
        color: $az-white !important; 
    }
}


.ltr_video_sec.ltr_detail_video{
    .video_step{
        height: 430px;
        @media (max-width:1024px) {
            height: 515px;
        }

        @media (max-width:991px) {
            height: auto;
        }

    }
    .video_pop_img{
        .styles_fakePreview__1UDw2 {
            img{
                max-height: 430px;
                min-height: 430px;
                @media (max-width:1024px) {
                    min-height: 515px;
                    max-height: 515px;
                }
            }
        }
    }
}

.mg-b-cust-24{
    margin-bottom: 25px !important;
}

.guest-availabity.guest-availabity-deskview.position-relative.leaseMobile{
    @media (max-width:767px) {
       position: unset !important;
       margin: 0;
    }
}

.guest-availabity.guest-availabity-deskview.position-relative.leaseMobile .accordion-item .accordion-body{
    @media (max-width:767px) {
        padding-bottom: 0 !important;
     }
}

main.oh-template.Brandmainpage.wizard_header.wzard_main_bg.parking_via_app{
    @media screen and (max-width: 768px) {
        @supports (-webkit-touch-callout: none) {
            height: 100vh !important;
            display: contents;
        }
      }
}

main.oh-template.Brandmainpage.wizard_header.wzard_main_bg.parking_via_web{
    @media screen and (max-width: 768px) {
        @supports (-webkit-touch-callout: none) {
            height: 100vh !important;
            display: contents !important;
        }
      }
}

main.oh-template.Brandmainpage.wizard_header.wzard_main_bg.parking_via_app .wzrd_middle_desk{
    @media (max-width:767px) {
        height: calc(100vh - 90px) !important;
        max-height: calc(100vh - 90px) !important;
     }
    
     @media screen and (max-width: 768px) {
        @supports (-webkit-touch-callout: none) {
            height: calc(100vh - 160px) !important;
            max-height: calc(100vh - 160px) !important;
        }
     }
}

main.oh-template.Brandmainpage.wizard_header.wzard_main_bg.parking_via_web .wzrd_middle_desk{
    @media (max-width:767px) {
        height: calc(100vh - 160px) !important;
        max-height: calc(100vh - 160px) !important;
     }
    
     @media screen and (max-width: 768px) {
        @supports (-webkit-touch-callout: none) {
            height: calc(100vh - 250px) !important;
            max-height: calc(100vh - 250px) !important;
        }
     }
}

.earn_aptmt_sec .earn_acordion .sectionOne .accordion .earn_rate .MuiFormControl-root .MuiInputBase-input{
        font-family: $az-heading-para !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
        font-family: $az-heading-two !important;
}

.digital-access{
    place-items: center;
}