body {
  margin: 0;
  padding: 0;
  font-family: "Lato-Regular" !important;
  font-size: 14px !important;
  -webkit-font-smoothing: antialiased;
}
:root {
    font-family: "Lato-Regular" !important;
    font-size: 14px !important;
    -webkit-font-smoothing: antialiased;
}
