////////////////////////////////////////////////////////////
//     							                          //
//  Program: Oh_html.scss                                 //
//  Application: scss                                     //
//  Option: Used to create custom css                     //
//  Developer: PS  						                  //
//  Date: 2022-04-12                                      //
//                                                        //
////////////////////////////////////////////////////////////

// KINI Brand style
@import "./oh_corporate_html";


// Custom variables and mixins
@import "./oh_common/variables";
@import "./oh_common/mixins";


/* ############### Fonts ############### */

// OH fonts

@import "../fonts/oh/fonts";

@font-face {
    @include headingBold;
}

@font-face {
    @include headingRg;
}

@font-face {
    @include headingMd;
}

@font-face {
    @include headingRgbd;
}

@font-face {
    @include headingLight;
}


// Kini fonts

// @import "../fonts/kini/fonts";

// @font-face {  @include SummerLovingRegular; }
// @font-face {  @include SummerLovingSolid; }
// @font-face {  @include TanPearl; }


/* ############### Spacing ############### */
@import "./lib/margin";
@import "./lib/padding";

// body{overflow: auto !important;}
.heading5 {
    @include heading5;
}

.heading6 {
    @include heading6;
}

p {
    @include p;
}

.border-b {
    @include borderOne;
}

.text-uppercase {
    text-transform: uppercase;
}

.container {
    @include maincontainer;
}

.col-xxl-4 {
    @media (min-width:1440px) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;

    }
}

// Modal box

.modal-xlg {
    .modal-dialog {

        @include modal-xlg;
    }

}

.modal-xxlg {
    .modal-dialog {

        @include modal-xxlg;
    }

}

// Home page

main.oh-template {
    .container {
        max-width: 1400px;

        @media(max-width:1366px) {
            padding: 0 30px;
        }

        @media(max-width:1280px) {
            padding: 0 30px;
        }

        @media(max-width:1199px) {
            padding: 0 30px;
        }

        @media(max-width:1024px) {
            padding: 0 30px;
        }

        @media(max-width:991px) {
            padding: 0 30px;
        }

        @media(max-width:767px) {
            padding: 0 10px;
        }
    }

    // header
    @include OHtopHead;

    // Middle

    .banner {
        background-image: url("");
        // background-image: url("https://did1d8jg9ar6w.cloudfront.net/cms_upload_image/banner.png");
        min-height: 740px !important;
        height: 740px !important;
        padding: 0;
        background-repeat: no-repeat;
        background-size: 100% !important;
        background-position: bottom 87% right 0 !important;
        position: relative;
        z-index: 1;
        background-color: $az-white !important;

        &.banner_two {
            // background-image: url(https://did1d8jg9ar6w.cloudfront.net/website/kini/banner.jpg);
        }

        @media(max-width: 1920px) {
            // background-size: 120% !important;
            background-size: 100% !important;
            background-position: bottom 87% right 0 !important;
            min-height: 740px !important;
            height: 740px !important;

        }

        @media(max-width: 1366px) {

            // background-size: 120% !important;
            background-size: 100% !important;
            background-position: top 0% right 15% !important;
            min-height: 740px !important;
            height: 740px !important;
        }

        @media(max-width: 1280px) {

            // background-size: 120% !important;
            background-size: 115% !important;
            background-position: top 0% right 15% !important;
            min-height: 740px !important;
            height: 740px !important;
        }

        @media(max-width: 1199px) {
            // background-size: 160% !important;
            background-size: 110% !important;
            background-position: top 0% right 13% !important;
            min-height: 640px !important;
            height: 640px !important;
        }

        @media(max-width: 1023px) {
            // background-size: 130% !important;
            background-size: 130% !important;
            background-position: top 0% right 13% !important;
            min-height: 640px !important;
            height: 640px !important;
        }


        @media(max-width: 991px) {
            background-size: 140% !important;
            background-position: top 0% right 0% !important;
            min-height: 570px !important;
            height: 570px !important;
        }

        @media(max-width: 767px) {
            background-image: url(../images/oh-corporate-img/banner.png);
            background-position: top 0% right 0% !important;
            min-height: 400px !important;
            height: 400px !important;
            background-size: 200% !important;
            background-position: 78% 0 !important;

        }



        // @include Banner;
        &::after {
            position: absolute;
            content: '';
            width: 100% !important;
            height: 740px !important;
            top: 0 !important;
            left: 0 !important;
            transform: translate(0, 0px) !important;
            bottom: 0 !important;
            z-index: 0 !important;
            border-top: 740px solid rgba(0, 0, 0, 0.3) !important;
            border-left: 0 solid transparent !important;
            border-right: 820px solid transparent !important;

            @media(max-width: 1366px) {
                border-top: 740px solid rgba(0, 0, 0, 0.3) !important;
                border-left: 0 solid transparent !important;
                border-right: 725px solid transparent !important;
            }

            @media(max-width: 1280px) {
                border-top: 740px solid rgba(0, 0, 0, 0.3) !important;
                border-left: 0 solid transparent !important;
                border-right: 725px solid transparent !important;
            }

            @media(max-width: 1199px) {
                border-top: 640px solid rgba(0, 0, 0, 0.3) !important;
                border-left: 0 solid transparent !important;
                border-right: 625px solid transparent !important;
            }

            @media(max-width: 1023px) {
                border-top: 640px solid rgba(0, 0, 0, 0.3) !important;
                border-left: 0 solid transparent !important;
                border-right: 625px solid transparent !important;
            }

            @media(max-width: 991px) {
                display: none;
            }

            @media(max-width: 767px) {
                display: none;
            }


        }

        h1 {
            color: $az-white;
            font-family: $az-kini-heading-three;
            margin-top: 15px;
            font-size: $az-font-66;
            text-align: left;
            line-height: 1.2;
            padding: 0px;
            margin-bottom: 0px;
            position: relative;
            z-index: 1;
            text-shadow: 1px 2px 3px #000;
            text-shadow: 1px 2px 3px #000;
            -webikit-text-shadow: 1px 2px 3px #000;
            -moz-text-shadow: 1px 2px 3px #000;
            -o-text-shadow: 1px 2px 3px #000;
            letter-spacing: 0rem;

            @media (max-width:1366px) {
                font-size: $az-font-66;
                padding: 0 0px;
            }

            @media (max-width:1280px) {
                font-size: $az-font-66;
                padding: 0 0px;
            }

            @media (max-width:1199px) {
                font-size: $az-font-50;
                padding: 0px;
            }

            @media (max-width:1023px) {
                font-size: $az-font-50;
                padding: 0px;
            }

            @media (max-width:991px) {
                font-size: $az-font-40;
                padding: 0px;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
            }

            @media (max-width:767px) {
                font-size: $az-font-26;
                padding: 0 5px;
                margin: 0px auto;
                text-align: center;
                line-height: 1.5;
                // display: contents;
            }

        }

        h4 {
            position: relative;
            z-index: 1;
            color: $az-white;
            text-align: left;
            font-size: $az-font-33;
            max-width: 825px;
            margin-top: 20px;
            font-family: $az-heading-two;
            letter-spacing: 0;

            @media(max-width: 1366px) {
                max-width: 800px;

            }

            @media(max-width: 1280px) {
                max-width: 800px;

            }

            @media(max-width: 1199px) {
                max-width: 600px;
                font-size: $az-font-25;

            }

            @media(max-width: 1023px) {
                max-width: 600px;
                font-size: $az-font-24;

            }

            @media (max-width:991px) {
                text-align: center;
                margin-top: 10px;
                font-size: $az-font-18;
                margin-left: auto;
                margin-right: auto;


            }

            @media (max-width:767px) {
                text-align: center;
                margin-top: 10px;
                font-size: $az-font-18;


            }
        }

        .container {

            @media (max-width:991px) {
                justify-content: start !important;
                padding-top: 175px !important;
            }

            @media (max-width:767px) {
                justify-content: start !important;
                padding-top: 120px !important;
            }
        }


        .deskViewBannercontent {
            display: flex !important;
            justify-content: start !important;
            padding-top: 130px;

            @media (max-width:991px) {
                justify-content: flex-start !important;
                padding: 70px 0 !important;
                align-items: center !important;
            }

            @media (max-width:767px) {
                justify-content: flex-start !important;
                padding: 70px 0 !important;
                align-items: center !important;
            }

        }
    }

    .howdoesitwork {
        padding: 70px 15px 40px;
        background: $az-kini-seconday;


        @media (max-width: 991px) {

            padding: 40px 15px 40px;
        }

        @media (max-width: 767px) {

            padding: 40px 15px 40px;
        }

        h2 {
            color: $az-black;
            font-family: $az-kini-heading-three;
            margin: 0;
            font-size: $az-font-75;
            text-align: center;
            line-height: 1;
            padding: 0 140px;
            margin-bottom: 60px;
            text-transform: inherit;

            @media (max-width:1280px) {
                padding: 0 60px;
            }

            @media (max-width:1240px) {

                font-size: $az-font-65;
                padding: 0 30px;


            }

            @media (max-width:1023px) {

                font-size: $az-font-50;
                padding: 0 30px;

            }

            @media (max-width:991px) {
                max-width: 180px;
                padding: 0px;
                max-width: initial;
                font-size: $az-font-50;
            }

            @media (max-width:767px) {
                font-size: $az-font-32;
                padding: 0px;
                max-width: initial;
                margin-bottom: 25px;
            }
        }

        .howwork {
            padding: 0 74px;

            @media (max-width:1440px) {
                padding: 0 40px;
            }

            @media (max-width:1280px) {
                padding: 0 40px;
            }

            @media (max-width:1199px) {
                display: block;
                flex-direction: column;
                padding: 0;
            }

            @media (max-width:991px) {
                display: block;
                flex-direction: column;
                padding: 0;
            }

            @media (max-width:767px) {
                display: flex;
                flex-direction: row;
                padding: 0;
            }


            h1 {
                color: $az-white;
                font-family: $az-kini-heading-three;
                padding: 0;
                margin: 0;
                font-size: $az-font-75;
                text-align: left;
                line-height: 1;
                padding: 0px;
                margin-bottom: 30px;

                @media (max-width:1280px) {
                    font-size: $az-font-75;
                }

                @media (max-width:1023px) {

                    font-size: $az-font-50;

                }

                @media (max-width:991px) {
                    font-size: $az-font-50;
                }

                @media (max-width:767px) {
                    font-size: $az-font-34;
                    width: calc(100% - 245px);
                    padding-top: 2px;

                }

            }

            .mobviewhowwork {
                width: 100%;

                h6 {
                    color: $az-oh-black;
                    font-family: $az-heading-para;
                    padding: 0;
                    margin: 0;
                    font-size: $az-font-18;
                    text-align: left;
                    line-height: 1;
                    padding: 0;
                    margin-bottom: 30px;

                    @media (max-width:767px) {

                        font-size: $az-font-20;
                        margin-bottom: 20px;
                    }

                }

                h6.md {
                    font-family: $az-heading-two;
                }

                p {
                    color: $az-oh-black;
                    font-family: $az-heading-para;
                    margin: 0;
                    font-size: $az-font-16;
                    text-align: left;
                    line-height: 1.5;
                    padding: 0px;
                    margin-bottom: 15px;

                    @media (max-width:767px) {
                        font-size: $az-font-14;
                    }
                }
            }
        }

        .bd-r {
            border-right: 1px solid $az-white;
            padding-bottom: 38px;

            @media (max-width:1199px) {
                border-right: 1px solid $az-white;
                padding: 0 25px;
            }

            @media (max-width:991px) {
                border-right: 1px solid $az-white;
                padding: 0 25px;
            }

            @media (max-width:767px) {
                border-right: none;
            }

            &:last-child {
                border-right: none;

                @media (max-width:767px) {
                    border-right: none;
                }
            }
        }
    }

    .we-actually {
        background-image: url("./../images/banner-2.jpg");
        // background-image: url(https://did1d8jg9ar6w.cloudfront.net/website/kini/banner-2.jpg);
        padding: 112px 0 !important;
        height: inherit !important;
        min-height: inherit !important;
        background-size: 100% !important;
        background-position: top 52% right 0 !important;

        @media (max-width:1366px) {

            padding: 55px 0 !important;
        }

        @media (max-width:1280px) {
            background-size: 130% !important;
            background-position: top 51% right 38% !important;
            padding: 140px 0 !important;
        }

        @media (max-width:991px) {
            padding: 50px 0 !important;
            background-size: cover !important;
            background-position: center !important;
        }

        @media (max-width:767px) {
            padding: 50px 0 !important;
            background-size: 235% !important;
            background-position: 60% 60% !important;
        }

        @include Banner;

        .mobileweactully {

            @media (max-width:767px) {
                padding: 0 10px !important;
                max-width: 100%;
            }

            .deskweactullyHeading {
                display: block;

                @media (max-width:767px) {
                    display: none;
                }
            }

            .mobileweactullyHeading {
                display: none;

                @media (max-width:767px) {
                    display: block;
                }

            }

            h1 {
                margin-bottom: 20px;
                line-height: 1;
                padding: 0 190px;

                @media (max-width:1280px) {
                    margin-bottom: 20px;
                    line-height: 1;
                    padding: 0 190px;
                    margin-top: 30px;
                }

                @media (max-width:1240px) {

                    padding: 0 90px;
                    margin-top: 30px;

                }

                @media (max-width:991px) {
                    font-size: 50px !important;
                    padding: 0 55px;
                    margin-top: 20px;
                }

                @media (max-width:767px) {
                    font-size: 32px !important;
                    padding: 0 20px;
                }
            }
        }

        h5 {
            color: $az-white;
            font-family: $az-heading-two;
            padding: 0 185px;
            font-size: $az-font-32;
            line-height: 1;
            margin-bottom: 60px;
            margin-top: 20px;
            text-align: center;
            line-height: 1.2;
            z-index: 1;

            @media (max-width:1240px) {
                padding: 0 15px;
                font-size: $az-font-22;
                margin-top: 0;
            }

            @media (max-width:1023px) {
                padding: 0 115px;

            }

            @media (max-width:991px) {
                padding: 0px 15px 0;
                font-size: $az-font-24;
                font-family: $az-heading-light;

            }

            @media (max-width:767px) {
                padding: 15px 15px 0;
                font-size: $az-font-16;
                font-family: $az-heading-light;
                margin-bottom: 30px;
            }

        }
    }

    .city-reimagined {
        background-image: url("./../images/banner-3.png");
        // background-image: url(https://did1d8jg9ar6w.cloudfront.net/website/kini/banner-3.png);
        background-repeat: no-repeat;
        position: relative;
        background-position: center;
        min-height: 821px !important;
        height: 821px !important;

        @media (max-width:1280px) {
            min-height: 720px;
            max-height: 720px;
        }

        @media (max-width:1023px) {
            min-height: 615px;
            height: 615px;
        }

        @media (max-width:991px) {
            padding: 115px 15px 85px;
            height: auto !important;
            min-height: auto !important;
            margin-bottom: 0px !important;
            max-height: 100%;
        }

        @media (max-width:767px) {
            padding: 115px 15px 85px;
            height: auto !important;
            min-height: auto !important;
            margin-bottom: 0px !important;
            max-height: 100%;

        }

        @include Banner;

        h1 {
            margin-bottom: 20px;

            @media (max-width:1280px) {
                padding: 0 150px;
                margin-top: 30px;
            }

            @media (max-width:1199px) {
                padding: 0 150px;
            }

            @media (max-width:991px) {
                padding: 0 150px;
                font-size: $az-font-50;
            }

            @media (max-width:767px) {
                padding: 0px;
                margin-bottom: 0;
                font-size: $az-font-32;
            }
        }

        p {
            color: $az-white;
            font-family: $az-heading-two;
            padding: 15px 80px 0px;
            font-size: $az-font-32;
            line-height: 1;
            margin-bottom: 60px;
            text-align: center;
            line-height: 1.2;

            @media (max-width:1240px) {
                padding: 0px 15px 0px;
                font-size: $az-font-22;


            }

            @media (max-width:991px) {
                padding: 0px 15px 0px;
                font-size: $az-font-24;
                font-family: $az-heading-light;
                margin-top: 0 !important;

            }

            @media (max-width:767px) {
                padding: 20px 15px 0px;
                font-size: $az-font-16;
                font-family: $az-heading-light;
                margin-bottom: 30px;
            }
        }

        .mobviewcityReimagined {

            @media (max-width:991px) {
                padding: 0 10px !important;

            }

            @media (max-width:767px) {
                padding: 0 10px !important;

            }
        }

        .chat-calender-icons {
            position: absolute;
            right: 0;
            bottom: 0;
            display: flex;
            z-index: 1;


            @media (max-width:991px) {
                right: auto;
                left: auto;
                bottom: 25px;
                display: none;

            }

            @media (max-width:767px) {
                right: auto;
                left: auto;
                bottom: 25px;
                display: none;

            }

            a {
                background: $az-oh-black;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 15px 28px 15px;
                margin: 15px 15px 30px 0;
                color: $az-white;
                text-align: center;
                text-decoration: none;
                font-family: $az-kini-heading-three;
                font-size: $az-font-12;

                @media (max-width:1280px) {
                    padding: 15px 28px 15px;

                }

                @media (max-width:1240px) {
                    padding: 10px 20px;

                }


                &:last-child {

                    @media (max-width:991px) {
                        margin: 15px 0px 30px;

                    }

                    @media (max-width:767px) {
                        margin: 15px 0px 30px;

                    }

                }

                @media (max-width:991px) {
                    padding: 15px 30px 20px;

                }

                @media (max-width:767px) {
                    padding: 15px 30px 20px;

                }

                img {

                    @media (max-width:1240px) {
                        max-width: 32px;
                    }

                    @media (max-width:767px) {
                        max-width: 20px;

                    }

                }
            }
        }

        button {
            background: $az-oh-seconday;
            color: $az-oh-black;
            padding: 17px 30px;

            @media (max-width:991px) {
                padding: 24px 43px;
            }

            @media (max-width:767px) {
                padding: 15px !important;
            }


        }
    }

    .liveHere-stay {
        background-image: url("");
        // background-image: url(https://did1d8jg9ar6w.cloudfront.net/website/kini/listing-banner.png);
        background-repeat: no-repeat;
        position: relative;
        background-position: center;
        min-height: 410px !important;
        height: 410px !important;
        ;

        @media (max-width:1023px) {
            // min-height: 615px;
            // height: 615px;
            min-height: 400px !important;
            height: 400px !important;
        }

        @media (max-width:991px) {
            // min-height: 450px;
            // height: 450px;
            min-height: 400px !important;
            height: 400px !important;
        }

        @media (max-width:767px) {
            min-height: 350px;
            height: 350px;
        }


        @include Banner;

        h1 {
            margin-bottom: 20px;
            padding: 0px 215px;
            margin-top: -50px !important;
            font-size: $az-font-66;
            // @media (max-width:1280px) {
            //     padding: 0px 330px;
            //     margin-top: 30px;
            // }
            @media (max-width:1199px) {
                font-size: $az-font-50;
            }

            @media (max-width:991px) {
                // padding: 0px;
                // font-size: $az-font-50;
                // margin-top: 0 !important;
                padding: 0px;
                font-size: $az-font-40;
                line-height: 1.5;

            }

            @media (max-width:767px) {
                // padding: 0px;
                // font-size: $az-font-32;
                // margin-bottom: 0;
                padding: 0px;
                font-size: $az-font-26;
                line-height: 1.5;
                margin-bottom: 0;
            }
        }

        h4 {
            position: relative;
            z-index: 1;
            color: $az-white;
            text-align: left;
            font-size: $az-font-33;
            margin-top: 20px;
            font-family: $az-heading-two !important;
            letter-spacing: 0;

            @media(max-width: 1199px) {
                font-size: $az-font-25;
            }
            @media(max-width: 1023px) {
                font-size: $az-font-24;
            }
            @media (max-width:991px) {
                margin-top: 10px;
                font-size: $az-font-18;
            }

            @media (max-width:767px) {
                text-align: center;
                margin-top: 10px;
                font-size: $az-font-18;
            }
        }

        span {
            position: relative;
            z-index: 1;
            color: $az-white;
            text-align: center;
            font-size: $az-font-33;
            margin-top: 20px;
            font-family: $az-heading-two !important;
            letter-spacing: 0;
            padding: 0 100px 40px;

            @media(max-width: 1199px) {
                font-size: $az-font-25;
            }
            @media(max-width: 1023px) {
                font-size: $az-font-24;
            }
            @media (max-width:991px) {
                margin-top: 10px;
                font-size: $az-font-18;
            }

            @media (max-width:767px) {
                text-align: center;
                margin-top: 10px;
                font-size: $az-font-18;
                padding: 0 10px 40px;
            }
        }

        h2 {
            margin-bottom: 20px;
            padding: 0px 215px;
            font-size: $az-font-60;
            color: $az-white;
            font-family: $az-heading-one;
            z-index: 1;

            // text-transform: uppercase;
            @media (max-width:1280px) {
                padding: 0px 130px;
            }

            @media (max-width:991px) {
                padding: 0px;
                font-size: $az-font-32;
                line-height: 1.5;
            }

            @media (max-width:767px) {
                padding: 0px;
                font-size: $az-font-24;
                line-height: 1.5;
                margin-bottom: 0;
            }
        }

        p {
            color: $az-white;
            font-family: $az-heading-two;
            padding: 0 125px;
            font-size: $az-font-35;
            margin-top: 20px;
            margin-bottom: 60px;
            text-align: center;
            line-height: 1.2;

            @media (max-width:1280px) {
                padding: 0 145px;
                font-family: $az-heading-two;
            }


            @media (max-width:991px) {
                padding: 0px;
                font-size: $az-font-24;
                margin-top: 0;
                font-family: $az-heading-light;
            }

            @media (max-width:767px) {
                padding: 15px 15px 0;
                font-size: $az-font-16;
                margin-bottom: 30px;
                font-family: $az-heading-light;
            }
        }

        h5 {
            color: $az-white;
            font-family: $az-kini-heading-three !important;
            padding: 0 260px 60px;
            font-size: $az-font-40;
            margin-top: 0px;
            margin-bottom: 40px;
            text-align: center;
            line-height: 1.2;
            z-index: 1;

            @media (max-width:1280px) {
                padding: 0px 245px 100px;
                font-size: $az-font-36;

            }

            @media (max-width:1199px) {
                padding: 0px 100px;

            }

            @media (max-width:991px) {
                padding: 0px 20px 75px;
                font-size: $az-font-24;
                margin-top: 0;
            }

            @media (max-width:767px) {
                padding: 0px 20px 75px;
                font-size: $az-font-24;
                margin-bottom: 30px;
            }
        }

        h6 {
            color: $az-white;
            font-family: $az-heading-two;
            padding: 0 260px;
            font-size: $az-font-35;
            margin-top: 0px;
            margin-bottom: 40px;
            text-align: center;
            line-height: 1.2;
            font-size: $az-font-24;
            z-index: 1;

            @media (max-width:1280px) {
                padding: 0px 245px;
                font-size: $az-font-24;
                font-family: $az-heading-two;

            }

            @media (max-width:1199px) {
                padding: 0px 100px;

            }

            @media (max-width:991px) {
                padding: 0px;
                font-size: $az-font-24;
                margin-top: 0;
                font-family: $az-heading-light;
            }

            @media (max-width:767px) {
                padding: 0px 5px;
                font-size: $az-font-16;
                margin-bottom: 30px;
                font-family: $az-heading-light;
            }
        }



        button {

            @include OHprimaybutton-xs;
        }

    }

    .aminintyMain {
        background-image: url("./../images/aminity.jpg");
        // background-image: url(https://did1d8jg9ar6w.cloudfront.net/website/kini/aminity.jpg);
        background-repeat: no-repeat;
        position: relative;
        background-position: center;
        min-height: 350px !important;
        height: 350px !important;
        @include Banner;

        @media (max-width:1280px) {
            min-height: 645px;
            height: 645px;
        }


        @media (max-width:1023px) {
            min-height: 615px;
            height: 615px;
        }

        @media (max-width:991px) {
            min-height: 450px;
            height: 450px;
        }

        @media (max-width:767px) {
            min-height: 300px;
            height: 300px;
        }



        h1 {
            margin-bottom: 20px;
            padding: 0px 185px;

            @media (max-width:1280px) {
                padding: 0px 185px;
                margin-top: 30px;
            }

            @media (max-width:991px) {
                padding: 0px;
                font-size: $az-font-50;
                margin-top: 0 !important;

            }

            @media (max-width:767px) {
                padding: 0px;
                font-size: $az-font-32;
                margin-bottom: 0;
            }
        }

        h2 {
            margin-bottom: 20px;
            padding: 0px 215px;
            font-size: $az-font-60;
            color: $az-white;
            font-family: $az-heading-one;
            z-index: 1;

            // text-transform: uppercase;
            @media (max-width:1280px) {
                padding: 0px 130px;
            }

            @media (max-width:991px) {
                padding: 0px;
                font-size: $az-font-32;
                line-height: 1.5;
            }

            @media (max-width:767px) {
                padding: 0px;
                font-size: $az-font-24;
                line-height: 1.5;
                margin-bottom: 0;
            }
        }

        p {
            color: $az-white;
            font-family: $az-heading-two;
            padding: 0 125px;
            font-size: $az-font-35;
            margin-top: 20px;
            margin-bottom: 60px;
            text-align: center;
            line-height: 1.2;

            @media (max-width:1280px) {
                padding: 0 145px;
                font-family: $az-heading-two;
            }


            @media (max-width:991px) {
                padding: 0px;
                font-size: $az-font-24;
                margin-top: 0;
                font-family: $az-heading-light;
            }

            @media (max-width:767px) {
                padding: 15px 15px 0;
                font-size: $az-font-16;
                margin-bottom: 30px;
                font-family: $az-heading-light;
            }
        }

        h6 {
            color: $az-white;
            font-family: $az-heading-two;
            padding: 0 360px;
            font-size: $az-font-35;
            margin-top: 0px;
            margin-bottom: 40px;
            text-align: center;
            line-height: 1.2;
            font-size: $az-font-24;
            z-index: 1;

            @media (max-width:1280px) {
                padding: 0px 245px;
                font-size: $az-font-24;
                font-family: $az-heading-two;

            }

            @media (max-width:1199px) {
                padding: 0px 100px;

            }

            @media (max-width:991px) {
                padding: 0px;
                font-size: $az-font-24;
                margin-top: 0;
                font-family: $az-heading-light;
            }

            @media (max-width:767px) {
                padding: 0px 5px;
                font-size: $az-font-16;
                margin-bottom: 30px;
                font-family: $az-heading-light;
            }
        }

        button {

            @include OHprimaybutton-xs;
        }

    }

    .blockOne {
        @include blockOne;
    }

    .blockTwo {
        @include blockOne;

        .Left {
            padding: 0 40px 0 50px;
            background: $az-white;

            @media (max-width:1366px) {
                padding: 0 25px 0 50px !important;
            }

            @media (max-width:1280px) {
                padding: 0 30px 0 30px !important;
            }

            @media (max-width:991px) {
                padding: 0 15px 0 15px !important;
            }

            @media (max-width:767px) {
                padding: 65px 30px 35px !important;
                min-height: 190px !important;

            }

            h2 {
                font-size: $az-font-34;
                color: $az-oh-black;
                padding: 0 30px 0 0;

                @media (max-width:1366px) {
                    font-size: $az-font-34;
                }

                @media (max-width:1280px) {
                    font-size: $az-font-45;
                }

                @media (max-width:1240px) {
                    font-size: $az-font-36;
                }

                @media (max-width:991px) {
                    font-size: $az-font-32;
                }

                @media (max-width:767px) {
                    font-size: $az-font-24;
                }
            }

            p {
                color: $az-oh-primary;

                @media (max-width:1280px) {
                    padding: 0 12px 0 0px;
                }

            }
        }

        .Right {
            background-image: url("./../images/homesharing.png");
            // background-image: url(https://did1d8jg9ar6w.cloudfront.net/website/kini/homesharing.png);
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            background-size: 100% 100%;

            @media (max-width:1366px) {
                background-size: 100% 100%;
            }

            @media (max-width:767px) {
                background-size: 100% 100%;
                min-height: 300px;
                height: 300px;
                max-height: 300px;
            }

        }

        .row {
            @media (max-width:767px) {
                flex-direction: column-reverse;
            }
        }
    }

    .blockThree {

        @include blockOne;

        .Left {
            @media (max-width:1280px) {
                padding: 0 85px 0 85px;

                h2 {
                    @media (max-width:767px) {
                        padding: 0 !important;
                    }
                }
            }
        }

        .Right {
            background-image: url('./../images/hosts.png');
            // background-image: url(https://did1d8jg9ar6w.cloudfront.net/website/kini/hosts.png);
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            background-size: 100% 100%;

            @media (max-width:1366px) {
                background-size: 100% 100%;
            }

            @media (max-width:767px) {
                background-size: 100% 100%;
                min-height: 300px;
                height: 300px;
                max-height: 300px;
            }
        }

    }

    .blockFour {
        @include blockOne;

        .Left {
            padding: 0 125px 0 !important;
            background: $az-white;


            h2 {
                color: $az-oh-black !important;
                line-height: 1 !important;

                @media (max-width:1240px) {

                    padding: 0 90px 0 0 !important;

                }


            }

            p {
                color: $az-oh-primary;
            }

            @media (max-width:1366px) {
                padding: 0 40px !important;
            }

            @media (max-width:1280px) {
                padding: 0 40px 0 85px !important;

            }

            @media (max-width:991px) {
                padding: 0 30px !important;
            }

            @media (max-width:767px) {
                padding: 0 30px !important;
            }

            button {
                @include OHprimaybutton-sm;

                @media (max-width:767px) {
                    margin-bottom: 10px;
                }
            }

            .mobviewbtnCenter {

                @media (max-width:991px) {
                    justify-content: start;
                    margin: 0;
                }

                @media (max-width:767px) {
                    justify-content: start;
                    margin: 0;
                    flex-wrap: wrap;
                }

            }
        }

        .Right {
            background-image: url('./../images/live_here.png');
            // background-image: url(https://did1d8jg9ar6w.cloudfront.net/website/kini/live_here.png);
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            background-size: 100% 100%;

            @media (max-width:1366px) {
                background-size: 100% 100%;
            }

            @media (max-width:767px) {
                background-size: 100% 100%;
                min-height: 300px;
                height: 300px;
                max-height: 300px;
            }

        }

        .row {
            @media (max-width:767px) {
                flex-direction: column-reverse;
            }
        }
    }

    .blockFive {
        @include blockOne;

        .Left {
            button {
                @include OHprimaybutton-sm;
                max-width: 245px;

                @media (max-width:991px) {
                    margin-bottom: 10px;
                    max-width: 160px;
                    min-width: 160px;
                }

                @media (max-width:767px) {
                    margin-bottom: 10px;
                    max-width: 140px;
                    min-width: 140px;
                    padding: 9px 6px 10px !important;
                }

            }

            .mobviewbtnCenter {

                @media (max-width:991px) {
                    justify-content: start;
                    margin: 0;
                }

                @media (max-width:767px) {
                    justify-content: start;
                    margin: 0;
                    flex-wrap: wrap;

                }

            }

            h2 {
                @media (max-width:767px) {
                    padding: 0 !important;
                }
            }
        }

        .Right {
            background-image: url('./../images/hosting_features.png');
            // background-image: url(https://did1d8jg9ar6w.cloudfront.net/website/kini/hosting_features.png);
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            background-size: 100% 100%;

            @media (max-width:1366px) {
                background-size: 100% 100%;
            }
        }

    }

    .fullblock {
        @include fullblock;
    }

    // Listing page
    .filter {
        @media (max-width:991px) {
            padding-top: 40px !important;
        }

        @media (max-width:767px) {
            padding-top: 40px !important;
        }

        .filterBlock {
            ul {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                padding: 0;

                li {
                    list-style: none;
                    align-items: center;
                    display: flex;
                    width: calc(100% / 6);

                    .dropmenuInputbox {
                        margin: 8px;
                        width: 100%;

                        .dropdown {
                            width: 100%;
                        }
                    }

                    @media (max-width:991px) {
                        width: calc(100% / 2);
                    }

                    @media (max-width:767px) {
                        width: calc(100% / 2);
                    }

                }
            }

            button.done {
                border: none;
                background: $az-black;
                color: $az-white;
                font-size: $az-font-14 !important;
                font-family: $az-kini-heading-three !important;
                outline: none;
                margin-top: 15px;
                padding: 2px 15px;
                border-radius: 4px;
                text-transform: inherit;
            }

        }

        .filterBlock2 {
            position: relative;
            z-index: 1;

            ul {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                padding: 0;

                @media (max-width:767px) {
                    justify-content: flex-start;
                }

                li {
                    list-style: none;
                    align-items: center;
                    display: flex;
                    width: calc(100% / 6);

                    @media (max-width:991px) {
                        width: calc(100% / 2);
                    }

                    @media (max-width:767px) {
                        width: calc(100% / 2);
                    }


                    .dropmenuInputbox {
                        margin: 8px;
                        width: 100%;

                        .dropdown {
                            width: 100%;
                        }
                    }

                }
            }

            button.done {
                border: none;
                background: $az-oh-primary;
                color: $az-white;
                font-size: $az-font-14 !important;
                font-family: $az-heading-one !important;
                outline: none;
                margin-top: 15px;
                padding: 2px 15px;
            }

        }

        .floorPlanList {

            img {
                max-width: 100%;
                background: #fff !important;
                // min-height: 500px;
                // max-height: 500px;

                // @media (max-width:1200px) {
                //     max-width: 550px;

                // }

                // @media (max-width:1024px) {
                //     max-width: 500px;

                // }
            }

            .content {

                span {

                    h6 {
                        font-family: $az-heading-two;
                        font-size: $az-font-14;
                        color: $az-oh-primary;
                        text-transform: uppercase;
                        text-align: center;
                    }

                    h5 {
                        font-family: $az-kini-heading-three;
                        font-size: $az-font-20;
                        color: $az-black;
                        text-align: left;
                    }

                    h4 {
                        font-family: $az-kini-heading-three;
                        font-size: $az-font-60;
                        color: $az-black;
                        margin-bottom: 0;
                        height: 85px;
                        position: relative;
                        top: 15px;

                    }

                    ul {
                        list-style: none;
                        padding: 5px 0 0;
                        margin-bottom: 0;

                        li {
                            display: flex;
                            justify-content: flex-start;

                            i {
                                font-family: $az-heading-two;
                                font-size: $az-font-14;
                                color: $az-oh-primary;
                                font-style: normal;
                                text-transform: uppercase;
                                min-width: 85px;
                                text-align: left;
                            }

                            b {
                                color: $az-oh-primary;
                                text-transform: uppercase;
                            }
                        }
                    }

                    &:first-child {
                        border-right: 1px solid $az-oh-primary;

                    }
                }

            }

            button {

                @include OHprimaybutton-sm;

            }
        }

    }

    footer {
        @include footer;
        text-align: left;
    }

    footer.footertwo {
        @include footertwo;
        text-align: center;
        display: none;
    }

    .floorPlanList {
        margin: auto;

        .listBlock {
            position: relative;

            &::before {
                content: '';
                bottom: 0;
                position: absolute;
                width: 100%;
                height: 1px;
                left: 50%;
                transform: translate(-50%, 0px);
                border-bottom: 1px solid #eaeaea;
            }

            &:last-child {
                &::before {
                    display: none;
                }
            }

            figure {
                max-width: 100% !important;
                background: #f5f5f5 !important;

                .floor-img {
                    max-width: 100% !important;
                    max-height: 420px !important;
                }
            }

            .contentOne ul {
                padding: 10px 0px;
                justify-content: space-between;
                margin-bottom: 0;

                li {
                    list-style: none;

                    h6 {
                        font-size: 20px;

                        @media (max-width: 1366px) {
                            font-size: 18px;
                        }

                        @media (max-width: 1199px) {
                            font-size: 18px;
                        }
                    }
                }
            }

            .contentTwo {
                ul {
                    column-count: 3;
                    text-align: left;
                    padding-bottom: 0px;
                    font-size: 14px;
                    list-style: none;
                    padding-left: 0;

                    ul:last-child {
                        padding: 0 0px 0 0;
                    }

                    li {
                        list-style: none;
                        margin-bottom: 5px;
                        position: relative;
                        padding-left: 12px;

                        &::before {
                            content: '';
                            position: absolute;
                            background-image: url(../images/kini/dot.svg);
                            background-repeat: no-repeat;
                            left: 0;
                            top: 12px;
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }
}

// Form scss

.filterMultiselect {
    @include Multiselect;
}

.dropmenuInputbox {
    @include dropmenuInputbox;
}

ul.MuiMenu-list {
    li {
        span {
            color: $az-oh-primary !important;
            font-size: $az-font-14 !important;
            font-family: $az-heading-two !important;
            padding: 5px 5px 5px 0 !important;
        }
    }
}

.movedate {

    @include datepiker;
}

.datepickermove {

    @include datepiker;
    position: relative;
    padding: 0px !important;
    z-index: 0;
    height: 40px;


    label {
        margin-left: auto;
        margin-right: auto;
        font-family: Lato-Regular;
        float: inherit;
        position: absolute;
        top: 0 !important;
        z-index: 1;
        background: #fff;
        padding: 0px 8px;
        font-size: 0.75em;

        @media (max-width:767px) {
            padding: 0px 5px;
        }

    }

    .react-datepicker-wrapper {
        position: absolute;
        left: 0;

        input {

            outline: none !important;
            box-shadow: none !important;
            padding: 10px 14px 12px !important;
            width: 100% !important;
            height: 40px;
            display: block;
            border: 1px solid rgba(0, 0, 0, 0.23);

            @media (max-width:767px) {

                padding: 12px 14px 12px !important;
            }

            &:hover {
                border: 1px solid $az-black;

            }

            &:focus {
                border: 1px solid $az-oh-seconday !important
            }

        }
    }


}

.filter {
    .filterBlock {
        padding: 0 60px;
        position: relative;
        z-index: 1;

        @media (max-width:1366px) {
            padding: 0 15px;
        }
    }
}

//Tabel

.simpleTable {

    @media(max-width:767px) {
        overflow: auto !important;
        max-height: 349px !important;
        min-height: 300px;

    }

    @include simpleTable;

    table {
        @media(max-width:767px) {
            display: block;
            max-width: 100%;
            min-width: 100%;
        }

        thead tr {
            @media(max-width:767px) {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }
        }

        tbody {
            @media(max-width:767px) {
                display: block;
                max-width: 100%;
                min-width: 100%;
            }

            td {
                @media(max-width:767px) {
                    border: none;
                    border-bottom: 1px solid $az-border-color;
                    position: relative;
                    padding-left: 50%;
                    white-space: normal;
                    text-align: left;


                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0px;
                        width: 45%;
                        padding-right: 10px;
                        white-space: nowrap;
                        text-align: left;
                        font-weight: bold;
                        content: attr(data-title);
                        background: $az-oh-seconday;
                        height: 100%;
                        padding-top: 15px;
                        padding-left: 15px;
                        color: $az-black;
                    }

                    &:last-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }

    }
}

// Right Drawer

// LTR Unit Listing

.ltrunitListing {

    h4 {
        font-family: $az-heading-one;
        font-size: $az-font-30;
        color: $az-oh-primary;
        text-align: left;
        margin-bottom: 10px;
    }

    ul.subTitle {
        display: flex;
        padding: 0;

        li {
            list-style: none;
            border-right: 1px solid $az-oh-primary;
            padding-right: 10px;

            &:last-child {
                border-right: 0;
                padding-left: 10px;
            }

            h6 {
                font-family: $az-heading-two;
                font-size: $az-font-18;
                color: $az-oh-primary;
                text-align: left;
                margin-bottom: 0;
            }
        }
    }

}


.MuiDrawer-paper {
    background: rgba(0, 0, 0, 0.7) !important;
    overflow-x: hidden;
    overflow-y: auto !important;

    .MuiTypography-body1 {
        color: $az-white;
        font-size: $az-font-25;
        font-family: $az-heading-one;

    }

    h5.toll-free,
    button,
    hr.hrline {
        display: none;
    }

    .btn-close {
        display: block !important;
        width: 20px !important;
        height: 20px !important;
        background-image: url(../images/kini/icon-close-drawer.svg);
        background-size: 100%;
        background-size: 100%;
        opacity: 1;
        position: absolute;
        top: 15px;
        right: 5%;

        &:hover,
        &:focus {
            box-shadow: none;
            outline: none;
            border: none;
        }
    }

    @media(max-width:991px) {
        h5.toll-free {
            display: block;
            color: $az-white;
            font-size: $az-font-20;
            font-family: $az-kini-heading-three;
            text-align: center;
            margin-bottom: 30px;
        }

        button {
            display: block;
            @include OHprimaybutton;
            background: $az-oh-seconday;
            width: 180px;
            display: flex;
            justify-content: center;

            @media (max-width:991px) {
                margin: auto !important;
                font-size: $az-font-18;
                height: 48px;
                padding: 9px 20px 10px;
            }

            @media (max-width:767px) {
                margin: auto !important;
                font-size: $az-font-18;
                height: 48px;
                padding: 9px 20px 10px;
            }

        }

        .btn-close {
            background-image: url(../images/kini/icon-close-drawer.svg);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 0;
            opacity: 1;
            top: 41%;
            right: 57px;
            height: 30px;
            width: 30px;
            border: none;
            background-color: transparent;
            margin-right: 0px;


            @media (max-width:991px) {
                top: 10px;
                right: 5%;
                position: absolute;
                background-size: 80%;
            }

            @media (max-width:767px) {
                top: 10px;
                right: 5%;
                position: absolute;
                background-size: 80%;
            }

        }

        hr {
            display: none !important;
            border-bottom: 1px solid #fff;
        }

        hr.hrline {
            display: block !important;
            border-bottom: 1px solid #fff;
            width: 80%;
            margin: 40px auto 0;
        }

    }

    @media(max-width:767px) {
        h5.toll-free {
            display: block;
            color: $az-white;
            font-size: $az-font-20;
            font-family: $az-kini-heading-three;
            text-align: center;
            margin-bottom: 30px;
        }

        button {
            display: block;
            @include OHprimaybutton;
            background: $az-oh-seconday;
            width: 215px;
            display: flex;
            justify-content: center;

            @media (max-width:991px) {
                margin: auto !important;
                font-size: $az-font-18;
                height: 48px;
                padding: 9px 20px 10px;
            }

            @media (max-width:767px) {
                margin: auto !important;
                font-size: $az-font-18;
                height: 48px;
                padding: 9px 20px 10px;
            }

        }

        .btn-close {
            background-image: url(../images/kini/icon-close-drawer.svg);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 0;
            opacity: 1;
            top: 41%;
            right: 57px;
            height: 30px;
            width: 30px;
            border: none;
            background-color: transparent;
            margin-right: 0px;


            @media (max-width:991px) {
                top: 10px;
                right: 5%;
                position: absolute;
                background-size: 80%;
            }

            @media (max-width:767px) {
                top: 10px;
                right: 5%;
                position: absolute;
                background-size: 80%;
            }

        }

        hr {
            display: none !important;
            border-bottom: 1px solid #fff;
        }

        hr.hrline {
            display: block !important;
            border-bottom: 1px solid #fff;
            width: 80%;
            margin: 40px auto 0;
        }

    }

    hr {
        display: none;
    }
}


// STR Listing Page

.strPlanList {
    .listBlock {
        position: relative;

        @media (max-width:767px) {
            padding-top: 20px !important;
        }

        &::before {
            content: '';
            bottom: 0;
            position: absolute;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid #eaeaea !important;
            left: 50%;
            transform: translate(-50%, 0px);

            @media (max-width:1440px) {

                width: 100%;
            }
        }

        &:last-child {


            &::before {
                border: none;
            }
        }


        .strContent {
            h5 {
                text-align: left;
                line-height: 1.5;
            }

            .contentOne {
                padding: 0;

                ul {
                    padding: 10px 55px 10px 0;
                    justify-content: space-between;
                    margin-bottom: 0;

                    @media(max-width:991px) {
                        flex-wrap: wrap;
                        padding: 15px 10px 0px 10px;
                        text-align: left;
                        margin-bottom: 20px;
                    }

                    @media(max-width:767px) {
                        flex-wrap: wrap;
                        padding: 15px 0px 15px;
                        text-align: left;
                        margin-bottom: 0px;
                    }

                    li {
                        list-style: none;

                        @media(max-width:991px) {
                            width: calc(100% / 2);
                            padding-right: 10px;
                        }

                        @media(max-width:767px) {
                            width: calc(100% / 2);
                            padding-right: 10px;
                        }

                        h6 {
                            font-size: 20px;

                            @media (max-width: 1366px) {
                                font-size: 18px;
                            }

                            @media (max-width: 1199px) {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            .contentTwo {
                // display: flex;
                column-count: 3;
                text-align: left;
                padding-bottom: 0px;
                font-size: $az-font-14;
                list-style: none;




                @media (max-width:767px) {
                    font-size: $az-font-14;
                    column-count: 2;
                }

                ul {
                    padding: 0 15px 0 0;

                    &:last-child {
                        padding: 0 0px 0 0;
                    }

                    li {
                        list-style: none;
                        margin-bottom: 5px;
                        position: relative;
                        padding-left: 12px;

                        p {
                            margin-bottom: 0;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            background-image: url(../images/kini/dot.svg);
                            background-repeat: no-repeat;
                            left: 0;
                            top: 12px;
                            width: 15px;
                            height: 15px;

                            @media (max-width:767px) {
                                top: 9px;
                            }

                        }
                    }
                }



            }

            span.btn {
                text-align: left;
                display: table;
                font-family: $az-heading-two;
                font-weight: 600;
                margin-bottom: 15px;
                padding-left: 0;

                @media (max-width:767px) {
                    margin-top: 0px;
                }
            }

            @media (max-width:767px) {
                padding: 0 15px;

            }


        }

        button {
            @include OHprimaybutton-sm;

        }

        .StrListImg {
            max-width: 100% !important;
            max-width: 420px;

            img {
                max-width: 100% !important;
                max-height: 420px;

            }
        }

        h3 {
            font-size: $az-font-50;
            font-family: $az-kini-heading-three;
            color: $az-oh-primary;
            // letter-spacing: -2.2px;

            @media (max-width:767px) {
                top: 9px !important;
                position: relative;

            }

            sup {
                font-size: $az-font-30;
                letter-spacing: 0;
                top: -6px;
            }

            b {
                font-family: $az-kini-heading-one;
                font-size: $az-font-49;
                letter-spacing: 0;
                top: -6px;
                margin: 0 1px 0 3px;
            }

            sub {
                font-size: $az-font-17;
                font-family: $az-heading-two;
                letter-spacing: 0;
                left: -5px;

                @media (max-width:767px) {
                    bottom: 0px !important;

                }
            }
        }

        .row {
            .col-md-6 {
                @media (max-width:767px) {
                    padding: 0px !important;
                    margin-bottom: 20px !important;
                }

                span {
                    @media (max-width:767px) {
                        img {
                            max-width: 17px;
                            margin-right: 0px;
                        }
                    }
                }

                p {
                    @media (max-width:767px) {
                        margin-left: 5px !important;

                        img {
                            margin-left: 10px !important;
                        }
                    }
                }

            }
        }

        .row {
            .col-md-6 {

                &:last-child {

                    @media (max-width:991px) {
                        padding-left: 0 !important;
                    }
                }
            }
        }
    }

    @media (max-width:767px) {

        .mob-justify-content-between {
            justify-content: space-between !important;
            padding: 0 15px;
        }
    }

    .reviewStr {
        max-width: 18px;

        @media (max-width: 991px) {
            max-width: 15px;
        }

    }

    .reviewStrpara {

        @media (max-width: 991px) {
            margin-left: 5px !important;
        }

    }

}



// STR Detail Page
.mainstrdetailContainer {
    margin-right: auto;
    margin-left: auto;
    max-width: 1400px;
    padding: 0 30px;
    padding-top: 60px !important;

    @media (max-width:767px) {
        padding: 0 !important;

    }
}

.mainltrdetailContainer {
    .guestBlock {
        span {
            width: 88%;

            .floorplan-basic {
                column-count: 3;
                width: 100%;
                page-break-inside: avoid;
                break-inside: avoid;

                ul {
                    flex-wrap: wrap;
                    display: table-cell;

                    li {
                        &:first-child {
                            padding-left: 15px !important;

                            &::after {
                                position: absolute;
                                width: 2px;
                                height: 2px;
                                border-radius: 50%;
                                background: #606060;
                                content: '';
                                top: 11px;
                                left: 1px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mainstrDetail {

    @media(max-width: 767px) {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .skeletonGallerymob {
        display: flex;

        @media(max-width: 767px) {
            display: block !important;
            height: 200px;
            overflow: hidden;
            margin-top: 10px;
        }

        .skeletonGallerythumbmob {
            display: flex;

            @media(max-width: 767px) {
                display: none !important;
            }
        }
    }

    .strDetail {
        padding: 45px 0 0px;

        .row {

            @media(max-width: 991px) {
                flex-direction: column;
            }

            @media(max-width: 767px) {
                flex-direction: column;
            }

            .col-md-6 {

                @media(max-width: 991px) {
                    max-width: 100%;
                    width: 100%;

                }

                @media(max-width: 767px) {
                    max-width: 100%;
                    width: 100%;
                }

                .guest-availabity {

                    @media(max-width: 991px) {
                        padding: 0;
                        margin-top: 20px;

                    }

                    @media(max-width: 767px) {
                        padding: 0;
                    }

                }

            }

        }

        @media(max-width: 767px) {
            padding: 0px !important;
            display: flex;
            order: 2;
        }

        h4.listing_title {
            @include brandHeading-4;
            text-align: left;
            color: $az-ligh-mid-black;
            // font-size: $az-font-44;
            font-weight: normal;




            @media (max-width:991px) {
                font-size: $az-font-33;

            }

            @media (max-width:767px) {
                font-size: $az-font-30;
            }

        }

        p {
            font-family: $az-heading-two;
            font-size: $az-font-16 !important;
            color: $az-oh-primary;
            text-align: justify;
            margin-bottom: 0;
        }

        span {
            width: 85%;

            h5 {
                font-family: $az-heading-para;
                font-size: $az-font-16;
                color: $az-oh-primary;
                text-align: left;
                margin-bottom: 0;
            }

            .floorplan-basic {
                column-count: inherit !important;
                width: 100%;
                page-break-inside: avoid;
                break-inside: avoid;

                ul {
                    font-family: $az-heading-two;
                    font-size: $az-font-14;
                    color: $az-oh-primary;
                    text-align: left;
                    margin-bottom: 0;
                    display: flex;
                    padding-left: 0;
                    margin-top: 5px;

                    @media(max-width: 767px) {
                        flex-wrap: wrap;
                    }

                    li {
                        list-style: none;
                        padding-left: 15px;
                        padding-right: 10px;
                        position: relative;
                        text-transform: capitalize;

                        @media(max-width: 767px) {
                            width: (100% / 2);
                        }

                        &::after {
                            position: absolute;
                            width: 2px;
                            height: 2px;
                            border-radius: 50%;
                            background: $az-gray;
                            content: '';
                            top: 11px;
                            left: 1px;
                            display: block;

                        }

                        &:first-child {
                            padding-left: 15px;

                            &::after {
                                //  display: none;
                            }
                        }

                    }
                }
            }

        }

        span.share {
            width: auto;
        }

        figure {
            margin: 0;
            width: 60px;
            height: 60px;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid $az-light-gray;
            position: relative;
            border-radius: 50%;

            @media(max-width: 767px) {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 1px solid #72818e33;
                max-width: 100%;
                overflow: hidden;
                margin-bottom: 0;

            }

            img {

                max-width: 100%;
                width: 60px;
                height: 60px;
                object-fit: cover;

                @media(max-width: 767px) {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .guest-availabity {
            padding: 0 0 0 50px;
            position: sticky;
            top: 140px;
            z-index: 1;

            @media(max-width: 767px) {
                padding: 0px;
                z-index: 1;
            }

            h4 {
                @include brandHeading-4;
                color: $az-oh-primary;
                text-align: left;
                color: $az-ligh-mid-black;

                @media (max-width:991px) {
                    font-size: $az-font-35;

                }

                @media (max-width:767px) {
                    font-size: $az-font-30;
                }

                b {
                    font-family: $az-kini-heading-three;
                    color: $az-oh-primary;
                    text-align: left;
                    margin-bottom: 0;
                    font-weight: normal;

                    @media(max-width: 767px) {
                        font-size: $az-font-20;
                    }
                }
            }

            ul {
                font-family: $az-heading-para;
                font-size: $az-font-20;
                color: $az-oh-primary;
                text-align: left;
                margin-bottom: 0;
                display: flex;
                padding-left: 0;
                align-items: center;

                @media(max-width: 767px) {
                    font-size: $az-font-16;
                }

                li {
                    list-style: none;
                    padding-right: 15px;
                    position: relative;
                    display: flex;
                    align-items: center;

                    &::after {
                        position: absolute;
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                        background: $az-gray;
                        content: '';
                        top: 16px;
                        right: 6px;

                        @media(max-width: 767px) {
                            top: 11px;
                        }
                    }

                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }

                    img {
                        max-width: 18px;

                        @media(max-width:991px) {
                            max-width: 15px;
                        }

                        @media(max-width: 767px) {
                            max-width: 15px;
                        }

                    }

                    a {
                        color: $az-oh-primary;
                    }
                }
            }

            .guestfilter {
                span {
                    width: 50%;

                    .movedate {
                        margin: 0;

                        .MuiFormControl-root {
                            label {
                                top: 0 !important;
                                left: 0 !important;
                                transform: translate(0%) scale(1) !important;
                                margin-left: 17px !important;
                                margin-top: 10px;
                                font-family: $az-heading-two !important;
                                font-weight: normal !important;
                                color: $az-kini-primary !important;
                                font-weight: 600 !important;
                            }

                            fieldset {
                                visibility: hidden;
                                top: 0;
                            }

                            .MuiOutlinedInput-input {
                                height: 50px;
                                padding: 17px 17px 12px !important;
                                font-size: $az-font-18;
                                border-right: 0px;
                                border-top: 1px solid $az-border-color;
                                border-bottom: 1px solid $az-border-color;
                                border-left: 1px solid $az-border-color;
                                border-radius: 12px 0 0 0;
                                text-align: left;
                            }

                        }

                        .checkout input {
                            border-right: 1px solid $az-border-color !important;
                            border-top: 1px solid $az-border-color;
                            border-bottom: 1px solid $az-border-color;
                            border-left: 0px solid $az-border-color;
                            border-radius: 0 12px 0 0 !important;
                        }
                    }
                }

                .dropmenuInputbox {
                    position: relative;
                    z-index: 1;

                    .MuiFormControl-root {
                        label {
                            top: 0 !important;
                            left: 0 !important;
                            transform: translate(0%) scale(1) !important;
                            margin-left: 17px !important;
                            margin-top: 10px;
                            font-family: $az-heading-two !important;
                            font-weight: normal !important;
                            color: $az-kini-primary !important;
                            font-weight: 600 !important;
                        }

                        fieldset {
                            visibility: hidden;
                            top: 0;
                        }

                        .MuiOutlinedInput-input {
                            height: 50px;
                            padding: 17px 17px 12px !important;
                            font-size: $az-font-18;
                            border-top: 0px;
                            border-right: 1px solid $az-border-color;
                            border-bottom: 1px solid $az-border-color;
                            border-left: 1px solid $az-border-color;
                            border-radius: 0 0 12px 12px;
                            font-family: $az-heading-two;

                        }

                    }

                    .dropdown-menu {
                        border: none;
                        top: 77px !important;
                        width: 100%;
                        position: absolute !important;
                        transform: inherit !important;

                        .dropdown-item {
                            display: flex;
                            justify-content: space-between;
                            flex-direction: column;
                            background: $az-white !important;
                            box-shadow: 0px 0px 6px 1px #ddd;
                            padding-bottom: 20px;
                            cursor: auto;
                            padding-left: 17px;

                            ul {
                                padding: 0;

                                li {
                                    list-style: none;
                                    flex-direction: column;
                                    align-items: start;
                                    padding-right: 0;

                                    h4 {
                                        font-family: $az-heading-two;
                                        font-size: $az-font-18;
                                        color: $az-oh-primary;
                                        text-align: left;
                                        margin-top: 2px;
                                        display: flex;
                                        padding-left: 0;
                                        align-items: left;
                                    }

                                    h5 {
                                        font-family: $az-heading-two;
                                        font-size: $az-font-14;
                                        color: $az-oh-primary;
                                        text-align: left;
                                        margin-bottom: 0;
                                        display: flex;
                                        padding-left: 0;
                                        align-items: left;

                                        a {
                                            color: $az-oh-primary;
                                        }
                                    }
                                }
                            }

                            .countBtn {
                                font-family: $az-heading-two;
                                font-size: $az-font-18;
                                color: $az-oh-primary;
                                text-align: left;
                                margin-bottom: 0;
                                display: flex;
                                padding-left: 0;
                                align-items: left;

                                span {
                                    button {
                                        background: none;
                                        border: none !important;
                                        // border-radius: 50%;
                                        // width: 30px;
                                        // height: 30px;
                                        font-size: $az-font-24;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        color: $az-gray;

                                        &:hover {
                                            border: none !important;
                                            // color: $az-white;
                                            // background: $az-oh-primary;
                                        }

                                    }

                                    &:nth-child(2) {
                                        margin: 0px 2px;
                                        min-width: 20px;
                                        text-align: center;
                                        max-width: 20px;
                                    }

                                    &:first-child {
                                        button {
                                            padding-top: 0px;
                                        }
                                    }

                                    &:last-child {
                                        button {
                                            padding-top: 0px;
                                        }
                                    }
                                }
                            }

                            p {
                                font-size: $az-font-14;
                                margin-top: 30px;
                                margin-bottom: 15px;
                                white-space: break-spaces;

                                @media (max-width: 767px) {
                                    white-space: break-spaces;
                                }
                            }

                            a.close {
                                color: $az-oh-primary;
                                font-size: $az-font-16;
                                font-family: $az-heading-two;
                                text-align: right;
                            }
                        }
                    }
                }

                .react-google-flight-datepicker {
                    position: relative;
                    z-index: 2;
                }
            }

            button.guest-avaialbility-btn {

                @include OHprimaybutton-sm;

                @media (max-width: 767px) {
                    min-width: 170px;
                    padding: 9px 11px 10px !important;
                }
                @media (max-width: 360px) {
                    padding: 9px 7px 10px!important;
                }
            }

            button.done {
                border: none;
                background: $az-black;
                color: $az-white;
                font-size: $az-font-14 !important;
                font-family: $az-kini-heading-three !important;
                outline: none;
                margin-top: 15px;
                margin-right: 7px;
                padding: 2px 15px;
                border-radius: 4px;
                width: 80px;
                text-transform: inherit;
                border-radius: 20px;
                letter-spacing: 1px;

            }

            .mobigustavi {

                @media(max-width: 767px) {
                    margin-top: 30px !important;
                    flex-direction: row;
                    align-items: baseline;
                }

               

            }

            .guestfilterNew {

                @include daterangerFormate;

                .date-picker {
                    padding: 0 !important;
                }

                .react-google-flight-datepicker {
                    .date.is-focus {
                        &:after {
                            border: 1px solid transparent;
                            border-radius: 12px;
                        }
                    }
                }

                .react-google-flight-datepicker .date:focus,
                .react-google-flight-datepicker .date.is-focus {
                    background: $az-white !important;
                }

                .react-google-flight-datepicker .date-picker-input {
                    height: 81px;
                    border-radius: 12px 12px 0 0;
                }

                .react-google-flight-datepicker .date-picker-input svg {
                    display: none;
                }

                .react-google-flight-datepicker .day.selected.hovered::after {
                    background-color: $az-black;
                }

                .react-google-flight-datepicker .day.hovered {
                    background-color: #eaeaea;
                }

                .react-google-flight-datepicker .day .background-day.last-day {
                    background: linear-gradient(to left, #fff, #eaeaea);
                }

                .react-google-flight-datepicker .day .background-day.first-day {
                    background: linear-gradient(to right, #fff, #eaeaea);
                }

                .react-google-flight-datepicker .day.selected.hovered.end {
                    background: linear-gradient(90deg, #eaeaea 50%, #fff 50%);
                }

                .react-google-flight-datepicker .day.selected.hovered:hover {
                    color: #fff;
                }

                .react-google-flight-datepicker .day:hover::after {
                    border: 2px solid $az-black;
                }

                .react-google-flight-datepicker .day.selected.hovered {
                    background: linear-gradient(90deg, #fff 50%, #eaeaea 50%);
                }

                .react-google-flight-datepicker .calendar-wrapper {
                    margin: 12px 23px 8px;
                }

                .react-google-flight-datepicker .calendar-content {
                    padding: 0 2px;
                    transform: inherit !important;
                }

                .react-google-flight-datepicker .day {
                    width: 39px;
                    height: 39px;
                }

                .react-google-flight-datepicker .month-calendar {
                    margin-right: 7px !important;
                }

                .react-google-flight-datepicker .dialog-footer .submit-button {
                    background: $az-black;
                }

                .react-google-flight-datepicker .day::after {
                    width: 39px;
                    height: 39px;
                }

                .react-google-flight-datepicker .day.hovered.end {
                    background: linear-gradient(90deg, #eaeaea 50%, #fff 50%);
                }

                .react-google-flight-datepicker .day.selected::after {
                    background: $az-black;
                }

                .react-google-flight-datepicker .day.selected:hover {
                    color: #fff !important;
                }

                .react-google-flight-datepicker .date:focus::after {
                    border: 1px solid $az-black;
                    border-radius: 12px;
                }

                .react-google-flight-datepicker .calendar-flippers {
                    align-items: inherit !important;
                    right: inherit;
                    top: -43px;
                    bottom: inherit;
                }

                .react-google-flight-datepicker .dialog-header {
                    justify-content: end;
                }

                .react-google-flight-datepicker .date {
                    border-radius: 12px 12px 0 0;
                }

                .react-google-flight-datepicker .flipper-button {
                    box-shadow: 0px 1px 4px #ddd;
                    height: 30px;
                    width: 30px;
                    -webkit-box-shadow: 0px 1px 4px #ddd;
                    margin: 0 10px;
                }

                .react-google-flight-datepicker .is-focus:last-child:before {
                    height: 100% !important;
                    content: '';
                    position: absolute;
                    left: -1px;
                    top: 0;
                    width: 1px;
                    background: #fff;
                    z-index: 1;
                }

                .react-google-flight-datepicker .date-picker-date-group::after {
                    height: 100% !important;
                    content: '';
                    position: absolute;
                    right: 50%;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: 1px;
                    height: 70%;
                    background: #dadce0;
                }

                .react-google-flight-datepicker .date-picker-date-group .date:first-child {
                    position: relative;
                    padding: 40px 13px 20px;
                    font-family: $az-heading-two;
                    font-size: $az-font-16;
                    height: 100% !important;
                    border-right: 0;
                    border-radius: 12px 0 0;
                }

                .react-google-flight-datepicker .date-picker-date-group .date:first-child::before {
                    content: 'CHECK-IN';
                    position: absolute;
                    left: 14px;
                    width: auto;
                    height: auto;
                    top: 20px;
                    background: no-repeat;
                    text-align: left;
                    font-family: $az-heading-para;
                    color: $az-black;
                    font-size: $az-font-14;

                }

                .react-google-flight-datepicker .date-picker-date-group .date:last-child {
                    position: relative;
                    padding: 40px 13px 20px;
                    font-family: $az-heading-two;
                    height: 100% !important;
                }

                .react-google-flight-datepicker .date-picker-date-group .date:last-child::before {
                    content: 'CHECKOUT';
                    position: absolute;
                    left: 14px;
                    width: auto;
                    height: auto;
                    top: 10px;
                    background: no-repeat;
                    text-align: left;
                    font-family: $az-heading-para;
                    color: $az-black;
                    font-size: $az-font-14;
                }

                .react-google-flight-datepicker .selected-date {
                    font-size: $az-font-16;
                }


            }

            span.share {
                a {
                    color: $az-heading-two;
                    font-size: $az-font-16;
                    filter: brightness(0);
                    text-decoration: none;
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 5px;
                    }

                    b {
                        color: $az-kini-primary-two;
                        font-family: $az-heading-two;
                        font-weight: normal;
                    }
                }

            }
        }

        .guest-availabity-deskview {
            display: block;
            display: block;
            padding: 30px;
            box-shadow: 0px 0px 10px #ddd;
            border-radius: 15px;
            margin-left: 100px;
            margin-bottom: 30px;

            @media(max-width: 991px) {
                display: none;
            }

            .pricebreakdownsection {
                margin-top: 20px;
                span {
                    font-family: $az-heading-two;
                    color: $az-kini-primary;
                    font-size: $az-font-14;
                }
                ul {
                    flex-direction: column;
                    margin-top: 20px;
                    li {
                        list-style: none;
                        width: 100%;
                        justify-content: space-between;
                        margin-right: 0;

                        &::after {
                            display: none;
                        }
                        span {
                            font-size: $az-font-16;
                            font-family: $az-heading-two;
                            &:last-child {
                                text-align: right;
                            }
                        }

                        padding: 10px 0 0;

                        &:last-child {
                            padding: 15px 0 0;
                            border-top: 1px solid $az-border-color;
                            margin-top: 15px;
                            span {
                                font-family: $az-heading-para;
                                font-size: $az-font-18;
                            }
                        }
                    }
                }
            }
        }

        .guest-availabity-mobileview {
            display: none;

            @media(max-width: 991px) {
                display: block;
                position: fixed;
                width: 100%;
                background: $az-white;
                bottom: 0px;
                top: inherit;
                left: 0;
                border-top: 1px solid #dee2e6 !important;
                padding: 10px 15px 15px!important;
                margin-top: 0 !important;
                z-index: 999;
            }

            .mobigustavi {
                @media(max-width: 991px) {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                    
                }

                
            }

            ul.addgust {
                display: flex;
                padding-left: 0;
                flex-direction: column;
                align-items: baseline;
                li {
                    list-style: none;
                    position: relative !important;
                    a {
                        font-weight: normal;
                        font-family: $az-heading-two;
                        font-size: $az-font-14;
                        text-align: left;
                        cursor: pointer;
                        &::after {
                            display: none;
                        }
                    }
                    &::after {
                        display: none;
                    }
                }
            }
            .guest-avaialbility-btn {
                @media(max-width: 767px) {
                    max-width: inherit !important;
                    margin-right: 0 !important;
                }
            }

            span p.text-danger {
                font-size: $az-font-12;
            }
        }

        .guest-availabity-review {

            ul {
                font-family: $az-heading-para;
                font-size: $az-font-20;
                color: $az-oh-primary;
                text-align: left;
                margin-bottom: 0;
                display: flex;
                padding-left: 0;
                align-items: center;

                @media(max-width: 767px) {
                    font-size: $az-font-16;
                    flex-wrap: wrap;
                }

                li {
                    list-style: none;
                    padding-right: 15px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-size: $az-font-16;

                    &::after {
                        position: absolute;
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                        background: $az-gray;
                        content: '';
                        top: 12px;
                        right: 6px;

                        @media(max-width: 767px) {
                            top: 11px;
                        }
                    }

                    &:nth-child(2) {
                        &::after {
                            display: none;
                        }
                    }

                    &:last-child {
                        padding-left: 10px;

                        &::after {
                            display: none;
                        }

                        &::before {
                            position: absolute;
                            width: 3px;
                            height: 3px;
                            border-radius: 50%;
                            background: $az-gray;
                            content: '';
                            top: 12px;
                            left: -6px;
                        }
                    }

                    img {
                        max-width: 15px;

                        @media(max-width:991px) {
                            max-width: 15px;
                        }

                        @media(max-width: 767px) {
                            max-width: 15px;
                        }

                    }

                    a {
                        color: $az-oh-primary;
                        font-size: $az-font-16;
                    }
                }
            }
        }

        .guestBlock {

            @media(max-width: 767px) {
                padding-right: 0 !important;

            }

            span {

                @media(max-width: 767px) {
                    max-width: calc(100% - 60px);

                }
            }

            figure {
                @media(max-width: 767px) {
                    max-width: 45px;
                    max-height: 45px;
                    min-height: 45px;
                    border-radius: 50%;
                    outline: 1px solid #72818e33;
                    margin-bottom: 0;
                    border: none;
                    overflow: hidden;
                    height: 45px;
                    width: 45px;
                }

                img {

                    @media(max-width: 767px) {
                        max-height: 47px;
                        max-width: 47px;
                        min-height: 47px;
                        height: 45px;
                        width: 45px;
                    }

                }
            }
        }


        .pd-l-35,
        .pd-r-35 {

            @media(max-width: 767px) {
                padding: 0 20px !important;
            }

        }


    }


    .strImgLising {

        // padding-left:35px !important;
        // padding-right: 25px !important;

        @media(max-width: 767px) {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .strSection {
            max-width: 100%;
            max-height: 410px;
            min-height: 410px;
            overflow: hidden;

            @media(max-width: 1240px) {
                max-height: 410px;
                min-height: 410px;
            }

            @media(max-width: 1023px) {
                max-height: 415px;
                min-height: 415px;
            }

            @media(max-width:991px) {
                max-height: 855px;
                min-height: 855px;
            }

            @media(max-width: 767px) {
                max-height: 100%;
                min-height: 100%;
            }

            figure.mainImg {
                margin: 0;
                max-width: 100%;
                // max-height: 382px !important;
                // min-height: 382px !important;
                max-height: 412px !important;
                min-height: 412px !important;
                padding-right: 0px;
                padding-top: 0px;
                margin-top: 3px;
                margin-right: 3px;
                padding-bottom: 3px;
                border-radius: 20px 0 0 20px;
                overflow: hidden;

                @media(max-width:1023px) {
                    border-radius: 0;
                }


                @media(max-width: 767px) {
                    padding-right: 0px;
                    max-height: 511px !important;
                    min-height: 511px !important;
                    border-radius: 0;
                    margin-top: 0px;
                    margin-right: 0px;
                }

                @media(max-width: 680px) {
                    padding-right: 0px;
                    max-height: 453px !important;
                    min-height: 453px !important;
                    border-radius: 0;
                    margin-top: 0px;
                    margin-right: 0px;
                }

                @media(max-width: 540px) {
                    padding-right: 0px;
                    max-height: 360px !important;
                    min-height: 360px !important;
                    border-radius: 0;
                    margin-top: 0px;
                    margin-right: 0px;
                }

                @media(max-width: 414px) {
                    padding-right: 0px;
                    max-height: 276px !important;
                    min-height: 276px !important;
                    border-radius: 0;
                    margin-top: 0px;
                    margin-right: 0px;
                }

                @media(max-width: 390px) {
                    padding-right: 0px;
                    max-height: 260px !important;
                    min-height: 260px !important;
                    border-radius: 0;
                    margin-top: 0px;
                    margin-right: 0px;
                }

                @media(max-width: 375px) {
                    padding-right: 0px;
                    max-height: 250px !important;
                    min-height: 250px !important;
                    border-radius: 0;
                    margin-top: 0px;
                    margin-right: 0px;
                }

                @media(max-width: 360px) {
                    padding-right: 0px;
                    max-height: 240px !important;
                    min-height: 240px !important;
                    border-radius: 0;
                    margin-top: 0px;
                    margin-right: 0px;
                }

                @media(max-width: 320px) {
                    padding-right: 0px;
                    max-height: 213px !important;
                    min-height: 213px !important;
                    border-radius: 0;
                    margin-top: 0px;
                    margin-right: 0px;
                }

                @media(max-width: 280px) {
                    padding-right: 0px;
                    max-height: 187px !important;
                    min-height: 187px !important;
                    border-radius: 0;
                    margin-top: 0px;
                    margin-right: 0px;
                }

                img {
                    max-width: 100%;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    object-fit: cover;
                    // max-height: 382px !important;
                    // min-height: 382px !important;
                    max-height: 412px !important;
                    min-height: 412px !important;
                    // border-radius: 22px 0 0 32px;
                    cursor: pointer;
                    position: relative;
                    transition: all .5s !important;

                    &:hover {
                        transform: scale(1.09, 1.09);
                        -webkit-filter: brightness(70%);
                        transition: all 1s ease;
                    }

                    @media(max-width: 767px) {
                        max-height: 511px !important;
                        min-height: 511px !important;
                    }

                    @media(max-width: 680px) {
                        max-height: 453px !important;
                        min-height: 453px !important;
                    }
    
                    @media(max-width: 540px) {
                        max-height: 360px !important;
                        min-height: 360px !important;
                    }
    
                    @media(max-width: 414px) {
                        max-height: 276px !important;
                        min-height: 276px !important;
                    }
    
                    @media(max-width: 390px) {
                        max-height: 260px !important;
                        min-height: 260px !important;
                    }
    
                    @media(max-width: 375px) {
                        max-height: 250px !important;
                        min-height: 250px !important;
                    }
    
                    @media(max-width: 360px) {
                        max-height: 240px !important;
                        min-height: 240px !important;
                    }
    
                    @media(max-width: 320px) {
                        max-height: 213px !important;
                        min-height: 213px !important;
                    }
    
                    @media(max-width: 280px) {
                        max-height: 187px !important;
                        min-height: 187px !important;
                    }
                }
            }

        }

        figure {
            margin: 0;

            .ReactGridGallery {
                display: flex;
                flex-wrap: wrap;

                .ReactGridGallery_tile {
                    max-width: calc(100% / 2);
                    float: inherit !important;
                    margin: 0 !important;
                    padding: 5px !important;
                    background: none !important;
                    max-height: 231px;



                    .ReactGridGallery_tile-viewport {
                        width: 100% !important;
                        height: 100% !important;
                        // background-color: rgb(238, 238, 238);

                        img {

                            margin: 0 !important;
                            padding: 0;
                            max-width: 100%;
                            max-height: 100% !important;
                            width: auto !important;
                            height: auto !important;
                        }
                    }


                }
            }

        }

        .mainstrviewimag {

            padding-left: 6px !important;
            padding-right: 0px !important;

            @media(max-width: 991px) {
                padding-left: 3 !important;
                padding-right: 3px !important;
                padding-bottom: 6px !important;
            }

            @media(max-width: 767px) {
                padding-left: 0 !important;
                padding-right: 0px !important;
                padding-bottom: 0 !important;
                padding-top: 0 !important;
            }

            .shareMobileview {
                display: none !important;

                @media(max-width: 767px) {
                    display: flex !important;
                    padding: 7px 15px;

                    img {
                        width: 20px;
                    }
                }

                .share {
                    display: none;

                    @media(max-width: 767px) {
                        display: flex !important;
                        padding: 0px;

                        img {
                            width: 17px;
                            margin-right: 3px;
                            filter: brightness(0);
                        }

                        b {
                            color: $az-black;
                            text-decoration: none;
                            font-size: $az-font-18;
                            font-weight: 400;
                        }
                    }

                    a {
                        @media(max-width: 767px) {
                            text-decoration: none;
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .strviewimag {
            position: relative;

            ul {
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                li {
                    list-style: none;
                    max-width: calc(100% / 2);
                    width: 100%;
                    padding: 3px;

                    &:nth-child(odd) {

                        @media(max-width: 991px) {
                            padding-left: 6px;
                        }

                        @media(max-width: 767px) {
                            padding-left: 0px;
                            max-width: calc(100% / 1);
                            padding-right: 0;

                        }

                    }

                    // &:nth-child(even) {
                    //     padding-right: 9px;

                    //     @media(max-width: 1280px) {
                    //         padding-right: 9px;
                    //     }

                    //     @media(max-width: 991px) {
                    //         padding-right: 6px;
                    //     }

                    //     @media(max-width:767px) {
                    //         padding-right: 0px;
                    //         max-width: calc(100% / 1);
                    //         padding-right: 0;
                    //         padding-left: 0;
                    //     }

                    //     figure {
                    //         // border-radius: 0 20px 0 0px;
                    //         overflow: hidden;

                    //         @media(max-width: 1023px) {
                    //             border-radius: 0;
                    //         }

                    //     }
                    // }
                    &:nth-child(2) {
                        padding-right: 3px;

                        @media(max-width: 1280px) {
                            padding-right: 3px;
                        }

                        @media(max-width: 991px) {
                            padding-right: 3px;
                        }

                        @media(max-width:767px) {
                            padding-right: 0px;
                            max-width: calc(100% / 1);
                            padding-right: 0;
                            padding-left: 0;
                        }

                        figure {
                            border-radius: 0 20px 0 0px;
                            overflow: hidden;

                            @media(max-width: 1023px) {
                                border-radius: 0;
                            }

                        }
                    }

                    &:nth-child(4) {
                        figure {
                            border-radius: 0 0px 40px 0px;
                            overflow: hidden;

                            @media(max-width: 1023px) {
                                border-radius: 0;
                            }
                        }
                    }

                    figure {
                        margin: 0;
                        position: relative;
                        overflow: hidden;

                        img {
                            max-width: 100%;
                            margin: 0;
                            padding: 0;
                            width: 100%;
                            object-fit: cover;
                            // max-height: 188px;
                            // min-height: 188px;
                            max-height: 206px;
                            min-height: 206px;
                            cursor: pointer;
                            transition: all .5s !important;

                            &:hover {
                                transform: scale(1.09, 1.09);
                                -webkit-filter: brightness(70%);
                                transition: all 1s ease;
                            }

                            @media(max-width:767px) {
                                max-height: 200px;
                                min-height: 200px;

                            }
                        }

                    }

                    // &:nth-child(2) {
                    //     figure {
                    //         img {
                    //             border-radius: 0px 22px 0px 0;
                    //         }
                    //     }
                    // }
                    // &:nth-child(4) {
                    //     figure {
                    //         img {
                    //             border-radius: 0px 0px 22px 0;
                    //         }
                    //     }
                    // }
                }
            }

            .Showmore {
                position: absolute;
                bottom: 30px;
                right: 25px;
                background: $az-black;
                color: $az-white;
                text-decoration: none;
                border-radius: 30px;
                text-transform: inherit;
                font-family: $az-kini-heading-three;
                display: flex;
                align-items: center;
                border: none;
                font-size: $az-font-14 !important;
                img {
                    margin-right: 3px;
                }
            }
        }

    }

    .strImgLising.deskview {
        display: block;

        @media (max-width:767px) {
            display: none;

        }
    }

    .strImgLising.mobview {
        display: none;

        @media (max-width:767px) {
            display: block;

        }

        .strviewimag {
            .Showmore {
                font-size: $az-font-14 !important;
                @media (max-width:767px) {
                    bottom: 15px;
                    right: 15px;
                    font-size: $az-font-12 !important;
                    padding: 7px 12px !important;
                    
                }
            }
        }
    }
}

.miscSection {

    padding: 0 35px;

    @media(max-width: 767px) {
        padding: 0 21px;
    }

    h5 {
        font-size: $az-font-32;
        font-family:  $az-kini-heading-three !important;
        color: $az-oh-primary;
        text-align: left;
        margin-bottom: 30px;
        text-transform: initial !important;
        // padding: 0 30px;

        @media(max-width: 767px) {
            font-size: $az-font-26;
            text-align: center;
        }

    }


    .commanBlockdetail {
        .guestBlock {
            .leftBlock {
                position: relative;

                .Bedtpye {
                    max-width: 282px;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    object-fit: cover;
                    max-height: 188px;
                    min-height: 188px;
                    border-radius: 12px;
                    overflow: hidden;

                    img {
                        max-width: 100%;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        object-fit: cover;
                        max-height: 188px;
                        min-height: 188px;

                    }

                }

                .bedtypeText {
                    b {
                        font-size: $az-font-16;
                        font-family: $az-heading-para;
                        color: $az-oh-primary;
                        text-align: left;

                        &:last-child {
                            font-size: $az-font-16;
                            color: $az-gray;
                            font-family: $az-heading-two;
                            font-weight: normal;
                        }
                    }

                    img {
                        margin: 0 10px;
                    }
                }

                &::after {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 100%;
                    border-right: 1px solid $az-border-color;
                    display: block;
                    right: 30px;
                    top: 0;

                    @media(max-width: 991px) {
                        display: none;
                    }

                    @media(max-width: 767px) {
                        display: none;
                    }
                }


            }

            .aminitiesDeskview {
                display: block;

                @media (max-width:767px) {
                    display: none;

                }

            }

            .aminitiesMobview {
                display: none;

                @media (max-width:767px) {
                    display: block;

                }

            }

            .amenitiesBlock {
                -webkit-column-count: 2;
                column-count: 2;
                width: 100%;
                page-break-inside: avoid;
                -webkit-column-break-inside: avoid;
                break-inside: avoid;

                .aminty_box_break{
                   display: flex;
                   place-items: end;
                }

                ul {
                    text-align: left;
                    padding-left: 0px;
                    margin: 0;
                    display: flex;
                    flex-flow: column wrap;
                    li {
                        // list-style-image: url(../images/oh/listing-img.svg);
                        list-style:none ;
                        font-size: $az-font-16;
                        color: $az-gray;
                        margin-bottom: 25px;
                        line-height: 26px;
                        display: flex;
                        place-items: center;
                        img{
                            margin-right: 10px;
                            max-height: 28px;
                            max-width: 28px;
                            object-fit: contain;
                            width: 28px;
                        }

                    }
                }

                @media(max-width: 991px) {
                    -webkit-column-count: 3;
                    column-count: 3;
                }

                @media(max-width: 767px) {
                    -webkit-column-count: 1;
                    column-count: 1;
                }

                h4 {
                    font-size: $az-font-16;
                    font-family: $az-heading-para;
                    color: $az-oh-primary;
                    text-align: left;
                    margin-bottom: 5px;
                }

            }

            .Showmore {
                background: $az-black;
                color: $az-white;
                text-decoration: none;
                border-radius: 50px;
                font-size: $az-font-15;
                display: block;
                margin-top: 30px;
                padding: 7px 20px;
                font-family: $az-kini-heading-three;
                border: none;
                &:active,
                &:focus,
                &:hover {
                    box-shadow: none;
                    outline: none;
                }
            }

        }

        .thingsBlock {
            text-align: left;
            // -webkit-column-count: 2;
            // column-count: 2;
            // width: 100%;
            // page-break-inside: avoid;
            // -webkit-column-break-inside: avoid;
            // break-inside: avoid;
            width: 33.333%;
            padding-right: 110px;
            position: relative;
            margin-bottom: 45px;

            @media(max-width: 991px) {
                width: 33.333%;
            }

            @media(max-width: 767px) {
                width: 100%;
                padding-right: 0px;
                margin-bottom: 45px;
            }

            // &:last-child {

            //     width: 44%;

            //     @media(max-width: 991px) {
            //         width: 30%;
            //         padding-right: 0px;
            //     }

            //     @media(max-width: 767px) {
            //         width: 100%;
            //         padding-right: 0px;
            //     }
            // }

            ul {
                // list-style: none;
                text-align: left;
                padding-left: 20px;
                margin-bottom: 18px;

                li {
                    // list-style-image: url(../images/oh/listing-img.svg);
                    font-size: $az-font-16;
                    color: $az-gray;
                    margin-bottom: 8px;

                }
            }

            .ShowmoreLink {
                // color: $az-oh-black;
                // text-decoration: underline;
                // border-radius: 12px;
                // text-transform: inherit;
                // font-size: $az-font-15;
                // display: block;
                // margin-top: 20px;
                // font-family: $az-heading-para;
                // position: relative;
                // padding-left: 0;

                // &:active,
                // &:focus,
                // &:hover {
                //     box-shadow: none;
                //     outline: none;
                // }

                // &::after {
                //     position: absolute;
                //     content: '';
                //     right: -4px;
                //     width: 7px;
                //     height: 11px;
                //     background-image: url(../images/oh/showmoe-arrow.svg);
                //     bottom: 9px;
                // }
                background: $az-black;
                color: $az-white;
                text-decoration: none;
                border-radius: 50px;
                font-size: $az-font-14 !important;
                display: block;
                margin-top: 0px;
                padding: 7px 20px;
                font-family: $az-kini-heading-three;

                &:active,
                &:focus,
                &:hover {
                    box-shadow: none;
                    outline: none;
                }
            }

            span {
                font-size: $az-font-18;
                font-family: $az-heading-para;
                color: $az-oh-primary;
                text-align: left;
                margin-bottom: 15px;
                display: block;

            }

            span.LinesEllipsis-unit, .LinesEllipsis-ellipsis{
                display: none;
            }


            // &::after {
            //     position: absolute;
            //     content: '';
            //     width: 1px;
            //     height: 100%;
            //     border-right: 1px solid $az-border-color;
            //     display: block;
            //     right: 60px;
            //     top: 0;

            //     @media(max-width: 991px) {
            //         right: 15px;
            //     }

            //     @media(max-width: 767px) {
            //         display: none;
            //     }
            // }

            // &:last-child {

            //     &::after {
            //         display: none;
            //     }

            // }
        }

        padding-top: 0px;
        padding-bottom: 0px;

        @media (max-width: 767px) {
            padding-top: 30px;
            padding-bottom: 20px;
        }

        .mg-sm-b-3 {

            @media(max-width: 767px) {
                margin-bottom: 30px;
            }

        }

    }

    .border-bottom {
        border-bottom: 1px solid $az-border-color;
    }

    .hostSection {
        .host {
            figure {
                border: 1px solid #eeedeb;
                border-radius: 8px;
                border-radius: 50%;
                height: 60px;
                margin: 0;
                overflow: hidden;
                position: relative;
                width: 60px;
                @media (max-width:767px) {
                    width: 75px;
                }
                img {
                    height: 60px;
                    max-width: 100%;
                    object-fit: cover;
                    width: 60px;
                }
            }

            .hostContent {
                text-align: left;
                width: calc(100% - 60px);
                padding-left: 10px;
                @media (max-width:767px) {
                    width: 100% !important;
                }
                span {
                    margin-bottom: 0;
                    word-break: break-word;
                    @include heading6;
                    font-family: $az-heading-para;
                    text-align: left;
                    display: contents !important;
                    @media (max-width:767px) {
                        text-align: left !important;
                        font-size:$az-font-22;
                    }
                }

                b {
                    color: $az-gray;
                    margin: 2px 0 10px;
                    font-size: $az-font-16;
                    display: block;
                    font-weight: normal !important;
                }
            }
        }

        .cohost {
            figure {
                border: 1px solid #eeedeb;
                border-radius: 8px;
                border-radius: 50%;
                height: 45px;
                margin: 0;
                overflow: hidden;
                position: relative;
                width: 45px;
                margin-right: 15px;

                img {
                    height: 45px;
                    max-width: 100%;
                    object-fit: cover;
                    width: 45px;
                }
            }

            p {
                font-size: $az-font-16;
                margin-bottom: 0px;
                font-family: $az-heading-para;
            }
        }

        .Showmore {
            background: $az-oh-black;
            color: $az-white;
            text-decoration: none;
            border-radius: 50px;
            font-size: $az-font-15;
            display: block;
            margin-top: 30px;
            padding: 7px 20px;
            font-family: $az-kini-heading-three;
            border: none;
            &:active,
            &:focus,
            &:hover {
                box-shadow: none;
                outline: none;
                background: $az-oh-black;
                color: $az-white;
                text-decoration: none;
                border-radius: 50px;
                font-size: $az-font-15;
                display: block;
                margin-top: 30px;
                padding: 7px 20px;
                font-family: $az-kini-heading-three;
            }
            &:focus-visible{
                border-color:transparent !important;
                box-shadow: none !important
            }
        }

    }

    .mapSection {
        text-align: left;

        img {
            width: 100%;
        }

        .map_address{
            padding-left: 10px !important;
            @media (max-width:1366px) {
                padding-left: 30px !important;
            }
        }

        .cityaddress {
            font-size: $az-font-18;
            font-family: $az-heading-para;
            margin: 20px 0 5px;
            display: block;
            padding: 0;

            @media(max-width:991px){
                padding: 0 0px !important;
            }
            @media(max-width: 767px){
                padding: 0 0px !important;
            }
        }

        p {
            font-size: $az-font-16;
            margin-bottom: 0px;
            font-family: $az-heading-two;
        }

        .ShowmoreLink {
            color: $az-oh-black;
            text-decoration: underline;
            border-radius: 12px;
            text-transform: inherit;
            font-size: $az-font-15;
            display: block;
            margin-top: 20px;
            font-family: $az-heading-para;
            position: relative;
            padding-left: 0;
            border: none;
            &:active,
            &:focus,
            &:hover {
                box-shadow: none;
                outline: none;
            }

            &::after {
                position: absolute;
                content: '';
                right: -4px;
                width: 7px;
                height: 11px;
                background-image: url(../images/oh/showmoe-arrow.svg);
                bottom: 9px;
            }
        }

        .mapblock {
            height: 572px;
            overflow: hidden;
            position: relative;

            // div{
            //     position: relative !important;
            // }
            @media(max-width:767px) {
                height: 372px;
            }
        }
        h5 {
            padding: 0 30px;
        }
    }
}

.strReviews {
    padding: 30px 0;

    span.reviewCount {
        padding: 0;
        font-family: $az-heading-two;
        font-size: $az-font-18;
        color: $az-oh-primary;
        text-align: left;
        margin-bottom: 0;
        display: flex;
        align-items: center;

        img {
            max-width: 18px;

            @media(max-width: 991px) {
                max-width: 15px;
                margin-right: 10px !important;
            }

            @media(max-width: 767px) {
                max-width: 15px;
                margin-right: 10px !important;
            }

        }
    }

    .reviewCountprogress {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media(max-width: 767px) {
            flex-direction: column;
        }

        ul {
            font-family: $az-heading-two;
            font-size: $az-font-18;
            color: $az-oh-primary;
            padding: 0;
            width: calc(100% / 2);
            margin-bottom: 0;
            padding-right: 30px;


            @media(max-width: 767px) {
                width: calc(100% / 1);
                padding-right: 0;
            }

            &:last-child {
                padding-right: 0px;
            }

            li {
                list-style: none;
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;

                @media(max-width: 767px) {
                    flex-direction: column;
                }

                h5 {
                    text-align: left;
                    margin-bottom: 0;
                    font-size: $az-font-18;
                    min-width: 200px;
                    display: block;

                    @media(max-width: 991px) {
                        font-size: $az-font-16;
                    }

                    @media(max-width: 767px) {
                        font-size: $az-font-16;
                    }
                }

                .progres {
                    display: flex;
                    flex-direction: row;
                    width: calc(100% - 200px);
                    align-items: center;

                    @media(max-width: 767px) {
                        width: calc(100%);

                    }

                    span {
                        background: $az-oh-seconday;
                        width: 100%;
                        height: 5px;
                        display: block;
                        margin-right: 15px;
                        border-radius: 15px;

                    }

                    span.filled {
                        width: 100%;
                        height: 5px;
                        display: block;
                        margin-right: 15px;
                        border-radius: 15px;
                        background: $az-oh-primary;

                    }

                    h6 {
                        font-size: $az-font-14;
                        margin-bottom: 0;
                        font-family: $az-heading-para;
                    }

                }
            }
        }

    }

    .reviewUsers {
        .profile {
            display: flex;

            figure {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                border: 1px solid $az-border-color;
                max-width: 100%;
                overflow: hidden;
                margin-bottom: 0;
                margin-right: 10px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }
            }

            h5 {
                text-align: left;
                margin-bottom: 0px;
                font-size: $az-font-17;
                color: $az-oh-primary;
                font-family: $az-heading-para;
            }

            h6 {
                text-align: left;
                margin-top: 5px;
                margin-bottom: 5px;
                font-size: $az-font-15;
                color: $az-gray;
                font-family: $az-heading-two;
            }

        }

        .decription {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            p {
                text-align: left;
                margin-bottom: 0px;
                font-size: $az-font-17;
                color: $az-oh-primary;
                font-family: $az-heading-two;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;

                @media(max-width: 991px) {
                    font-size: $az-font-14;
                }

                @media(max-width: 767px) {
                    font-size: $az-font-14;
                }

            }

            a {
                color: $az-oh-primary;
                font-family: $az-heading-para;
                font-size: $az-font-17;
                margin-top: 5px;

                img {
                    width: 13px;
                }
            }
        }
    }

    .mobviewreview {

        @media(max-width: 1240px) {
            padding: 0 35px !important;
        }

        @media(max-width: 767px) {
            padding: 0px !important;
        }
    }
}

.showmoreimgModalbox {

    height: 100%;
    padding: 0 !important;
    position: relative;
    // .d-block {
    //     .ReactGridGallery {
    //         display: flex;
    //         flex-wrap: wrap;
    //         justify-content: start;
    //         padding: 20px 3% 15px !important;


    //         .ReactGridGallery_tile {
    //             margin: 3px !important;
    //             max-width: calc(1200px / 4 - 20px);
    //             float: inherit !important;
    //             padding: 0px !important;
    //             background: none !important;
    //             min-height: 190px;
    //             max-height: 190px;

    //             @media(max-width: 1199px) {
    //                 width: 100%;
    //              }
    //             @media(max-width: 991px) {
    //                 width: 100%;
    //              }
    //             @media(max-width: 767px) {
    //                 width: 100%;
    //                 margin: 3px !important;
    //                 max-width: calc(1200px / 1 - 20px);
    //              }

    //             .ReactGridGallery_tile-viewport {
    //                     width: 100% !important;
    //                     height: 100% !important;
    //                     float: inherit !important;
    //                     margin: 0 !important;
    //                     position: relative;


    //                     &:hover {

    //                         &:after {
    //                             position: absolute;
    //                             content: '';
    //                             background: rgba(0,0,0,0.5);
    //                             height: 100%;
    //                             width: 100%;
    //                             left: 0;
    //                             top: 0;
    //                             cursor: pointer;
    //                         }
    //                         &:before {
    //                             background-image: url(../images/kini/zoom.svg);
    //                             background-repeat: no-repeat;
    //                             position: absolute;
    //                             top: 50%;
    //                             left: 50%;
    //                             content: '';
    //                             height: 24px;
    //                             width: 24px;
    //                             z-index: 2;
    //                             cursor: pointer;
    //                             transform: translate(-50%, -50%);
    //                         }
    //                      }


    //                     img {
    //                         width: 100% !important;
    //                         max-height: 100% !important;
    //                         min-height: 100%;
    //                         object-fit: cover;
    //                         height: auto !important;

    //                         @media(max-width: 767px) {
    //                                margin: 0 !important;
    //                          }


    //                     }

    //            }
    //         }
    //     }
    // }
    .galleryHead {
        padding: 30px 4% 15px !important;

        @media(max-width: 991px) {

            padding: 30px 3% 15px !important;
        }

        @media(max-width: 767px) {

            padding: 20px 3% 20px !important;
        }

        h3 {
            font-family: $az-kini-heading-three ;
            color: $az-black;
            margin: 0;
        }

        .welcomeClose {
            float: inherit !important;

        }
    }
}

// Amenities modalbox str detail page
.unitmodal {

    @media(max-width: 767px) {
        padding: 0;
    }

    .modal-dialog {
        @media(max-width: 767px) {
            margin: auto;
            max-width: 100%;
            display: block;
            //    height: 100%;
        }

        .modal-content {
            @media(max-width: 767px) {
                margin: auto;
                max-width: 100%;
                min-height: 100%;
                padding-top: 5px;
                border-radius: 0;
                border-color: $az-white ;
            }

            .title {
                font-size: 32px !important;
                line-height: 30px !important;
                margin-top: 0px !important;
                margin-bottom: 0px !important;
                color: $az-oh-primary !important;
                display: block !important;
                font-family: $az-kini-heading-three !important;
                @media (max-width:767px) {
                    font-size:$az-font-26 !important;
                }
            }
        }
    }


    .title {
        font-size: $az-font-30 !important;
        margin-top: 15px !important;
        margin-bottom: 15px !important;
        display: block !important;
        font-family: $az-heading-one;
        color: $az-black;
        text-align: left;
        margin-bottom: 30px;
        line-height: 1.5;

        @media(max-width: 767px) {
            font-size: $az-font-40 !important;
            margin-top: 40px !important;
        }

    }

    .place-offers {
        column-count: 2;
        width: 100%;
        page-break-inside: avoid;
        break-inside: avoid;
        padding-bottom: 20px;
        .amt_box{
            display: inline-grid;
            width: 100%;
        }
        @media(max-width: 991px) {
            column-count: 2;
        }

        @media(max-width: 767px) {
            column-count: 2;
        }

        @media(max-width: 580px) {
            column-count: 1;
        }

        ul {
            // list-style: none;
            text-align: left;
            padding-left: 0px;
            margin: 0;
            display:block;
            margin-bottom: 10px;
            li {
                // list-style-image: url(../images/oh/listing-img.svg);
                font-size: $az-font-16;
                color: $az-gray;
                margin-bottom: 25px;
                margin-left:0px;
                display: flex;
                place-items: center;
                padding-right: 10px;
                img{
                    margin-right: 10px;
                    max-height: 28px;
                    max-width: 28px;
                    object-fit: contain;
                    width: 28px;
                }
            }
        }
    }

    .mobile {
        @media(max-width: 767px) {
            // padding: 0 !important;
            align-items: baseline;
            flex-direction: column;
        }
    }

    .welcomeClose {
        display: block;
    }

    .mobilewelcomeClose {
        display: none;
    }
}

// Aminitiy

.aminityBlock {

    @media (max-width:991px) {
        padding: 30px 0 !important;
    }

    @media (max-width:767px) {
        padding: 30px 0 !important;

    }

    .content {
        h3 {
            color: $az-black;
            font-family: $az-kini-heading-three;
            font-size: $az-font-45;
            margin-top: 0px;
            text-align: left;
            line-height: 1;
            padding: 0 35px 0 70px;

            @media (max-width:1400px) {
                padding: 0 0 0 70px;
            }

            @media (max-width:991px) {
                padding: 0 20px 30px;
                font-size: $az-font-32;
                margin-bottom: 0;

            }

            @media (max-width:767px) {
                padding: 0 20px 30px;
                font-size: $az-font-32;
                margin-bottom: 0;

            }

        }

        h3.smarter {

            padding: 0 90px 0 70px;

            @media (max-width:1400px) {
                padding: 0 70px 0 70px;
            }

            @media (max-width:991px) {
                padding: 0px 15px 30px;

            }

            @media (max-width:767px) {
                padding: 30px 15px 30px;

            }

        }

        .aminityListitems {
            -moz-column-count: 2;
            column-count: 2;
            width: 100%;
            padding-right: 100px;

            @media (max-width:991px) {
                padding: 0px 15px 30px;

            }

            @media (max-width:767px) {
                padding: 0px 15px 30px;

            }

            ul {
                padding-left: 50px;
                color: $az-oh-primary;
                font-family: $az-heading-two;
                font-size: $az-font-17;

                @media (max-width:991px) {
                    padding: 0 5px;
                    font-size: $az-font-14;
                }

                @media (max-width:767px) {
                    padding: 0 5px;
                    font-size: $az-font-14;
                }

                li {
                    list-style: none;
                    text-align: left;
                    margin-bottom: 25px;

                }
            }
        }

    }

    figure {
        position: relative;
        max-width: 100%;
        max-height: 747px;
        // min-height: 712px;
        border-radius: 0px;
        display: block;
        overflow: hidden;
        text-align: center;

        @media (max-width:991px) {
            margin: 10px 0 !important;

        }

        @media (max-width:767px) {
            margin: 10px 0 !important;

        }

        img {
            max-width: 100%;
            max-height: 747px;
        }


    }

    .mobAminity {

        @media (max-width:991px) {
            margin: 0px 0 !important;

        }

        @media (max-width:767px) {
            margin: 0px 0 !important;

        }

        &:last-child,
        &:nth-child(4) {

            @media (max-width:991px) {
                margin-bottom: 0px !important;

            }

            @media (max-width:767px) {
                margin-bottom: 0px !important;

            }


        }

    }


}

// Neighborhoods

.neighborhoods {

    @media (max-width: 991px) {
        padding-top: 30px !important;
    }

    @media (max-width:767px) {
        padding-top: 30px !important;
    }

    h3 {
        color: $az-black;
        font-family: $az-kini-heading-three;
        font-size: $az-font-45;
        margin-top: 0px;
        text-align: left;
        line-height: 1;
        padding: 0 170px 0 0px;

        @media (max-width:1400px) {
            padding: 0 170px 0 0px;
        }

        @media (max-width:767px) {
            font-size: $az-font-28;
            padding: 0 50px;
            text-align: left;
        }
    }

    p {
        color: $az-oh-primary;
        font-family: $az-heading-two;
        font-size: $az-font-17;
        margin: 60px 0 0;
        text-align: left;

        @media (max-width:767px) {
            margin: 15px 0 30px;
            font-size: $az-font-14;
            padding: 0 50px;
        }
    }

    .content {
        ul {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0;

            @media (max-width:767px) {
                margin-bottom: 10px;
                margin-top: 20px;
            }

            li {
                list-style: none;
                width: calc(100% / 4);
                padding: 0 12px;

                button {
                    @include OHprimaybutton-sm;
                    width: 100%;
                    // background: #1480C3;
                }

                // button.two {
                //     @include  OHprimaybutton-sm;
                //     width: 100%;
                //     background: #75C1D0;
                // }
                // button.three {
                //     @include  OHprimaybutton-sm;
                //     width: 100%;
                //     background: #A377B2;
                // }
                // button.four {
                //     @include  OHprimaybutton-sm;
                //     width: 100%;
                //     background: #28337A;
                // }

                @media (max-width:767px) {
                    width: calc(100% / 2);
                    margin-bottom: 15px;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        table {
            width: 100%;
            margin-bottom: 30px;
            padding-left: 5px;
            padding-right: 5px;
            border: none;

            @media (max-width:767px) {

                margin-top: 0px !important;
                margin-bottom: 0px !important;

            }

            td {
                list-style: none;
                text-align: left;
                margin-bottom: 0;
                padding: 14px 0 14px 0px;
                border-top: none;
                border-left: none;
                border-right: none;
                width: 25%;
                min-height: 100px;
                max-height: 100px;
                display: inline-flex;

                @media (max-width:767px) {
                    width: 100%;
                    padding: 5px 0 5px 0px;
                    min-height: 50px;
                    max-height: initial;
                }

                h6 {
                    font-family: $az-heading-two;
                    font-size: $az-font-17;
                    color: $az-oh-primary;
                    margin-bottom: 0;
                    border-bottom: 1px solid $az-border-color;
                    width: 100%;
                    padding: 10px 15px 30px;
                    margin-left: 5px;
                    margin-right: 10px;

                    @media (max-width:767px) {
                        padding: 5px 15px 15px;
                    }
                }

            }
        }

    }

    figure {
        position: relative;
        max-width: 100%;
        max-height: 842px;
        // min-height: 842px;
        border-radius: 0px;
        display: block;
        overflow: hidden;
        text-align: center;
        display: block;

        @media (max-width:767px) {
            padding-left: 0 !important;
            padding-top: 0px !important;
            max-height: 220px;
            margin-bottom: 0 !important;

        }

        img {
            max-width: 100%;
            max-height: 842px;

            @media (max-width:767px) {
                max-height: 220px;
            }
        }
    }

    figure.mob {
        display: none;

        @media (max-width:767px) {
            display: block;
        }
    }

    figure.desk {
        display: block;

        @media (max-width:767px) {
            display: none;
        }
    }

    figure.desk-2 {
        display: block;

        @media (max-width:767px) {
            display: none;
        }
    }

    .container {
        &:first-child {
            @media (max-width:767px) {
                padding: 0 !important;
            }
        }

        .row {
            @media (max-width:767px) {
                margin: 0 !important;
            }

            .col-lg-4 {
                @media (max-width:767px) {
                    padding: 0 !important;
                }
            }
        }
    }

    .container-fluid {

        @media (max-width:767px) {
            padding-top: 0 !important;
        }

    }
}


// How it works

.howitWorks {

    @media (max-width: 991px) {
        padding-top: 30px !important;
    }

    @media (max-width:767px) {
        padding-top: 30px !important;
    }

    h3 {
        color: $az-black;
        font-family: $az-kini-heading-three;
        font-size: $az-font-45;
        margin-top: 0px;
        text-align: left;
        line-height: 1;
        padding: 0 200px 0 0px;

        @media (max-width:1400px) {
            padding: 0 200px 0 0px;
        }

        @media (max-width:1199px) {
            padding: 0 85px 0 0px;
        }

        @media (max-width:991px) {
            padding: 0 0 15px;
            font-size: $az-font-45;
        }

        @media (max-width:767px) {
            font-size: $az-font-40;
            padding: 0 0 15px;
            text-align: left;
        }
    }

    p {
        color: $az-oh-primary;
        font-family: $az-heading-two;
        font-size: $az-font-17;
        margin: 0px;
        text-align: left;
        display: block;

        @media (max-width:991px) {
            font-size: $az-font-16;
            display: none;
        }

        @media (max-width:767px) {
            font-size: $az-font-14;
            display: none;
        }
    }

    .para {
        margin: 60px 0 0;
        display: block;

        @media (max-width:991px) {
            margin: 30px 0;
            display: none;
        }

        @media (max-width:767px) {
            margin: 30px 0;
            display: none;
        }
    }

    figure.mob {
        display: block;

        @media (max-width:991px) {
            display: none;
        }

        @media (max-width:767px) {
            display: none;
        }
    }

    .content {

        .block-md {
            @include block-md;

            figure.bgOne {
                background-image: url(../images/oh/howitworkimg-2.png);
                position: relative;

                &:after {
                    background-color: rgba(0, 0, 0, 0.3) !important;
                    position: absolute;
                    content: '';
                    width: 100% !important;
                    height: 100% !important;
                    top: 0 !important;
                    left: 0 !important;
                    transform: translate(0, 0px) !important;
                    bottom: 0 !important;
                    z-index: 0 !important;
                }

                @media (max-width:991px) {
                    margin-right: auto;
                    justify-content: flex-end;
                }

                @media (max-width:767px) {
                    margin-right: auto;
                    justify-content: flex-end;
                }

                h2 {
                    @media (max-width:991px) {
                        right: -72px;
                        color: $az-black;
                    }

                    @media (max-width:767px) {
                        right: -48px;
                    }
                }
            }

            figure.bgTwo {
                background-image: url(../images/oh/howitworkimg-3.png);
                position: relative;

                &:after {
                    background-color: rgba(0, 0, 0, 0.3) !important;
                    position: absolute;
                    content: '';
                    width: 100% !important;
                    height: 100% !important;
                    top: 0 !important;
                    left: 0 !important;
                    transform: translate(0, 0px) !important;
                    bottom: 0 !important;
                    z-index: 0 !important;
                }

                @media (max-width:991px) {
                    margin-left: auto;
                    justify-content: flex-start;
                }

                @media (max-width:767px) {
                    margin-left: auto;
                    justify-content: flex-start;
                }

                h2 {

                    @media (max-width:991px) {
                        left: -180px;
                        color: $az-black;
                    }

                    @media (max-width:767px) {
                        left: -90px;
                    }
                }
            }

            figure.bgThree {
                background-image: url(../images/kini/howitworkimg-4.png);
                position: relative;

                &:after {
                    background-color: rgba(0, 0, 0, 0.3) !important;
                    position: absolute;
                    content: '';
                    width: 100% !important;
                    height: 100% !important;
                    top: 0 !important;
                    left: 0 !important;
                    transform: translate(0, 0px) !important;
                    bottom: 0 !important;
                    z-index: 0 !important;
                }

                @media (max-width:991px) {
                    margin-right: auto;
                    justify-content: flex-end;
                }

                @media (max-width:767px) {
                    margin-right: auto;
                    justify-content: flex-end;
                }

                h2 {

                    @media (max-width:991px) {
                        right: -86px;
                        color: $az-black;
                    }

                    @media (max-width:767px) {
                        right: -48px;
                    }
                }
            }



        }

    }

    .mg-xs-0 {
        @media (max-width:767px) {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
}

.unitmodal {
    .modal-dialog {
        max-width: 1024px;
        position: relative;

        .welcomeClose {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}

.Florplan-box {

    .modal-dialog {
        max-width: 1024px;
        position: relative;

        .welcomeClose {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}

.MuiMenu-paper {
    max-width: 11.5% !important;
    width: 15% !important;
    margin-left: 0%;
}

.nav-text {
    font-size: $az-font-20;
    color: $az-white !important;
    font-family: $az-kini-heading-three;
    text-transform: capitalize;
    text-decoration: none;
    text-align: left;
    padding: 10px 35px !important;
}

.pointer {
    cursor: pointer !important;
}

.reservationBook {
    z-index: 9999 !important;
    padding-right: 0 !important;

    h1 {
        @include heading1;
        color: $az-oh-primary;
        font-size: $az-font-40;
    }
    h4 {
        @include heading4;
        text-align: left;
        margin-bottom: 0px;
        font-size: $az-font-32;

        @media(max-width: 991px) {
            font-size: $az-font-24;
            text-align: center;
            margin: auto;
        }
        @media(max-width: 767px) {
            font-size: $az-font-24;
            text-align: center;
            margin: auto;
        }
    }


    button {

        @include OHprimaybutton-sm;
        // text-transform: uppercase;
        font-size: $az-font-22;
        min-width: 100%;
       
        &:hover,
        &:focus {
            background-color: $az-black;
        }

    }

    .MuiAutocomplete-hasPopupIcon {
        width: 100%;

        .MuiAutocomplete-endAdornment {
            display: flex;
            align-items: center;

            button.MuiIconButton-sizeMedium {
                background: none;
                padding: 0;
                color: $az-black;
                min-width: auto;
                display: block;
            }
        }

    }

    label.Mui-disabled {
        color: rgba(0, 0, 0, 0.38);
        top: 3px !important;
        left: 7px !important;
        margin-left: 8px !important;
        transform: translate(0px, 0px) scale(0.75) !important;
    }

    @include Multiselect;

    label {
        top: 17px !important;
        left: 15px;
        transform: inherit;
        color: $az-gray !important;
        font-size: $az-font-16;
        font-weight: normal !important;
    }

    legend {
        margin-left: inherit !important;
        margin-right: inherit !important;
        max-width: 0.01px !important;
        transition: inherit;

        span {
            font-weight: normal !important;
        }
    }

    // label.MuiFormLabel-filled {
    //     top: 3px !important;
    //     transform: inherit;
    //     left: 7px;
    //     margin-left: 8px;
    //     font-size: $az-font-12;
    // }

    label.Mui-focused {
        top: 3px !important;
        transform: inherit;
        left: 7px;
        margin-left: 8px;
    }

    .filterMultiselect {
        margin: 0;
    }

    .MuiSelect-select {
        padding: 16.5px 14px !important;

    }

    .MuiOutlinedInput-input {
        text-align: left;
        padding: 17px 17px 12px !important;
        font-family: $az-heading-two;
        font-size: $az-font-18;
    }

    .MuiOutlinedInput-root {
        padding: inherit !important;

        &:first-child {
            fieldset {
                border-bottom: transparent;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }

        &:last-child {
            fieldset {
                border-bottom: transparent;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }

    }

    .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid $az-black !important;
            border-radius: 12px;
        }
    }

    .MuiAutocomplete-hasPopupIcon {

        &:first-child {
            fieldset {
                border-bottom: 1px solid $az-border-color;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }

        &:last-child {
            fieldset {
                border-bottom: transparent;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }

    }

    .MuiFormControl-fullWidth {

        &:first-child {
            fieldset {
                border-bottom: 1px solid $az-border-color;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }

        &:last-child {
            fieldset {
                border-bottom: transparent;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }

    }

    .countryphone {
        // border-radius: 12px;
        // border: 1px solid $az-border-color;

        label{
            font-weight: normal !important;
            font-family: $az-heading-para!important;
        }

        p.Mui-error{
            font-size: $az-font-14 !important ;
            font-family: $az-heading-two !important;
        }

        .MuiInputBase-formControl{
            border: 1px solid $az-border-color;
            border-radius: 12px;
        }

        .MuiAutocomplete-hasPopupIcon {

            &:first-child {
                fieldset {
                    border-bottom: 1px solid $az-border-color;
                    border-top: none;
                    border-left: none;
                    border-right: none;


                }


            }

            &:last-child {
                fieldset {
                    border-bottom: 1px solid transparent;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                }

            }


        }

        &:hover {
            fieldset {
                border-bottom: 1px solid transparent !important;

            }

            .Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid $az-black !important;
                    border-radius: 12px;
                }
            }
        }

    }
    .booktourbasicInfo {
        border-radius: 12px;
        border: 1px solid $az-border-color;
        position: relative;

        label{
            font-weight: normal !important;
            font-family: $az-heading-two !important;
        }

        p.Mui-error{
            font-size: $az-font-14 !important ;
            font-family: $az-heading-two !important;
            position: absolute;
            bottom: 2px;
        }


        .MuiInputBase-formControl{
            // border: 1px solid $az-border-color;
            // border-radius: 12px;
        }

        .MuiAutocomplete-hasPopupIcon {

            &:first-child {
                fieldset {
                    // border-bottom: 1px solid $az-border-color;
                    border-top: none;
                    border-left: none;
                    border-right: none;


                }


            }

            &:last-child {
                fieldset {
                    // border-bottom: 1px solid transparent;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                }

            }


        }

        &:hover {
            fieldset {
                border-bottom: 1px solid transparent !important;

            }

            .Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid $az-black !important;
                    border-radius: 12px;
                }
            }
        }
        .MuiOutlinedInput-input {
            padding: 22px 17px 22px !important;
        }
    }
    .passwordInput {
        // border-radius: 12px;
        // border: 1px solid $az-border-color;

        label{
            font-weight: normal !important;
            font-family: $az-heading-two !important;
        }

        p.Mui-error{
            font-size: $az-font-14 !important ;
            font-family: $az-heading-two !important;
        }

        .MuiInputBase-formControl{
            border: 1px solid $az-border-color;
            border-radius: 12px;
        }

        .MuiAutocomplete-hasPopupIcon {

            &:first-child {
                fieldset {
                    border-bottom: 1px solid $az-border-color;
                    border-top: none;
                    border-left: none;
                    border-right: none;


                }


            }

            &:last-child {
                fieldset {
                    border-bottom: 1px solid transparent;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                }

            }


        }

        &:hover {
            fieldset {
                border-bottom: 1px solid transparent !important;

            }

            .Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid $az-black !important;
                    border-radius: 12px;
                }
            }
        }


        .borderInput {
            border-radius: 0;
            border: none;
        }
    }
    .Dob {

        position: relative;

        input {
            border-radius: 12px;
        }

        .ant-picker {
            border-radius: 12px;
            padding: 20px 14px 15px !important;
            font-family: $az-heading-two;
            font-size: $az-font-16;

            &::before {
                position: absolute;
                content: 'Date of birth';
                top: 2px;
                left: 15px;
                opacity: 1;
                z-index: 1;
                font-size: $az-font-12;
                color: $az-gray;

            }

        }


    }

    .Isfoucsinput {

        position: relative;

        .ant-picker-focused {
            position: relative;


        }
    }

    .textfield {

        fieldset {
            border-bottom: 1px solid transparent !important;
            border-top: none;
            border-left: none;
            border-right: none;
        }
    }

    p {
        font-size: $az-font-16;
        text-align: left;
    }

    p.text-danger { 

        padding: 10px 15px 10px;
        font-size: $az-font-12;
    }

    .MuiOutlinedInput-input.Mui-disabled {
        background-color: #fbfbfb;
        opacity: 0.9;
    }

    .subhead {
        font-size: $az-font-20;
        font-family: $az-heading-two;
        color: $az-black;
        text-align: left;
        display: block;
        margin-bottom: 30px;
    }

    .requestaddedSection {
        border: 1px solid $az-border-color;
        // background:#fbfbfb;
        // border-bottom: 2px solid $az-black;
        position: -webkit-sticky;
        position: sticky;
        top: 190px;
        margin-top: 15px;
        margin-bottom: 30px;
        border-radius: 10px;
        min-width: 400px;
        max-width: 400px;

        
        @media(max-width: 1366px) {
            min-width: 400px;
            max-width: 400px;
        }

        @media(max-width: 1280px) {
            min-width: 400px;
            max-width: 400px;
        }

        @media(max-width: 1199px) {
            min-width: 400px;
            max-width: 400px;
        }


        @media(max-width: 1024px) {
            min-width: 400px;
            max-width: 400px;
        }

        @media(max-width: 991px) {
            border-radius: 0px;
            background: $az-white;
            border: none;
            min-width: 445px;
            max-width: 445px;
        }
        @media(max-width: 767px) {
            border-radius: 0px;
            background: $az-white;
            border: none;
            min-width: 445px;
            max-width: 445px;
        }

        @media(max-width: 650px) {
            border-radius: 0px;
            background: $az-white;
            border: none;
            min-width: 100%;
            max-width: 100%;
        }

        @media(max-width: 580px) {
            border-radius: 0px;
            background: $az-white;
            border: none;
            min-width: 100%;
            max-width: 100%;
        }

        figure {
            // position: relative;
            // max-width: 100%;
            // max-height: 230px;
            // min-height: 230px;
            // overflow: hidden;
            // text-align: center;
            margin: 0;
            // max-width: 100%;
            // max-height: 296px;
            max-height: 318px;
            border-radius: 10px 10px 0 0;
            overflow: hidden;
            position: relative;
            // border: 1px solid #72818e33;
            background: #fcfcfc;
            // min-height: 296px;
            text-align: center;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            min-height: 318px;
            display: block;

            @media(max-width:1199px) {
               border-bottom-right-radius: 0;
               border-bottom-left-radius: 0;
                max-height: 266px;
            }

            @media(max-width:1023px) {
               border-bottom-right-radius: 0;
               border-bottom-left-radius: 0;
                max-height: 266px;
            }
            @media(max-width: 991px) {
                border-radius: 0px;
                max-height: 300px;
            }
           
            @media(max-width: 767px) {
                border-radius: 0px;
                max-height: 300px;
            }
    
            @media(max-width: 650px) {
                border-radius: 0px;
                max-height: 300px;
            }
    
            @media(max-width: 580px) {
                border-radius: 0px;
                max-height: 385px;
            }

            img {
                // margin: 0;
                // max-height: 230px !important;
                // max-width: 100%;
                // min-height: 230px !important;
                // object-fit: cover;
                // padding: 0;
                // width: 100%;requestaddedSection

                max-height: 318px;
                width: auto;
                min-height: 318px;

                @media(max-width: 991px) {
                    max-height: 300px;
                }

                @media(max-width: 767px) {
                    max-height: 300px;
                }
        
                @media(max-width: 650px) {
                    max-height: 300px;
                }
        
                @media(max-width: 580px) {
                    max-height: 385px;
                }
            }
        }

        .priceInfo {
            padding: 15px 15px 10px 15px ;
            text-align: left;

            @media (max-width:991px) {
                 padding: 15px 10px 10px 0px ;
            }

            @media (max-width:767px) {
                 padding: 15px 10px 10px 0px ;
            }
            .priceTop {

                h5 {
                    // font-family: $az-heading-two !important;
                    font-family:  $az-kini-heading-three !important;
                    font-size: $az-font-22;
                    color: $az-ligh-mid-black;
                    text-align: left;
                    // line-height: 1.5;
                    @media (max-width:1024px) {
                        font-size: $az-font-22;
                    }
                }

                h6 {
                    color: $az-black;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 18px;
                    font-family: $az-heading-two !important;
                    font-size: $az-font-18;
                    @media (max-width:1024px) {
                        font-size: $az-font-16;
                    }
                    b {
                        font-size: $az-font-18;
                        font-weight: normal;
                        @media (max-width:1024px) {
                            font-size: $az-font-16;
                        }
                    }
                }
            }

            .priceMiddle {
                border-top: 1px solid $az-light-gray;
                border-bottom: 1px solid $az-light-gray;
                padding: 15px 0;
                margin: 15px 0;

                span {
                font-size: $az-font-22;
                color: $az-oh-primary;
                font-family: $az-heading-para;
                padding: 0;
                text-align: left;
                font-weight: 400;
                }
                
                ul {
                    list-style: none;
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        display: flex;
                        justify-content: space-between;
                        margin: 10px 0;

                        span {
                            font-size: $az-font-16;
                            color: $az-oh-primary;
                            font-family: $az-heading-two;
                            font-weight: normal;
                            margin-bottom: 0;
                        }


                    }
                }

                @media(max-width: 991px) {
                    padding: 20px 15px 15px !important;
                }
                @media(max-width: 767px) {
                    padding: 20px 15px 15px !important;
                }
            }

            .priceBottom {
                ul {
                    list-style: none;
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        display: flex;
                        justify-content: space-between;
                        margin: 10px 0;

                        span {
                            font-size: $az-font-18;
                            color: $az-oh-primary;
                            font-family: $az-heading-para;
                            font-weight: normal !important;
                        }
                        span.final-price{
                            font-family: $az-heading-para;
                        }
                    }
                }

            }

            @media (max-width: 991px) {
                padding: 15px 0px 10px 0px;
             }
    
            @media (max-width: 767px) {
                padding: 15px 0px 10px 0px;
            }
        }
.promoCode {
    .MuiOutlinedInput-input {
        padding-right: 70px !important;
    }
    button {
        background: none;
        color: $az-black;
        font-size: $az-font-14;
        padding: 0;
        border-radius: 0 0 4px 4px;
        min-width: auto;
        font-family: $az-heading-para;
        margin-left: -70px;
        position: relative;
        z-index: 1;
        //cursor: pointer;

    }
    button:disabled,
    button[disabled] {
        color: $az-light-gray;
    }
}
    }

    .mobView {

        @media(max-width:767px) {
            flex-direction: column-reverse;
        }

        .requestSection {
            button {
                @media(max-width:767px) {
                    min-width: 100%;
                    max-width: 100%;
                }
            }

            strong {
                font-family: $az-heading-two;
                color: $az-gray;
                font-size: $az-font-16;
                font-weight: normal;
                text-align: left;
            }

            a {
                color: $az-black;
            }

            span {
                font-size: $az-font-18;
                color: $az-light-black;
                font-family: $az-heading-para;
                padding: 0;
                text-align: left;
                font-weight: 400;
                // display: block;
                white-space: break-spaces;
                word-break: break-word;

                p {
                    font-size: $az-font-18;
                    color: $az-light-black;
                    font-family: $az-heading-para;
                    padding: 0;
                    text-align: left;
                    font-weight: 400;
                }

                a {
                    color: $az-light-black;
                }
            }

            span.labelTitle {
                font-size: $az-font-22 !important;
                display: block !important;
                padding-left: 0px;
                font-family: $az-heading-para !important;
                
              
                @media (max-width: 991px) {
                    padding-top: 5px !important;
                }
                 @media (max-width: 767px) {
                     padding-top: 5px !important;
                }
            }

           .guestedit {

             margin-bottom: 30px;

             @media (max-width: 991px) {
                margin-bottom: 0px;
            }
             @media (max-width: 767px) {
                 margin-bottom: 0px;
            }

           }
            span.signsection {
                font-size: $az-font-22;
            }

            span.Mui-error {
                color: rgb(211, 47, 47) !important;
            }
        @media (max-width: 991px) {
            margin-bottom: 30px;
        }
         @media (max-width: 767px) {
             margin-bottom: 30px;
        }
       }
       .mobilView {
        display: none !important;
            @media (max-width: 991px) {
                display: flex !important;
            }
            @media (max-width: 767px) {
                display: flex !important;
          }
        }
        .deskView {
            display: flex !important;
            @media (max-width: 991px) {
                 display: none !important;
            }
            @media (max-width: 767px) {
                display: none !important;
          }
        }

        .headingpage {

            @media (max-width: 991px) {
                order: 1;
                background: $az-white;
                margin-bottom: 15px;
           }
           @media (max-width: 767px) {
                 order: 1;
                 background: $az-white;
                 margin-bottom: 15px;
             }
        }

        .yourtrip {
            
            @media (max-width: 991px) {
                padding: 0;
                order: 3;
           }
           @media (max-width: 767px) {
                padding: 0;
                order: 3;
             }

             .book_app_info {

                padding: 15px 0px 0px;

                @media (max-width: 991px) {
                     background: $az-white;
                     padding: 15px 10px 0px;
               }
               @media (max-width: 767px) {
                     background: $az-white;
                     padding: 15px 10px 0px;
                 }
               
                 .first {
                    @media (max-width: 991px) {
                        margin-bottom: 15px !important;
                        padding-left: 15px !important;
                        padding-right: 15px !important;
                  }
                  @media (max-width: 767px) {
                         margin-bottom: 15px !important;
                         padding-left: 15px !important;
                         padding-right: 15px !important;
                    }
                 }
             }


             .basInfo {
                @media (max-width: 991px) {
                    background: $az-white;
                    padding: 30px 15px 0px  !important;
              }
              @media (max-width: 767px) {
                    background: $az-white;
                    padding: 30px 15px 0px !important;
                }
             }
             
             .book_pay_info {

                @media (max-width: 991px) {
                    margin-top: 30px;
                    background: $az-white;
                    padding: 15px 20px 30px;
                    
              }
              @media (max-width: 767px) {
                margin-top: 30px;
                background: $az-white;
                padding: 15px 20px 30px;
                margin-left: 0;
                margin-right: 0;
               }

             }
        }
    }

    hr {
        @include divider;
        margin: 15px auto 40px;
    }

    .ResivationTitle {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        position: relative;
        left: 0px;

        @media (max-width:991px) {
            position: unset ;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        @media (max-width:767px) {
            position: unset ;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        a {
            margin-right: 30px;

            img {
                max-width: 15px;
            }
            @media (max-width:991px) {
               position: absolute;
            }
            @media (max-width:767px) {
               position: absolute;
            }

        }
    }

    .mobView {
        padding: 0 100px;

        @media(max-width: 1199px) {
            padding: 0px;
        }

        @media(max-width: 991px) {
            padding: 0px;
        }

        @media(max-width: 767px) {
            padding: 0px;
        }

        .priceDetail{

            @media(max-width: 991px) {
                padding: 0px;
                order: 2;
            }
    
            @media(max-width: 767px) {
                padding: 0px;
                order: 2;
            }

            .requestaddedSection {
                .priceInfo .priceTop, .priceInfo .priceMiddle, .priceInfo .priceBottom {
                    @media(max-width: 991px) {
                        margin-left: 15px;
                    }
            
                    @media(max-width: 767px) {
                           margin-right: 15px;
                    }
                }

                .priceInfo .priceMiddle {
                    @media(max-width: 991px) {
                        padding: 10px 15px 10px;
                    }
            
                    @media(max-width: 767px) {
                        padding: 10px 0 10px;
                    }
                    
                }
            }
        }
    }

    .yourtrip {
        .book_app_info.guestDetails {

            @media(max-width:991px) {
                padding:15px 15px 10px !important;
             }
             @media(max-width: 767px) {
                padding:15px 15px 10px !important;
             }
        }
    }
}

.ConfirmationBooking {

    h4 {
        @include heading4;
        text-align: center;
        margin-bottom: 50px
    }

    img {
        width: 50px;
        height: 50px;
    }

    ul {
        list-style: none;
        padding-left: 0;
        text-align: left;
        margin-bottom: 50px;

    }

    button {
        @include OHprimaybutton-sm;
            @media (max-width:767px) {
                font-size: $az-font-19 !important;
                padding: 7px 30px !important;
            }
    }

}

// Datepicker Ranger


// Responsive Date Ranger

.react-google-flight-datepicker .date.is-focus::after {
    border: 1px solid $az-black !important;
}

.react-google-flight-datepicker .date.is-focus {
    background: #fff !important;
}


@media (max-width: 768px) {
    .react-google-flight-datepicker .dialog-header .date-picker-input {
        height: 60px !important;
    }

    .react-google-flight-datepicker .date.is-focus {
        padding-top: 15px;
        height: 60px !important;
    }

    .react-google-flight-datepicker .date:first-child {
        height: 60px !important;
        padding-top: 15px;
        position: relative;
        font-family: $az-heading-two;
        font-size: $az-font-16;
    }

    .react-google-flight-datepicker .date:first-child::after {
        content: 'CHECK-IN' !important;
        position: absolute;
        left: 10px;
        width: auto;
        height: auto;
        top: 0px;
        background: no-repeat;
        text-align: left;
        font-family: $az-heading-para;
        color: $az-black;
        font-size: $az-font-12;
        padding-left: 0px;
        padding-top: 4px;


    }

    .react-google-flight-datepicker .is-focus:first-child::after {
        padding-left: 10px;
        padding-top: 4px;
        top: 0 !important;
    }

    .react-google-flight-datepicker .date:last-child {
        position: relative;
        font-family: $az-heading-two;
        padding-top: 15px;
    }

    .react-google-flight-datepicker .date:last-child::before {
        content: 'CHECKOUT';
        position: absolute;
        left: 10px;
        width: auto;
        height: auto;
        top: 4px;
        background: no-repeat;
        text-align: left;
        font-family: $az-heading-para;
        color: $az-black;
        font-size: $az-font-12;
    }

    .react-google-flight-datepicker .day.selected::after {
        background-color: $az-black !important;
    }

    .react-google-flight-datepicker .day.hovered {
        background-color: #eaeaea !important;
    }

    .react-google-flight-datepicker .day.selected.hovered {
        background: linear-gradient(90deg, #fff 50%, #eaeaea 50%) !important;

    }

    .react-google-flight-datepicker .day .background-day.last-day {
        background: linear-gradient(to left, #fff, #eaeaea) !important;

    }

    .react-google-flight-datepicker .day .background-day.first-day {
        background: linear-gradient(to right, #fff, #eaeaea) !important;

    }

    .react-google-flight-datepicker .day.hovered.end {
        background: linear-gradient(90deg, #eaeaea 50%, #fff 50%) !important;
    }

    .react-google-flight-datepicker .dialog-footer .submit-button {
        background: $az-black !important;
    }

    // .react-google-flight-datepicker .date:focus::after {
    //     border: 1px solid $az-black !important;
    // }
    .react-google-flight-datepicker .date:focus {
        background-color: $az-white !important;
    }

    // .react-google-flight-datepicker .date-picker-input {
    //     border: 1px solid #dadce0 !important;
    // }
    .strDetail .guest-availabity .guestfilterNew .react-google-flight-datepicker .date-picker-date-group .date:last-child {
        border-radius: 0px 12px 0 0;
        border-left: 0;
        margin-left: 0;
    }

    .strDetail .guest-availabity .guestfilterNew .react-google-flight-datepicker .date.is-focus:after {
        font-size: $az-font-14;
        padding-top: 10px;
    }

    .strDetail .guest-availabity .guestfilterNew .react-google-flight-datepicker .date:after {
        font-size: $az-font-14;
        padding-top: 10px;
    }

    .react-google-flight-datepicker .selected-date {
        font-size: $az-font-14;
    }
}

// End Responsive Date Ranger


//  End Datepicker Ranger

.DateModalbox {
    @include DateModalbox;
}

.guestModalbox {
    @include guestModalbox;

}

.Link {
    color: $az-black;
    text-decoration: underline;
    font-family: $az-heading-para;
    cursor: pointer;
}

.text-align-left {
    text-align: left !important;
}

.reservationBook .borderInput {
    border: 1px solid #72818e33;
    border-radius: 12px;
    overflow: hidden;
}

.marign-bottom {
    margin-bottom: 0px;

    @media(max-width:767px) {
        margin-bottom: 10px;
    }
}

.react-datepicker__input-container {
    .borderInput {

        &:focus {
            border: 1px solid #72818e33;
            outline: 1px solid $az-black;
        }
    }
}


.react-datepicker-popper {
    z-index: 3 !important;

    .react-datepicker {
        min-width: 316px;

        button.react-datepicker__navigation {
            background: none;
            padding: inherit;
            min-width: auto;
            top: 4px;

            .react-datepicker__navigation-icon {
                &:hover {
                    &::before {
                        border-color: $az-white !important;
                    }
                }

                &::before {
                    border-color: $az-white !important;
                }
            }
        }
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 38px;
        line-height: 38px;

    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--selected {
        background: #000;
        border-radius: 50%;
    }

    .react-datepicker__day:hover {
        border-radius: 50%;
    }

    .react-datepicker__header {
        background: $az-black;

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            color: $az-white !important;
        }

        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {

            color: $az-white !important;
            margin-top: 3px;
        }

        .react-datepicker__navigation-icon--previous::before,
        .react-datepicker__navigation-icon--next::before {
            border-color: $az-white !important;
        }

    }

    .react-datepicker__month-container {
        width: 100% !important;
    }

    .react-datepicker__day--keyboard-selected:hover {
        background-color: $az-black !important;
    }

    .react-datepicker__navigation-icon {
        top: 4px !important
    }


}

// .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
//     border-top-color: $az-black !important;
// }

// .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
//     border-bottom: none;
//     border-top-color: $az-black !important;
// }
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: $az-black !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: $az-black !important;
}

.react-datepicker__navigation-icon::before {
    color: $az-white;
}

.social-link-buttons {
    width: 100% !important;
    height: 76px !important;
    outline: none !important;
    cursor: pointer !important;
    border: 1px solid rgb(221, 221, 221) !important;
    border-radius: 12px !important;
    box-sizing: border-box !important;
    background: rgb(255, 255, 255) !important;
    margin-bottom: 16px !important;
}

.social-link-main-heding-div {
    color: inherit !important;
    font-size: 1em !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    margin: 0px !important;
    padding: 0px !important;
}

.social-link-main-heding-div .title {
    font-size: 32px !important;
    line-height: 30px !important;
    margin-top: 20px !important;
    margin-bottom: 0px !important;
    // font-weight: 600 !important;
    color: $az-light-black !important;
    display: block !important;
    font-family: $az-kini-heading-three !important;
    padding-right: 50px;
    @media (max-width:767px) {
        font-size: 26px !important;
    }
}

.social-link-div-1 {
    -webkit-box-align: center !important;
    display: flex !important;
    align-items: center !important;
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}

.social-link-div-1 .social-link-img {
    max-width: 100% !important;
    width: 64px !important;
    height: 64px !important;
    border-radius: 8px !important;
    display: inline-block !important;
    object-fit: cover !important;
    margin-right: 16px !important;
}

.social-link-div-1 .social-link-heading {
    font-size: 16px !important;
    line-height: 20px !important;
    color: rgb(34, 34, 34) !important;
    margin-top: -10px;
}

.requestSection {
    .react-tel-input.phoneInput {
        input {
            padding: 17px 48px 17px !important;
            height: auto;
            border: 1px solid #72818e33;
            border-radius: 12px;
            width: 100%;

            &:active,
            &:focus {
                border: 1px solid $az-black;
                outline: none;
                box-shadow: none;
            }

            &:disabled {
                background: #fbfbfb;
                color: #a6a6a6;
                border: none;
                font-family: $az-heading-two !important;
                font-size: $az-font-18;
            }

        }

        .flag-dropdown {
            border-radius: 12px 0 0 12px;
            border-right: 1px solid #72818e33;
            border-top: none;
            border-left: none;
            border-bottom: none;
            border-radius: 12px 0 0 12px;
            border-right: 1px solid #72818e33;
            left: 2px;
            height: 82%;
            top: 5px;
            background: none;

            .country-list {
                input {
                    padding: 5px 10px 5px !important;
                    border-radius: 3px;
                }

                li {
                    padding: 7px 20px !important;
                    text-align: left !important;
                    font-family: $az-heading-two !important;
                    font-size: $az-font-14 !important;
                    font-weight: normal !important;

                    span.country-name {
                        font-family: $az-heading-two !important;
                        font-size: $az-font-14 !important;
                        font-weight: normal !important;
                    }
                    span.dial-code {
                        font-family: $az-heading-two !important;
                        font-size: $az-font-14 !important;
                        font-weight: normal !important;
                    }
                }
            }
        }


    }


    .confirm-policy {
        .MuiFormGroup-root {
            width: 35px !important;
            margin-top: 0px ;
            .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
            .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
                color: $az-black;
            }

            @media(max-width: 991px) {
                margin-top: 0px ;
            }
    
            @media(max-width: 767px) {
                margin-top: 0px ;
            }
        }

        @media (max-width: 991px) {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
         }
     
         @media (max-width: 767px) {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
         }
    }
}

.error-message {
    color: #d32f2f;
    font-size: 14px !important;
    text-align: left;
    font-family: $az-heading-two;
    font-weight: normal !important;
    // line-height: 1.66;
    // letter-spacing: 0.03333em;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

#menu-furnished {
    .MuiMenu-paper {
        max-width: 15.5% !important;
    }
}

.filter {
    z-index: 0;
    position: relative;
}

.secodry-header {
    @include secodry-header;
    display: block;

    @media(max-width: 1280px) {
        display: block;
    }

    @media(max-width: 1199px) {
        display: none;
    }

    @media(max-width: 767px) {
        display: none;
    }
}

.mobileHeader {
    display: none;

    @media(max-width: 1280px) {
        display: none;
    }

    @media(max-width: 991px) {
        padding: 3px 5px 5px 15px !important;
        background: #ced6d7;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    @media(max-width: 767px) {
        padding: 3px 5px 5px 15px !important;
        background: #ced6d7;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    .navigationMenu {
        @include navigationMenu;
        display: none;

        @media(max-width: 1280px) {
            display: none;
        }

        @media(max-width: 1199px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            //  width: 100%;
        }

        @media(max-width: 767px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            //  width: 100%;
        }

        .headerRight {
            display: flex;
            justify-content: space-between;
            align-items: center;

            figure.user {
                max-height: 28px;
                min-height: 28px;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                width: 28px;
                background: $az-white;
                border-radius: 50%;
                position: relative;
                overflow: hidden;

                img {
                    max-height: 28px;
                    min-height: 28px;
                    width: 28px;
                }

                .msg {
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    line-height: 15px;
                    background: $az-kini-primary-two;
                    color: $az-white;
                    text-align: center;
                    font-size: $az-font-10;
                    font-family: $az-heading-two;
                    right: -6px;
                    top: -5px;
                    border-radius: 50%;
                }

            }

            button {
                border: none;
                box-shadow: none;
                outline: none;
                padding: 0;

                &:hover,
                &:focus {
                    border: none;
                    box-shadow: none;
                    outline: none;
                }

                .navbar-toggler-icon {
                    display: none;
                    background-image: url(../images/oh/menu-nav.svg);
                    background-repeat: no-repeat;
                }
            }

            ul {
                padding: 0;
                list-style: none;
                display: flex;
                margin: 0;
                align-items: center;

                @media(max-width: 991px) {
                    margin: 0px 10px 0 0;
                }

                @media(max-width: 767px) {
                    margin: 0px 10px 0 0;
                }

                li {
                    margin: 0 10px;

                    a {
                        font-family: $az-heading-two !important;
                        font-size: $az-font-16 !important;
                        height: 30px !important;
                        padding: 6px 15px !important;
                        text-transform: uppercase !important;
                        background: transparent;
                        border-radius: 6px;
                        transition: 00.2s;
                        text-transform: uppercase;
                        color: $az-white;
                        text-decoration: none;

                        &:hover,
                        &:active {
                            background: $az-kini-primary-two !important;
                            transition: 00.2s;
                            color: $az-white;
                        }
                    }

                    a.active {
                        font-family: $az-heading-two !important;
                        font-size: $az-font-14 !important;
                        height: 30px !important;
                        padding: 6px 15px !important;
                        text-transform: uppercase !important;
                        background: $az-kini-primary-two !important;
                        transition: 00.2s;
                        color: $az-white;
                    }
                }

                li.notification-m {
                    margin: 0 10px;

                    a {
                        font-family: $az-heading-two !important;
                        font-size: $az-font-14 !important;
                        height: 30px !important;
                        padding: 6px 0px !important;
                        text-transform: uppercase !important;
                        background: transparent;
                        border-radius: 6px;
                        transition: 00.2s;
                        text-transform: uppercase;
                        color: $az-ligh-black;
                        text-decoration: none;
                        position: relative;

                        &:hover,
                        &:active {
                            background: transparent !important;
                            transition: 00.2s;
                            color: $az-ligh-black;
                            border: none;
                            box-shadow: none;
                        }

                        span {
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            line-height: 18px;
                            background: #d23876;
                            color: #ffffff;
                            text-align: center;
                            font-size: 10px;
                            font-family: Lato-Regular;
                            right: -12px;
                            top: 0px;
                            border-radius: 50%;
                        }

                        img {
                            filter: invert(1) brightness(0.8);

                            @media(max-width: 991px) {
                                filter: inherit;
                            }

                            @media(max-width: 767px) {
                                filter: inherit;
                            }
                        }
                    }

                    a.active {
                        font-family: $az-heading-two !important;
                        font-size: $az-font-14 !important;
                        height: 30px !important;
                        padding: 6px 15px !important;
                        text-transform: uppercase !important;
                        background: transparent;
                        transition: 00.2s;
                        color: $az-ligh-black;

                        &:hover,
                        &:active {
                            background: transparent !important;
                            transition: 00.2s;
                            color: $az-ligh-black;
                            border: none;
                            box-shadow: none;
                        }
                    }
                }

                li.notification {
                    margin: 0 10px;

                    button {
                        font-family: $az-heading-two !important;
                        font-size: $az-font-14 !important;
                        height: 30px !important;
                        padding: 6px 0px !important;
                        text-transform: uppercase !important;
                        background: transparent;
                        border-radius: 6px;
                        transition: 00.2s;
                        text-transform: uppercase;
                        color: $az-ligh-black;
                        text-decoration: none;
                        position: relative;
                        border: none;
                        box-shadow: none;
                        margin-right: 0 !important;

                        &:hover,
                        &:active {
                            background: transparent !important;
                            transition: 00.2s;
                            color: $az-ligh-black;
                            border: none;
                            box-shadow: none;
                        }

                        span {
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            line-height: 18px;
                            background: #d23876;
                            color: #ffffff;
                            text-align: center;
                            font-size: 10px;
                            font-family: Lato-Regular;
                            right: -7px;
                            top: 0px;
                            border-radius: 50%;
                        }

                        img {
                            filter: invert(1) brightness(0.8);

                            @media(max-width: 991px) {
                                filter: inherit;
                            }

                            @media(max-width: 767px) {
                                filter: inherit;
                            }
                        }
                    }

                    button.active {
                        font-family: $az-heading-two !important;
                        font-size: $az-font-14 !important;
                        height: 30px !important;
                        padding: 6px 15px !important;
                        text-transform: uppercase !important;
                        background: transparent;
                        transition: 00.2s;
                        color: $az-ligh-black;
                        border: none;
                        box-shadow: none;

                        &:hover,
                        &:active {
                            background: transparent !important;
                            transition: 00.2s;
                            color: $az-ligh-black;
                            border: none;
                            box-shadow: none;
                        }

                    }
                }

            }
        }

    }

}


// Reservation List page style

.reservationListSection {
    margin-bottom: 100px;

    @media(max-width: 767px) {
        margin-bottom: 50px;

    }

    .reservationList {
        h1 {
            text-align: left;
            display: flex;
            align-items: center;

            a {
                display: flex;
                align-items: center;
                margin-top: 5px;
                margin-right: 15px;

                img {
                    max-width: 22px;
                }
            }
        }
    }

    .tabsNav {
        font-family: $az-heading-two;

        .nav-tabs {
            margin-bottom: 30px;

            @media(max-width: 767px) {
                margin-bottom: 0px;
            }

            .nav-item {

                button {
                    font-family: $az-heading-two;
                    color: $az-black !important;
                    border: none;
                    font-size: $az-font-20;
                    padding: 10px 30px;

                    @media(max-width: 767px) {
                        font-size: $az-font-18;
                        padding: 10px 30px;

                    }
                }

                button.active {
                    font-family: $az-heading-two;
                    border: none;
                    font-size: $az-font-20;
                    padding: 10px 30px;
                    background-color: $az-black ;
                    color: $az-white !important;

                    @media(max-width: 767px) {
                        font-size: $az-font-18;
                        padding: 10px 30px;

                    }
                }
            }
        }

        table {
            display: inline-table;

            @media(max-width: 767px) {
                display: none;

            }

            tbody {
                border-top: none;

                td {
                    padding: 15px 10px;
                    vertical-align: baseline;

                    button {
                        font-family: $az-kini-heading-three;
                        border: none;
                        color: $az-white;
                        border-radius: 4px;
                        padding: 4px 12px 3px;
                        text-decoration: none;
                        background: $az-black;
                    }

                    img {
                        max-width: 50px;
                        min-width: 50px;
                    }
                }
            }
        }

        .reservationListMobview {
            display: none;

            @media(max-width: 767px) {
                display: none;

                ul {
                    list-style: none;
                    padding-left: 0;

                    li {
                        button {
                            text-decoration: none;
                            color: #000000;
                            display: flex;
                            padding: 25px 0 15px;
                            border: 0;
                            background: none;
                            box-shadow: none;
                            border-bottom: 1px solid $az-border-color;

                            figure {
                                max-width: 80px;
                                min-height: 63px;
                                overflow: hidden;
                                object-fit: cover;
                                margin-bottom: 0;

                                img {
                                    max-width: 100%;
                                    height: 63px;
                                }
                            }

                            .content {
                                text-align: left;
                                font-family: $az-heading-two;
                                font-size: $az-font-14;
                                padding-left: 15px;
                                width: calc(100% - 80px);

                                h3 {
                                    font-family: $az-heading-para;
                                    font-size: $az-font-16;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                h4,
                                h6 {
                                    color: $az-gray;
                                    font-size: $az-font-14;
                                }
                            }

                            border-bottom: 1px solid $az-border-color;


                        }


                        &:last-child {
                            button {
                                border-bottom: none;
                            }
                        }

                    }
                }
            }

        }
    }
}

.reservationDetailmodalbox {

    // position: relative;
    .welcomeClose {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 1;
    }

    .reservationDetails {
        .header {
            figure {
                max-width: 100%;
                min-height: 332px;
                overflow: hidden;
                object-fit: cover;
                margin-bottom: 0;

                img {
                    max-width: 100%;
                    height: 332px;
                }
            }
        }

        .contents {
            padding: 30px 30px 0;
            width: 95%;
            margin: auto;
            background: #fff;
            position: relative;
            top: -70px;
            border-radius: 12px 12px 0 0;

            ul {
                list-style: none;
                font-size: $az-font-18;
                padding: 10px 0;

                li {
                    padding: 5px 0;

                    .clockIcon {
                        width: 19px;
                    }
                }
            }

            h3 {
                font-size: $az-font-22;
                font-family: $az-heading-para;
                margin-top: 30px;
                margin-bottom: 15px;
            }

            label {
                color: $az-gray;
                font-size: $az-font-14;
            }

            p {
                color: $az-black;
                font-size: $az-font-15;
            }

            @media(max-width:767px) {
                .col-md-6 {
                    width: 50%;
                    max-width: 50%;
                }
            }
        }

        button {
            font-family: $az-kini-heading-three;
            border: none;
            color: #ffffff;
            border-radius: 4px;
            padding: 4px 12px 6px;
            text-decoration: none;
            background: $az-black;
            font-size: $az-font-16;

            &:hover,
            &:focus,
            &:active {
                border: none;
                outline: none;
                box-shadow: none;
            }
        }
    }

    .modal-body {
        padding: 0 !important;
    }
}

// End Reservation List page style

// main filter
.globalFilter {
    .mainfilter {
        @include mainfilter;
        margin-top: -80px;

        .filtersection ul.nav-tabs {
            //  display: none;
            // visibility: hidden;
        }
    }
}


.braintree-sheet__content {
    .braintree-form__field-group {
        display: flex;
        width: 100%;
        flex-direction: column;

        .braintree-form__field-error {
            text-align: left;
        }

        .braintree-form__hosted-field {
            height: 57px !important;
            border: 1px solid #dfdfdf !important;
            border-radius: 12px;
            padding: 26px 14px 15px !important
        }

        .braintree-form__label {
            color: #606060 !important;
            font-size: $az-font-12;
            margin: 15px 15px -28px;
            padding: 0;
            text-align: left;
        }
    }
}

//  Message & notification style

.message .leftSection .listItem,
.message .middleSection .chatSection .chatList,
.addContacts .addContactsList .listSetction,
.showquickreplyModal .listSetction, .estimatePotential .modal-body {
    scrollbar-width: 3px;
    scrollbar-color: #333 #e9e9e9;
    -webkit-scrollbar-width: 3px;
    -moz-scrollbar-width: 3px;
}

.message .leftSection .listItem::-webkit-scrollbar,
.message .middleSection .chatSection .chatList::-webkit-scrollbar,
.addContacts .addContactsList .listSetction::-webkit-scrollbar,
.showquickreplyModal .listSetction::-webkit-scrollbar, .estimatePotential .modal-body::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}

.message .leftSection .listItem::-webkit-scrollbar-track,
.message .middleSection .chatSection .chatList::-webkit-scrollbar-track,
.addContacts .addContactsList .listSetction::-webkit-scrollbar-track,
.showquickreplyModal .listSetction::-webkit-scrollbar-track, .estimatePotential .modal-body::-webkit-scrollbar-track {
    background-clip: content-box;
    border: 0px solid transparent;
    width: 3px;
    background-color: #e9e9e9;
}

.message .leftSection .listItem::-webkit-scrollbar-thumb,
.message .middleSection .chatSection .chatList::-webkit-scrollbar-thumb,
.addContacts .addContactsList .listSetction::-webkit-scrollbar-thumb,
.showquickreplyModal .listSetction::-webkit-scrollbar-thumb, .estimatePotential .modal-body::-webkit-scrollbar-thumb {
    background-color: #333;
}

.message .leftSection .listItem::-webkit-scrollbar-thumb:hover,
.message .middleSection .chatSection .chatList::-webkit-scrollbar-thumb:hover,
.addContacts .addContactsList .listSetction::-webkit-scrollbar-thumb:hover,
.showquickreplyModal .listSetction::-webkit-scrollbar-thumb:hover, .estimatePotential .modal-body::-webkit-scrollbar-thumb:hover {
    background-color: #333;
}

.message .leftSection .listItem::-webkit-scrollbar-corner,
.message .leftSection .listItem::-webkit-scrollbar-track,

.message .middleSection .chatSection .chatList::-webkit-scrollbar-corner,
.message .middleSection .chatSection .chatList::-webkit-scrollbar-track,

.addContacts .addContactsList .listSetction:-webkit-scrollbar-corner,
.addContacts .addContactsList .listSetction:-webkit-scrollbar-track,

.showquickreplyModal .listSetction .modal-body::-webkit-scrollbar-corner,
.showquickreplyModal .listSetction .modal-body::-webkit-scrollbar-track {
    background-color: #e9e9e9;
    width: 3px;
}

.estimatePotential::-webkit-scrollbar-corner,
.estimatePotential::-webkit-scrollbar-track {
    background-color: #e9e9e9;
    width: 3px;
}



[class^="message-container"] {
    /* height: 200px; */
    overflow-y: scroll;
    width: 3px;
}

[class^="message-container"] div {
    /* height: 400px;
  width: 200%;
  padding: 4rem;
  background-image: linear-gradient(130deg, #c8c8c8 0%, #fefefe 100%); */
}

.message {

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 20px 10px;
    min-height: 60vh;

    @media(max-width: 767px) {
        flex-direction: column;
        padding: 0px 0px 60px;
    }

    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;

        @media(max-width: 1280px) {
            flex: 0 0 25%;
            max-width: 25%;
        }

        @media(max-width: 1199px) {
            flex: 0 0 25%;
            max-width: 25%;
        }

        @media(max-width: 1023px) {
            flex: 0 0 25%;
            max-width: 25%;
        }

        @media(max-width: 991px) {
            flex: 0 0 100%;
            max-width: 100%;
        }

        @media(max-width: 767px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .col-xxl-6 {
        flex: 0 0 75%;
        max-width: 75%;

        @media(max-width: 1280px) {
            flex: 0 0 75%;
            max-width: 75%;
        }

        @media(max-width: 1199px) {
            flex: 0 0 75%;
            max-width: 75%;
        }

        @media(max-width: 1023px) {
            flex: 0 0 75%;
            max-width: 75%;
        }

        @media(max-width: 991px) {
            flex: 0 0 100%;
            max-width: 100%;
        }

        @media(max-width: 767px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .leftSection {
        width: 100%;
        min-height: auto;

        .header {
            margin-bottom: 15px;
            position: relative;

            .searchSection {
                display: flex;
                position: relative;

                input {
                    font-size: $az-font-14;
                    font-family: $az-heading-two;
                    color: $az-black !important;
                    background: $az-light-bg !important;
                    border: none !important;
                    box-shadow: none !important;
                    padding-right: 42px;
                    border-radius: 4px !important;
                    height: 42px;

                    &:hover,
                    &:focus {
                        border: none !important;
                        box-shadow: none !important;
                    }

                }

                span {
                    width: 42px;
                    height: 42px;
                    display: block;
                    text-align: center;
                    line-height: 42px;
                    position: absolute;
                    right: 0;

                }
            }

            .listSetction {
                background: $az-light-bg !important;
                box-shadow: 0px 1px 2px #ddd !important;
                margin-top: 2px;
                position: absolute;
                top: 42px;
                width: 100%;

                ul {
                    list-style: none;
                    padding: 0;

                    li {
                        padding: 10px 15px;

                        a {
                            text-decoration: none;
                            color: $az-black;
                            display: flex !important;
                            justify-content: space-between;

                            figure {
                                margin: 0;
                                display: block;
                                width: 32px;
                                height: 32px;
                                // border: 2px solid #000;
                                border-radius: 50%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 100%;
                                }
                            }

                            .content {
                                width: calc(100% - 32px);
                                padding-left: 10px;
                                display: flex;
                                align-items: center;

                                b {
                                    font-family: $az-heading-para;
                                    font-weight: normal;
                                    font-size: $az-font-14;
                                    text-align: left;
                                }

                            }

                        }

                        &:hover,
                        &:active {
                            background: $az-white;
                        }
                    }
                }
            }
        }

        .listItem {
            margin-top: 2px;
            overflow-x: auto;
            max-height: 535px;

            ul {
                list-style: none;
                padding: 0;
                margin-right: 5px;

                li {
                    padding: 0;
                    border-bottom: 1px solid $az-border-color;

                    &:last-child {
                        border-bottom: none;
                    }

                    a {
                        text-decoration: none;
                        color: $az-black;
                        display: flex !important;
                        justify-content: space-between;
                        width: 100%;
                        padding: 10px;

                        figure {
                            margin: 0;
                            display: block;
                            width: 32px;
                            height: 32px;
                            // border: 2px solid #000;
                            border-radius: 50%;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 100%;
                            }
                        }

                        .content {
                            width: calc(100% - 32px);
                            padding-left: 10px;
                            display: flex;
                            align-items: baseline;
                            justify-content: space-between;

                            b {
                                font-family: $az-heading-two;
                                font-weight: normal;
                                font-size: $az-font-14;

                            }

                            span {
                                b {
                                    font-weight: normal;
                                    color: $az-black;
                                }

                                font-size: $az-font-13;
                                margin-bottom: 0px;
                                color: $az-gray;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                            }

                            p {
                                font-size: $az-font-13;
                                margin-bottom: 5px;
                                color: $az-gray;
                            }

                            i {
                                font-style: normal;
                                text-align: right;
                                font-family: $az-heading-two;
                                font-weight: normal;
                                font-size: $az-font-12;
                                color: $az-gray;
                            }

                            b.aleart {
                                background: $az-light-black;
                                color: $az-white;
                                text-align: center;
                                height: 18px;
                                width: 18px;
                                line-height: 18px;
                                border-radius: 50%;
                                font-size: $az-font-10;

                            }

                        }

                        &:hover,
                        &:active {
                            background: #f5f5f5;
                        }
                    }

                    a.active {
                        background: #f5f5f5;
                    }

                    &:hover,
                    &:active {
                        background: $az-white;
                    }
                }
            }
        }
    }

    .middleSection {
        width: 100%;
        min-height: auto;
        padding: 0 0 0 15px;
        border-left: 1px solid $az-eage-color;

        .header {
            margin-bottom: 0px;
            position: relative;

            @media(max-width:767px) {
                position: fixed;
                width: 100%;
                z-index: 1;
                background: #fff;
                top: 0;
                padding-top: 10px;
                left: 1px;
                padding-left: 0;
            }

            h6 {
                font-family: $az-heading-two;
                font-size: $az-font-18;
                color: $az-black;
                border-bottom: 1px solid $az-border-color;
                padding: 8px 0 12px;
                margin-bottom: 0;
                text-align: left;

                @media(max-width: 767px) {
                    padding: 0px 15px 12px;
                }
            }
        }

        .chatSection {
            min-height: 549px;
            position: relative;
            max-height: 549px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .status {
                font-family: $az-heading-two;
                font-size: $az-font-12;
                color: $az-gray;
                padding: 15px 0 5px;
                width: 100%;
                display: block;
                text-align: center;
            }

            .chatList {
                overflow-x: auto;
                max-height: 487px;

                @media(max-width: 767px) {
                    max-height: inherit;
                }

                .listItem {
                    margin-top: 2px;
                    max-height: 487px;

                    ul {
                        list-style: none;
                        padding: 0 10px 0 0;

                        li {
                            padding: 10px 0 10px 0;
                            border-bottom: 1px solid $az-border-color;

                            &:last-child {
                                border-bottom: none;
                            }

                            .chatContent {
                                text-decoration: none;
                                color: $az-black;
                                display: flex;
                                justify-content: space-between;
                                width: 85%;
                                margin: auto;

                                @media(max-width: 767px) {
                                    width: 100%;
                                }

                                figure {
                                    margin: 0;
                                    display: block;
                                    width: 32px;
                                    height: 32px;
                                    // border: 2px solid #000;
                                    border-radius: 50%;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        border-radius: 100%;
                                    }
                                }

                                .content {
                                    width: calc(100% - 32px);
                                    padding-left: 10px;
                                    display: flex;
                                    align-items: baseline;
                                    justify-content: space-between;

                                    .top {
                                        display: flex;
                                        align-items: baseline;
                                        justify-content: space-between;

                                        b {
                                            font-family: $az-heading-two;
                                            font-weight: normal;
                                            font-size: $az-font-15;

                                            i {
                                                font-style: normal;
                                                font-family: $az-heading-two;
                                                font-size: $az-font-12;
                                                color: $az-gray;
                                                border-left: 1px solid $az-border-color;
                                                padding-left: 5px;
                                                margin-left: 3px;
                                            }
                                        }

                                        .chatNav {
                                            display: none;

                                            button {
                                                background: none !important;
                                                box-shadow: none;
                                                padding: 0;
                                                line-height: 0;
                                                min-height: auto;

                                                &::after {
                                                    display: none;
                                                }
                                            }

                                            .dropdown-menu.show {
                                                border: 0;
                                                box-shadow: 0px 4px 10px 0px #dfdfdf;
                                                position: relative;
                                                margin-top: 30px !important;
                                                right: 0 !important;
                                                left: inherit !important;
                                                transform: inherit !important;

                                                .dropdown-item {
                                                    &:hover {
                                                        background: $az-black;
                                                        color: $az-white;
                                                    }

                                                }

                                                &::before {
                                                    position: absolute;
                                                    content: '';
                                                    border-top: 15px solid transparent;
                                                    border-right: 15px solid $az-white;
                                                    top: -6px;
                                                    right: 3px;
                                                    transform: rotate(225deg);
                                                    box-shadow: 4px 3px 5px 0px #e1e1e1;
                                                    z-index: -1;

                                                }
                                            }
                                        }
                                    }

                                    .chatmessage {
                                        background: $az-light-bg;
                                        padding: 5px 10px;
                                        margin-top: 2px;
                                        border-radius: 0 10px 10px 10px;
                                        text-align: left;

                                        span {
                                            font-size: $az-font-14;
                                            margin-bottom: 0px;
                                            color: $az-gray;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                        }

                                        p {
                                            font-size: $az-font-13;
                                            margin: 5px 0 0;
                                            color: $az-black;
                                            text-align: right;

                                            i {
                                                font-style: normal;
                                                padding-left: 5px;
                                                margin-left: 3px;
                                                border-left: 1px solid $az-border-color;
                                                color: $az-black;
                                            }
                                        }
                                    }

                                    i {
                                        font-style: normal;
                                        text-align: right;
                                        font-family: $az-heading-two;
                                        font-weight: normal;
                                        font-size: $az-font-12;
                                        color: $az-gray;
                                    }

                                    b.aleart {
                                        background: $az-light-black;
                                        color: $az-white;
                                        text-align: center;
                                        height: 18px;
                                        width: 18px;
                                        line-height: 18px;
                                        border-radius: 50%;
                                        font-size: $az-font-10;

                                    }

                                }

                            }

                        }
                    }
                }
            }

            .Chatbox {
                background: $az-light-bg;
                padding: 10px;
                display: flex;
                position: relative;
                bottom: 0;
                width: 100%;

                @media(max-width: 767px) {
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    left: 1px;
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 70px;
                    margin-right: 5px;

                    .fileUpload {
                        width: 22px;
                        margin: 0 5px 5px;

                        img {
                            width: 22px;
                        }
                    }

                    button {
                        border: none;
                        padding: 0;
                        margin: 0 6px;

                        img {
                            width: 32px;
                        }
                    }

                    button.emoji {
                        border: none;
                        padding: 0;
                        margin: 0 6px;

                        img {
                            width: 25px;
                        }
                    }

                }

                textarea {
                    width: calc(100% - 70px) !important;
                    height: 42px;
                    padding: 15px 15px 12px;
                    min-height: 45px;

                    &:hover,
                    &:focus,
                    &:visited,
                    &:active {
                        border: none;
                        box-shadow: none;
                        outline: none;
                    }
                }

                button.post {
                    background: $az-black;
                    border-radius: 50%;
                    width: 50px;
                    height: 48px;
                    margin-left: 15px;
                    padding: 0 5px 0 9px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover,
                    &:focus,
                    &:visited,
                    &:active {
                        border: none;
                        box-shadow: none;
                        outline: none;
                    }

                    img {
                        width: 20px;
                    }
                }

                input[type=file] {
                    width: calc(100% - 70px) !important;
                    height: auto;
                }
            }
        }
    }

    .rightSection {
        width: 100%;
        min-height: auto;

        .reservationInfo {
            border-bottom: 1px solid $az-eage-color;
            padding-bottom: 20px;
            margin-bottom: 20px;

            .header {
                margin-bottom: 20px;
                position: relative;

                h6 {
                    font-family: $az-heading-two;
                    font-size: $az-font-18;
                    color: $az-black;
                    padding: 8px 0 0px;
                    margin-bottom: 0;
                }


            }

            .mainContent {
                text-decoration: none;
                color: $az-black;
                display: flex;
                justify-content: space-between;
                width: 100%;

                figure {
                    margin: 0;
                    display: block;
                    width: 32px;
                    height: 32px;
                    // border: 2px solid #000;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100%;
                    }
                }

                .content {
                    width: calc(100% - 32px);
                    padding-left: 10px;
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;

                    .top {
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;

                        b {
                            font-family: $az-heading-two;
                            font-weight: normal;
                            font-size: $az-font-15;

                        }
                    }

                    .info {
                        padding: 0px;
                        margin-top: 0px;

                        span {
                            font-size: $az-font-14;
                            margin-bottom: 0px;
                            color: $az-gray;
                            display: block;
                        }

                        p {
                            font-size: $az-font-13;
                            margin: 0;
                            color: $az-black;
                            text-align: right;

                            i {
                                font-style: normal;
                                padding-left: 5px;
                                margin-left: 3px;
                                border-left: 1px solid $az-border-color;
                                color: $az-black;
                            }
                        }

                    }
                }

            }

            .contentfooter {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                margin-top: 20px;

                p {
                    color: $az-gray;
                    margin: 0;
                }

                b {
                    font-size: 18px;
                    font-weight: normal;
                    color: #000;
                }
            }
        }

        .guestpaid,
        .otherinfo {
            border-bottom: 1px solid $az-eage-color;
            padding-bottom: 0px;
            margin-bottom: 20px;

            .header {
                margin-bottom: 13px;
                position: relative;

                h6 {
                    font-family: $az-heading-two;
                    font-size: $az-font-18;
                    color: $az-black;
                    padding: 0px;
                    margin-bottom: 0;
                }

            }

            .mainContent {
                text-decoration: none;
                color: $az-black;
                display: flex;
                justify-content: space-between;
                width: 100%;

                ul {
                    padding: 0;
                    list-style: none;
                    display: block;
                    width: 100%;

                    li {
                        display: flex;
                        justify-content: space-between;
                        margin: 7px 0;

                        i {
                            font-style: normal;
                            padding-left: 0px;
                            margin-left: 0px;
                            color: $az-gray;
                            font-size: $az-font-14;
                        }

                        b {
                            font-family: $az-heading-two;
                            font-weight: normal;
                            font-size: $az-font-14;
                            color: $az-black;
                        }
                    }
                }
            }
        }

        .otherinfo {
            border-bottom: 0;
            padding-bottom: 0px;
            margin-bottom: 0px;
        }
    }

}

// Modalbox messages
.addContacts {
    .modal-content {
        min-height: 400px;

        .addContactsList {
            .header {
                margin-bottom: 3px;
                position: relative;

                .searchSection {
                    display: flex;
                    position: relative;

                    input {
                        font-size: $az-font-14;
                        font-family: $az-heading-two;
                        color: $az-black !important;
                        background: $az-light-bg !important;
                        border: none !important;
                        box-shadow: none !important;
                        padding-right: 42px;
                        border-radius: 4px !important;
                        height: 42px;

                        &:hover,
                        &:focus {
                            border: none !important;
                            box-shadow: none !important;
                        }

                    }

                    span {
                        width: 42px;
                        height: 42px;
                        display: block;
                        text-align: center;
                        line-height: 42px;
                        position: absolute;
                        right: 0;

                        img {
                            margin-bottom: 0;
                            width: 18px;
                        }
                    }
                }

            }

            .listSetction {
                background: $az-light-bg !important;
                box-shadow: 0px 1px 2px #ddd !important;
                margin-top: 2px;
                position: relative;
                top: 0;
                width: 100%;
                overflow-x: auto;
                max-height: 307px;

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        padding: 10px 15px;

                        a {
                            text-decoration: none;
                            color: $az-black;
                            display: flex;

                            figure {
                                margin: 0;
                                display: block;
                                width: 32px;
                                height: 32px;
                                // border: 2px solid #000;
                                border-radius: 50%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 100%;
                                }
                            }

                            .content {
                                width: calc(100% - 32px);
                                padding-left: 10px;
                                display: flex;
                                align-items: center;

                                b {
                                    font-family: $az-heading-para;
                                    font-weight: normal;
                                    font-size: $az-font-14;
                                    display: block;
                                }

                            }

                        }

                        &:hover,
                        &:active {
                            background: $az-white;
                        }
                    }
                }
            }
        }

        .modal-body {
            padding: 24px !important;
        }
    }
}

.showquickreplyModal {

    @include modalbox;

    .modal-body {
        padding: 32px 2px 32px 24px !important;
    }

    .listSetction {
        max-height: 485px;
        overflow-x: auto;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                border-bottom: 1px solid $az-border-color;
                margin-bottom: 15px;
                padding-bottom: 25px;

                .content {
                    b {
                        font-family: $az-heading-para;
                        font-size: $az-font-16;
                        font-weight: normal;
                        text-align: left;
                        color: $az-black;
                        display: block;
                    }

                    p {
                        font-family: $az-heading-para;
                        font-size: $az-font-14;
                        text-align: left;
                        color: $az-black;
                        margin-bottom: 20px !important;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }

                    button {
                        min-width: 115px !important;
                        max-width: 115px !important;
                        margin-right: 0 !important;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

}

.chatDeskview {
    display: block !important;

    @media (max-width: 767px) {
        display: none !important;
    }
}

.chatMobileview {
    display: none;

    @media (max-width: 767px) {
        display: block;
        border: none !important;
        width: 100%;
        padding: 0 !important;
        border-radius: inherit !important;
        line-height: inherit !important;

    }
}

.chatmiddleMobileview {
    display: block !important;

    @media (max-width: 767px) {
        display: none !important;
    }
}

.offcanvas-header {
    @media (max-width: 767px) {
        padding: 0 !important;
    }
}

.offcanvas-header button.btn-close {
    @media (max-width: 767px) {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 2;
    }
}

//end  Message & notification style


//   profile page

.accountProfile {

    .left {
        width: 300px;
        padding: 30px 15px;

        // figure {
        //     max-width: 100%;
        //     height: 150px;
        //     overflow: hidden;
        //     object-fit: cover;
        //     background: #ddd;
        //     border-radius: 50%;
        //     width: 150px;
        //     margin: auto;

        //     img {
        //         max-width: 100%;
        //         height: 100%;
        //     }
        // }

        // a {
        //     color: $az-ligh-black;
        //     font-size: $az-font-16;
        //     margin-top: 15px;
        //     display: block;
        //     text-align: center;
        // }

        .profile-img-upload {
            @include fileUploadeditpage;
        }

        @media(max-width: 991px) {
            width: 100%;
        }

        @media(max-width: 767px) {
            width: 100%;
        }
    }

    .right {
        width: calc(100% - 300px);
        padding: 0 60px;

        @media(max-width: 991px) {
            width: 100%;
            padding: 0 15px;
        }

        @media(max-width: 767px) {
            width: 100%;
            padding: 0 15px;
        }

        h3 {
            font-size: $az-font-44;
            text-align: left;
            margin-bottom: 30px;
        }

        h5 {
            font-size: $az-font-22;
            text-align: left;
            font-family: $az-kini-heading-three;

            @media(max-width: 991px) {
                margin-top: 30px;
            }

            @media(max-width: 767px) {
                margin-top: 30px;
            }
        }

        span {
            font-size: $az-font-18;
            text-align: left;
            margin-top: 0px;
            color: $az-black;
            font-weight: 300;
        }

        p {
            font-size: $az-font-18;
            text-align: left;
        }

        label {
            span.MuiFormControlLabel-label {
                font-size: $az-font-16;
                text-align: left;
                margin-top: 3px;
                color: $az-black;
                font-family: $az-heading-two;

                @media (max-width:767px) {
                    margin-top: -2px;
                }
            }
        }

        button {
            border: none;
            background: $az-black;
            color: $az-white;
            font-size: $az-font-16;
            border-radius: 50px;
            padding: 10px 40px;
            font-family: $az-kini-heading-three;
            letter-spacing: 1px;

            &:hover,
            &:focus {
                border: none;
                box-shadow: none;
                outline: none;
            }
        }

        .MuiFilledInput-root {
            background: transparent;

            input {
                background: transparent;
                padding: 20px 14px 9px !important;
                font-size: $az-font-18 !important;
                font-weight: 400;
                font-family: $az-heading-two;
                color: $az-ligh-black;
                border-radius: 12px;
            }

            &::before,
            &::after {
                display: none;
            }
        }

        .MuiTextField-root {


            .MuiInputBase-formControl {
                border: 1px solid #72818e33;
                border-radius: 12px;
                font-size: $az-font-18 !important;
                font-weight: 400;
                font-family: $az-heading-two;
                color: $az-ligh-black;
            }

            .Mui-focused {
                border: 1px solid $az-black;
            }

            label {
                margin-top: 2px;
                font-size: $az-font-14 ;
                color: $az-ligh-black ;
                font-weight: bold;
                font-family: $az-heading-two;
            }

            label.Mui-focused {
                border: none;
                color: #606060 !important;

            }
        }

        .updateProfile {
            .mobile {

                @media(max-width: 991px) {
                    justify-content: center !important;
                    flex-direction: column-reverse;
                    margin: 40px 0 0px;
                }

                @media(max-width: 767px) {
                    justify-content: center !important;
                    flex-direction: column-reverse;
                    margin: 40px 0 0px;
                }
            }

            p.first {

                @media(max-width: 991px) {
                    text-align: center;
                }

                @media(max-width: 767px) {
                    text-align: center;
                }
            }

            hr {
                @media(max-width: 991px) {
                    margin: 30px 0 !important;
                }
            }
        }
    }

    .notification {

        @media(max-width: 991px) {
            margin-top: 30px !important;
        }

        @media(max-width: 767px) {
            margin-top: 0px !important;
        }
    }

    .change-pass {

        @media(max-width:991px) {
            flex-direction: column;
        }

        @media(max-width: 767px) {
            flex-direction: column;
        }

        h5 {
            margin-bottom: 0px;

            // @media(max-width: 991px) {
            //     margin-bottom: 30px !important;
            // }
        }
    }

    p.errorimg {

        text-align: center !important;
        margin: 0 !important;
        font-size: $az-font-14 !important;
        display: block;
        margin-top: 47px !important;
    }
}


// New mobiscroll calendar str detail page style

.mbsc-picker .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    color: $az-white !important;
}

.guest-availabity {
    .mbsc-page {
        background: none;
    }
}

.mobiscrollCalendar {
    border-right: 1px solid #72818e33;
    border-top: 1px solid #72818e33;
    border-left: 1px solid #72818e33;
    border-bottom: 1px solid #72818e33;
    border-radius: 12px 12px 0px 0px;
    background: none;
    position: relative;

    .mbsc-label {
        text-align: left !important;
        width: 100% !important;
        top: 19px !important;
        font-family: $az-heading-two !important;
        font-weight: normal !important;
        color: $az-kini-primary !important;
        left: 15px !important;
        font-size: $az-font-14 !important;
        font-weight: 600 !important;
    }

    ::placeholder {
        font-size: $az-font-18;
        color: $az-gray ;
        font-family: $az-heading-two;
    }

    span.mbsc-textfield-inner {
        width: 100% !important;
    }

    label.mbsc-textfield-wrapper {
        border: none;

        &::after,
        &::before {
            border: none;
        }


        .mbsc-ios.mbsc-textfield {
            height: 4.5em !important;
            font-size: $az-font-18;
            padding: 20px 15px 0;
            font-family: $az-heading-two;
        }
    }

    label.mbsc-hover {
        // position: relative;
        border: none;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }

    .mbsc-col-6 {

        &:first-child {
            border: 1px solid transparent;

            &:hover {
                border: 1px solid $az-black;
                // border-right: 1px solid transparent;
                border-radius: 12px 0px 0px 0px;
            }

            .mbsc-textfield-inner {
                position: relative;

                &::after {
                    content: '';
                    height: 100%;
                    width: 1px;
                    top: 0;
                    background: #72818e33;
                    position: absolute;
                    right: -1px;
                }

            }
        }

        &:last-child {
            border: 1px solid transparent;

            &:hover {
                border: 1px solid $az-black;
                // border-left: 1px solid transparent;
                border-radius: 0px 12px 0px 0px;
            }
        }
    }
}

.guest-availabity-mobileview {
    input {
        border: none;
        font-family: $az-heading-two;
        margin-left: -1px;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;

        &:hover,
        &:focus,
        &:active {
            border: none;
            box-shadow: none;
            outline: none;
            text-underline-offset: 4px;
            text-decoration: underline;
            text-decoration-style: solid;
            text-decoration-thickness: 1px;
        }
    }
}

@media (max-width: 767px) {

    .mbsc-ios.mbsc-range-control-wrapper {
        position: fixed;
        top: 23px;
        z-index: 2;
        background: #ffffff;
        width: 100%;
        padding-top: 30px;
    }
}

@media only screen and (max-device-width:767px) {
    .mbsc-ios.mbsc-range-control-wrapper {
        padding-top: 45px;
    }

}


// End New mobiscroll calendar str detail page style

.googleplaceInput {

    input {
        text-align: left;
        padding: 14px !important;
        font-family: $az-heading-two;
        font-size: $az-font-18;
        width: 100%;
        border: 1px solid #72818e33;
        border-radius: 12px;
        overflow: hidden;

        &::placeholder {
            color: #606060 !important;
        }
    }

    .place-dropdown {
        text-align: left;
        padding: 5px 15px;
        color: $az-black;

    }
}

.reservationDetailmodalbox {
    .modal-content {
        padding: 21px;

        .reservationDetails {
            h5 {
                font-family: $az-kini-heading-three;
                font-size: $az-font-26;
                margin-bottom: 30px;
            }

            h6 {
                font-size: $az-font-20;
                margin: 10px 0;
                color: $az-gray;

            }

            ul {
                li {
                    font-size: $az-font-16 ;
                }
            }

            button {
                padding: 4px 20px;
                background: $az-black;
                border: none;
                border-radius: 4px;
                color: $az-white;
                font-family: $az-kini-heading-three;
                padding: 7px 20px;
                text-decoration: none;
                border-radius: 20px;
                font-size: $az-font-16 ;
                letter-spacing: 1px;
            }
        }
    }

    span.welcomeClose {
        position: absolute;
        top: 20px;
        right: 20px;
    }

}

// Mobile app in formatting terms & privacy page
.f-sz-12 {
    font-size: 12px !important;
    margin-bottom: 15px;
    text-align: justify !important;
}

.search {
    .MuiOutlinedInput-root {
        width: fit-content !important;
        @media (max-width:767px) {
            width: 100% !important;
        }
    }
}

.termsandpolicy-m-b-5 {
    margin-bottom: 3px !important;
}

.p-bottom-5 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.user-conduct-p-bottm-5 {
    padding-bottom: 10px !important;
}


.pry_mob_head {
    font-size: 36px;
    font-family: $az-kini-heading-three;
    @media (max-width:767px) {
        font-size: 20px;
    }
}

.privacy_sec{
    .newcontentPrivacy{
        @media (max-width:767px) {
            margin: 15px 0 25px;

        }
        p{
            a{
                color: $az-light-black !important;
            }
           @media (max-width:767px) {
            font-size: 12px !important;
            margin-bottom: 15px !important;
            text-align: justify !important;

           }
        }
        ol{
            li{
                @media (max-width:767px) {
                    font-size: 12px !important;
                    padding: 0 !important;
                    text-align: justify !important;
                    margin-bottom: 15px !important;
                    list-style: none !important;
                    ul{
                        li{
                            list-style: disc !important;
                        }
                    }
                   }
                   span{
                    @media (max-width:767px) {
                        font-size: 12px !important;
                        margin:0 0 3px 0 !important;
                        text-align: justify !important;
                       }
                }
            }
        }
    }
    .howtocontactus{
        @media (max-width:767px) {
            margin-left: 0 !important;
            p{
                margin-bottom: 0 !important;
            }
        }
    }

}

.userConduct{
    li{
        @media (max-width:767px) {
            padding: 0 0 0 15px;
        }
    }
}

.privacy_sec{
    .reservationBook{
        padding-bottom: 20px;
        @media (max-width:767px) {
            padding-bottom: 0px;
        }
    }
}

.detail_page_ltr {
    .strDetail {
        @media (max-width: 767px) {
            padding: 30px 0 0px !important;
            display: block;
        }
    }

    .container {

        @media (max-width:991px) {
                padding: 0 15px !important;
         }
         @media (max-width:767px) {
                padding: 0 15px !important;
         }
    }
}

.guestviewmobile {
    position: relative;
    .guest-availabity-deskview {
        box-shadow: none !important;

        .pricebreakdownsection span {
            text-align: center;
            display: block;
            width: 100%;

        }
        .pricebreakdownsection ul {
            li {
                span {
                    &:first-child {
                        text-align: left !important;
                    }
                   

                    &:last-child {
                        text-align: right;
                    }
                }
                
            }
        }

       
    }
    
   button.guest-avaialbility-btn {
        position: relative;
        bottom: -60px;
        left: 0;
        transform: inherit;
        // position: fixed;
        // bottom: 120px;
        // left: 50%;
        // transform: translate(-50%, 0px);
    }

    .dropdown-menu {
        min-width: 100% !important;
    }
}

.mbsc-picker .mbsc-ios.mbsc-popup-body .mbsc-popup-buttons-bottom {

    @media(max-width: 991px) {
        border: none;
        background: none;
        padding-top: 5px;
    }  

    @media(max-width: 767px) {
        border: none;
        background: none;
        padding-top: 5px;
    }  

    button {
       
        @media(max-width: 991px) {
            background: #eeedeb;
            border-radius: 50%;
            padding: 1px;
            font-size: 17px;
            margin-right: 10px;
            height: auto;
            width: auto;
        }  
    
        @media(max-width: 767px) {
            background: #eeedeb;
            border-radius: 50%;
            padding: 1px;
            font-size: 17px;
            margin-right: 10px;
            height: auto;
            width: auto;
        }  
    }
}

.mbsc-calendar-controls {

    @media(max-width: 991px) {
        padding: 2px 30px;
    }  

    @media(max-width: 767px) {
        padding: 2px 30px;
    }  
}

.detail_page_ltr {
    .guest-availabity-deskview {
        box-shadow: none !important;

        @media(max-width: 991px) {
            margin-left: 0 !important;
        }  
    
        @media(max-width: 767px) {
            margin-left: 0 !important;
        }  
    }
}

.guestModalbox {

    .modal-dialog {
        @media(max-width: 767px) { 
            margin: 0;  
        }
    }
.modal-content {
    @media(max-width: 767px) {
        margin: auto;
        max-width: 100%;
        height: 100%;
        padding-top: 5px;
        border-radius: 0;
        border-color: #ffffff;
    }

    .dropmenuInputbox {
        @media(max-width: 767px) { 
            padding:  0 20px;
        }
    }
   
}

}
main.Brandmainpage {
.req_book_sec {
    .container {

        @media(max-width: 991px) {
            padding: 0px !important;
        }

        @media(max-width: 767px) {
            padding: 0px !important;
        }
    }

    @media(max-width:991px) {
        background: $az-light-white !important;
    }

    @media(max-width:767px) {
        background: $az-light-white !important;
    }
    .mobView {
    @media(max-width: 991px) {
       background: $az-light-white !important;
       padding-top: 0;
       max-width: 445px;
       margin: auto !important;
    }

    @media(max-width: 767px) {
       background: $az-light-white !important;
       padding-top: 0;
       max-width: 445px;
       margin: auto !important;
    }  

    @media(max-width: 650px) {
        background: $az-light-white !important;
        padding-top: 0;
        max-width: 445px;
        margin: auto !important;
     }  
     @media(max-width: 580px) {
        background: $az-light-white !important;
        padding-top: 0;
        max-width: 100%;
        margin: auto !important;
     }  
 }
}
}

.privacymodal {
    .modal-dialog {
       .privacy_sec {
        min-height: 400px;
        overflow: auto;
        max-height: 400px;
        padding-top: 0;
        padding-right: 5px;
       }
    }
   }
.str_property_info {
    .d-contents {
        display: contents;
    }
}

.guestDetails {
    border-top: 1px solid $az-border-color;
}

.StripeElement {
    .Input {
        padding: 17px 17px 12px !important;
    }
}

.tripsdetailsmob {
    display: none; 

    @media(max-width: 991px){
        display: block;
        padding: 0 15px !important;

        .confirm_booking {
            margin-bottom: 0 !important;
        }
    }

    @media(max-width: 767px){
        display: block;
        padding: 0 15px !important;
        .confirm_booking {
            margin-bottom: 0 !important;
        }
    }
}
.tripsdetailsdesk {
    display: block; 

    @media(max-width: 991px){
        display: none;
    }

    @media(max-width: 767px){
        display: none;
    }
}

.req_book_sec {
    .confirm_recpt {
        .priceTop {
            border-bottom: 1px solid $az-light-white;
            padding-bottom: 15px;
            margin-bottom: 15px;
            padding-top: 5px;
        }
    }
}

.getdirection {
  background: $az-black;
  font-family: $az-kini-heading-three;
  padding: 9px 20px;
  border-radius: 30px;
  font-size: $az-font-14 !important;
  color: $az-white !important;
  text-decoration: none;
  img {
    filter: invert(1);
    width: 12px;
    position: relative;
    top: -2px;
  }

}

b.getdirection {
    background: $az-black;
    font-family: $az-kini-heading-three;
    padding: 8px 20px;
    border-radius: 30px;
    font-size: $az-font-12 !important;
    color: $az-white !important;
    text-decoration: none;
    img {
      filter: invert(1);
      width: 12px;
      position: relative;
      top: -2px;
    }
  
  }

.requestSection {
    .showmore{
        background: $az-black;
        font-family: $az-kini-heading-three;
        padding: 9px 20px;
        border-radius: 30px;
        font-size: $az-font-14 !important;
        color: $az-white !important;
        text-decoration: none;
        border: none;
    }
}

.reservation-info {
    .addressmobilview {
        display: none;

        @media (max-width: 991px) {
            display: block;
            padding-bottom: 0 !important;
        }
        @media (max-width: 767px) {
            display: block;
            padding-bottom: 0 !important;
        }

        .gettingmobileview {

            @media (max-width: 991px) {
                margin-bottom: 0px !important;
            }
            @media (max-width: 767px) {
                margin-bottom: 0px !important;
            }
        }

        .unitmobileview {

            @media (max-width: 991px) {
                margin-top: 30px;
            }
            @media (max-width: 767px) {
                margin-top: 30px;
            }
        }
        
    }

    .addressdeskview {

        display: block;

        @media (max-width: 991px) {
            display: none;
        }
        @media (max-width: 767px) {
            display: none;
        }
    }

    .reservSection.checkdatemob {

        @media(max-width:991px) {
            padding: 0 !important;
            margin-top: 0px !important;
        }
        @media(max-width: 767px) {
            padding: 0 !important;
            margin-top: 0px !important;
        }
        ul {

            @media(max-width:991px) {
                padding: 0 !important;
            }
            @media(max-width: 767px) {
                padding: 0 !important;
            }

        }
    }
    .requestSection.reqestmobview {
        margin-top: 40px;
        @media(max-width:991px) {
           margin-top: 40px;
           background: $az-white;
        }
        @media(max-width: 767px) {
           margin-top: 40px;
           background: $az-white;
        }

    }
    .requestSection.getrecieptmobview {
        margin-top: 40px;
        @media(max-width:991px) {
           margin-top: 40px;
           background: $az-white;
        }
        @media(max-width: 767px) {
           margin-top: 40px;
           background: $az-white;
        }

    }
    .requestSection.cancelpolicymobview {
        margin-top: 40px;
        @media(max-width:991px) {
           margin-top: 40px;
           background: $az-white;
           padding: 10px 0 20px;
        }
        @media(max-width: 767px) {
           margin-top: 40px;
           background: $az-white;
           padding: 10px 0 20px;
        }

    }
    .reservSection.thingsknowmobview {
        @media(max-width:991px) {
            padding: 0 0px 25px;
         }
         @media(max-width: 767px) {
           padding: 0 0px 25px;
         }
    }
    .requestSection.host {
        .reserv_mid_heading {
            @media(max-width:991px) {
                padding-top: 20px !important;
              }
              @media(max-width: 767px) {
                padding-top: 20px !important;
              }
        }

    }

    .requestSection.doitlocal {
        padding-bottom: 30px;
        background: $az-white;
        .local_box_new {
            p {
                min-height: 70px;
                padding: 5px 0 15px 0;
                text-transform: inherit !important;
            }
        }

        .swiper-pagination {
            bottom: 0px;
            .swiper-pagination-bullet-active {
                background: $az-ligh-black;
            }
        }

        .do_live_footer {
            p{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

    }

    .requestSection.gettingaroundmobview {
        margin-top: 40px;
        padding-bottom: 20px;
        background: $az-white;
        @media(max-width:991px) {
           margin-top: 40px;
           background: $az-white;
        }
        @media(max-width: 767px) {
           margin-top: 40px;
           background: $az-white;
        }

    }
}

// Access your reservation page style
main.Brandmainpage.accessyourreservation {
    background-image:url(../images/oh-corporate-img/access-your-reservation.jpg) !important;
    background-position: top 0% right 0% !important;
    height: 100vh !important;
    min-height: max-content !important;
    width: 100% !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;

    .contact_form_box {
        background: $az-white;
        padding: 40px 40px 60px;
        border-radius: 12px;
        margin: 180px auto !important;

        button {
            min-width: 300px;
            @media (max-width:360px) {
                min-width: 100%;
            }
        }

        @media(max-width:1366px) {
            padding: 25px 40px 35px;
            margin: 30px auto !important;

        }
        @media(max-width:1280px) {
            padding: 25px 40px 35px;
            margin: 30px auto !important;
        }
        @media(max-width:1023px) {
            padding: 25px 40px 35px;
            margin: 30px auto !important;
        }
        @media(max-width:1199px) {
            padding: 25px 40px 35px;
            margin: 30px auto !important;
        }
        @media(max-width:991px) {
            padding: 25px 40px 35px;
            margin: 30px auto !important;
        }
        @media(max-width:767px) {
            padding: 25px 30px 35px;
            margin: 25% auto !important;
           

        }
    }
       
}
// End Access your reservation page style

.what-place-sec{
    @media (max-width:767px) {
        margin-top: 0 !important;
    }
}
// Get receipt page style

.getreceiptsection {
    .confirm_text {
        p {
            @media (max-width: 991px) {
                font-size: $az-font-14 !important;
            }
    
            @media (max-width: 767px) {
                font-size: $az-font-14 !important;
            }
    
        }
         }
    .reservation-data-mob {
        display: block;

        .back_go {
            display: flex;
            align-items: baseline;
            margin-bottom: 30px;

            @media (max-width: 991px) {
                margin-bottom: 0px;
            }
    
            @media (max-width: 767px) {
                margin-bottom: 0px;
            }
        }
      
    }

    .reservationBook .mobView {
        padding: 0 50px 0 120px !important;

        @media (max-width: 991px) {
            padding: 0px !important;
        }

        @media (max-width: 767px) {
            padding: 0px !important;
        }

        .priceInfo {

            &:first-child {
                .priceBottom {
                    border-bottom: 1px solid $az-light-white !important;
                }
            }

            &:last-child {
                .confirm_recpt {
                    position: relative !important;
                    top: 0 !important;
                    border: 1px solid $az-border-color;

                    @media (max-width: 991px) {
                        border-left: 0px;
                        border-right: 0px;
                    }
            
                    @media (max-width: 767px) {
                        border-left: 0px;
                        border-right: 0px;
                    }

                    .priceMiddle {
                        border-top: 0 !important;
                        padding: 0;
                        margin: 10px 0;
                    }
                    .priceBottom {
                        border-bottom:none;
                    }
                }
            }

            .priceMiddle {
                ul.payu{
                    display: flex;
                    justify-content: space-between;
                    li {
                        flex-direction: column;
                        strong {
                            font-family: $az-heading-two;
                            color: $az-gray;
                            font-size: $az-font-16;
                            font-weight: normal;
                            text-align: left;
                        }
                        strong.payuprice {
                            color: $az-oh-primary;
                        }
                        span {
                            font-size: $az-font-18;
                            color: $az-ligh-mid-black;
                            font-family: $az-heading-para;
                            padding: 0;
                            text-align: left;
                            font-weight: 400;
                            white-space: break-spaces;
                            word-break: break-word;
                        }
                    }
                   
                }
            }
        }
    }

    .receiptmainsection {
        padding:  0 30px;
        display: flex;
        justify-content: space-around;

        @media (max-width: 991px) {
            padding: 0px 15px 30px !important;
            flex-direction: column;
            background: $az-white;
        }

        @media (max-width: 767px) {
            padding: 0px 15px 30px !important;
            flex-direction: column;
            background: $az-white;
        }

   
    }

    .downloadbtn {
        @include OHprimaybutton-md;
        min-width: 250px;
        border-radius: 30px;
        font-size: $az-font-18;
        padding: 12px 20px 13px;
    }

    .confirm_booking .reservation-info ul li {

        &:last-child  {
            @media (max-width: 991px) {
              padding-left: 10px !important;
            }
    
            @media (max-width: 767px) {
              padding-left: 10px !important;
            }
        }
    }
    
}
// End Get receipt page style

.strtitledeskview {
    display: block !important; 

    @media (max-width: 991px) {
        display: none  !important;
    }
    @media (max-width: 767px) {
        display: none  !important;
    }

}

.strtitlemobview {
    display: none !important; 

    @media (max-width: 991px) {
        display: block !important;
    }
    @media (max-width: 767px) {
        display: block !important;
    }

}

.shareMobileview {
    display: none !important; 

    @media (max-width: 991px) {
        display: block !important;
    }
    @media (max-width: 767px) {
        display: block !important;
    }

}

.arrivalinst {
    padding-bottom: 20px !important;
    @media (max-width: 991px) {
       padding-bottom: 20px !important;
    }
    @media (max-width: 767px) {
       padding-bottom: 20px !important;
    }

}

.dateinputmobile {
    input {
        width: 100% !important;
        padding-left: 0px !important;
        @media (max-width: 991px) {
            padding-left: 0px !important;
         }
         @media (max-width: 767px) {
            padding-left: 0px !important;
         }

    }
}

.housemanulsection {
    .sectionone {
        &:last-child {
            margin-top: 30px !important;
        }
    }
}
.formatter {
    font-weight: normal;
    font-family: $az-heading-two;
    font-size: $az-font-16;

    .LinesEllipsis-unit {
        font-weight: normal !important;
        font-family: $az-heading-two !important;
        font-size: $az-font-16 !important;
    }
}
.welcomeClose.signmodalbox {
    @media(max-width:767px) {
        top: 20px !important;
    
    }
}

.signmodalfootersect {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;

    @media(max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
    .textColornormal {
        @include textnormal;
     }
     .textColorbold {
        @include textbold;

        @media(max-width: 767px) {
          margin-top: 10px;
        }
      }
}

.ltr_pro_desp {
    margin-bottom: 16px;
}

.unitmobileview2 {
        display: none;
    @media(max-width: 991px) {
        display: block;
        margin-bottom: 10px !important;
        padding-bottom: 5px;
      }

    @media(max-width: 767px) {
        display: block;
        margin-bottom: 10px !important;
        padding-bottom: 5px;
      }

}
.unitsectionmob {
    display: block;

    @media(max-width: 991px) {
        display: none;
      }

    @media(max-width: 767px) {
        display: none;
      }

}


.deskviewresver {
    display: block;
    @media(max-width: 991px) {
        display: none;
      }

    @media(max-width: 767px) {
        display: none;
      }
}

.newsectionchangemobilereservdetail {
    display: none;
    @media(max-width: 991px) {
        display: block;
        padding: 0 !important;

        .priceInfomainone {
            padding-left: 0px;
            background: $az-white;
            padding-bottom: 10px;
        }
        .priceInfomain {
            padding-left: 10px;
            background: $az-white;

            .requestSection.confirm_booking {
                .reservation-info {
                    .reservSection {
                        &:first-child {
                            margin-top: 0;
                            .reserv_mid_heading {
                                border-top:none !important;
                                margin-top: 0;
                                padding-top: 0;
                                h5 {
                                    padding-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .arrivalinst {
            margin-top: 30px !important;
        }
        .requestSection.host {
            margin-top: 30px !important;
        }
      }

    @media(max-width: 767px) {
        display: block;
        padding: 0 !important;

        .priceInfomainone {
            padding-left: 0px;
            background: $az-white;
            margin-top: 30px;
            padding-bottom: 10px;

            .priceInfo {
                @media(max-width: 991px) { 
                    padding: 15px 0px 10px 10px !important;
                }
            
                @media(max-width: 767px) { 
                        padding: 15px 0px 10px 10px !important;
                }
            }
        }
        .priceInfomain {
            padding-left: 0px;
            background: $az-white;

            .requestSection.confirm_booking {
                .reservation-info {
                    .reservSection {
                        &:first-child {
                            margin-top: 0;
                            .reserv_mid_heading {
                                border-top:none !important;
                                margin-top: 0;
                                padding-top: 0;
                                h5 {
                                    padding-top: 10px;
                                }
                            }
                        }

                        h5 {
                            padding-left: 10px;
                        }
                    }
                }
            }
           
            .priceMiddle {
                ul {
                    margin-bottom: 25px !important;
                }
              
            }

            .priceMiddle, .priceBottom {
                padding: 0 10px !important;
            }
        }

        .arrivalinst {
            margin-top: 30px !important;
        }
        .requestSection.host {
            margin-top: 30px !important;
        }
      }
.addressmobilview {
    .row.m-0 {
        .col-12 {
            @media(max-width: 991px) { 
                padding: 0 0 0 0!important;
            }
        
            @media(max-width: 767px) { 
                    padding:0 0 0 0!important;
            }
        }
       
    }
}
     
}
.req_book_sec.bgwhitecolor {

    @media(max-width: 991px) { 
        padding: 30px 0 0 !important;
    }

    @media(max-width: 767px) { 
            padding: 30px 0 0 !important;
    }
}

.LinesEllipsis {
    strong {
        font-weight: inherit !important;
        font-size: inherit !important;
        font-family: inherit !important;
        color: inherit !important;
    }
    
}

.formatter {
    strong {
        font-weight: inherit !important;
        font-size: initial !important;
        font-family: inherit !important;
        color: inherit !important;
    }
    
}

.requestSection.arrivalinst {

    p, strong {
        color: $az-oh-primary !important;
        font-family: $az-heading-two;
        font-size: $az-font-15;
        font-weight: revert !important;
        text-align: inherit !important;
        
    }
    strong { 
        span {
            display: inherit !important;
        }
    }
    span {
        // display: inherit !important;
            display: initial !important;
    }

    video{
        max-width: 100%;
    }

    jodit-media{
        width: 100% !important;
        height: 100% !important;
    }

    p {
        jodit-media{
            width: 100% !important;
            height: 100% !important;
        }
        video{
            max-width: 100%;
        }
        ul{
            font-size: unset !important;
            li{
                display: revert !important;
                list-style: inherit !important;
                font-size: unset !important;
                span{
                    font-size: inherit !important;
                    font-weight: inherit !important;
                    font-family: inherit !important;
                }
            }
        }
        ol{
            font-size: unset !important;
            li{
                display: revert !important;
                list-style: inherit !important;
                font-size: unset !important;
                span{
                    font-size: inherit !important;
                    font-weight: inherit !important;
                    font-family: inherit !important;
                }
            }
        }

        img {
            max-width: 100% !important;
        }
    }

    ul {
        padding-left: 31px;
        margin: 0;
        li {
            width: inherit !important;

            &::before {
                display: none;
            }
            &:last-child {
                padding-left: initial;
                margin-bottom: 0 !important;
                strong { 
                    span {
                        // display: inherit !important;
                            display: initial !important;
                    }
                }
                span {
                    // display: inherit !important;
                        display: initial !important;
                }
            }

            strong { 
                span {
                    // display: inherit !important;
                        display: initial !important;
                }
            }
            span {
                // display: inherit !important;
                    display: initial !important;
            }
            
        }
    }
}

.unitmodal.guestviewmobile {
    @media(max-width: 991px) { 
       height: 100vh !important;
    }

    @media(max-width: 767px) { 
        height: 100vh !important;
    }
}

.fade.modal-backdrop.show {
    transition: inherit !important;
}

.fade.unitmodal.guestviewmobile {
    transition: inherit !important;
}


// Resident-onboarding 

.bannerimg {
    width: 100%;
    height: 340px;
}

.content {
    padding: 30px 15px;
}

.appwrapper h3 {
        font-family: $az-kini-heading-three; font-size: 24px; color: #000; text-align: left; margin: 0; font-weight: revert;      
     }

  .appwrapper h4 {
    font-family: $az-heading-para; font-size: 16px; color: #333333; text-align: left;  margin: 15px 0 0;
}

.appwrapper p {
        font-family:$az-heading-two; font-size: 16px; color: #333; text-align: left; margin: 5px 0 15px;     
     }

.appwrapper ul li {
    font-family: $az-heading-two; font-size: 16px; color: #333; text-align: left; margin: 5px 0;
}


// End Resident-onboarding

// new ltr style

.table_compare_sec {
    ul {
        padding: 0;
        li{
            width: 33.33%;
            background: $az-white;
            padding: 30px;
        }
    }
}

.table_compare_sec {
    .homesharingmadeeasy {
        position: relative;
        z-index: 2;
        .block {
            background: $az-white;
            padding: 30px;
            border-radius: 8px;
            margin-bottom: 20px;
            min-height: 175px;
            justify-content: space-around;
            display: flex;
            flex-direction: column;
            align-items: inherit;

            span {
                font-family: $az-heading-para;
                font-size: $az-font-16;
            }
        }
    }
}

// new partnership page style

.newpartnercontent {
    .partnerships-platform {
        padding: 0px 0 100px;

        @media (max-width: 991px) {
            padding: 0px 0 10px;
        }

        @media (max-width: 767px) {
            padding: 0px 0 10px;
        }
      
    }
   .blockone, .blocktwo {
        padding-top: 100px;
        padding-bottom: 0px !important;

        .partnerships-cnt {
            padding: 0 60px 0 0;

            @media (max-width: 991px) {
                padding: 0 0px 0 0 !important;
            }

            @media (max-width: 767px) {
                padding: 0 0px 0 0 !important;
            }
          
            p {
                font-size: $az-font-18 !important;
            }
        } 
    }
    .blockone {

        .partnerships-cnt {
            padding: 0 60px 0 0 !important;

            @media (max-width: 991px) {
                padding: 0 0px 0 0 !important;
            }

            @media (max-width: 767px) {
                padding: 0 0px 0 0 !important;
            }
          
        } 

        .partner_cnt_box {
            padding-top: 0px !important;
        }

        @media (max-width: 991px) {
           padding-bottom: 0px !important;
        }

        @media (max-width: 767px) {
           padding-bottom: 0px !important;
        }
    }
    .blocktwo {

        @media (max-width: 991px) {
            padding-top: 0px !important;
         }
 
         @media (max-width: 767px) {
            padding-top: 0px !important;
         }
        .partnerships-cnt {
            padding: 0 !important;
        } 
    }
    .partnerships-img {
        img {
            min-height: 450px !important;
            max-height: 450px !important;
        }
    }
    .partnerships-img2 {
        img {
            min-height: 450px !important;
            max-height: 450px !important;
            object-position: 100% 42% !important;
        }
    }

    .partnerships_heading  {

        p {
            padding: 0 250px !important; 
            font-size: $az-font-22;
            text-align: center;
            margin-top: 10px;

            @media (max-width: 767px) {
                padding: 15px 15px 30px !important;
            }
        }
    }

    .partnerships_heading1  {
        
        @media (max-width: 991px) {
            padding: 0px 15px 30px !important;
        }

        @media (max-width: 767px) {
            padding: 0px 15px 30px !important;
        }

        span {
            padding: 0 250px !important; 

            @media (max-width: 767px) {
                padding: 30px 15px !important;
            }
        }
    }
    .partnerships_heading2 {
        background: $az-light-black;
        padding: 100px 0 80px;

        @media (max-width: 991px) {
            padding: 50px 0  15px !important;
        }

        @media (max-width: 767px) {
             padding: 50px 0  15px !important;
        }

        h2, span {
            color: $az-white !important;
            padding: 0 250px;

            @media (max-width: 767px) {
                padding: 0 15px;
            }
        }
        span {
            font-size: $az-font-22;
        }
        p {
            @media (max-width:991px) {
                margin-bottom: 0px;
                margin-top: 0;
            }
            @media (max-width: 767px) {
                margin-bottom: 0px;
                margin-top: 0;
            }
        }
    }

    .partnership_ltr_video_sec {
        padding: 60px 0 90px ;
      
        p {
            @media (max-width:991px) {
                margin-bottom: 0px;
                margin-top: 0;
                padding: 0px 0px 15px !important;
                font-size: $az-font-18 !important;
            }
            @media (max-width: 767px) {
                margin-bottom: 0px;
                margin-top: 0;
                padding: 0px 0px 15px !important;
                font-size: $az-font-18 !important;
            }
        }
    }
.appcontrols {
    padding-top: 60px;
    .appcontrolsimg {
        padding-top: 15px;
        text-align: center;
        figure {
            img {
                border-radius: 50%;
                min-height: 120px;
                max-height: 120px;
                min-width: 120px;
                max-width: 120px;
                object-fit: cover;
                object-position: 0px -40px;
                border: 5px solid #fafafa;
                // &:hover {
                //     box-shadow: -3px 2px 20px 6px #cdcdcd;
                // }
            }
        }
        span {
            padding: 0 30px;
            text-align: center;
            display: block;
            font-size: $az-font-14;
            font-family: $az-heading-para;
            font-family: $az-oh-primary;
        }
        @media (max-width: 991px) {
             padding-top: 40px;
        }
        @media (max-width: 767px) {
             padding-top: 40px;
        }

        }

   @media (max-width: 991px) {
        padding: 0px 0 60px;
    }
    @media (max-width: 767px) {
        padding: 0px 0 60px;
    }

    }
  
.casestudies {

    @media (max-width: 991px) {
        padding:  0px 0px 60px !important;
    }
    @media (max-width: 767px) {
        padding:  0px 0px 60px !important;
    }

    h2 {
        @include heading2;
        margin-bottom: 0 !important;
    }

    .brandBlockthree {
        .content {
            padding: 35px 45px 35px !important;
            // max-height: 420px;
            // min-height: 420px;
            h3 {
                margin-bottom: 15px;
                line-height: initial;
            }
            p {
                padding: 0px 0;
                margin: 5px 0px;
                font-size: $az-font-18 !important;

                @media (max-width: 991px) {
                    padding:  0px !important;
                }
                @media (max-width: 767px) {
                    padding:  0px !important;
                }
            }

            @media (max-width: 991px) {
                max-height: inherit !important;
             }
             @media (max-width: 767px) {
                max-height: inherit !important;
             }

            @media (max-width: 991px) {
            padding: 35px 15px 35px !important;
            }
            @media (max-width: 767px) {
                padding: 35px 15px 35px !important;
            }
        }
        figure {
            img {
                max-height: 600px;
            }
        }

      
    }
    .brandBlockfour {
        .content {
            padding: 35px 45px 35px !important;
            // max-height: 420px;
            // min-height: 420px;
            h3 {
                margin-bottom: 15px;
                line-height: initial;
            }
            p {
                padding: 0px 0;
                margin: 5px 0px;
                font-size: $az-font-18 !important;

                @media (max-width: 991px) {
                    padding:  0px !important;
                }
                @media (max-width: 767px) {
                    padding:  0px !important;
                }
            }

            @media (max-width: 991px) {
                padding: 35px 15px 35px !important;
            }
            @media (max-width: 767px) {
                padding: 35px 15px 35px !important;
            }
        }

        figure {
            img {
                max-height: 600px;
            }
        }
    }
}   


.brandParent {
h2 {
    @media (max-width: 991px) {
            padding: 60px 0 30px !important;
     }
     @media (max-width: 767px) {
            padding: 60px 0 30px !important;
     }

}
}
.howwork {
    @media (max-width: 991px) {
        padding: 0px !important;
    }
    @media (max-width: 767px) {
            padding: 0px !important;
    }
    p {
        @media (max-width: 991px) {
            padding: 0px 0 30px !important;
        }
        @media (max-width: 767px) {
                padding: 0px 0 30px !important;
        }
    }
  }

 .partnerships_heading {

    p {
        @media (max-width: 991px) {
            margin: 0 !important;
            padding: 0 !important;
            font-size: $az-font-18 !important;
        }
        @media (max-width: 767px) {
            margin: 0 !important;
            padding: 0 !important;
            font-size: $az-font-18 !important;
        }
    }
 }

}

.leasing, .detail_page_ltr {
    h2.oh_tbl_heading {
        @include heading2;
        color: $az-white !important;
        margin-bottom: 0 !important;
    }

    p {
        // padding: 0 250px !important; 
        // font-size: $az-font-22 !important;
        // text-align: center;

        @media (max-width: 767px) {
            // padding: 15px 15px 30px !important;
        }
    }
} 

.color-white {
    color: $az-white !important;
}

span.spinner_loader {
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin-top: 14px;
    margin-left: 2px;
}


.btn-agree{
    background: $az-oh-black;
    color: $az-white;
    text-decoration: none;
    border-radius: 50px;
    font-size: $az-font-25;
    display: block;
    margin: 30px auto 15px auto;
    padding: 7px 40px;
    font-family: $az-kini-heading-three;
    border: none;
    &:active,
    &:focus,
    &:hover {
        box-shadow: none;
        outline: none;
    }
    &:focus-visible{
        border-color:transparent !important;
        box-shadow: none !important
    }
    @media (max-width:767px) {
        font-size: $az-font-16;
    }
}
 
span.spinner_loader {
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin-top: 14px;
    margin-left: 2px;
}
.hand-cursor{
    cursor: pointer;
}
.blogColView{
    ul{
        font-size: 25px;
    }
    li{
        font-size: 15px;
    }
}
